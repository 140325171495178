import React, { Component } from "react";
import { Link } from "react-router-dom";

import EditableRowElement from "../EditableRowElement/EditableRowElement";
import axios from "axios";
import "./TestName.css";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import {APIURL} from "../../../Global";

function SubmitPopUp(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Data updated sucessfully!!</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
        

        <Link to="/testlist"><Button className='btn btn-primary btn-col' > Goto Test Name List </Button></Link>

       
      </Modal.Footer>
    </Modal>
  );
}

class TestName extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.match.params.id,
      test_name: "",
      synonyms: [],
      newTestName: "",
      submitmsg: "",
      deletemsg: "",
      modalShow:false,
      keys : {
        'h': false,
        'd': false,
        'u': false,
        'r': false,
        'l': false,
        'i': false,
        'a': false,
        'p': false,
        'v': false,
        't': false,
        'n': false
       }
    };
  }
  componentDidMount() {
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); /* REGEX To remove double quotes from tokenstring */

 
    axios
      .get(
        `${APIURL}/api/v1/staff/lab-tests/${this.state.id}/`,
        { headers: { Authorization: "Token "+ v2} }
      )
      .then((res) => {
        if (res.data.status === "success") {
          const test = res.data.data.test_name;
          const syn = res.data.data.synonyms;

          this.setState({ test_name: test });
          this.setState({ synonyms: syn });
        } else {

        }
      })
      .catch((err) => {

      });

        //////* Shotcuts ********/ 

  window.onkeydown =(e) => {
    
    this.state.keys[e.key]=true

    if(e.altKey && this.state.keys['h']){
      this.props.history.push('/hospitallist')
    }
    if(e.altKey && this.state.keys['d']){
      this.props.history.push('/doctorslist')
    }
    if(e.altKey && this.state.keys['u']){
      this.props.history.push('/search-hospitals')
    }
    if(e.altKey && this.state.keys['r']){
      this.props.history.push('/rejectedlist')
    }
    if(e.altKey && this.state.keys['l']){
      this.props.history.push('/reportlist')
    }
    if(e.altKey && this.state.keys['i']){
      this.props.history.push('/addsynonyms')
    }
    if(e.altKey && this.state.keys['a']){
      this.props.history.push('/failedlist')
    }
    if(e.altKey && this.state.keys['p']){
      this.props.history.push('/prescriptionlist')
    }
    if(e.altKey && this.state.keys['v']){
      this.props.history.push('/written-prescriptionlist')
    }
    if(e.altKey && this.state.keys['t']){
      this.props.history.push('/testlist')
    }
    if(e.altKey && this.state.keys['n']){
      this.props.history.push('/nutrition')
    }


  }
  window.onkeyup=(ev)=> {
    
    this.state.keys[ev.key]= false
  }

}

componentWillUnmount(){ 
  window.onkeydown=null
  window.onkeyup=null
}

  render() {
    const { id, test_name, synonyms, newTestName } = this.state;

    const handleAddRowElement = (e) => {
      e.preventDefault();
      let newSynonyms = synonyms;
      newSynonyms.push(newTestName);

      this.setState({ newTestName: "" });
    };

    const handleDeleteRow = (e) => {
      e.preventDefault();
      const tokenString = sessionStorage.getItem("usertoken");

      let v2 = tokenString.replace(
        /["]+/g,
        ""
      ); /* REGEX To remove double quotes from tokenstring */

    
      let ans = window.confirm(
        "Are you sure you want to delete the Test ID Row?"
      );
      if (ans === true) {
        
      

        axios
          .delete(`${APIURL}/api/v1/staff/lab-tests/${id}/`, {
            headers: {
              "content-type": "application/json",
              Accept: "application/json",
              Authorization: "Token "+ v2
            },

            data: { is_confirm: true },
          })
          .then((res) => {
            if (res.data.status === "success") {
              this.setState({ deletemsg: "success" });
              this.setState({modalShow: true});

            } else {
              this.setState({ deletemsg: "error" });
            }
          })
          .catch((err) => {
            this.setState({ deletemsg: "error" });
          });
      }
    };

    const handleInputChange = (e) => {
      e.preventDefault();
      this.setState({ newTestName: e.target.value });
    };
    const handleSaveAndSubmit = (e) => {
      e.preventDefault();
      e.preventDefault();

      const tokenString = sessionStorage.getItem("usertoken");

      let v2 = tokenString.replace(
        /["]+/g,
        ""
      ); /* REGEX To remove double quotes from tokenstring */

    
      const dataToSend = {
        synonyms: synonyms,
      };

      axios
        .put(
          `${APIURL}/api/v1/staff/lab-tests/${id}/`,
          dataToSend,
          {
            headers: {
              "content-type": "application/json",
              Accept: "application/json",
              Authorization: "Token "+ v2
            },
          }
        )
        .then((res) => {
          if (res.data.status === "success") {
            this.setState({ submitmsg: "success" });
            this.setState({modalShow: true});
          } else {
            this.setState({ submitmsg: "error" });
          }
        })
        .catch((err) => {
          this.setState({ submitmsg: "error" });
        });
    };

    let display = this.state.synonyms.map((syn, index) => {
      return (
        <>
          <EditableRowElement
            syn={syn}
            index={index}
            synonyms={this.state.synonyms}
          />
        </>
      );
    });

    return (
      <>
        {" "}
        <div className="container">
          <div className="dashboard-navigation">
            <div>
              {" "}
              <Link to="/dashboard">
                {" "}
                <i className="fas fa-home dasboard-icon"> </i>Dashboard{" "}
              </Link>
            </div>

            <div>
              {" "}
              <i className="fas fa-angle-double-right angle-icon"></i>{" "}
            </div>
            <div>
              {" "}
              <Link to="/testlist"> Test Names List </Link>{" "}
            </div>
            <div>
              {" "}
              <i className="fas fa-angle-double-right angle-icon"></i>{" "}
            </div>
            <div> Test </div>
          </div>
          <h2 className="title-of-report">
            Update Test Name Synonyms
          </h2>  
          <h4 className="title-of-report"> Test ID_{id} : {test_name} </h4>
          <div className="row-testid">
            <div className="each-row row">
              <div className="syn-headings-title syn-id">
                <h6> ID {id} </h6>
              </div>
              <div className="syn-headings-title">
                <h6 className="syn-title"> {test_name} </h6>
              </div>

              {display}
            </div>

            <div className="add-save-btns">
              <input
                className="add-elt form-control"
                type="text"
                maxLength="150"
                onChange={handleInputChange}
                value={this.state.newTestName}
                placeholder="Add Test Name "
              />
              <button
                type="button"
                onClick={handleAddRowElement}
                className="btn btn-primary btn-col add-elt-btn"
              >
                +
              </button>

              <button
                type="submit"
                onClick={handleDeleteRow}
                className="btn btn-danger "
              >
                {" "}
                Delete Row
              </button>
              <button
                type="submit"
                onClick={handleSaveAndSubmit}
                className="btn btn-primary btn-col "
              >
                Save & Submit
              </button>
            </div>
          </div>
        </div>

        


        {this.state.submitmsg === "success" ? (
<SubmitPopUp
        show={this.state.modalShow}
        onHide={() =>  this.setState({modalShow: false})}
      />
        ) : this.state.submitmsg === "error" ? (
          <h5 style={{ color: "red" }}>
            {" "}
            Error in Data submission.Please check again!{" "}
          </h5>
        ) : (
          ""
        )}
        {this.state.deletemsg === "success" ? (
          <div>
            <h5 style={{ color: "green" }}> Test Row deleted sucessfully !!</h5>{" "}
            <br />
            <br />
            <h6>
              Go back to <Link to="/testlist">Test Name List </Link>
            </h6>
          </div>
        ) : this.state.deletemsg === "error" ? (
          <h5 style={{ color: "red" }}>
            {" "}
            Error in Data deletion.Please check again!{" "}
          </h5>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default TestName;
