import React ,{Component} from 'react';
import Pagination from "react-js-pagination";
import "./pagination.css";
import './userlist.css';
import "./subscriberslist.css"
import axios from "axios";
import {APIURL} from '../../../Global'
import HospBdoNavbar from "../../HospBdoNavbar/HospBdoNavbar";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';

//let prev =['']

//let j=0

//const menu = 'a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z'.split(',')


const SuccessPopup =(props)=> {
  return(
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
           backdrop="static"
            keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
  
         <h4 className="text-success">Subscriber Enabled Successfully</h4>
           
          </Modal.Body>
          <Modal.Footer>
           
             <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
    
          </Modal.Footer>
        </Modal>
      )
}

const ErrorPopup =(props)=> {
  return(
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
           backdrop="static"
            keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
  
          <h4 className="text-danger">Error in Data Submission. Please try again!</h4>
           
          </Modal.Body>
          <Modal.Footer>
           
             <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
    
          </Modal.Footer>
        </Modal>
      )
}

class SuspendedList extends Component {
    constructor(props) {
        super(props)
        this.state = {
             patientdata : [],
             searchTerm : '',
             currentPage : 1,
             perPage : 10 ,
             loading:true ,
             successShow: false,
             errorShow: false,
             refresh: false,
             keys : {
              'u': false,
              'c': false,
              'n': false,
              'a': false,
              's': false,
              'q': false,
              'l': false
             }   
        }
    }
   

    
  handleBack=(e)=>{
    this.props.history.goBack();
}
    componentDidMount(){
      window.scrollTo(0, 0);
        const tokenString = sessionStorage.getItem("usertoken");
  
      let v2 = tokenString.replace(
        /["]+/g,
        ""
      ); /* REGEX To remove double quotes from tokenstring */


        axios
        .get(`${APIURL}/api/v1/hospital/care-programs/suspend/`, {
          headers: { Authorization: "Token "+ v2 },
        })
        .then((res) => {
          this.setState({loading : false});
          if (res.data.status === "success") {
            const data = res.data.data       
            this.setState({patientdata : data})
        }
        else{

        }
    }).catch((error)=>{

    })

     //////* Shotcuts ********/ 

     window.onkeydown =(e) => {
    
      this.state.keys[e.key]=true

      if(e.altKey && this.state.keys['u']){
        this.props.history.push('/directory')
      }
      if(e.altKey && this.state.keys['c']){
        this.props.history.push('/careplans-db')
      }
      if(e.altKey && this.state.keys['n']){
        this.props.history.push('/notifications')
      }
      if(e.altKey && this.state.keys['a']){
        this.props.history.push('/analytics')
      }
      if(e.altKey && this.state.keys['s']){
        this.props.history.push('/service-requests')
      }
      if(e.altKey && this.state.keys['q']){
        this.props.history.push('/qrcode')
      }
      if(e.altKey && this.state.keys['l']){
        this.props.history.push('/call-log')
      }
  
  
    }
    window.onkeyup=(ev)=> {
      
      this.state.keys[ev.key]= false
    }
}

componentWillUnmount(){ 
  window.onkeydown=null
  window.onkeyup=null
}

componentDidUpdate(prevProps, prevState){
  //window.scrollTo(0, 0);

  if(prevState.refresh !== this.state.refresh){
    const tokenString = sessionStorage.getItem("usertoken");

  let v2 = tokenString.replace(
    /["]+/g,
    ""
  ); /* REGEX To remove double quotes from tokenstring */


    axios
    .get(`${APIURL}/api/v1/hospital/care-programs/suspend/`, {
      headers: { Authorization: "Token "+ v2 },
    })
    .then((res) => {
      this.setState({loading : false});
      if (res.data.status === "success") {
        const data = res.data.data       
        this.setState({patientdata : data})
    }
    else{

    }
}).catch((error)=>{

    })
  }

}

    handleSearchChange = (event)=> {
      this.setState({currentPage:1})
        this.setState({searchTerm: event.target.value})
    }

    handlePageChange = (pageNumber)=> {
      this.setState({currentPage:pageNumber})
    }

    handleActivate =(id, careId)=> {
      const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '');

   let data= {
     suspend: false,
     patient_id: id,
     care_program_id: careId
   }

   axios.post(`${APIURL}/api/v1/hospital/care-programs/suspend/`,data , {headers: {

    'Content-Type': 'application/json',
      Authorization : 'Token '+str  }})

    .then(res=> {

      if(res.data.status==='success'){
        this.setState({successShow:true})
      }
      else{
        this.setState({errorShow:true})
      }
    })
    .catch((err)=> {
      this.setState({errorShow:true})
    })
  }

    render() {
          
        const{patientdata ,searchTerm, currentPage, perPage, successShow, errorShow, refresh}=this.state

        let data =   patientdata.filter((value)=>{
          if(this.state.searchTerm===''){
              return value
          } else if (value.full_name.toLowerCase().includes(this.state.searchTerm.toLowerCase())){
              return value
          }
      }).sort((a,b)=>{
          if(a.full_name.toLowerCase() < b.full_name.toLowerCase()) return -1;
          if(a.full_name.toLowerCase() > b.full_name.toLowerCase()) return 1;
          return 0;
      })
      


        const indexLast= currentPage * perPage;
        const indexFirst= indexLast - perPage;
        const current = data.slice(indexFirst ,indexLast);
        const total = data.length;

        const pageNumbers = []
        for(let i=0 ;i<= Math.ceil(total/perPage) ;i++){
          pageNumbers.push(i)
        }
        const totalPages=pageNumbers.length;
        return ( <div className="subscribers">
          <HospBdoNavbar />
          <br/>
          <div className="flex-head">
        
          <button className="btn btn-info topleft-single-btn" onClick={this.handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back</button> 
          <h2 className=" title-of-tasks title-mg">Suspended Subscribers list </h2><br/>
    </div> 
          
            <div className="search-patient-page directory-page">

                
       
 
          
            
        <div className="search-section">
  
       
            <div className="search-div">
              <input
                className="form-control search-input"
                type="text"
                maxLength="30"
                placeholder="Enter Name"
                value={searchTerm}
                onChange={this.handleSearchChange}
              />
              <button  className="btn btn-primary btn-col search-btn">
                {" "}
                <i className="fas fa-search"></i>{" "}
              </button>        </div>
          <div className="container search-results">  
          {
             this.state.loading===true ?
            <h6 className="text-muted"> Loading...</h6>
            : 
          current.length>0 ? current.map((value,index)=>{
            //let link_url=`/subscriber/${value.id}`;
              return(
                  <div key ={index} className='list-item'>               
                      <h5 className="each-list-link" id={value.id} key={value.id}>{(currentPage-1)*10 + index+1}.{value.full_name}</h5>  
                      <div className="details">
                        <p> Patient Id: {value.id} </p>
                        <p> Phn : {value.mobile_number} </p>
                      </div>  
                      <button onClick={()=>this.handleActivate(value.id, value.care_program_id)} style={{alignSelf:'center'}} className="btn btn-primary btn-col btn-small">Enable</button>                                                               
                  </div>
              )          
            })
            : <h6 className="text-muted">Suspended Subscribers list is empty!</h6>
          }
          
           
          </div>
              
            
            {totalPages > 1 ?
    <div className="pagn pagn-small">
     <br/>
    <br/>
            <Pagination
          activePage={currentPage}
          itemsCountPerPage={perPage}
          totalItemsCount={total}
          pageRangeDisplayed={totalPages}
          onChange={this.handlePageChange}
          disabledClass = "disabled-class" 
          />
    </div>
    : null}



{
        successShow ? 
        <SuccessPopup
        show={successShow}
        onHide={()=> {
          this.setState({successShow:false});
          this.setState({refresh: !refresh})
        }} /> : null
      } 

{
        errorShow ? 
        <ErrorPopup
        show={errorShow}
        onHide={()=> this.setState({errorShow:false})} /> : null
      } 
            
        </div>
        </div>
         </div>
        )
    }
}

export default SuspendedList