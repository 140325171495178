import React, { useState, createContext } from "react";

export const PharmacyLoginContext = createContext();

export const PharmacyLoginProvider = (props) => {
  const [pharmacyData,setPharmacyData] = useState({});
   
  return (
    <PharmacyLoginContext.Provider value={[pharmacyData,setPharmacyData]}>
      {props.children}
    </PharmacyLoginContext.Provider>
  );
};
