import React, {useState,useEffect} from "react";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import axios from "axios";
import {APIURL} from "../../Global";
import './inference.css'
import {ButtonGroup} from 'react-bootstrap';
import {ToggleButton} from 'react-bootstrap';
import {ToggleButtonGroup} from 'react-bootstrap';

import {Tooltip} from 'react-bootstrap';
import {OverlayTrigger} from 'react-bootstrap';

function Inference(props) {
const [id,setId]=useState(props.patientId);
const [summary,setSummary]=useState(props.summary)
const [selected,setSelected]=useState(1);
const [tableData3,setTableData3]=useState([]);
const [tableData4,setTableData4]=useState([]);
const [tableData5,setTableData5]=useState([]);
useEffect(()=>{
   
    const tokenString= sessionStorage.getItem('usertoken');
     
    let str= tokenString.replace(/["]+/g, '');  

  axios.get(`${APIURL}/api/v1/patient/event-based-inferences/?patient_id=${id}`, { headers: {
       
   "Authorization" : 'Token '+str  }})
  .then(res=>{
  let tableMed=res.data.medicine_event_inference;
 
 
   const tableDataMed= tableMed.map((item,index)=>{
    let newArray=[]
    
          if(item.bp_inference){
            let strArray= item.bp_inference.summary.split(/\r?\n/);
            let sys_summary=strArray[0];
            let dia_summary=strArray[1]

             newArray.push(
                {
            title: "Systolic BP",
            max: item.bp_inference.max_sys_bp,
            max_date:item.bp_inference.max_sys_date,
            min:item.bp_inference.min_sys_bp,
            min_date:item.bp_inference.min_sys_date,
            sd: item.bp_inference.sys_bp_sd,
            summary:sys_summary
        },
        {
            title: "Diastolic BP",
            max: item.bp_inference.max_dia_bp,
            max_date:item.bp_inference.max_dia_date,
            min:item.bp_inference.min_dia_bp,
            min_date:item.bp_inference.min_dia_date,
            sd: item.bp_inference.dia_bp_sd,
            summary:dia_summary
        }
                )
          }
          if(item.sugar_analyse){
             newArray.push(
                {
     title:"Sugar",
     max: item.sugar_analyse.max_sugar,
     max_date: item.sugar_analyse.max_sugar_date,
     min:item.sugar_analyse.min_sugar,
     min_date:item.sugar_analyse.min_sugar_date,
     sd:item.sugar_analyse.sugar_sd,
     summary:item.sugar_analyse.summary


  }
            )
         }
         if(item.pulse_analyse){
            newArray.push(
           {
        title:"Pulse",
        max:item.pulse_analyse.max_pulse,
        max_date:item.pulse_analyse.max_pulse_date,
        min:item.pulse_analyse.min_pulse,
         min_date:item.pulse_analyse.min_pulse_date,
         sd: item.pulse_analyse.pulse_sd,
         summary:item.pulse_analyse.summary
    })
         }

         if(item.cholesterol_inference){
            if(item.cholesterol_inference.hdl_cholesterol_data){
                let data=item.cholesterol_inference.hdl_cholesterol_data;
            newArray.push(
           {
        title:data.cholesterol_name,
        max:data.max_cholesterol,
        max_date:data.max_cholesterol_date,
        min:data.min_cholesterol,
         min_date:data.min_cholesterol_date,
         sd: "-",
         summary:data.summary
    })
        }
         if(item.cholesterol_inference.ldl_cholesterol_data){
                let data=item.cholesterol_inference.ldl_cholesterol_data;
            newArray.push(
           {
        title:data.cholesterol_name,
        max:data.max_cholesterol,
        max_date:data.max_cholesterol_date,
        min:data.min_cholesterol,
         min_date:data.min_cholesterol_date,
         sd: "-",
         summary:data.summary
    })
        }
         if(item.cholesterol_inference.serum_cholesterol_data){
                let data=item.cholesterol_inference.serum_cholesterol_data;
            newArray.push(
           {
        title:data.cholesterol_name,
        max:data.max_cholesterol,
        max_date:data.max_cholesterol_date,
        min:data.min_cholesterol,
         min_date:data.min_cholesterol_date,
         sd: "-",
         summary:data.summary
    })
        }
         if(item.cholesterol_inference.total_cholesterol_data){
                let data=item.cholesterol_inference.total_cholesterol_data;
            newArray.push(
           {
        title:data.cholesterol_name,
        max:data.max_cholesterol,
        max_date:data.max_cholesterol_date,
        min:data.min_cholesterol,
         min_date:data.min_cholesterol_date,
         sd: "-",
         summary:data.summary
    })
        }
         }
        
    return ({
        date:item.date,
        medicines:item.medicine,
        inferenceDatas:newArray
    })


   })


   let tableTarget=res.data.target_event_inference;
 
 
   const tableDataTarget= tableTarget.map((item,index)=>{
    let newArrayTarget=[]
    
          if(item.bp_inference){
            let strArray= item.bp_inference.summary.split(/\r?\n/);
            let sys_summary=strArray[0];
            let dia_summary=strArray[1]

             newArrayTarget.push(
                {
            title: "Systolic BP",
            max: item.bp_inference.max_sys_bp,
            max_date:item.bp_inference.max_sys_date,
            min:item.bp_inference.min_sys_bp,
            min_date:item.bp_inference.min_sys_date,
            sd: item.bp_inference.sys_bp_sd,
            summary:sys_summary
        },
        {
            title: "Diastolic BP",
            max: item.bp_inference.max_dia_bp,
            max_date:item.bp_inference.max_dia_date,
            min:item.bp_inference.min_dia_bp,
            min_date:item.bp_inference.min_dia_date,
            sd: item.bp_inference.dia_bp_sd,
            summary:dia_summary
        }
                )
          }
          if(item.sugar_analyse){
           
             newArrayTarget.push(
                {
     title:"Sugar",
     max: item.sugar_analyse.max_sugar,
     max_date: item.sugar_analyse.max_sugar_date,
     min:item.sugar_analyse.min_sugar,
     min_date:item.sugar_analyse.min_sugar_date,
     sd:item.sugar_analyse.sugar_sd,
     summary:item.sugar_analyse.summary


  }
            )
         }
         if(item.pulse_analyse){
        
            newArrayTarget.push(
           {
        title:"Pulse",
        max:item.pulse_analyse.max_pulse,
        max_date:item.pulse_analyse.max_pulse_date,
        min:item.pulse_analyse.min_pulse,
         min_date:item.pulse_analyse.min_pulse_date,
         sd: item.pulse_analyse.pulse_sd,
         summary:item.pulse_analyse.summary
    })
         }

         if(item.cholesterol_inference){
          
            if(item.cholesterol_inference.hdl_cholesterol_data){
                let data=item.cholesterol_inference.hdl_cholesterol_data;
            newArrayTarget.push(
           {
        title:data.cholesterol_name,
        max:data.max_cholesterol,
        max_date:data.max_cholesterol_date,
        min:data.min_cholesterol,
         min_date:data.min_cholesterol_date,
         sd: "-",
         summary:data.summary
    })
        }
         if(item.cholesterol_inference.ldl_cholesterol_data){
                let data=item.cholesterol_inference.ldl_cholesterol_data;
            newArrayTarget.push(
           {
        title:data.cholesterol_name,
        max:data.max_cholesterol,
        max_date:data.max_cholesterol_date,
        min:data.min_cholesterol,
         min_date:data.min_cholesterol_date,
         sd: "-",
         summary:data.summary
    })
        }
         if(item.cholesterol_inference.serum_cholesterol_data){
                let data=item.cholesterol_inference.serum_cholesterol_data;
            newArrayTarget.push(
           {
        title:data.cholesterol_name,
        max:data.max_cholesterol,
        max_date:data.max_cholesterol_date,
        min:data.min_cholesterol,
         min_date:data.min_cholesterol_date,
         sd: "-",
         summary:data.summary
    })
        }
         if(item.cholesterol_inference.total_cholesterol_data){
                let data=item.cholesterol_inference.total_cholesterol_data;
            newArrayTarget.push(
           {
        title:data.cholesterol_name,
        max:data.max_cholesterol,
        max_date:data.max_cholesterol_date,
        min:data.min_cholesterol,
         min_date:data.min_cholesterol_date,
         sd: "-",
         summary:data.summary
    })
        }
         }
        
    return ({
        date:item.date,
        targets:item.target,
        inferenceDatas:newArrayTarget
    })


   })

 let tableDiet=res.data.food_instruction_event_inference;
 
 
   const tableDataDiet= tableDiet.map((item,index)=>{
    let newArrayDiet=[]
    
          if(item.bp_inference){
            let strArray= item.bp_inference.summary.split(/\r?\n/);
            let sys_summary=strArray[0];
            let dia_summary=strArray[1]

             newArrayDiet.push(
                {
            title: "Systolic BP",
            max: item.bp_inference.max_sys_bp,
            max_date:item.bp_inference.max_sys_date,
            min:item.bp_inference.min_sys_bp,
            min_date:item.bp_inference.min_sys_date,
            sd: item.bp_inference.sys_bp_sd,
            summary:sys_summary
        },
        {
            title: "Diastolic BP",
            max: item.bp_inference.max_dia_bp,
            max_date:item.bp_inference.max_dia_date,
            min:item.bp_inference.min_dia_bp,
            min_date:item.bp_inference.min_dia_date,
            sd: item.bp_inference.dia_bp_sd,
            summary:dia_summary
        }
                )
          }
          if(item.sugar_analyse){
             newArrayDiet.push(
                {
     title:"Sugar",
     max: item.sugar_analyse.max_sugar,
     max_date: item.sugar_analyse.max_sugar_date,
     min:item.sugar_analyse.min_sugar,
     min_date:item.sugar_analyse.min_sugar_date,
     sd:item.sugar_analyse.sugar_sd,
     summary:item.sugar_analyse.summary


  }
            )
         }
         if(item.pulse_analyse){
            newArrayDiet.push(
           {
        title:"Pulse",
        max:item.pulse_analyse.max_pulse,
        max_date:item.pulse_analyse.max_pulse_date,
        min:item.pulse_analyse.min_pulse,
         min_date:item.pulse_analyse.min_pulse_date,
         sd: item.pulse_analyse.pulse_sd,
         summary:item.pulse_analyse.summary
    })
         }

         if(item.cholesterol_inference){
            if(item.cholesterol_inference.hdl_cholesterol_data){
                let data=item.cholesterol_inference.hdl_cholesterol_data;
            newArrayDiet.push(
           {
        title:data.cholesterol_name,
        max:data.max_cholesterol,
        max_date:data.max_cholesterol_date,
        min:data.min_cholesterol,
         min_date:data.min_cholesterol_date,
         sd: "-",
         summary:data.summary
    })
        }
         if(item.cholesterol_inference.ldl_cholesterol_data){
                let data=item.cholesterol_inference.ldl_cholesterol_data;
            newArrayDiet.push(
           {
        title:data.cholesterol_name,
        max:data.max_cholesterol,
        max_date:data.max_cholesterol_date,
        min:data.min_cholesterol,
         min_date:data.min_cholesterol_date,
         sd: "-",
         summary:data.summary
    })
        }
         if(item.cholesterol_inference.serum_cholesterol_data){
                let data=item.cholesterol_inference.serum_cholesterol_data;
            newArrayDiet.push(
           {
        title:data.cholesterol_name,
        max:data.max_cholesterol,
        max_date:data.max_cholesterol_date,
        min:data.min_cholesterol,
         min_date:data.min_cholesterol_date,
         sd: "-",
         summary:data.summary
    })
        }
         if(item.cholesterol_inference.total_cholesterol_data){
                let data=item.cholesterol_inference.total_cholesterol_data;
            newArrayDiet.push(
           {
        title:data.cholesterol_name,
        max:data.max_cholesterol,
        max_date:data.max_cholesterol_date,
        min:data.min_cholesterol,
         min_date:data.min_cholesterol_date,
         sd: "-",
         summary:data.summary
    })
        }
         }
        
    return ({
        date:item.date,
        instruction:item.instruction,
        inferenceDatas:newArrayDiet
    })


   })


    
setTableData3(tableDataMed)
setTableData4(tableDataTarget)
setTableData5(tableDataDiet)
  


  })
  .catch(err=>{

  })
  
},[id]) 
      
      const tableData = [
        {
            inference: 'BP',
            trend: 'high',
            sec: 'inconsistent',
            evt: 'food intake',
             data_from:"last 3 months"
        },
        {
            inference: 'Chol',
            trend: 'low',
            sec: 'consistent',
            evt: 'exercise',
             data_from:"last 3 months"
        },
        {
            inference: 'Pulse',
            trend: 'medium',
            sec: 'consistent',
            evt: 'outing',
             data_from:"last 3 months"
        },
        {
            inference: 'Sugar',
            trend: 'critical',
            sec: 'inconsistent',
            evt: 'food intake',
             data_from:"last 3 months"
        },
    ]

 const tableDataEvents = [
        {
            inference: 'BP',
            trend: 'high',
            sec: 'inconsistent',
            evt: 'food intake'
        },
        {
            inference: 'Chol',
            trend: 'low',
            sec: 'consistent',
            evt: 'exercise'
        },
        {
            inference: 'Pulse',
            trend: 'medium',
            sec: 'consistent',
            evt: 'outing'
        },
        {
            inference: 'Sugar',
            trend: 'critical',
            sec: 'inconsistent',
            evt: 'food intake'
        },
    ]

const tableSummary=summary

    const renderTable= (cellInfo)=> {
      return(
            <div>
            {tableData3[cellInfo.index][cellInfo.column.id]}
            </div>
        )
    }
     const renderTableMedicine= (cellInfo)=> {
       
          let d=tableData3[cellInfo.index][cellInfo.column.id]
          if(d.length>0){

          
          
        let res=  d.map(item=>{
     
           return ( <div className="badge-holder">
           <span className="medicine-badge"> {item.drug_name} </span>
            </div>
            )
            })
            
            return res;
          
        }
        
    
    
    

    }

    const renderTableTitle= (cellInfo)=> {
       
          let d=tableData3[cellInfo.index].inferenceDatas
          if(d.length>0){

          
          
        let res=  d.map(item=>{
     
           return ( <div className="each-in-list">
            {item.title} 
                        </div>
            )
            })
           
            return res;
          
        }
        
    
    
    

    }

     const renderTableMin= (cellInfo)=> {
      
          let d=tableData3[cellInfo.index].inferenceDatas
          if(d.length>0){

          
          
        let res=  d.map(item=>{
     
           return ( <div className="each-in-list">
            {item.min} 
            </div>
            )
            })
           
            return res;
          
        }
        
    
    
    

    }
     const renderTableMax= (cellInfo)=> {
       
          let d=tableData3[cellInfo.index].inferenceDatas
          if(d.length>0){

          
          
        let res=  d.map(item=>{
     
           return ( <div className="each-in-list">
            {item.max} 
            </div>
            )
            })
           
            return res;
          
        }
        
    
    
    

    }

     const renderTableMinDate= (cellInfo)=> {
      
          let d=tableData3[cellInfo.index].inferenceDatas
          if(d.length>0){

          
          
        let res=  d.map(item=>{
     
           return ( <div className="each-in-list">
            {item.min_date} 
            </div>
            )
            })
           
            return res;
          
        }
        
    
    
    

    }

     const renderTableMaxDate= (cellInfo)=> {
        
          let d=tableData3[cellInfo.index].inferenceDatas
          if(d.length>0){

          
          
        let res=  d.map(item=>{
     
            return ( <div className="each-in-list">
            {item.max_date} 
            </div>
            )
            })
           
            return res;
          
        }
        
    
    
    

    }

     const renderTableSD= (cellInfo)=> {
       
          let d=tableData3[cellInfo.index].inferenceDatas
          if(d.length>0){

          
          
        let res=  d.map(item=>{
     
           return ( <div className="each-in-list">
            {item.sd} 
            </div>
            )
            })
            
            return res;
          
        }
        
    
    
    

    }
    
    const renderTableSummary= (cellInfo)=> {
      
          let d=tableData3[cellInfo.index].inferenceDatas
          if(d.length>0){

          
          
        let res=  d.map(item=>{
     
           return ( <div className="each-in-list">
            

              <OverlayTrigger trigger='click' rootClose placement="top" overlay={<Tooltip className='my-tooltip' id="button-tooltip">
          {item.summary}  <br/>
           
       
          </Tooltip> }><i style={{color:'var(--theme-blue)'}} className="fas fa-info-circle"></i></OverlayTrigger>   


            </div>
            )
            })
           
            return res;
          
        }
        
    
    
    

    }

       const renderTableSummary3= (cellInfo)=> {
      
          let d=tableData3[cellInfo.index].inferenceDatas
          if(d.length>0){

          
          
        let res=  d.map(item=>{
     
           return ( <div className="each-in-list">
            

                   {item.summary}  

            </div>
            )
            })
           
            return res;
          
        }
        
    
    
    

    }

    /********Target table render****/
  const renderTable4= (cellInfo)=> {
      return(
            <div>
            {tableData4[cellInfo.index][cellInfo.column.id]}
            </div>
        )
    }
     const renderTableTargets4= (cellInfo)=> {
       
          let d=tableData4[cellInfo.index][cellInfo.column.id]
          if(d.length>0){

          
          
        let res=  d.map(item=>{
     
           return ( <div className="">

            <div className="badge-holder">
            <span className="target-badge">
            {item.target_name} </span>
             </div>
            <div>
             {item.comparison==="Lower" ? "Max limit " : item.comparison==="Greater" ? "Min limit " : item.comparison==="Equal" ? "Equals" : null} {item.target_value}  {item.target_unit}  
          
            
            </div>
            </div>
            )
            })
            
            return res;
          
        }
        
    
    
    

    }

    const renderTableTitle4= (cellInfo)=> {
       
          let d=tableData4[cellInfo.index].inferenceDatas
          if(d.length>0){

          
          
        let res=  d.map(item=>{
     
           return ( <div className="each-in-list">
            {item.title} 
                        </div>
            )
            })
           
            return res;
          
        }
        
    
    
    

    }

     const renderTableMin4= (cellInfo)=> {
      
          let d=tableData4[cellInfo.index].inferenceDatas
          if(d.length>0){

          
          
        let res=  d.map(item=>{
     
           return ( <div className="each-in-list">
            {item.min} 
            </div>
            )
            })
           
            return res;
          
        }
        
    
    
    

    }
     const renderTableMax4= (cellInfo)=> {
       
          let d=tableData4[cellInfo.index].inferenceDatas
          if(d.length>0){

          
          
        let res=  d.map(item=>{
     
           return ( <div className="each-in-list">
            {item.max} 
            </div>
            )
            })
           
            return res;
          
        }
        
    
    
    

    }

     const renderTableMinDate4= (cellInfo)=> {
      
          let d=tableData4[cellInfo.index].inferenceDatas
          if(d.length>0){

          
          
        let res=  d.map(item=>{
     
           return ( <div className="each-in-list">
            {item.min_date} 
            </div>
            )
            })
           
            return res;
          
        }
        
    
    
    

    }

     const renderTableMaxDate4= (cellInfo)=> {
        
          let d=tableData4[cellInfo.index].inferenceDatas
          if(d.length>0){

          
          
        let res=  d.map(item=>{
     
            return ( <div className="each-in-list">
            {item.max_date} 
            </div>
            )
            })
           
            return res;
          
        }
        
    
    
    

    }

     const renderTableSD4= (cellInfo)=> {
       
          let d=tableData4[cellInfo.index].inferenceDatas
          if(d.length>0){

          
          
        let res=  d.map(item=>{
     
           return ( <div className="each-in-list">
            {item.sd} 
            </div>
            )
            })
            
            return res;
          
        }
        
    
    
    

    }
    
    const renderTableSummary4= (cellInfo)=> {
      
          let d=tableData4[cellInfo.index].inferenceDatas
          if(d.length>0){

          
          
        let res=  d.map(item=>{
     
           return ( <div className="each-in-list">
            

              <OverlayTrigger trigger='click' rootClose placement="top" overlay={<Tooltip className='my-tooltip' id="button-tooltip">
          {item.summary}  <br/>
           
       
          </Tooltip> }><i style={{color:'var(--theme-blue)'}} className="fas fa-info-circle"></i></OverlayTrigger>   


            </div>
            )
            })
           
            return res;
          
        }
        
    
    
    

    }


const renderTableSummaryNew= (cellInfo)=> {
      
          let d=tableData4[cellInfo.index].inferenceDatas
          if(d.length>0){

          
          
        let res=  d.map(item=>{
     
           return ( <div className="each-in-list">
             {/*<textarea type="text" className="text-area-remarks " value={item.summary}/>*/}

                    {item.summary}
       
         

            </div>
            )
            })
           
            return res;
          
        }
        
    
    
    

    }




    /*********target table ends*****/

/****Optimise*******************/

const renderTableSummaryT= (cellInfo)=> {
      
          let d=tableData4[cellInfo.index].inferenceDatas
          if(d.length>0){

          
          
        let res=  d.map(item=>{
     
           return ( <div className="each-in-list">
            
              <span className="inference-text">{item.summary}</span>
            


            </div>
            )
            })
           
            return res;
          
        }
        
    
    
    

    }


const renderTableSummaryM= (cellInfo)=> {
      
          let d=tableData3[cellInfo.index].inferenceDatas
          if(d.length>0){

          
          
        let res=  d.map(item=>{
     
           return ( <div className="each-in-list">
            
              <span className="inference-text">{item.summary}</span>
             


            </div>
            )
            })
           
            return res;
          
        }
        
    
    
    

    }

const renderTableSummaryI= (cellInfo)=> {
      
          let d=tableData5[cellInfo.index].inferenceDatas
          if(d.length>0){

          
          
        let res=  d.map(item=>{
     
           return ( <div className="each-in-list">
            
               <span className="inference-text">{item.summary}</span>
              

            </div>
            )
            })
           
            return res;
          
        }
        
    
    
    

    }

const renderTableDetailsT= (cellInfo)=> {
      
          let d=tableData4[cellInfo.index].inferenceDatas
          if(d.length>0){

          
          
        let res=  d.map(item=>{
     
           return ( <div className="each-in-list">
            
             
              <OverlayTrigger trigger='click' rootClose placement="top" overlay={<Tooltip className='my-tooltip' id="button-tooltip">
         <span> Min Value :{item.min} </span> <br/>
          <span> Min Value Date : {item.min_date} </span> <br/>
           <span> Max Value :{item.max} </span> <br/>
          <span> Max Value Date : {item.max_date} </span> <br/>
           
       
          </Tooltip> }> 

         

         <i style={{color:'var(--theme-blue)'}} className="fas fa-info-circle"></i></OverlayTrigger>   


            </div>
            )
            })
           
            return res;
          
        }
        
    
    
    

    }


const renderTableDetailsI= (cellInfo)=> {
      
          let d=tableData5[cellInfo.index].inferenceDatas
          if(d.length>0){

          
          
        let res=  d.map(item=>{
     
           return ( <div className="each-in-list">
            
             
              <OverlayTrigger trigger='click' rootClose placement="top" overlay={<Tooltip className='my-tooltip' id="button-tooltip">
         <span> Min Value :{item.min} </span> <br/>
          <span> Min Value Date : {item.min_date} </span> <br/>
           <span> Max Value :{item.max} </span> <br/>
          <span> Max Value Date : {item.max_date} </span> <br/>
           
       
          </Tooltip> }> 

         

         <i style={{color:'var(--theme-blue)'}} className="fas fa-info-circle"></i></OverlayTrigger>   


            </div>
            )
            })
           
            return res;
          
        }
        
    
    
    

    }


const renderTableDetailsM= (cellInfo)=> {
    
      
          let d=tableData3[cellInfo.index].inferenceDatas
          if(d.length>0){

          
          
        let res=  d.map(item=>{
     
           return ( <div className="each-in-list">
            
             
              <OverlayTrigger trigger='hover' rootClose placement="top" overlay={<Tooltip className='my-tooltip' id="button-tooltip">
         <span> Min Value :{item.min} </span> <br/>
          <span> Min Value Date : {item.min_date} </span> <br/>
           <span> Max Value :{item.max} </span> <br/>
          <span> Max Value Date : {item.max_date} </span> <br/>
           
       
          </Tooltip> }> 

         

         <i style={{color:'var(--theme-blue)'}} className="fas fa-info-circle"></i></OverlayTrigger>   


            </div>
            )
            })
           
            return res;
          
        }
        
    
    
    

    }







/***********optimise ends**********/


    /********Diet Instruction table render****/
  const renderTable5= (cellInfo)=> {
      return(
            <div>
            {tableData5[cellInfo.index][cellInfo.column.id]}
            </div>
        )
    }
     const renderTableInstructions5= (cellInfo)=> {
       
          let d=tableData5[cellInfo.index][cellInfo.column.id]
          if(d.length>0){

          
          
        let res=  d.map(item=>{
     
           return ( <div className="">

            <div className="badge-holder">
            <span className="instr-badge">
            {item.instruction} </span> </div>
           
            </div>
            )
            })
            
            return res;
          
        }
        
    
    
    

    }

    const renderTableTitle5= (cellInfo)=> {
       
          let d=tableData5[cellInfo.index].inferenceDatas
          if(d.length>0){

          
          
        let res=  d.map(item=>{
     
           return ( <div className="each-in-list">
            {item.title} 
                        </div>

                      
            )
            })
           
            return res;
          
        }
        
    
    
    

    }

     const renderTableMin5= (cellInfo)=> {
      
          let d=tableData5[cellInfo.index].inferenceDatas
          if(d.length>0){

          
          
        let res=  d.map(item=>{
     
           return ( <div className="each-in-list">
            {item.min} - {item.min_date}
            </div>
            )
            })
           
            return res;
          
        }
        
    
    
    

    }
     const renderTableMax5= (cellInfo)=> {
       
          let d=tableData5[cellInfo.index].inferenceDatas
          if(d.length>0){

          
          
        let res=  d.map(item=>{
     
           return ( <div className="each-in-list">
            {item.max}- {item.max_date}
            </div>
            )
            })
           
            return res;
          
        }
        
    
    
    

    }

     const renderTableMinDate5= (cellInfo)=> {
      
          let d=tableData4[cellInfo.index].inferenceDatas
          if(d.length>0){

          
          
        let res=  d.map(item=>{
     
           return ( <div className="each-in-list">
            {item.min_date} 
            </div>
            )
            })
           
            return res;
          
        }
        
    
    
    

    }

     const renderTableMaxDate5= (cellInfo)=> {
        
          let d=tableData4[cellInfo.index].inferenceDatas
          if(d.length>0){

          
          
        let res=  d.map(item=>{
     
            return ( <div className="each-in-list">
            {item.max_date} 
            </div>
            )
            })
           
            return res;
          
        }
        
    
    
    

    }

     const renderTableSD5= (cellInfo)=> {
       
          let d=tableData4[cellInfo.index].inferenceDatas
          if(d.length>0){

          
          
        let res=  d.map(item=>{
     
           return ( <div className="each-in-list">
            {item.sd} 
            </div>
            )
            })
            
            return res;
          
        }
        
    
    
    

    }
    
    const renderTableSummary5= (cellInfo)=> {
      
          let d=tableData5[cellInfo.index].inferenceDatas
          if(d.length>0){

          
          
        let res=  d.map(item=>{
     
           return ( <div className="each-in-list">
            

                   {item.summary}  

            </div>
            )
            })
           
            return res;
          
        }
        
    
    
    

    }







    /*********Diet Instr table ends*****/
   const renderInference2= (cellInfo)=> {
      return(
            <div>
            {tableDataEvents[cellInfo.index][cellInfo.column.id]}
            </div>
        )
    }

    const renderTrend2= (cellInfo)=> {

        if(tableDataEvents[cellInfo.index][cellInfo.column.id]==='critical'){
            return(          
                <div className='critical-trend'>
                {tableDataEvents[cellInfo.index][cellInfo.column.id]}
                </div>
            )
        }
        else if(tableDataEvents[cellInfo.index][cellInfo.column.id]==='high'){
            return(          
                <div className='high-trend'>
                {tableDataEvents[cellInfo.index][cellInfo.column.id]}
                </div>
            )
        }
        else if(tableDataEvents[cellInfo.index][cellInfo.column.id]==='medium'){
            return(          
                <div className='medium-trend'>
                {tableDataEvents[cellInfo.index][cellInfo.column.id]}
                </div>
            )
        }
        else if(tableDataEvents[cellInfo.index][cellInfo.column.id]==='low'){
            return(          
                <div className='low-trend'>
                {tableDataEvents[cellInfo.index][cellInfo.column.id]}
                </div>
            )
        }
        else {
            return(          
                <div className='low-trend'>
                {tableDataEvents[cellInfo.index][cellInfo.column.id]}
                </div>
            )
        }
        
    }

    const renderSecondary2= (cellInfo)=> {
        return(
            <div>
            {tableDataEvents[cellInfo.index][cellInfo.column.id]}
            </div>
        )
    }

    const renderEvent2= (cellInfo)=> {
        return(
            <div>
            {tableDataEvents[cellInfo.index][cellInfo.column.id]}
            </div>
        )
        
    }


 const renderDataFrom2= (cellInfo)=> {
        return(
            <div>
            {tableDataEvents[cellInfo.index][cellInfo.column.id]}
            </div>
        )
        
    }


    /***************************/
    const renderInference= (cellInfo)=> {
        return(
            <div>
            {tableData[cellInfo.index][cellInfo.column.id]}
            </div>
        )
    }

    const renderTrend= (cellInfo)=> {

        if(tableData[cellInfo.index][cellInfo.column.id]==='critical'){
            return(          
                <div className='critical-trend'>
                {tableData[cellInfo.index][cellInfo.column.id]}
                </div>
            )
        }
        else if(tableData[cellInfo.index][cellInfo.column.id]==='high'){
            return(          
                <div className='high-trend'>
                {tableData[cellInfo.index][cellInfo.column.id]}
                </div>
            )
        }
        else if(tableData[cellInfo.index][cellInfo.column.id]==='medium'){
            return(          
                <div className='medium-trend'>
                {tableData[cellInfo.index][cellInfo.column.id]}
                </div>
            )
        }
        else if(tableData[cellInfo.index][cellInfo.column.id]==='low'){
            return(          
                <div className='low-trend'>
                {tableData[cellInfo.index][cellInfo.column.id]}
                </div>
            )
        }
        else {
            return(          
                <div className='low-trend'>
                {tableData[cellInfo.index][cellInfo.column.id]}
                </div>
            )
        }
        
    }

    const renderSecondary= (cellInfo)=> {
        return(
            <div>
            {tableData[cellInfo.index][cellInfo.column.id]}
            </div>
        )
    }

    const renderEvent= (cellInfo)=> {
        return(
            <div>
            {tableData[cellInfo.index][cellInfo.column.id]}
            </div>
        )
        
    }


 const renderDataFrom= (cellInfo)=> {
        return(
            <div>
            {tableData[cellInfo.index][cellInfo.column.id]}
            </div>
        )
        
    }
    const columns = [
        {
            Header: "Inferences",
            accessor : "inference",
            Cell : renderInference,
            sortable : true,
            width :100
        },
        {
            Header: "Trend",
            accessor: "trend",
            Cell: renderTrend,
            sortable: true,
            width: 90
        },
        {
            Header: "Secondary",
            accessor: "sec",
            Cell: renderSecondary,
            sortable: true,
            width: 190
        },
        
       
        
        {
            Header: "Data from",
            accessor: "data_from",
            Cell: renderDataFrom,
            sortable: true,
            
        }
    ]
    /*

const columns3 = [
        {
            Header: "date",
            accessor : "date",
            Cell : renderDate,
            sortable : true,
            width :100
        },
        {
            Header: "medicines",
            accessor: "medicine",
            Cell: renderMedicine,
            sortable: true,
            width: 90
        },
        {
            Header: "title",
            accessor: "title",
            Cell: renderTitle,
            sortable: true,
            width: 190
        },        
        {
            Header: "Max value",
            accessor: "max",
            Cell: renderMax,
            sortable: true,
            
        },
        {
            Header: "Max Value date",
            accessor: "max_date",
            Cell: renderMaxDate,
            sortable: true,
            
        },
        {
            Header: "Min value",
            accessor: "min",
            Cell: renderMin,
            sortable: true,
            
        },
        {
            Header: "Min Value date",
            accessor: "min_date",
            Cell: renderMinDate,
            sortable: true,
            
        },
        {
            Header: "SD",
            accessor: "sd",
            Cell: renderSD,
            sortable: true,
            
        },
    ]
*/
const columns3 = [
        {
            Header: "Date",
            accessor : "date",
            Cell : renderTable,
            sortable : true,
            width :100
        },
        {
            Header: "Medicines",
            accessor: "medicines",
            Cell: renderTableMedicine,
            sortable: true,
            width: 150
        },
        {
            Header: "Vitals",
            accessor: "inferenceDatas",
          Cell: renderTableTitle,
            sortable: true,
            width: 140
        },        
        {
            Header: "Max value",
            accessor: "inferenceDatas",
            Cell: renderTableMax,
            sortable: true,
            
        },
        {
            Header: "Max value date",
            accessor: "inferenceDatas",
            Cell: renderTableMaxDate,
            sortable: true,
            
        },
        {
            Header: "Min value",
            accessor: "inferenceDatas",
            Cell: renderTableMin,
            sortable: true,
            
        },
        {
            Header: "Min value date",
            accessor: "inferenceDatas",
            Cell: renderTableMinDate,
            sortable: true,
            
        },
        /*{
            Header: "SD",
            accessor: "inferenceDatas",
            Cell: renderTableSD,
            sortable: true,
            
        },*/
         {
            Header: "Summary",
            accessor: "inferenceDatas",
            Cell: renderTableSummary3,
            sortable: true,
            width:300
        }
    ]

const columnsM = [
        {
            Header: "Date",
            accessor : "date",
            Cell : renderTable,
            sortable : true,
            
            width :100
        },
        {
            Header: "Medicines",
            accessor: "medicines",
            Cell: renderTableMedicine,
            sortable: true,
            },
        {
            Header: "Vitals",
            accessor: "inferenceDatas",
          Cell: renderTableTitle,
        
            width: 160
        },        
      
         {
            Header: "Inference",
            accessor: "inferenceDatas",
            Cell: renderTableSummaryM,
        
            minWidth:300,
              style:{
      backgroundColor:"rgba(0,0,0,0.1)"
    }
        },
        {
            Header: "Details",
            accessor: "inferenceDatas",
            Cell: renderTableDetailsM,
        
            width:100
            
        }
    ]
/*
const columns4 = [
        {
            Header: "Date",
            accessor : "date",
            Cell : renderTable4,
            sortable : true,
            width :100
        },
        {
            Header: "Targets",
            accessor: "targets",
            Cell: renderTableTargets4,
            sortable: true,
            width: 200
        },
        {
            Header: "Vitals",
            accessor: "inferenceDatas",
          Cell: renderTableTitle4,
            sortable: true,
            width: 190
        },        
        {
            Header: "Max value",
            accessor: "inferenceDatas",
            Cell: renderTableMax4,
            sortable: true,
            
        },
        {
            Header: "Max value date",
            accessor: "inferenceDatas",
            Cell: renderTableMaxDate4,
            sortable: true,
            
        },
        {
            Header: "Min value",
            accessor: "inferenceDatas",
            Cell: renderTableMin4,
            sortable: true,
            
        },
        {
            Header: "Min value date",
            accessor: "inferenceDatas",
            Cell: renderTableMinDate4,
            sortable: true,
            
        },
        {
            Header: "SD",
            accessor: "inferenceDatas",
            Cell: renderTableSD4,
            sortable: true,
            
        },
         {
            Header: "Summary",
            accessor: "inferenceDatas",
            Cell: renderTableSummaryNew,
            sortable: true,
            width:300
            
        }
    ]

    */
const columnsT = [
        {
            Header: "Date",
            accessor : "date",
            Cell : renderTable4,
            sortable : true,
         },   
        {
            Header: "Targets",
            accessor: "targets",
            Cell: renderTableTargets4,
            sortable: true,
           
            width: 200
        },
        {
            Header: "Vitals",
            accessor: "inferenceDatas",
          Cell: renderTableTitle4,
            
            width: 160
        },        
       
         {
            Header: "Inference",
            accessor: "inferenceDatas",
            Cell: renderTableSummaryT,
            
            minWidth:300,
            style:{
      backgroundColor:"rgba(0,0,0,0.1)"
    }
            
        },
        {
            Header: "Details",
            accessor: "inferenceDatas",
            Cell: renderTableDetailsT,
            
            width:100
            
        }
    ]



const columns5 = [
        {
            Header: "Date",
            accessor : "date",
            Cell : renderTable5,
            sortable : true,
            width :100
        },
        {
            Header: "Diet Instructions",
            accessor: "instruction",
            Cell: renderTableInstructions5,
            sortable: true,
            minWidth: 240
        },
        {
            Header: "Vitals",
            accessor: "inferenceDatas",
          Cell: renderTableTitle5,
            sortable: true,
            width: 190
        },    
        {
            Header: "Inference",
            accessor: "inferenceDatas",
            Cell: renderTableSummary5,
            sortable: true,
            width:300
            
        },    
        {
            Header: "Max value",
            accessor: "inferenceDatas",
            Cell: renderTableMax5,
            sortable: true,
            
        },
        /*
        {
            Header: "Max value date",
            accessor: "inferenceDatas",
            Cell: renderTableMaxDate5,
            sortable: true,
            
        },*/
        {
            Header: "Min value",
            accessor: "inferenceDatas",
            Cell: renderTableMin5,
            sortable: true,
            
        },
        /*
        {
            Header: "Min value date",
            accessor: "inferenceDatas",
            Cell: renderTableMinDate5,
            sortable: true,
            
        },
        {
            Header: "SD",
            accessor: "inferenceDatas",
            Cell: renderTableSD5,
            sortable: true,
            
        },*/
         
    ]


const columnsI = [
        {
            Header: "Date",
            accessor : "date",
            Cell : renderTable5,
            sortable : true,
          
            width :100
        },
        {
            Header: "Diet Instructions",
            accessor: "instruction",
            Cell: renderTableInstructions5,
            sortable: true,
            
            minWidth: 240
        },
        {
            Header: "Vitals",
            accessor: "inferenceDatas",
          Cell: renderTableTitle5,
        
            width: 160
        },    
        {
            Header: "Inference",
            accessor: "inferenceDatas",
            Cell: renderTableSummaryI,
            
            minWidth:300,
             style:{
      backgroundColor:"rgba(0,0,0,0.1)"
    }
            
        },
        {
            Header: "Details",
            accessor: "inferenceDatas",
            Cell: renderTableDetailsI,
            
            width:100
            
        }
        /*,    
        {
            Header: "Max value",
            accessor: "inferenceDatas",
            Cell: renderTableMax5,
            sortable: true,
            
        },
    
        {
            Header: "Max value date",
            accessor: "inferenceDatas",
            Cell: renderTableMaxDate5,
            sortable: true,
            
        },
        {
            Header: "Min value",
            accessor: "inferenceDatas",
            Cell: renderTableMin5,
            sortable: true,
            
        },
    
        {
            Header: "Min value date",
            accessor: "inferenceDatas",
            Cell: renderTableMinDate5,
            sortable: true,
            
        },
        {
            Header: "SD",
            accessor: "inferenceDatas",
            Cell: renderTableSD5,
            sortable: true,
            
        },*/
         
    ]

const displayTable3=
               <ReactTable
                columns={columnsM}
                data={tableData3}
                defaultPageSize = {5}
                className="inference-table"
                resizable 
                
         >

         </ReactTable> 
const displayTable4=
               <ReactTable
                columns={columnsT}
                data={tableData4}
                defaultPageSize = {5}
                className="inference-table"

                resizable 
                
         >

         </ReactTable> 

const displayTable5=
               <ReactTable
                columns={columnsI}
                data={tableData5}
                defaultPageSize = {5}
                className="inference-table"
                resizable 
                
         >

         </ReactTable> 
    

const displaySelected=selected===1 ?
             <div className="inf-time-based-holder flex-col">
            {tableSummary}

        </div> 
        : 
        selected ===2 ?
        displayTable3
        : selected===3 ?
         displayTable4
        : selected===4 ?
         displayTable5
         : null

const handleChangeRadio=(val)=>{
   
    setSelected(val);

   }

    return (<div className="">
    


  <div className="flex-col">            
   
 <ToggleButtonGroup type="radio" name="options" defaultValue={1} onChange={handleChangeRadio}>
    <ToggleButton id="tbg-radio-1" value={1} className="btn btn-light toggle-btns toggle-btn-1">
     Time based
    </ToggleButton>
    <ToggleButton id="tbg-radio-2" value={2} className="btn btn-light toggle-btns toggle-btn-2">
     Medicines 
    </ToggleButton>
     <ToggleButton id="tbg-radio-2" value={3} className="btn btn-light toggle-btns toggle-btn-3">
     Targets
    </ToggleButton>
    <ToggleButton id="tbg-radio-2" value={4} className="btn btn-light toggle-btns toggle-btn-4">
     Diet
    </ToggleButton>
    
  </ToggleButtonGroup>
  </div>
<br/>
<br/>


        


        <div>
        {displaySelected}
        </div>
        </div>
    )
}

export default Inference
