import React,{useState} from 'react'
import Select from 'react-select';
import axios from "axios";
import {APIURL} from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import DatePicker from 'react-date-picker';

function EditEquipment(props) {

    const[data, setData]= useState(props.data)
    const [type, setType]= useState({value:props.data.service_type, label: props.data.service_type.replace("_"," ")})
    const [unit, setUnit]= useState({value:props.data.service_unit, label: props.data.service_unit})

    const [successShow, setSuccessShow] = useState(false)
    const [errorShow, setErrorShow] = useState(false)

    const [year, setYear]= useState(data.year_of_manufacture=== null ? '' : new Date(data.year_of_manufacture))
    const [expiry, setExpiry]= useState(data.expiry_date=== null ? '' : new Date(data.expiry_date))

    const [yearData, setYearData]= useState(data.year_of_manufacture)
    const [expiryData, setExpiryData]= useState(data.expiry_date)

    const serviceOptions = [{ value:'general', label:'General' },
    { value:'service_request', label:'Service Request' },
    { value:'rental', label:'Equipment Request' }
]

    const unitOptions = [{ value:'days', label:'Days' },
    { value: 'count', label: 'count' }
]

    const SuccessPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4 className='text-success'>Updated Successfully</h4>
             
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
             
            
            </Modal.Footer>
          </Modal>
        );
      }
    
      const ErrorPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <h5 style={{color: "red"}}>Error in data submission.Please try again!</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
             
            
            </Modal.Footer>
          </Modal>
        );
      }

      const handleTitle = (e)=> {
        e.preventDefault();        
        const val=e.target.value;
        const key=e.target.name;
        setData( current => ({
            ...current , [key] : val
        }))
      }

      const handleServiceChange= (data) => {

        setType(data)
   }

   const handleUnitChange= (data) => {

        setUnit(data)
  }

  const handleRequest = (e)=> {
   const val = e.target.value
   const key = e.target.name

   setData((current) => ({
       ...current , [key] : val==='true'? true : val==='false'? false : null
   }))

  }

  const handleYear = (date)=> {
    setYear(date);
      
      let dateUpdated= date;
        if(date!==null){
      const offset = date.getTimezoneOffset();
      
      dateUpdated = new Date(date.getTime() - (offset*60*1000))
      
      let DateExtracted = dateUpdated.toISOString().split('T')[0];
      
      /*let dateString= DateExtracted.toString(); */
      setYearData(DateExtracted);
      
      }
  }

  const handleExpiry = (date)=> {
    setExpiry(date);
      
      let dateUpdated= date;
        if(date!==null){
      const offset = date.getTimezoneOffset();
      
      dateUpdated = new Date(date.getTime() - (offset*60*1000))
      
      let DateExtracted = dateUpdated.toISOString().split('T')[0];
      
      /*let dateString= DateExtracted.toString(); */
      setExpiryData(DateExtracted);
      
      }
  }

  const saveChanges = (e)=> {
    e.preventDefault();
    console.log(data)
    const tokenString = sessionStorage.getItem("usertoken");
 
     let v2 = tokenString.replace(
       /["]+/g,
       ""
     ); /* REGEX To remove double quotes from tokenstring */
 
  const dataToSend = {
     title2 : data.title2,
     service_type : type.value,
     service_unit : unit.value,
     on_request_service : data.on_request_service,
     is_active : data.is_active,
     name: data.name,
     make_company: data.make_company,
     model: data.model,
     serial_number: data.serial_number,
     year_of_manufacture: yearData,
     expiry_date: expiryData
  }
 
     axios
       .put(`${APIURL}/api/v1/staff/update-cp-services/?id=${data.id}`, dataToSend , {
         headers: { Authorization: "Token " + v2 },
       })
       .then((res) => {
         if (res.data.status === "success") {
           
           setSuccessShow(true)
         } else {
           setErrorShow(true)
         }
       })
       .catch((err) => {
         setErrorShow(true)
       });

}

    return (
        <>
    <h2 style={{marginTop:'0'}} className="title-of-page">*Edit Equipment Request</h2>
        <div style={{maxHeight:'700px'}} className='config-box fit-content'>
            
            <div className='feature-data pad-features'>
                <div className='flex-col justify-feature'>
                    <span className='align-rental'>*Feature Name : </span>
                    <input type='text' className='form-control title-flex' name='title2' value={data.title2} onChange={handleTitle} />
                </div>

                <div className='flex-col justify-feature'>
                    <span className='align-rental'>*Brand Name : </span>
                    <input type='text' className='form-control title-flex' name='name' value={data.name} onChange={handleTitle} />
                 </div>

            </div>

            <div className='feature-data pad-features'>
                
                <div className='flex-col justify-feature'>
                    <span className='align-rental'>*Equipment Unit :  </span>

                <Select
                            value={unit}
                            onChange={(data,e)=>{handleUnitChange(data,e)
                            }}
                            options={unitOptions}
                            name='service_unit'
                            className="select-currency select-flex"
                        />
                 </div>

                 <div className='flex-col justify-feature'>
                    <span className='align-rental'>*Type : </span>
                    <Select
                            value={type}
                            onChange={(data,e)=>{handleServiceChange(data,e)
                            }}
                            options={serviceOptions}
                            name='service_type'
                            className="select-currency select-flex"
                    />
                 </div>
            </div>
            
            <div className='feature-data pad-features'>
                <div className='flex-col justify-feature'>
                    <span className='align-rental'>*On Request Service :</span>

                <div style={{marginBottom:'auto'}} className="form-group gender-radio-check col-lg-6 title-flex">
                   
                        <input className='checkbox-class1' type='radio' id="true" name='on_request_service'  value= {true} checked={data.on_request_service === true} onChange={handleRequest} />
                        <label htmlFor='true'>Yes</label>

                        <input className='checkbox-class1' type='radio' id="false"  name='on_request_service'  value= {false} checked={data.on_request_service === false} onChange={handleRequest} />
                        <label htmlFor='false'>No</label>
                   
                </div>
                </div>

                <div className='flex-col justify-feature'>
                    <span className='align-rental'>*Active Feature : </span>
                
                <div style={{marginBottom: 'auto'}} className="form-group gender-radio-check col-lg-6 title-flex">
                   
                        <input className='checkbox-class1' type='radio' id="true" name='is_active'  value= {true} checked={data.is_active === true} onChange={handleRequest} />
                        <label htmlFor='true'>Yes</label>

                        <input className='checkbox-class1' type='radio' id="false"  name='is_active'  value= {false} checked={data.is_active === false} onChange={handleRequest} />
                        <label htmlFor='false'>No</label>
                    
                </div>
                </div>

            </div>

            <div className='feature-data pad-features'>
                <div className='flex-col justify-feature'>
                    <span className='align-rental'>*Make: </span>
                    <input type='text' className='form-control title-flex' name='make_company' value={data.make_company} onChange={handleTitle} />
                </div>

                <div className='flex-col justify-feature'>
                    <span className='align-rental'>*Model : </span>
                    <input type='text' className='form-control title-flex' name='model' value={data.model} onChange={handleTitle} />
                 </div>
            </div>

            <div className='feature-data pad-features'>
                <div className='flex-col justify-feature'>
                    <span className='align-rental'>*Year of Manufacture : </span>
                    <div className='form-control dob item title-flex'>
                        <DatePicker
                          value={year}
                          onChange={date=>{handleYear(date)}}                         
                          maxDate={new Date()}
                          
                     />
                     </div>
                </div>

                <div className='flex-col justify-feature'>
                    <span className='align-rental'>*Expiry Date : </span>
                    <div className='form-control dob item title-flex'>
                        <DatePicker
                          value={expiry}
                          onChange={date=>{handleExpiry(date)}}                         
                          minDate={new Date()}
                          
                     />
                     </div>
                </div>
            </div>

            <div className='feature-data pad-features'>
                <div className='flex-col justify-feature'>
                    <span className='align-rental'>*Serial Number : </span>
                        <input type='text' className='form-control title-flex' name='serial_number' value={data.serial_number} onChange={handleTitle} />
                    </div>
            </div>

            <div className='text-center'>
                <button onClick={saveChanges} className='btn btn-primary btn-col mb-2'>Save</button>
                
            </div>
            {successShow ? 
<SuccessPopUp
show={successShow}
onHide={() =>  {setSuccessShow(false); props.modalClose(); }}
/> 
: null}

{errorShow ? 

<ErrorPopUp
show={errorShow}
onHide={() =>  {setErrorShow(false)}}
/> 
: null}
            
        </div>
        </>
    )
}

export default EditEquipment
