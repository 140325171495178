import React, {useState, useEffect ,useContext} from 'react'
import axios from 'axios'
import {APIURL} from "../../Global";
import {useHistory} from "react-router-dom";
import './generalsearch.css';
import HospDrMessages from "../HospDrMessages/HospDrMessages";
import PatientPrescription from "../HospPatientPresc/HospPatientPresc";
import PatientLabReport from "../HospPatientLabReport/HospPatientLabReport";
import HospDocNavbar from "../HospDocNavbar/HospDocNavbar";
import {SearchContext} from "../../contexts/GeneralSearchContext"
import {Modal ,Button} from 'react-bootstrap';
function GeneralSearch(props) {
    const [searchTerm ,setSearchTerm] = useContext(SearchContext)
    const [category ,setCategory] =useState('')
    const [data ,setData] = useState([])
    const [data1 ,setData1] =useState([])
    const [data2 ,setData2] =useState([])
    const [id] = useState(props.match.params.id);
    const [,setErrorMsg] =useState(false)
    const [prescShow, setPrescShow] =useState(false)
    const [prescId, setPrescId] = useState();
    const [reportId, setReportId]= useState();
    const [reportShow, setReportShow] =useState(false)
    const [messagesShow ,setMessagesShow] =useState(false)
    const [msgId, setMsgId] =useState();
    const [invalid ,setInvalid] =useState(false)

    let track = null  
function msgData(childData) {
  track =childData
}

const handleTrack= ()=> {
  setMessagesShow(false)
  if(track!==null){
    track.stop();
  }
}
    
let resultDisplay=null;
let history=useHistory();
useEffect(()=>{
  if(searchTerm===null || searchTerm===""){
    resultDisplay= null;
  }
  else{
  resultDisplay= <>{data.length>0 ?
            <div >
            <p style={{display:'inline-block',fontSize:'20px'}} className="text-muted">Showing results in </p>{'  '}
             <h3 style={{display:'inline-block'}} className=" additional-text">Prescriptions</h3>
             </div> : null}
          
            {data.length>0 ?
            
            display : null}

{category==='prescriptions' ? searchTerm!==null && searchTerm!=="" && data.length <1 && invalid===false ? <h6 style={{color:"red"}}>No results found in prescriptions</h6> :  searchTerm!==null && searchTerm!=="" &&  data.length <1 && invalid===true ?<h6 style={{color:"red"}}>Unsupported language used for search!</h6>: null :null} 

          {<br/>}

          {data1.length>0 ?
          <div>
            <p style={{display:'inline-block',fontSize:'20px'}} className="text-muted">Showing results in </p>{'  '}
            <h3 style={{display:'inline-block'}} className="additional-text">Lab Reports</h3></div> : null}
            

          {data1.length>0 ?
            display1 : null}

{category==='reports' ? searchTerm!==null && searchTerm!=="" && data1.length <1 && invalid===false ? <h6 style={{color:"red"}}>No results found in lab reports</h6> :  searchTerm!==null && searchTerm!=="" &&  data1.length <1 && invalid===true ?<h6 style={{color:"red"}}>Unsupported language used for search!</h6>: null :null} 

            {<br/>}
          {data2.length>0 ?
          <div>
            <p style={{display:'inline-block',fontSize:'20px'}} className="text-muted">Showing results in </p>{'  '}
            <h3 style={{display:'inline-block'}} className="additional-text">Messages</h3></div> : null}
            

          {data2.length>0 ?
            display2 : null}

{category==='messages' ? searchTerm!==null && searchTerm!=="" &&  data2.length <1 && invalid===false ? <h6 style={{color:"red"}}>No results found in messages</h6> :  searchTerm!==null && searchTerm!=="" &&  data2.length <1 && invalid===true ?<h6 style={{color:"red"}}>Unsupported language used for search!</h6>: null :null} 

          {category===''? searchTerm!==null && searchTerm!=="" &&  data.length<1 && data1.length<1 && data2.length<1 && invalid===false ?  <h6 style={{color:"red"}}>No results found</h6> : searchTerm!==null && searchTerm!=="" &&  data.length<1 && data1.length<1 && data2.length<1 && invalid===true ? <h6 style={{color:"red"}}>Unsupported language used for search!</h6>: null :null}
          </>
 }
  },[data,data1,data2,searchTerm]);
    const handleSearchChange = (e) => {
        e.preventDefault();
        setSearchTerm(e.target.value)
       
    }

    const handleChange = (e) =>{
      e.preventDefault();
      setCategory(e.target.value)
      setData([])
      setData1([])
      setData2([])
    }

    const handleMessagesOverlay=(id)=>{    
      setMessagesShow(true)
      setMsgId(id)
     }

     const handlePrescOverlay=(id)=>{    
      setPrescShow(true)
      setPrescId(id)
     }

     const handleReportOverlay=(id)=>{    
      setReportShow(true)
      setReportId(id)
     }

     

    useEffect(()=>{

        const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')
   setData([])
      setData1([])
      setData2([])
     if(searchTerm!==null && searchTerm!==""){
        axios.get(`${APIURL}/api/v1/connect/search/?q=${searchTerm}&patient_id=${id}&category=${category}`, { headers: {
     
      
      "Authorization" : 'Token '+str  }})
      .then(res=>{
        
           if(res.data.status==="success"){
            setInvalid(false)
             const check1= res.data.results

                if(category===''){
                  check1.map((item)=>{
                    if(item.category==='prescriptions'){
                      setData(item.prescriptions)
                    }
                    if(item.category==='reports'){
                      setData1(item.reports)
                    }
                    if(item.category==='messages'){
                      setData2(item.messages)
                    }
                  })                                                    
                }
                else if(category==='prescriptions'){               
                  setData( res.data.results[0].prescriptions)                  
                }
                else if(category==='reports'){
                  setData1(res.data.results[0].reports)
                }
                else if(category==='messages'){
                  setData2(res.data.results[0].messages)
                }                                
           
           }
           else if(res.data.status === 'error' && res.data.message=== 'Unsupported language used for search!'){
            setInvalid(true)
          }
           
           else{
            setErrorMsg("error");
            setInvalid(false)
           }
     
     
         })
         .catch(err=>{
          
       
     })   
     }
     else{
      setData([]);
       setData1([]);
        setData2([]);
     }      
},[searchTerm])

const handleBack=(e)=>{
  history.goBack();
}

const handleSubmit= (e)=>{
    e.preventDefault();
    setData([])
      setData1([])
      setData2([])
  
     const tokenString= sessionStorage.getItem('usertoken');
     
     let str= tokenString.replace(/["]+/g, '')  
                  
     if(searchTerm!==null && searchTerm!==""){
      axios.get(`${APIURL}/api/v1/connect/search/?q=${searchTerm}&patient_id=${id}&category=${category}`, { headers: {
        
        "Authorization" : 'Token '+str  }})
      .then(res=>{
     
        if(res.data.status==="success"){
          
          const check1= res.data.results
             if(check1.length>0){
                if(category===''){
                  check1.map((item)=>{
                    if(item.category==='prescriptions'){
                      setData(item.prescriptions)
                    }
                    if(item.category==='reports'){
                      setData1(item.reports)
                    }
                    if(item.category==='messages'){
                      setData2(item.messages)
                    }
                  })                                                    
                }
            else if(category==='prescriptions'){
              setData( res.data.results[0].prescriptions)
            }
            else if(category==='reports'){
              setData1(res.data.results[0].reports)
            }
            else if(category==='messages'){
              setData2(res.data.results[0].messages)
            }  
            }
            else{
              setData([]);
             setData1([]);
             setData2([]);
            }   
        
        }
  
  
      })
      .catch(err=>{
        setErrorMsg("error");
  }) 
  } 
   else{
      setData([]);
       setData1([]);
        setData2([]);
     }    
}

const MessagesPopup=(props)=> {
  return (
    <Modal
      {...props}
     
       aria-labelledby="example-custom-modal-styling-title"
       backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
      
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <HospDrMessages patientId={props.id} msgId={msgId} track={msgData} />
        
       
      </Modal.Body>
      <Modal.Footer>
       
        
       <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>
       
       
      </Modal.Footer>
    </Modal>
  );
}

const PrescPopup=(props)=> {
 
 
  return (
    <Modal
      {...props}
     
       aria-labelledby="example-custom-modal-styling-title"
       backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
      
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          
         {/* <h4 className="title-of-tasks"> Prescription {props.prescid}  </h4> */}
         <h5 className="text-muted"> Patient ID {id}</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PatientPrescription {...props} />
       
     </Modal.Body>
     <Modal.Footer>
       
        
       <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>
       
       
      </Modal.Footer>
    </Modal>
  );

}


const ReportPopup=(props)=> {
 
 
  return (
    <Modal
      {...props}
     
       aria-labelledby="example-custom-modal-styling-title"
       backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
      
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        {/* <h4 className="title-of-tasks"> Lab Report {props.reportid}  </h4> */}
         <h5 className="text-muted"> Patient ID {id}</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PatientLabReport {...props} />
       
     </Modal.Body>
     <Modal.Footer>
       
        
       <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>
       
       
      </Modal.Footer>
    </Modal>
  );
}


const display =data.length > 0 ? data.map((items ,index)=>{
  return(<>
  <div key={index}>
  <div onClick={()=> handlePrescOverlay(items.id)}>
  <div style={{cursor:'pointer'}} className="list-item"> 
          <h5 className="each-list-link">
            {index + 1}. Prescription ID : {items.id}  
          </h5>
          {/*<div className="details">
           <p> Label: {items.label} </p>
            <p> Text : {items.text} </p>
          </div>*/}
         </div>
         </div>
                   
  </div>
  
  </>
  )
}):null

const display1 =data1.length > 0 ? data1.map((items ,index)=>{
  return(<>
  <div key={index}>
  <div onClick={()=> handleReportOverlay(items.id)}>
  <div style={{cursor:'pointer'}} className="list-item"> 
          <h5 className="each-list-link">
            {index + 1}. Lab Report ID : {items.id}  
          </h5>
          {/*<div className="details">
           <p> Label: {items.label} </p>
            <p> Text: {items.text} </p>
          </div>*/}
         </div>
        </div>    
  </div>
  
  </>
  )
}):null


const display2 =data2.length > 0 ? data2.map((items ,index)=>{
  return(<>
  <div key={index}>
  <div onClick={()=> handleMessagesOverlay(items.id)}>
  <div style={{cursor:'pointer'}} className="list-item"> 
          <h5 className="each-list-link">
            {index + 1}. Message : {items.message.slice(0,30)}  
          </h5>
          <div className="details">
           <p> Date: {items.created_at.slice(0,16).split('T').join('\n')} </p>
          </div>
         </div>
        </div>
  </div>
                   
  
  </>
  )
}):null

if(searchTerm===null || searchTerm===""){
    resultDisplay= null;
  }
  else{
resultDisplay= <>{data.length>0 ?
            <div >
            <p style={{display:'inline-block',fontSize:'20px'}} className="text-muted">Showing results in </p>{'  '}
             <h3 style={{display:'inline-block'}} className=" additional-text">Prescriptions</h3>
             </div> : null}
          
            {data.length>0 ?
            
            display : null}

          {category==='prescriptions' ? searchTerm!==null && searchTerm!=="" && data.length <1 && invalid===false ? <h6 style={{color:"red"}}>No results found in prescriptions</h6> :  searchTerm!==null && searchTerm!=="" &&  data.length <1 && invalid===true ?<h6 style={{color:"red"}}>Unsupported language used for search!</h6>: null :null}

          {<br/>}

          {data1.length>0 ?
          <div>
            <p style={{display:'inline-block',fontSize:'20px'}} className="text-muted">Showing results in </p>{'  '}
            <h3 style={{display:'inline-block'}} className="additional-text">Lab Reports</h3></div> : null}
            

          {data1.length>0 ?
            display1 : null}

          {category==='reports' ? searchTerm!==null && searchTerm!=="" && data1.length <1 && invalid===false ? <h6 style={{color:"red"}}>No results found in lab reports</h6> :  searchTerm!==null && searchTerm!=="" &&  data1.length <1 && invalid===true ?<h6 style={{color:"red"}}>Unsupported language used for search!</h6>: null :null}

            {<br/>}
          {data2.length>0 ?
          <div>
            <p style={{display:'inline-block',fontSize:'20px'}} className="text-muted">Showing results in </p>{'  '}
            <h3 style={{display:'inline-block'}} className="additional-text">Messages</h3></div> : null}
            

          {data2.length>0 ?
            display2 : null}

          {category==='messages' ? searchTerm!==null && searchTerm!=="" &&  data2.length <1 && invalid===false ? <h6 style={{color:"red"}}>No results found in messages</h6> :  searchTerm!==null && searchTerm!=="" &&  data2.length <1 && invalid===true ?<h6 style={{color:"red"}}>Unsupported language used for search!</h6>: null :null} 

          {category===''? searchTerm!==null && searchTerm!=="" &&  data.length<1 && data1.length<1 && data2.length<1 && invalid===false ?  <h6 style={{color:"red"}}>No results found</h6> : searchTerm!==null && searchTerm!=="" &&  data.length<1 && data1.length<1 && data2.length<1 && invalid===true ? <h6 style={{color:"red"}}>Unsupported language used for search!</h6>: null :null}
          </>
}
    return (
        <> <HospDocNavbar /><br/>
        <div className=" text-left">
        
          <button className="btn btn-info back-btn" onClick={handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back</button> 
    </div> 
        <div className="search-patient-page">
     <h3 className="title-of-tasks"> Search in Patient Record ID: {id}</h3>
     
    
    {/*<label for="category" style={{position:'relative',top:'40px',left:'-350px',fontSize:'20px',fontWeight:'bold'}} >Select a Category :</label>*/}
    
<div className="search-section general-search-section">
<div className="flex-row ">
<form onSubmit={handleSubmit}>
          <div className="search-div">
            <input
              className="form-control search-input"
              type="text"
              placeholder="Search Here"
              value={searchTerm}
              maxLength="150"
              onChange={handleSearchChange}
            />
            <button type="submit" className="btn btn-primary btn-col search-btn">
              {" "}
              <i className="fas fa-search"></i>{" "}
            </button>        </div>
        </form>

        <select name="category"  value={category} onChange={handleChange}>
      <option value=''>All</option>
      <option value='prescriptions'>Prescriptions</option>
      <option value='reports'>Lab Reports</option>
      <option value='messages'>Messages</option>
    </select>
</div>
        <div className="container search-results">

        {resultDisplay}

       
        </div>
    </div>
    {messagesShow ?
    <MessagesPopup 
    show={messagesShow}
    onHide={handleTrack}
    id={id}
    msgId={msgId}
    

   /> : null }

{prescShow ?
    <PrescPopup 
    show={prescShow}
    onHide={()=> setPrescShow(false)}
    prescid ={prescId}
    patientid= {id}
    

   /> : null }

{reportShow ?
    <ReportPopup 
    show={reportShow}
    onHide={()=> setReportShow(false)}
    reportid ={reportId}
    patientid= {id}
    

   /> : null }


</div>
            
        </>
    )
}

export default GeneralSearch
