import React,{useState} from 'react'
import Select from 'react-select';
import axios from "axios";
import {APIURL} from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import DatePicker from 'react-date-picker';

function EditTest(props) {

    const[data, setData]= useState(props.data)
   const [group, setGroup]= useState({value:props.data.group, label: props.data.group})
   const [country1, setCountry1]= useState({value:props.data.country, label: props.data.country}
    )

    const [successShow, setSuccessShow] = useState(false)
    const [errorShow, setErrorShow] = useState(false)

   // const [year, setYear]= useState(data.year_of_manufacture=== null ? '' : new Date(data.year_of_manufacture))
    //const [expiry, setExpiry]= useState(data.expiry_date=== null ? '' : new Date(data.expiry_date))

  //  const [yearData, setYearData]= useState(data.year_of_manufacture)
    //const [expiryData, setExpiryData]= useState(data.expiry_date)
    const group1 = [
        { value: "male", label: "MALE" },
        { value: "female", label: "FEMALE" },
        { value: "kids", label: "KIDS" },
        { value: "pregnancy", label: "PREGNANCY" },
        { value: "foetal", label: "FOETAL" },
        { value: "others", label: "OTHERS" },
        { value: "na", label: "NA" },
      ];
    
      const country = [
        { value: "India", label: "INDIA" },
        { value: "US", label: "USA" },
      ];

const handleServiceChange= (data) => {
  setGroup(data)
}

const handleUnitChange= (data) => {

  setCountry1(data)
}


    const SuccessPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4 className='text-success'>Updated Successfully</h4>
             
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
             
            
            </Modal.Footer>
          </Modal>
        );
      }
    
      const ErrorPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <h5 style={{color: "red"}}>Error in data submission.Please try again!</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
             
            
            </Modal.Footer>
          </Modal>
        );
      }

      const handleTitle = (e)=> {
        e.preventDefault();        
        const val=e.target.value;
        const key=e.target.name;
        setData( current => ({
            ...current , [key] : val
        }))
      }

//       const handleServiceChange= (data) => {

//         setType(data)
//    }

//    const handleUnitChange= (data) => {

//         setUnit(data)
//   }

//   const handleRequest = (e)=> {
//    const val = e.target.value
//    const key = e.target.name

//    setData((current) => ({
//        ...current , [key] : val==='true'? true : val==='false'? false : null
//    }))

//   }

//   const handleYear = (date)=> {
//     setYear(date);
      
//       let dateUpdated= date;
//         if(date!==null){
//       const offset = date.getTimezoneOffset();
      
//       dateUpdated = new Date(date.getTime() - (offset*60*1000))
      
//       let DateExtracted = dateUpdated.toISOString().split('T')[0];
      
//       /*let dateString= DateExtracted.toString(); */
//       setYearData(DateExtracted);
      
//       }
//   }

//   const handleExpiry = (date)=> {
//     setExpiry(date);
      
//       let dateUpdated= date;
//         if(date!==null){
//       const offset = date.getTimezoneOffset();
      
//       dateUpdated = new Date(date.getTime() - (offset*60*1000))
      
//       let DateExtracted = dateUpdated.toISOString().split('T')[0];
      
//       /*let dateString= DateExtracted.toString(); */
//       setExpiryData(DateExtracted);
      
//       }
//   }


const saveChanges = (e)=> {
    e.preventDefault();


        const tokenString = sessionStorage.getItem("usertoken");
 
     let v2 = tokenString.replace(
       /["]+/g,
       ""
     ); /* REGEX To remove double quotes from tokenstring */
     const dataToSend = {
        test_name: data. test_name,
        test_sample: data.test_sample,
        test_method: data.test_method,
        test_type: data.test_type,
        unit: data.unit,
        min_ref_range: data.min_ref_range,
        max_ref_range: data.max_ref_range,
        group: data.group.value,
        country: data.country.value,
        is_verified : false,
        cpt_code: data. cpt_code,
        description: data.description,
       asso_disease: data.asso_disease,
      };
 
     axios
       .put(`${APIURL}/api/v1/staff/master-labtest-detail/${data.id}/`, dataToSend , {
         headers: { Authorization: "Token " + v2 },
       })
       .then((res) => {
         if (res.status==200) {
          setSuccessShow(true);
          setData({
            testname: "",
    testsample: "",
    testmethod: "",
    unit: "",
    minrefrange: "",
    maxrefrange: "",
    group: { value: "", label: "" },
    country: { value: "", label: "" },
    cptcode: "",
    description: "",
    disease: "",
         });}
          else {
           setErrorShow(true)
         }
       })
       .catch((err) => {
         setErrorShow(true)
       });
       setSuccessShow(false)
    }




    return (
        <>
    <h2 style={{ marginTop: "0" }} className="title-of-page">
        Edit Lab Test
      </h2>
      <div style={{ maxHeight: "1000px" }} className="config-box fit-content">
        <div className="feature-data pad-features">
          <div className="flex-col justify-feature">
            <span className="align-rental">*Test Name: </span>
            <input
              type="text"
              className="form-control title-flex"
              name="test_name"
              value={data.test_name}
              onChange={handleTitle}
            />
           
          </div>

          <div className="flex-col justify-feature">
            <span className="align-rental">*Test Method : </span>
            <input
              type="text"
              className="form-control title-flex"
              name="test_method"
             
              value={data.test_method}
              onChange={handleTitle}
            />
         
          </div>
          <div className="flex-col justify-feature">
            <span className="align-rental">Test Type : </span>
            <input
              type="text"
              className="form-control title-flex"
              name="test_type"
             
              value={data.test_type}
              onChange={handleTitle}
            />
         
          </div>

          <div className="flex-col justify-feature">
            <span className="align-rental">*Test Sample : </span>
            <input
              type="text"
              className="form-control title-flex"
              name="test_sample"
             
              value={data.test_sample}
              onChange={handleTitle}
            />
          
          </div>
          <div className="flex-col justify-feature">
            <span className="align-rental">*Unit: </span>
            <input
              type="text"
              className="form-control title-flex"
              name="unit"
              min="0"
              max="100"
              value={data.unit}
              onChange={handleTitle}
            />
           
          </div>
          <div className="flex-col justify-feature">
            <span className="align-rental">*Min Range: </span>
            <input
              type="number"
              className="form-control title-flex"
              name="min_ref_range"
            
              max = "100"
              value={data.min_ref_range}
              onChange={handleTitle}
            />
          
          </div>
          <div className="flex-col justify-feature">
            <span className="align-rental">*Max Range: </span>
            <input
              type="number"
              className="form-control title-flex"
              name="max_ref_range"
              min = "0"
              max = "100"
              value={data.max_ref_range}
              onChange={handleTitle}
            />
            
          </div>
         <div className="flex-col justify-feature">
            <span className="align-rental">CPT Code: </span>
            <input
              type="text"
              className="form-control title-flex"
              name="cpt_code"
              value={data.cpt_code}
              onChange={handleTitle}
            />
           
          </div>
          <div className="flex-col justify-feature">
            <span className="align-rental">Associate Disease: </span>
            <input
              type="text"
              className="form-control title-flex"
              name="asso_disease"
              value={data.asso_disease}
              onChange={handleTitle}
            />
             
          </div>
          <div className="flex-col justify-feature">
            <span className="align-rental">Description: </span>
            <textarea
              type="text"
              className="form-control title-flex"
              name="description"
              value={data.description}
              onChange={handleTitle}
            />
            
          </div>

          <div className="flex-row name-content">
            <span className="align-rental">*Group: </span>
            <Select
             defaultValue={group}
              
              onChange={(data, e) => {
                handleServiceChange(data, e);
              }}
              options={group1}
              name="group"
              className="select-currency select-flex"
            />
         
          </div>

           <div className="flex-row name-content">
            <span className="align-rental">*Country: </span>
            <Select
              defaultValue={country1}
              
              onChange={(data, e) => {
                handleUnitChange(data, e);
              }}
              options={country}
              name="country"
              className="select-currency select-flex"
            />
          
          </div>
        
       
        </div>

        <div className="text-center">
          <button onClick={saveChanges} className="btn btn-primary btn-col mb-2">
            Save
          </button>
        </div>
            {successShow ? 
<SuccessPopUp
show={successShow}
onHide={() =>  {setSuccessShow(false);props.modalClose() }}
/> 
: null}

{errorShow ? 

<ErrorPopUp
show={errorShow}
onHide={() =>  {setErrorShow(false)}}
/> 
: null}
            
        </div>
        </>
    )
}

export default EditTest
