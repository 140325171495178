import React, { useState, useRef, useEffect, useContext } from "react";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import "./hosppatientrecord.css";
import alanBtn from '@alan-ai/alan-sdk-web';
import { Link, useHistory } from "react-router-dom";
import DatePicker from "react-date-picker";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import "./reacttable.css";
import moment from "moment";
import FoodHabits from "../FoodHabits/FoodHabits";
import GlucoseVitals from "../GlucoseVitals/GlucoseVitals";
import AddPrescription from "../HospDr_AddPrescription/AddPrescription";
import VitalsBP from "../VitalsBP/VitalsBP";
import VitalsPulse from "../VitalsPulse/VitalsPulse";
import VitalsChol from "../VitalsChol/VitalsChol";
import HospDocNavbar from "../HospDocNavbar/HospDocNavbar";
import DrNotes from "../DrNotes/DrNotes";
import TargetSet from "../TargetSet/TargetSet";
import CurrentMedications1 from "./CurrentMedications1";
import WeightAdd from "./WeightAdd";
import HeightAdd from "./HeightAdd";
import AddObs from "../Observations/AddObs";
import ViewSymptoms from "../Symptoms/ViewSymptoms";
import AddSymptoms from "../Symptoms/AddSymptoms";
import ViewAller from "../Allergy/ViewAller";
import AddAller from "../Allergy/AddAller";
import { APIURL } from "../../Global";
import { TargetContext } from "../../contexts/TargetContext";
import GeneralSearchBar from "../GeneralSearch/GeneralSearchBar";
import PastMedications from "../HospDr_AddPrescription/PastMedications";
import ImageUpdate from "./ImageUpdate";
import AddRemark from "../ServiceRequests/AddRemark";
import AddPulse from "../Vitals/AddPulse";
import AddBp from "../Vitals/AddBp";
import AddCholestrol from "../Vitals/AddCholestrol.js";
import AddMedicinesPat from "../HospDr_AddPrescription/AddMedicinesPat";
import AddSugar from "../Vitals/AddSugar";
import { Suspense, lazy } from "react";
import EditRemark from "../HospDr_AddPrescription/EditRemark";
import ViewRemark from "../HospDr_AddPrescription/ViewRemark";
import ProcedureHistory from "../ProcedureHistory/ProcedureHistory";
import ProcedureList from "../ProcedureFulfillment/ProcedureList";
const Inference = React.lazy(() => import("../Inference/Inference"));
const TargetBasic = React.lazy(() => import("../TargetSet/TargetBasic"));
const CurrentMedications = React.lazy(() =>
  import("../HospDr_AddPrescription/CurrentMedications")
);

const Chronic = React.lazy(() => import("../Chronic/Chronic"));
const Comorbidities = React.lazy(() =>
  import("../Comorbidities/Comorbidities")
);
const HospDrMessages = React.lazy(() =>
  import("../HospDrMessages/HospDrMessages")
);
const ViewObs = React.lazy(() => import("../Observations/ViewObs"));
const Diagnosis = React.lazy(() => import("./Diagnosis"));

function PatientRecord(props) {
  const [height, setHeight] = useState();
  const [maritalStatus, setMaritalStatus] = useState();
  const [weight, setWeight] = useState();
  const [age, setAge] = useState();
  const [summary, setSummary] = useState("");
  const [refresh, setRefresh] = useContext(TargetContext);
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();
  const ref5 = useRef();
  const ref6 = useRef();
  const ref7 = useRef();
  const ref8 = useRef();
  const ref9 = useRef();
  const ref10 = useRef();
  const ref11 = useRef();
  const [id] = useState(props.match.params.id);
  const [, setuserDetails] = useState();
  const [name, setName] = useState();
  const [gender, setGender] = useState();
  const [dob, setDob] = useState();
  const [imgUrl, setImgUrl] = useState();
  const [medRefresh, setMedRefresh] = useState(false);
   const [addPrescShow1, setAddPrescShow1] = useState(false);
  const [instructions, setInstructions] = useState();
  const [instructionsDate, setInstructionsDate] = useState();
  const [addWeight, setAddWeight] = useState(false);
  const [heightAdd, setAddHeight] = useState(false);
  const [weightAddShow, setWeightAddShow] = useState(false);
  const [heightAddShow, setHeightAddShow] = useState(false);
  const [, setInstructionsArray] = useState([]);
  const [next_visit, setNext_visit] = useState(null);
  const [next, setNext] = useState();
  const [editweight, seteditweight] = useState();
  const [editheight, seteditheight] = useState();
  const [testsList, setTestsList] = useState([
    {
      id: "",
      test: "",
      date: "",
      labtest_reason: { details: "", image: "", reason: "" },
    },
  ]);
  const [scanList, setScanList] = useState([
    {
      id: "",
      test: "",
      date: "",
      scan_details: { details: "", image: "", reason: "" },
    },
  ]);
  const [popupShow, setPopupShow] = useState(false);
  const [imgEditShow, setImgEditShow] = useState(false);
  const [reasonAddShow, setReasonAddShow] = useState(false);
  const [imgViewShow, setImgViewShow] = useState(false);
  const [flag, setFlag] = useState(false);
  const [reasonEditShow, setReasonEditShow] = useState(false);
  const [index, setIndex] = useState();
  const [editShow, setEditShow] = useState(false);
  const [editShow1, setEditShow1] = useState(false);
  const [addShow, setAddShow] = useState(false);
  const [viewShow, setViewShow] = useState(false);
  const [remarkViewShow, setRemarkViewShow] = useState(false);
  const [detailData, setDetailData] = useState("");
  const [cell, setCell] = useState("");
  const [editSuccess, setEditSuccess] = useState(false);
  const [sizeShow, setSizeShow] = useState(false);
  const [validShow, setValidShow] = useState(false);
  const [contentShow, setContentShow] = useState(false);
  const [uploadShow, setUploadShow] = useState(false);
  const [tableRefresh, setTableRefresh] = useState(false);
  const [searchAssign, setSearchAssign] = useState("");
  const [displayDoctor, setDisplayDoctor] = useState(false);
  const [doctorId, setDoctorId] = useState("");
  const [doctorName, setDoctorName] = useState("");
  const [assignList, setAssignList] = useState([]);

  const [submitMsg, setSubmitMsg] = useState("");
  const [submitMsg1, setSubmitMsg1] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [deleteNextMsg, setDeleteNextMsg] = useState("");
  const [deleteNextModalShow, setDeleteNextModalShow] = useState(false);
  const [addPulse, setAddPulse] = useState(false);
  const [addBp, setAddBp] = useState(false);
  const [addChol, setAddCholestrol] = useState(false);
  const [addSugar, setAddSugar] = useState(false);

  const [submitInstructionsMsg, setSubmitInstructionsMsg] = useState("");
  const [modalInstructionsShow, setModalInstructionsShow] = useState(false);
  const [, setPatientData] = useState();
  const [, setDisplaySuggestions] = useState(false);
  const [, setDisplaySuggestions1] = useState(false);
  const [, setDisplaySuggestions2] = useState(false);
  const [options, setOptions] = useState([]);
  const [display, setDisplay] = useState(false);
  const [display1, setDisplay1] = useState(false);
  const [display2, setDisplay2] = useState(false);
  const [display3, setDisplay3] = useState(false);
  const [search, setSearch] = useState("");
  const [searchScan, setSearchScan] = useState("");
  const [scanSuggest, setScanSuggest] = useState();
  const [currentIndex, setCurrentIndex] = useState("");
  const [, setCurrentColumn] = useState("");
  const [testListCounter, setTestListCounter] = useState(0);
  const [scanListCounter, setScanListCounter] = useState(0);
  const wrapperRef = useRef(null);

  const [render, setRender] = useState(false);
  const [reRender, setReRender] = useState(false);
  const [, setDateVal] = useState("");
  const [deleteScanConfirmMsg, setDeleteScanConfirmMsg] = useState("");
  const [deleteScanConfirmModalShow, setDeleteScanConfirmModalShow] =
    useState(false);
  const [deleteNextVisitConfirmMsg, setDeleteNextVisitConfirmMsg] =
    useState("");
  const [deleteNextVisitConfirmModalShow, setDeleteNextVisitConfirmModalShow] =
    useState(false);

  const [scanIndexToDelete, setScanIndexToDelete] = useState();
  const [deleteTestConfirmMsg, setDeleteTestConfirmMsg] = useState("");
  const [deleteTestConfirmModalShow, setDeleteTestConfirmModalShow] =
    useState(false);
  const [testIndexToDelete, setTestIndexToDelete] = useState();
  const [showTestTable, setShowTestTable] = useState(false);
  const [showScanTable, setShowScanTable] = useState(false);
  const [testErr, setTestErr] = useState("false");
  const [scanErr, setScanErr] = useState("false");
  const [updatedTestList, setUpdatedTestList] = useState([]);
  const [updatedScanList, setUpdatedScanList] = useState([]);

  const [submitTrigger, setSubmitTrigger] = useState(true);
  const [errorModalShow, setErrorModalShow] = useState(false);
  const [errorScanShow, setErrorScanShow] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState(false);
  const [errorSubmit1, setErrorSubmit1] = useState(false);
  const [foodHabitsShow, setFoodHabitsShow] = useState(false);
  const [glucoseShow, setGlucoseShow] = useState(false);
  const [bpShow, setBpShow] = useState(false);
  const [pulseShow, setPulseShow] = useState(false);
  const [cholesterolShow, setCholesterolShow] = useState(false);
  const [, setScanShow] = useState(false);
  const [, setTestShow] = useState(false);
  const [addPrescShow, setAddPrescShow] = useState(false);
  const [drNotesShow, setDrNotesShow] = useState(false);
  const [targetShow, setTargetShow] = useState(false);
  const [inferenceShow, setInferenceShow] = useState(false);
  const [observationsShow, setObservationsShow] = useState(false);
  const [observationsArray, setObservationsArray] = useState([]);
  const [symptoms, setSymptoms] = useState([]);
  const [symptomsShow, setSymptomsShow] = useState(false);
  const [refreshInstr, setRefreshInstr] = useState(false);
  const [timing] = useState(200);
  const [isBooped, setIsBooped] = useState(false);
  const [allergy, setAllergy] = useState([]);
  const [allergyArray, setAllergyArray] = useState([]);
  const [allergyShow, setAllergyShow] = useState(false);
  const [errorNextModalShow, setErrorNextModalShow] = useState(false);
  const [nextErr, setNextErr] = useState("nil");
  const [messagesShow, setMessagesShow] = useState(false);
  const [summaryDone, setSummaryDone] = useState("");
  const [prescForm, setPrescForm] = useState(false);
  const [userID, setUserID] = useState(0);
  const freq_link = `analysis/${id}`;
  const [procedureRefresh, setProcedureRefresh]= useState(false)
  const [searchProcedure, setSearchProcedure] = useState("");
  const [procedureSuggest, setProcedureSuggest] = useState();
  const [editData, setEditData] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [procedureList, setProcedureList] = useState([
    {
      procedure_details: {
        procedure_name: "",
        procedure_id: "",
        procedure_type: "",
        amount: "",
      },
      date_of_procedure: new Date().toISOString(),
      area: "",
      session: "",
      quantity: "",
      details: "",
      reason: "",
      image: "",
      assign_name: "",
      status: "",
    },
  ]);

  const [procedureListCounter, setProcedureListCounter] = useState(0);
  const [procedureReasonShow, setProcedureReasonShow] = useState(false);
  const [deleteProcedureConfirmMsg, setDeleteProcedureConfirmMsg] =
    useState("");
  const [deleteProcedureConfirmModalShow, setDeleteProcedureConfirmModalShow] =
    useState(false);
  const [procedureIndexToDelete, setProcedureIndexToDelete] = useState();
  const [procedureErr,setProcedureErr]=useState("");
  const [errorShow, setErrorShow]= useState(false)
  const [updatedProcedureList,setUpdatedProcedureList]=useState([]);
  const [historyShow, setHistoryShow] = useState(false);
  const alanBtnRef = useRef({}).current;





  let history = useHistory();

  let formData = new FormData();
  const reader = new FileReader();

  const setDefaultDoctor = () => {
    const hosp_doc_data =sessionStorage.getItem('hosp_doc_data');
    if(hosp_doc_data) {
      const {doctor_name, user_id} = JSON.parse(hosp_doc_data);
      setDoctorId(user_id);
      setDoctorName(doctor_name);
    }
  }

  const [keys] = useState({
    p: false,
    a: false,
    n: false,
    m: false,
    l: false,
    s: false,
  });
  let track = null;

  const hospId = props.location.hospId;

  useEffect(() => {
    let getPath = JSON.parse(sessionStorage.getItem("logindatas"));

    setUserID(getPath.user_id);

    if (hospId !== undefined) {
      sessionStorage.setItem("hospId", hospId);
    }
  }, []);

  useEffect(() => {



    alanBtn({
          key: '0b7c9da3c6b59e806a0b8a1e4fe8029c2e956eca572e1d8b807a3e2338fdd0dc/stage',
          rootEl: document.getElementById("alan-btn"),
          bottom: '50px',
          left: '50px',
          zIndex: 10,
          onCommand :({command})=>{
           

          
            console.log("sdgfsghdfhd",command)
                    if(command==="current medications"){
                      //alert("testdata")
                      handleClick(ref4);
                    }
                    
                    if(command==="add prescription"){
                     // alert("testdata")
                     history.push("/prescription-form");
                   
                      
                    }
                    if(command==="past medications"){
                      // alert("testdata")
                      setAddPrescShow(true);
                    
                       
                     }
                    if(command==="inference and impact analysis"){
                      //alert("testdata")
                      handleClick(ref2);
                    }
                    if(command==="diagnostics info"){
                      //alert("testdata")
                      handleClick(ref3);
                    }
                    if(command==="observations"){
                      //alert("testdata")
                      handleClick(ref5);
                    }
                    if(command==="symptoms"){
                      //alert("testdata")
                      handleClick(ref6);
                    }
                    if(command==="allergy"){
                      //alert("testdata")
                      handleClick(ref7);
                    }
                    if(command==="health info and medical records"){
                      //alert("testdata")
                      handleClick(ref8);
                    }
                    if(command==="personal info"){
                      //alert("testdata")
                      handleClick(ref1);
                    }
                    if(command==="treatment planner"){
                      //alert("testdata")
                      handleClick(ref9);
                    }
                    if(command==="instructions"){
                      //alert("testdata")
                      handleClick(ref10);
                    }
                    if(command==="doctors note"){
                      //alert("testdata")
                      setDrNotesShow(true);
                    }
                    if(command==="frequent medicines or test analysis"){
                      //alert("testdata")
                      history.push(`analysis/${id}`);
                    }
                    if(command==="goto target tables"){
                      //alert("testdata")
                     
                    setTargetShow(true);
                    }
                    if(command==="view details"){
                      //alert("testdata")
                     
                      setInferenceShow(true);
                    }
                    if(command==="weight"){
                      //alert("testdata")
                     
                      setWeightAddShow(true);
                    }
                    if(command==="height"){
                      //alert("testdata")
                     
                      setHeightAddShow(true);
                    }
                    if(command==="glucose"){
                      //alert("testdata")
                     
                      setGlucoseShow(true);
                    }
                    if(command==="bp"){
                      //alert("testdata")
                     
                      setBpShow(true);
                    }
                    if(command==="cholestrol"){
                      //alert("testdata")
                     
                      setCholesterolShow(true);
                    }
                    if(command==="pulse"){
                      //alert("testdata")
                     
                      setPulseShow(true);
                    }
                    if(command==="add observarions"){
                      //alert("testdata")
                     
                      setObservationsShow(true);
                    }
                    if(command==="add symptoms"){
                      //alert("testdata")
                     
                      setSymptomsShow(false);
                    }
                    if(command==="add allergy"){
                      //alert("testdata")
                    
                      setAllergyShow(false);
                    }
                    if(command==="test lab report"){
                      //alert("testdata")
                      history.push(  `/lab-reports/${id}`);
                    }
                    if(command==="patient prescription"){
                      //alert("testdata")
                      history.push( `/patient-prescriptions/${id}`);
                    }
                    if(command==="discharge summary"){
                      //alert("testdata")
                      history.push(`/patient-discharge-summary/${id}`);
                    }
                    if(command==="patient scan report"){
                      //alert("testdata")
                      history.push(`/patient-scan-report/${id}`);
                    }
                    if(command==="fitness data"){
                      //alert("testdata")
                      history.push("/in-progress");
                    }
                    if(command==="food habit"){
                      //alert("testdata")
                    
                      setFoodHabitsShow(true);
                    }
                   
                   
                   
                  
                   
                    
                    
                  }

      });
      
    }, []);

  useEffect(() => {
    window.onkeydown = (e) => {
      keys[e.key] = true;

      if (e.altKey && keys["p"]) {
        history.push("/patients-directory");
      }
      if (e.altKey && keys["a"]) {
        history.push("/appointment");
      }
      if (e.altKey && keys["n"]) {
        history.push("/doctor/notifications");
      }
      if (e.altKey && keys["m"]) {
        history.push("/message");
      }
      if (e.altKey && keys["l"]) {
        history.push("/doctor/analytics");
      }
      if (e.altKey && keys["s"]) {
        history.push("/service-requestlist");
      }
    };
    window.onkeyup = (ev) => {
      keys[ev.key] = false;
    };

    return () => {
      window.onkeydown = null;
      window.onkeyup = null;
    };
  }, []);
  const handleAddPrescOverlay1 = (e) => {
    e.preventDefault();
    setAddPrescShow1(true);
  };

  useEffect(() => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    if (testErr === "nil" && scanErr === "nil") {
      const scans = updatedScanList;
      const tests = updatedTestList;

      for (let i = 0; i < scans.length; i++) {
        if (
          scans[i].scan_details.image instanceof File === true ||
          scans[i].scan_details.image === ""
        ) {
          formData.append(`scans[${i}]test`, scans[i].test);
          formData.append(`scans[${i}]date`, scans[i].date);
          formData.append(
            `scans[${i}]patient_scan_details`,
            scans[i].scan_details.details
          );
          formData.append(
            `scans[${i}]patient_scan_reason`,
            scans[i].scan_details.reason
          );
          formData.append(
            `scans[${i}]patient_scan_details_image`,
            scans[i].scan_details.image
          );
        }
      }
      for (let i = 0; i < tests.length; i++) {
        if (
          tests[i].labtest_reason.image instanceof File === true ||
          tests[i].labtest_reason.image === ""
        ) {
          formData.append(`tests[${i}]test`, tests[i].test);
          formData.append(`tests[${i}]date`, tests[i].date);
          formData.append(
            `tests[${i}]patient_lab_test_details`,
            tests[i].labtest_reason.details
          );
          formData.append(
            `tests[${i}]patient_lab_test_details_image`,
            tests[i].labtest_reason.image
          );
          formData.append(
            `tests[${i}]patient_lab_test_reason`,
            tests[i].labtest_reason.reason
          );
        }
      }
      formData.append("patient_id", id);
      formData.append("doctor_id", userID);

      if (next_visit !== null) {
        formData.append("next_visit", next_visit);
      }

      formData.append("quick_summary", summary);

      axios
        .post(`${APIURL}/api/v1/doctor/patient-record/`, formData, {
          headers: {
            Authorization: "Token " + str,
            "Content-Type": "application/json",
          },
        })

        .then((res) => {
          if (res.data.status === "success") {
            setSubmitMsg("success");
            setModalShow(true);
          } else {
            setSubmitMsg("error");
            setErrorSubmit(true);
          }
        })
        .catch((err) => {
          setSubmitMsg("error");
          setErrorSubmit(true);
        });
    }
  }, [testErr !== "nil", scanErr !== "nil", submitTrigger]);

  useEffect(() => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/hospital/procedure-assignee/?q=${searchAssign}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setAssignList(res.data.details);
          setDefaultDoctor();
        }
      })
      .catch((err) => {});
  }, [searchAssign]);

  useEffect(() => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/doctor/lab-tests/?per_page=20&q=${search}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setOptions(res.data.lab_tests);
        }
      })
      .catch((err) => {});
  }, [search]);

  useEffect(() => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/doctor/scan-search/?q=${searchScan}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setScanSuggest(res.data.scans);
        }
      })
      .catch((err) => {});
  }, [searchScan]);

  useEffect(() => {

    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(
        `${APIURL}/api/v1/hospital/hospital-procedure/?q=${searchProcedure}`,
        {
          headers: {
            Authorization: "Token " + str,
          },
        }
      )
      .then((res) => {
     

        if (res.status == 200) {
          // const procedures = res.data.hospital_procedures
          // procedures.map(item => {
          //   return(

          //   )
          // })

          setProcedureSuggest(res.data.details);
        }
      })
      .catch((err) => {});
  }, [searchProcedure]);
  
  const handleMed = () => {
    setAddPrescShow(false);
    setMedRefresh(!medRefresh);
  };



  const AddRowProcedure = () => {

    const list = procedureList;
    console.log(list)
    list.push({
      procedure_details: {
        procedure_name: "",
        procedure_id: "",
        procedure_type: "",
        amount: "",
      },
      date_of_procedure: new Date().toISOString(),
      area: "",
      session: "",
      quantity: "",
      details: "",
      reason: "",
      image: "",
      assign_name: "",
      status: "",
    });

    setProcedureList(list);
    setRender(true);
  };



  const handleProcedureReasonEdit = (data, index) => {
    setDetailData(data);
    setCell(index);
    setProcedureReasonShow(true);
  };

  const DeleteProcedureConfirmPopup =(props)=>{
    

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Please confirm to delete Scheduled Procedure</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Cancel</Button>
  
          <Button variant="danger" onClick={()=>{setDeleteProcedureConfirmModalShow(false);
                                                                 handleDeleteProcedure(props.index);;
                                                                   setProcedureIndexToDelete(null);}}> Confirm</Button>
  
         
  
        </Modal.Footer>
      </Modal>
    );
  }

  const handleDeleteProcedure=(index)=>{

    // debugger;
    const tokenString= sessionStorage.getItem('usertoken');
     
     let str= tokenString.replace(/["]+/g, '');
      
    
     const dataToUpdate ={
       procedure_id: procedureList[index].procedure_details.procedure_id,
  
     }
  
  
    
      axios.delete(`${APIURL}/api/v1/hospital/hospital-patient-procedure-detail/${procedureList[index].procedure_details.procedure_id}`, { headers: {
        
        "Authorization" : 'Token '+str,
    }
  
  
      })
  
  
  
      .then(res=>{
        
     
        if(res.status==200){

          ProcedureGet();
          setProcedureRefresh(!procedureRefresh);


    }
    else{
      setSubmitMsg("error");
      
     
    }
  
      })
      .catch(err=>{
    
    setSubmitMsg("error");
  
  })
  
  
 
 
   }

  const handleDeleteRowProcedure = (index) => {
    setDeleteProcedureConfirmMsg("success");
    setDeleteProcedureConfirmModalShow("true");
    setProcedureIndexToDelete(index);
  };

  const deleteProcedureRow = (index) => {
    // debugger;
    // let newlist = [];

    // if (index > procedureListCounter - 1) {
    //   newlist = procedureList.filter((test, i) => {
    //     return i !== index;
    //   });

    //   setProcedureList(newlist);
    //   if (newlist.length <= 0) {
    //     setProcedureList([
    //       {
    //         procedure_details: {
    //           procedure_name: "",
    //           procedure_id: "",
    //           procedure_type: "",
    //           amount: "",
    //         },
    //         date_of_procedure: new Date().toISOString(),
    //         area: "",
    //         session: "",
    //         quantity: "",
    //         details: "",
    //         reason: "",
    //         image: "",
    //         assign_name: "",
    //         status: "",
    //       },
    //     ]);
    //   }
    // } else {
      handleDeleteRowProcedure(index);
    
  };

  useEffect(() => {
    // debugger;
    ProcedureGet();
  }, []);

  const ProcedureGet = () =>
  {
    setProcedureList([
      {
        procedure_details: {
          procedure_name: "",
          procedure_id: "",
          procedure_type: "",
          amount: "",
        },
        date_of_procedure: new Date().toISOString(),
        area: "",

        session: "",
        quantity: "",
        details: "",
        reason: "",
        image: "",
        assign_name: "",
        status: "",
      },
    ]);

    setProcedureListCounter(0);

    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/hospital/hospital-patient-procedure/?patient_id=${id}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          if (res.data.procedures.length > 0) {
            const data = res.data.procedures;
            const filteredData = data.filter((item) => {
              return item.status !== "completed";
            });

            if (filteredData.length < 1) {
              setProcedureList([
                {
                  procedure_details: {
                    procedure_name: "",
                    procedure_id: "",
                    procedure_type: "",
                    amount: "",
                  },
                  date_of_procedure: new Date().toISOString(),
                  area: "",
                  session: "",
                  quantity: "",
                  details: "",
                  reason: "",
                  image: "",
                  assign_name: "",
                  status: "",
                },
              ]);
            } else {
              // added_by: "George David"
              // amount_paid: 0
              // area: "a"
              // assign: 1033
              // assign_to: "cvgigi LSax"
              // created_at: "2022-01-13T16:10:54.363887Z"
              // date_of_procedure: "2022-01-13"
              // details: "a"
              // id: 18
              // image: null
              // modified_at: "2022-01-13T16:10:54.363954Z"
              // patient: "Abdul Majid"
              // patient_procedure: "cleantes"
              // payment_status: "not_paid"
              // procedure: null
              // quantity: "1"
              // reason: "dddd"
              // session: "a"
              // status: "pending"

              //

              let valuesArrays = [];
              for (let index = 0; index < filteredData.length; index++) {
                valuesArrays.push({
                  date_of_procedure: filteredData[index].date_of_procedure,
                  area: filteredData[index].area,
                  session: filteredData[index].session,
                  quantity: "1",
                  details: filteredData[index].details,
                  reason: filteredData[index].reason,
                  image: "",
                  assign_name: filteredData[index].assign_to,
                  status: filteredData[index].status,
                  procedure_details: {
                    procedure_name: filteredData[index].patient_procedure,
                    procedure_id: filteredData[index].id,
                    procedure_type:  filteredData[index].procedure_type,
                    amount: filteredData[index].procedure_amount,
                  },
                });
              }
              setProcedureList(valuesArrays)
             // let dbghg= valuesArrays
            }

           

           // setProcedureListCounter(data.length);
          }
        }
      })
      .catch((err) => {});
  }

  const procedureEdit = (data) => {
 
// debugger
    const procedures = data

    

    const tokenString= sessionStorage.getItem('usertoken');
  
    let str= tokenString.replace(/["]+/g, '');

    

    // const fd = new FormData();

    //     if(procedures.procedure_details.procedure_id){
    //       fd.append(`procedure_id`,procedures.procedure_details.procedure_id)
    //     }
    //     if(procedures.date_of_procedure){
    //       fd.append(`date_of_procedure`,procedures.date_of_procedure.split('T').join(" ").slice(0,16))
    //     }
    //     if(procedures.assign_id){
    //       fd.append(`assign_id`,procedures.assign_id)
    //     }
    //     if(procedures.details){
    //       fd.append(`details`,procedures.details)
    //     }  
          
          
          // fd.append(`area`,procedures.area)
          // fd.append(`session`,procedures.session)
          // fd.append(`quantity`,procedures.quantity)
          
          // fd.append(`reason`,procedures.reason)


  axios.put(`${APIURL}/api/v1/hospital/hospital-patient-procedure-detail/${procedures.procedure_id}/`,procedures, { headers: {
               
               "Authorization" : 'Token '+str,
               'Content-Type': 'application/json'  }

             })

             .then(res=>{
             // debugger
               if(res.status==200){
               setSubmitMsg("success");
               setModalShow(true);
 
           }
           else{
             setSubmitMsg("error");
             setErrorSubmit(true);
            
           }

             })
             .catch(err=>{
              // debugger
           setSubmitMsg("error");
           setErrorSubmit(true);
         })
  }




  const renderEditableProcedure = (cellInfo) => {
    if (
      cellInfo.index > procedureListCounter - 1 ||
      procedureListCounter === 0
    ) {
      return (
        <div ref={wrapperRef}>
          <input
            style={{ backgroundColor: "#fafafa" }}
            value={
              procedureList[cellInfo.index][cellInfo.column.id].procedure_name
            }
            className="form-control"
            name="procedure"
            onChange={(e) => {
              e.preventDefault();

              setCurrentIndex(cellInfo.index);
              setCurrentColumn(cellInfo.column.id);
              setDisplaySuggestions(true);
              setDisplay(true);

              setSearchProcedure(e.target.value);

              const procedures = procedureList;
              procedureList[cellInfo.index][cellInfo.column.id].procedure_name =
                e.target.value;

              setProcedureList(procedures);
            }}
            autoComplete="off"
          />

          {currentIndex === cellInfo.index &&
            cellInfo.column.id === "procedure_details" &&
            display && (
              <div className="suggestions-box-holder">
                 <button
                className="btn-suggestion btn btn-secondary btn-smallest"
                title="close"
                onClick={() => setDisplay(false)}
              >
                {" "}
                X{" "}
              </button>
              <div className="suggestions-box suggestions-assignee-box">
                {procedureSuggest.map((item, i) => {
                  return (
                    <div
                      className="scan-item"
                      key={item.id}
                      onClick={(e) => {
                        e.preventDefault();

                        setRender(!render);

                        const procedure = procedureList;
                        procedure[cellInfo.index][
                          cellInfo.column.id
                        ].procedure_id = item.id;
                        procedure[cellInfo.index][
                          cellInfo.column.id
                        ].procedure_name = item.procedure_name;
                        procedure[cellInfo.index][
                          cellInfo.column.id
                        ].procedure_type = item.procedure_type;
                        procedure[cellInfo.index][cellInfo.column.id].amount =
                          item.amount;

                        setProcedureList(procedure);
                        setDisplay(false);
                      }}
                    >
                      <div className="search-procedure">
                        <p className="search-item">{item.procedure_name}</p>
                        {/* //<p className="search-item">{item.procedure_type}</p> */}
                      </div>
                    </div>
                  );
                })}
              </div>
              </div>
            )}
        </div>
      );
    } else {
      return (
        <div>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col"
            name="date"
            value={
              procedureList[cellInfo.index][cellInfo.column.id].procedure_name
            }
            onBlur={(e) => {
              const procedure = procedureList;
              procedureList[cellInfo.index][cellInfo.column.id].procedure_name =
                e.target.innerHTML;

              setProcedureList(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html:
                procedureList[cellInfo.index][cellInfo.column.id]
                  .procedure_name,
            }}
          />
        </div>
      );
    }
  };
  const renderEditableProcedureDate = (cellInfo) => {
    if (cellInfo.index > procedureListCounter - 1) {
      const today = new Date();

      const offset = today.getTimezoneOffset();

      let dateUpdated = new Date(today.getTime() - offset * 60 * 1000);

      let DateExtracted = dateUpdated.toISOString().split("T")[0];
      return (
        <div>
          <input
            type="date"
            data-date={procedureList[cellInfo.index].date_of_procedure.slice(
              0,
              10
            )}
            className="scan-date-col date-custom-format"
            min={DateExtracted}
            onChange={(e) => {
              e.preventDefault();
              let date = e.target.value;
              let data = new Date(date);
              // const time = new Date().toLocaleTimeString()
              //  const final = date+time
              const dateData = data.toISOString();
              const procedure = procedureList;
              procedure[cellInfo.index].date_of_procedure = dateData;

              setProcedureList(procedure);
              const m = moment(date, "YYYY-MM-DD").format("YYYY-MM-DD");

              setDateVal(m);
            }}
          />
        </div>
      );
    } else {
      return (
        <div>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-date-col"
            name="date"
            value={procedureList[cellInfo.index].date_of_procedure.slice(0, 10)}
            onBlur={(e) => {
              const procedure = procedureList;
              procedure[cellInfo.index].date_of_procedure = e.target.innerHTML;

              setProcedureList(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: procedureList[cellInfo.index].date_of_procedure.slice(
                0,
                10
              ),
            }}
          />
        </div>
      );
    }
  };
  const renderProcedureArea = (cellInfo) => {
    if (cellInfo.index > procedureListCounter - 1) {
      return (
        <div>
          <input
            type="text"
            className="form-control"
            value={procedureList[cellInfo.index].area}
            placeholder="Enter Area"
            onChange={(e) => {
              e.preventDefault();
              let data = e.target.value;

              const procedure = procedureList;
              procedure[cellInfo.index].area = data;
              setTableRefresh(!tableRefresh);
              setProcedureList(procedure);
            }}
          />
        </div>
      );
    } else {
      return (
        <div>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col"
            name="date"
            value={procedureList[cellInfo.index].area}
            onBlur={(e) => {
              const procedure = procedureList;
              procedureList[cellInfo.index].area = e.target.innerHTML;

              setProcedureList(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: procedureList[cellInfo.index].area,
            }}
          />
        </div>
      );
    }
  };
  const renderProcedureType = (cellInfo) => {
    if (
      procedureList[cellInfo.index][cellInfo.column.id].amount === "" &&
      procedureList[cellInfo.index][cellInfo.column.id].procedure_name === ""
    ) {
      return <div>Select a Procedure</div>;
    } else {
      return (
        <div>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col"
            name="date"
            value={procedureList[cellInfo.index].session}
            onBlur={(e) => {
              const procedure = procedureList;
              procedureList[cellInfo.index][cellInfo.column.id].procedure_type =
                e.target.innerHTML;

              setProcedureList(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html:
                procedureList[cellInfo.index][cellInfo.column.id]
                  .procedure_type,
            }}
          />
        </div>
      );
    }
  };
  const renderProcedureSession = (cellInfo) => {
    if (cellInfo.index > procedureListCounter - 1) {
      return (
        <div>
          <input
            type="text"
            className="form-control"
            value={procedureList[cellInfo.index].session}
            placeholder="Enter Session"
            onChange={(e) => {
              e.preventDefault();
              let data = e.target.value;

              const procedure = procedureList;
              procedure[cellInfo.index].session = data;
              setTableRefresh(!tableRefresh);
              setProcedureList(procedure);
            }}
          />
        </div>
      );
    } else {
      return (
        <div>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col"
            name="date"
            value={procedureList[cellInfo.index].session}
            onBlur={(e) => {
              const procedure = procedureList;
              procedureList[cellInfo.index].session = e.target.innerHTML;

              setProcedureList(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: procedureList[cellInfo.index].session,
            }}
          />
        </div>
      );
    }
  };
  const renderProcedureQuantity = (cellInfo) => {
    if (cellInfo.index > procedureListCounter - 1) {
      return (
        <div>
          <input
            type="number"
            min="0"
            className="form-control"
            value={procedureList[cellInfo.index].quantity}
            placeholder="Qty"
            onChange={(e) => {
              e.preventDefault();
              let data = e.target.value;
              if (data.length < 4) {
                const procedure = procedureList;
                procedure[cellInfo.index].quantity = data;
                setProcedureList(procedure);
                setTableRefresh(!tableRefresh);
              }
            }}
          />
        </div>
      );
    } else {
      return (
        <div>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col"
            name="date"
            value={procedureList[cellInfo.index].quantity}
            onBlur={(e) => {
              const procedure = procedureList;
              procedureList[cellInfo.index].quantity = e.target.innerHTML;

              setProcedureList(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: procedureList[cellInfo.index].quantity,
            }}
          />
        </div>
      );
    }
  };

  const renderProcedurePrice = (cellInfo) => {
    if (
      procedureList[cellInfo.index][cellInfo.column.id].amount === "" &&
      procedureList[cellInfo.index][cellInfo.column.id].procedure_name === ""
    ) {
      return <div>Select a Procedure</div>;
    } else {
      return (
        <div>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col"
            name="date"
            value={procedureList[cellInfo.index][cellInfo.column.id].amount}
            onBlur={(e) => {
              const procedure = procedureList;
              procedureList[cellInfo.index][cellInfo.column.id].amount =
                e.target.innerHTML;

              setProcedureList(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: procedureList[cellInfo.index][cellInfo.column.id].amount,
            }}
          />
        </div>
      );
    }
  };

  // const renderProcedureDetails = (cellInfo) => {
  //   if (cellInfo.index > procedureListCounter - 1) {
  //     return (
  //       <div>
  //         <textarea
  //           className="text-area"
  //           placeholder="Add Procedure Details"
  //           cols="100"
  //           rows="2"
  //           onChange={(e) => {
  //             e.preventDefault();
  //             let data = e.target.value;

  //             const procedure = procedureList;
  //             procedure[cellInfo.index].details = data;

  //             setProcedureList(procedure);
  //           }}
  //         />
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <textarea
  //         className="scan-text"
  //         value={procedureList[cellInfo.index].details}
  //         cols="100"
  //         rows="2"
  //       />
  //     );
  //   }
  // };

  const renderProcedureDetails = (cellInfo) => {
  

    if(cellInfo.index > procedureListCounter-1){

        return (<div>
          <textarea type='text' className="text-area" value={procedureList[cellInfo.index].details} placeholder="Add Reason" onChange={e=>
    
                                  {
                                    e.preventDefault();
                                    let data=e.target.value;
                                   
                                    const procedure = procedureList;
                                    procedure[cellInfo.index].details = data;
                                    setTableRefresh(!tableRefresh)
              setProcedureList(procedure)
  
                                   }
    }
    /> 
                         </div>
    
          )
      }

      else{
        return (<div>
          <div
            style={{ backgroundColor: "#fafafa" }}
            
            className="form-control test-name-col"        
           name="date"
            
            
           
            value={procedureList[cellInfo.index].details}
           
            onBlur={(e)=>{const procedure = procedureList;
              procedureList[cellInfo.index].details = e.target.innerHTML;
              
              setProcedureList(procedure);
    
            
    
           }}
            
            
         autoComplete="off"
          dangerouslySetInnerHTML={{
              __html: procedureList[cellInfo.index].details
            }}
          />
    
           
    
             
           
            </div>)
    }
      
}


  const renderProcedureReason = (cellInfo) => {
  

      if(cellInfo.index > procedureListCounter-1){

          return (<div>
            <textarea type='text' className="text-area" value={procedureList[cellInfo.index].reason} placeholder="Add Reason" onChange={e=>
      
                                    {
                                      e.preventDefault();
                                      let data=e.target.value;
                                     
                                      const procedure = procedureList;
                                      procedure[cellInfo.index].reason = data;
                                      setTableRefresh(!tableRefresh)
                setProcedureList(procedure)
    
                                     }
      }
      /> 
                           </div>
      
            )
        }

        else{
          return (<div>
            <div
              style={{ backgroundColor: "#fafafa" }}
              
              className="form-control test-name-col"        
             name="date"
              
              
             
              value={procedureList[cellInfo.index].reason}
             
              onBlur={(e)=>{const procedure = procedureList;
                procedureList[cellInfo.index].area = e.target.innerHTML;
                
                setProcedureList(procedure);
      
              
      
             }}
              
              
           autoComplete="off"
            dangerouslySetInnerHTML={{
                __html: procedureList[cellInfo.index].reason
              }}
            />
      
             
      
               
             
              </div>)
      }
        
  }


  const renderAssigned = (cellInfo) => {
    return (
      <div>
        <input
          style={{ backgroundColor: "#fafafa" }}
          value={procedureList[cellInfo.index].assign_name}
          className="form-control"
          name="assignee"
          onChange={(e) => {
            e.preventDefault();

            const list = procedureList;
            list[cellInfo.index].assign = e.target.value;

            setProcedureList(list);

            setCurrentIndex(cellInfo.index);
            setCurrentColumn(cellInfo.column.id);

            setDisplay3(true);

            setSearchAssign(e.target.value);
          }}
        />

        {currentIndex === cellInfo.index &&
          cellInfo.column.id === "assign_name" &&
          display3 && (
            <div className="suggestions-box-holder">
              <button
                className="btn-suggestion btn btn-secondary btn-smallest"
                title="close"
                onClick={() => setDisplay3(false)}
              >
                {" "}
                X{" "}
              </button>

              <div className="suggestions-box suggestions-assignee-box">
                {assignList.map((item, i) => {
                  return (
                    <div
                      className="scan-item"
                      key={item.id}
                      onClick={(e) => {
                        e.preventDefault();

                        setRender(!render);

                        const List = procedureList;
                        //List[cellInfo.index].assign_id= item.id;
                        List[cellInfo.index].assign_name = item.full_name;
                        List[cellInfo.index].assign_id = item.id;
                      
                        setProcedureList(List);
                        setDisplay1(false);
                      }}
                    >
                      <div className="search-item each-option-suggestion">
                        <h6 className="">ID: {item.id} </h6>
                        <h6 className=""> {item.full_name}</h6>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
      </div>
    );
  };

  const handleAssignee = () => {
    const data = procedureList
    const sessionData = JSON.parse(sessionStorage.getItem('logindatas'))
  
    data.forEach(item => {
      if(item.assign_id==="" || item.assign_name===""){
        item.assign_id = sessionData.user_id
        item.assign_name = sessionData.name
      }
    })
  
    setProcedureList(data)
  }
  const handleSubmitProcedure = () => {
    // debugger
    let isValid = true
    handleAssignee()

  for (let index = 0; index < procedureList.length; index++) {
    var validation=procedureList[index].procedure_details.procedure_id;

    if (validation==""){
      procedureList.splice(index, 1);
    }

    
  }

  const updatedProcedure = procedureList.filter((row,i)=>{
    return (row.procedure_details.procedure_name!=="" && row.date_of_procedure!=="" && row.assign_id!=="" && row.assign_id !== undefined)
    
   })

   const procedureData = procedureList.filter((row,i)=> {
     return ( row.procedure_details.procedure_name!=="" || row.assign_id!=="" || row.details!=="")
   })

   procedureData.map((item)=> {
    if(item.procedure_details.procedure_name===""){
      setProcedureErr("*Procedure name is mandatory!")
      setErrorShow(true)
      isValid = false
    }
    if(item.date_of_procedure === ""){
      setProcedureErr("*Date in Procedure Table is mandatory!")
      setErrorShow(true)
      isValid = false
    }
    // if(item.assign_name === ""){
    //   setProcedureErr("*Assignee in Procedure Table is mandatory!")
    //   setErrorShow(true)
    //   isValid = false
    // }
    /*if(item.test==="" && item.date===""){
      setTestErr("Please fill the Test and Date Field in row " + (index+1))
      setErrorModalShow(true)
    }*/
    if(item.procedure_details.procedure_name !=="" && item.date_of_procedure !=="" && item.assign_id !== ""){
        setUpdatedProcedureList(updatedProcedure);

    }
  })

  if(isValid){
    procedureSubmit(procedureData)
  }

}
const procedureSubmit = async(ObjProcedure) =>
{




const tokenString= sessionStorage.getItem('usertoken');
  
let str= tokenString.replace(/["]+/g, '');

 

// if(ObjupdatedProcedure.length < 1){
//   return false
// }

//   for(let i=0 ; i<ObjupdatedProcedure.length; i++){

//     // if(procedures[i].image instanceof File === false && procedures[i].assign_id !== undefined && procedures[i].status !== ""){
//     //   procedureEdit(procedures[i]);
//     //   continue
//     // }

//     if(ObjupdatedProcedure[i].image instanceof File === true || ObjupdatedProcedure[i].image === "" && ObjupdatedProcedure[i].assign !== undefined){
//       fd.append(`procedure[${i}]procedure_id`,ObjupdatedProcedure[i].procedure_details.procedure_id)
//       fd.append(`procedure[${i}]date_of_procedure`,ObjupdatedProcedure[i].date_of_procedure.split('T').join(" ").slice(0,16))
//       fd.append(`procedure[${i}]assign`,ObjupdatedProcedure[i].assign)
//       fd.append(`procedure[${i}]area`,ObjupdatedProcedure[i].area)
//       fd.append(`procedure[${i}]session`,ObjupdatedProcedure[i].session)
//       fd.append(`procedure[${i}]quantity`,ObjupdatedProcedure[i].quantity)
//       fd.append(`procedure[${i}]image`,ObjupdatedProcedure[i].image)
//       fd.append(`procedure[${i}]details`,ObjupdatedProcedure[i].details)
//       fd.append(`procedure[${i}]reason`,ObjupdatedProcedure[i].reason)
//     }
//     else{
//       if(i < ObjupdatedProcedure.length-1){
//         continue
//       }
//       else{
//         return false
//       }
//   }
    
// }

 


 

let valuesArrays=[];
let valuesUpdateArrays=[];
// 
let saveFilteredProcedure=ObjProcedure.filter(x=>x.status=="");
if(saveFilteredProcedure.length>0){
  for (let index = 0; index < saveFilteredProcedure.length; index++) {
    let today= new Date(saveFilteredProcedure[index].date_of_procedure).toISOString();
    let todayAfterSplit=today.split('T')[0];
    
    valuesArrays.push({
                procedure_id:saveFilteredProcedure[index].procedure_details.procedure_id,
                area:saveFilteredProcedure[index].area,
                session:saveFilteredProcedure[index].session,
                quantity:Number(saveFilteredProcedure[index].quantity)  ,
                details:saveFilteredProcedure[index].details,
                reason:  saveFilteredProcedure[index].reason ==""  ? 'test':saveFilteredProcedure[index].reason,
                assign:saveFilteredProcedure[index].assign_id,  
                date_of_procedure:todayAfterSplit
                }) 
  }
  
  const ProcedureData={
    procedure:valuesArrays
  }
  axios.post(`${APIURL}/api/v1/hospital/hospital-patient-procedure/?patient_id=${id}`,ProcedureData, { headers: {
             
             "Authorization" : 'Token '+str,
             'Content-Type': 'application/json'  }
           })
  
           .then(res=>{
           // debugger
             if(res.status==200){
             setSubmitMsg("success");
             setModalShow(true);
           
  
         }
         else{
           setSubmitMsg("error");
           setErrorSubmit(true);
          
         }
  
           })
           .catch(err=>{
         
         setSubmitMsg("error");
         setErrorSubmit(true);
       })
}

// const updateProcedure=ObjProcedure.filter(x=>x.status!="");
// // debugger



// procedureEdit(ProcedureDataUpdate)

}

const ProcedureHistoryPopup = (props) => {
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
      dialogClassName="modal-90w"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <ProcedureHistory id={id} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};




  function msgData(childData) {
    track = childData;
  }
  const handleTrack = () => {
    setMessagesShow(false);
    if (track !== null) {
      track.stop();
    }
  };
  useEffect(() => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/patient/observations-list/?patient_id=${id}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          setObservationsArray(res.data);
        }
      })
      .catch((err) => {});
  }, [refresh]);

  useEffect(() => {
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/patient/symptoms-list/?patient_id=${id}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          setSymptoms(res.data);
        }
      })
      .catch((err) => {});
  }, [refresh]);

  useEffect(() => {
    PatientDetails();
  }, []);

  useEffect(() => {
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/doctor/allergies-list/?patient_id=${id}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          console.log("Allergies", res.data.message)
          setAllergyArray(res.data.message);
        }
      })
      .catch((err) => {});
  }, [refresh]);

  const allDatas = async () => {
    setScanList([
      {
        id: "",
        test: "",
        date: "",
        scan_details: { details: "", image: "", reason: "" },
      },
    ]);
    setScanListCounter(0);
    setTestsList([
      {
        id: "",
        test: "",
        date: "",
        labtest_reason: { details: "", image: "", reason: "" },
      },
    ]);
    setTestListCounter(0);
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");
    await axios
      .get(`${APIURL}/api/v1/doctor/patient-record/?patient_id=${id}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setuserDetails(res.data);
          setPatientData(res.data);
          setName(res.data.name);
          setDob(res.data.dob);
          function getAge(dateString) {
            var today = new Date();
            var birthDate = new Date(dateString);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
              age--;
            }
            return age;
          }

          setAge(getAge(res.data.dob));
          setSummary(res.data.quick_summary);

          if (res.data.scans.length > 0) {
            setScanList(res.data.scans);
            setScanListCounter(res.data.scans.length);
          }

          if (res.data.tests.length > 0) {
            setTestsList(res.data.tests);
            setTestListCounter(res.data.tests.length);
          }
          setGender(res.data.gender);
          setNext_visit(res.data.next_visit);

          if (res.data.next_visit !== null) {
            setNext(new Date(res.data.next_visit));
          } else {
            setNext(null);
          }

          setImgUrl(res.data.photo);
        }
      })
      .catch((err) => {});
  };

  const saveNextVisit = () => {
    const fd1 = new FormData();

    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    console.log('THE DOCTOR', searchAssign);
    console.log('THE VISIT', next_visit);

    if (doctorId === '' || doctorId === null) {
      setSubmitMsg("error");
      setErrorSubmit(true);
      setErrorMessage('Please select a Doctor for next visit');
    } else if (next_visit === null) {
      setSubmitMsg("error");
      setErrorSubmit(true);
      setErrorMessage('Please select a Valid Date for next visit');
    } else if (next_visit !== null &&  doctorId !== null) {
      fd1.append("patient_id", id);
      fd1.append("next_visit", next_visit);
      fd1.append("doctor_id", doctorId);
      axios
        .post(`${APIURL}/api/v1/doctor/patient-record/`, fd1, {
          headers: {
            Authorization: "Token " + str,
            "Content-Type": "application/json",
          },
        })

        .then((res) => {
          if (res.data.status === "success") {
            setSubmitMsg("success");
            setModalShow(true);
          } else {
            setSubmitMsg1("error");
            setErrorSubmit1(true);
          }
        })
        .catch((err) => {
          setSubmitMsg1("error");
          setErrorSubmit1(true);
        });
    }
  };

  const handleInferenceOverlay = (e) => {
    e.preventDefault();
    setInferenceShow(true);
  };
  const summaryData = () => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(
        `${APIURL}/api/v1/patient/cholesterol-inferences/?patient_id=${id}`,
        {
          headers: {
            Authorization: "Token " + str,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          const hdl = res.data.cholesterol_inference_data.hdl_cholesterol_data;
          const ldl = res.data.cholesterol_inference_data.ldl_cholesterol_data;
          const serum =
            res.data.cholesterol_inference_data.serum_cholesterol_data;
          const total =
            res.data.cholesterol_inference_data.total_cholesterol_data;

          if (hdl !== null) {
            setSummary((chol) => chol.concat(`\n${hdl.summary}`));
          }
          if (ldl !== null) {
            setSummary((chol) => chol.concat(`\n${ldl.summary}`));
          }
          if (serum !== null) {
            setSummary((chol) => chol.concat(`\n${serum.summary}`));
          }

          if (total !== null) {
            setSummary((chol) => chol.concat(`\n${total.summary}`));
          }
        }

        setSummaryDone("done");
      })
      .catch((err) => {});
  };

  useEffect(() => {
    async function execute() {
      await allDatas();
      await summaryData();
    }

    execute();
  }, [id, reRender]);

  useEffect(() => {
    setTestsList(testsList);
  }, [testsList]);

  useEffect(() => {
    setTestsList(testsList);
    setScanList(scanList);
    setRender(false);
  }, [render]);

  const PatientDetails = () => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/patient/user-details/?patient_id=${id}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          const data = res.data.health_details;
          setWeight(data.weight);
          setHeight(data.height);
          setMaritalStatus(data.married);
        }
      })
      .catch((err) => {
        setSubmitMsg("error");
        setErrorSubmit(true);
      });
    setWeightAddShow(false);
    setHeightAddShow(false);
  };

  useEffect(() => {
    return () => {
      return (
        <>
          {" "}
          <ReactTable
            columns={columns}
            data={testsList}
            defaultPageSize={4}
            resizable
          ></ReactTable>
        </>
      );
    };
  }, [testsList]);

  useEffect(() => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");
    axios
      .get(`${APIURL}/api/v1/patient/instructions/?patient_id=${id}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setInstructionsArray(res.data.instructions);

          const arr =
            res.data.instructions.length > 0
              ? res.data.instructions[0].instruction
              : null;

          if (arr) {
            const instrString =
              arr.length > 0
                ? arr.reduce((accumulator, arr) => {
                    return accumulator + arr + "\n";
                  }, "")
                : null;

            setInstructions(instrString);
            setInstructionsDate(res.data.instructions[0].created_at);
          }
        }
      })
      .catch((err) => {});
  }, [id, refreshInstr]);

  const handleBack = (e) => {

   history.goBack();

   };

  const handleClick = (ref) => {
    ref.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  const handleInstructions = (e) => {
    setInstructions(e.target.value);
  };
  const handleWeightAdd = () => {
    setWeightAddShow(true);
  };

  const handleHeightAdd = () => {
    setHeightAddShow(true);
  };

  const submitweightHandle = () => {
    setAddWeight(false);
  };

  const submitheightHandle = () => {
    setAddHeight(false);
  };
  const handleSubmit = () => {
    setSubmitTrigger(!submitTrigger);
    setTestErr("nil");
    setScanErr("nil");
    const updatedTests = testsList.filter((row, i) => {
      return row.test !== "" && row.date !== "";
    });
    const updatedScans = scanList.filter((row, i) => {
      return (
        row.test !== "" && row.date !== "" && row.scan_details.details !== ""
      );
    });

    const testData = testsList.filter((row, i) => {
      return (
        row.test !== "" || row.date !== "" || row.labtest_reason.details !== ""
      );
    });

    const scanData = scanList.filter((row, i) => {
      return (
        row.test !== "" || row.date !== "" || row.scan_details.details !== ""
      );
    });

    testData.map((item) => {
      if (item.test === "") {
        setTestErr("*Test name in Test Schedules Table is mandatory!");
        setErrorModalShow(true);
      }
      if (item.date === "") {
        setTestErr("*Date in Test Schedules Table is mandatory!");
        setErrorModalShow(true);
      }
      if (item.test !== "" && item.date !== "") {
        setUpdatedTestList(updatedTests);
        setSubmitTrigger(!submitTrigger);
      }
    });

    scanData.map((item) => {
      if (item.test === "") {
        setScanErr("*Scan name in Scan Schedules table is mandatory!");
        setErrorScanShow(true);
      }
      if (item.date === "") {
        setScanErr("*Date in Scan Schedules Table is mandatory!");
        setErrorScanShow(true);
      }
      if (item.scan_details.details === "") {
        setScanErr("*Scan details in Scan Schedules Table is mandatory!");
        setErrorScanShow(true);
      }

      if (
        item.test !== "" &&
        item.date !== "" &&
        item.scan_details.details !== ""
      ) {
        setUpdatedScanList(updatedScans);
        setSubmitTrigger(!submitTrigger);
      }
    });

    setUpdatedTestList(updatedTests);
    setUpdatedScanList(updatedScans);
    setSubmitTrigger(!submitTrigger);
  };
  const handleNextVisit = (date) => {
    setNext(date);

    if (date !== null) {
      const offset = date.getTimezoneOffset();

      let dateUpdated = new Date(date.getTime() - offset * 60 * 1000);

      let DateExtracted = dateUpdated.toISOString().split("T")[0];

      setNext_visit(DateExtracted);
     
    }
  };

  const handleAddObs = () => {
    setObservationsShow(false);
    setRefresh(!refresh);
  };

  const handleAddSymptoms = () => {
    setSymptomsShow(false);
    setRefresh(!refresh);
  };
  const handleAddAllergy = () => {
    setAllergyShow(false);
    setRefresh(!refresh);
  };
  const handleObs = () => {
    setRefresh(!refresh);
  };
  const handleDeleteNextVisit1 = () => {
    setDeleteNextVisitConfirmMsg("success");
    setDeleteNextVisitConfirmModalShow("true");
  };
  const handleDeleteNext = () => {
    setNext_visit(null);
    setNext(null);
    callDeleteNext();
  };

  const handleDeleteTest = (index) => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    const dataToUpdate = {
      ts_id: testsList[index].id,
      patient_id: id,
    };

    axios
      .put(`${APIURL}/api/v1/doctor/patient-record/`, dataToUpdate, {
        headers: {
          Authorization: "Token " + str,
          "Content-Type": "application/json",
        },
      })

      .then((res) => {
        if (res.data.status === "success") {
          setReRender(!reRender);
        } else {
          setSubmitMsg("error");
        }
      })
      .catch((err) => {
        setSubmitMsg("error");
      });
  };
  const callDeleteNext = (index) => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    const dataToUpdate = {
      patient_id: id,
      next_visit: "True",
    };

    axios
      .put(`${APIURL}/api/v1/doctor/patient-record/`, dataToUpdate, {
        headers: {
          Authorization: "Token " + str,
          "Content-Type": "application/json",
        },
      })

      .then((res) => {
        if (res.data.status === "success") {
          setDeleteNextMsg("Successfully deleted next visit data");
          setDeleteNextModalShow(true);
          setDoctorName("")
          setDisplayDoctor(false)
        } else if (res.data.status === "error") {
          setNextErr(
            res.data.message ? res.data.message : "Error in submission!"
          );
        } else {
          setNextErr("Error in submission!");
        }
      })
      .catch((err) => {
        setNextErr("Error in submission!");
      });
  };

  const handleDisplayTestTable = () => {
    setShowTestTable(!showTestTable);
  };

  const handleDisplayScanTable = () => {
    setShowScanTable(!showScanTable);
  };

  const SymptomsPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 className="title-of-tasks header">Symptom</h3>

          <AddSymptoms id={id} handleAdd={handleAddSymptoms} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            {" "}
            X Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const AllergyPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 className="title-of-tasks header">Allergy</h3>

          <AddAller id={id} handleAdd={handleAddAllergy} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            {" "}
            X Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const DeleteNextVisitConfirmPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Please confirm to delete Next Visit!</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Cancel
          </Button>

          <Button
            variant="danger"
            onClick={() => {
              setDeleteNextVisitConfirmModalShow(false);
              handleDeleteNext();
            }}
          >
            {" "}
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const DeleteTestConfirmPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Please confirm to delete Scheduled Test</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Cancel
          </Button>

          <Button
            variant="danger"
            onClick={() => {
              setDeleteTestConfirmModalShow(false);
              handleDeleteTest(props.index);
              setTestIndexToDelete(null);
            }}
          >
            {" "}
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const FoodHabitsPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
        className="nutrients-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FoodHabits {...props} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const TargetPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TargetSet {...props} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const WeightAddPopup = (props) => {
    ;
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <WeightAdd
            id={id}
            submitweight={submitweightHandle}
            seteditweight={weight}
            onHide={PatientDetails}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={PatientDetails}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const AddPrescPopup1 = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddMedicinesPat patient={id} closeMed={handleMed} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const HeightAddPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <HeightAdd
            id={id}
            submitheight={submitheightHandle}
            seteditheight={height}
            onHide={PatientDetails}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={PatientDetails}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const PulsePopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VitalsPulse {...props} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const GlucosePopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <GlucoseVitals {...props} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

const editObservations = (cellInfo) => {

  
  let selectedRow=cellInfo.row;
  let valueslist=procedureList;

  let valuesUpdateArrays;
  
  let today= new Date(selectedRow.date_of_procedure).toISOString();
  let todayAfterSplit=today.split('T')[0];
      valuesUpdateArrays={
                procedure_id:selectedRow.procedure_details.procedure_id,
                area:selectedRow.area,
                session:selectedRow.session,
               // quantity:Number(selectedRow.quantity)  ,
                details:selectedRow.details,
                reason:  selectedRow.reason ==""  ? 'test':selectedRow.reason,
                assign:valueslist[0].assign_id,  
                date_of_procedure:todayAfterSplit
                }
  

 


const ProcedureDataUpdate={
  procedure:valuesUpdateArrays
}

 
  // setEditData(ProcedureList[cellInfo])
  //  setEditShow1(true)
  procedureEdit(valuesUpdateArrays)
}

  const columnsProcedure = [
    {
      Header: <i className="fas fa-trash-alt"></i>,
      Cell: (props) => {
        return (
          <i
            className="fas fa-trash-alt trash-btn"
            onClick={() => {
              deleteProcedureRow(props.index);
            }}
          ></i>
        );
      },
      width: 60,
      sortable: false,
      filterable: false,
    },

    

    {
      Header: (
        <>
          <h6>Procedure Name *</h6>
          <h6>(Select from the </h6>
          <h6>list only)</h6>
        </>
      ),
      accessor: "procedure_details",
      Cell: renderEditableProcedure,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },
      width: 250,
      minWidth: 100,
      sortable: false,
    },

    {
      Header: "DATE (YYYY-MM-DD)",
      accessor: "date_of_procedure",
      Cell: renderEditableProcedureDate,
      sortable: false,
      width: 150,
    },
    {
      Header: "Area *",
      accessor: "area",
      Cell: renderProcedureArea,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },
      width: 140,
      minWidth: 100,
      sortable: false,
    },
    {
      Header: "Type",
      accessor: "procedure_details",
      Cell: renderProcedureType,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },
      width: 140,
      minWidth: 100,
      sortable: false,
    },
    {
      Header: "Sessions *",
      accessor: "session",
      Cell: renderProcedureSession,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },
      width: 140,
      minWidth: 100,
      sortable: false,
    },
    // {
    //   Header: "Qty *",
    //   accessor: "quantity",
    //   Cell: renderProcedureQuantity,
    //   style: {
    //     backgroundColor: "rgba(0,0,0,0.1)",
    //   },
    //   width: 100,
    //   minWidth: 100,
    //   sortable: false,
    // },
    {
      Header: "Price",
      accessor: "procedure_details",
      Cell: renderProcedurePrice,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },
      width: 140,
      minWidth: 100,
      sortable: false,
    },
    {
      Header: "Procedure Details *",
      accessor: "details",
      Cell: renderProcedureDetails,
      sortable: false,
      filterable: false,
      width: 310,
    },
    {
      Header: "Reason *",
      accessor: "reason",
      Cell: renderProcedureReason,
      sortable: false,
      filterable: false,
      width: 140,
      minWidth: 100
    },
    // {
    //   Header: <><h6>Status</h6>
    //             </>,
    //   accessor: "status",
    //   //Cell: renderStatus,
    //    sortable:true,
    //     filterable:false,

    //    width:150

    // },
    {
      Header: (
        <>
          <h6>Assignee *</h6>
          <h6>(Select from the </h6>
          <h6>list only)</h6>
        </>
      ),
      accessor: "assign_name",
      Cell: renderAssigned,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },

      sortable: false,
      filterable: false,

      //  filterMethod:(filter, row, column) => {
      //   const id = filter.pivotId || filter.id
      //   return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
      // },
      width: 140,
    },
    

    {
      Header: "ADD",

      Cell: (props) => {
        return props.index === procedureList.length - 1 ? (
          <button
            className="btn btn-primary btn-col btn-col btn-col btn-small"
            onClick={AddRowProcedure}
          >
            +Add
          </button>
        ) : null;
      },
      width: 80,
      sortable: false,
      filterable: false,
    },
    {
      Header: "Update",
      Cell: props=>{
        return (
        <button className="btn btn-primary btn-col btn-square btn-smallest" 
           onClick={()=>{editObservations(props)}}>Update</button>
        )
      },
      sortable:false,
      filterable:false,
      minWidth:50,
      width:100
    },
  ];

  const BpPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VitalsBP {...props} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const CholesterolPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VitalsChol {...props} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const AddPrescPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h2 className="title-of-tasks"> Past Medications </h2>

          <Suspense fallback={<div>Loading...</div>}>
           <PastMedications id={id} />
          </Suspense>

       
          
          {/* <AddPrescription {...props} /> */}
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const InferencePopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h2 className="title-of-tasks"> Inference & Impact Analysis </h2>
          <Inference patientId={id} summary={displaySummaryTable} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const MessagesPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Suspense fallback={<div>Loading...</div>}>
            <HospDrMessages patientId={id} track={msgData} />
          </Suspense>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const SubmitPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Data submitted sucessfully!</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const DeletePopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>{deleteNextMsg}</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const SubmitInstructionsPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Instructions submitted sucessfully!</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ErrorNextPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}> {nextErr}</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ErrorPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}> {testErr}</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const SubmitErrorPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}>
            {" "}
            {errorMessage ? errorMessage : 'Error in Data Submission. Please try again!'}{" "}{" "}
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const SubmitErrorPopup1 = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}>
            {" "}
            Please Select doctor name{" "}
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleDrNotesOverlay = (e) => {
    e.preventDefault();
    setDrNotesShow(true);
  };
  const handleObservationsOverlay = (e) => {
    e.preventDefault();
    setObservationsShow(true);
  };

  const handleFoodHabitsOverlay = (e) => {
    e.preventDefault();
    setFoodHabitsShow(true);
  };
  const handleTargetOverlay = (e) => {

    //e.preventDefault();
    setTargetShow(true);
  };

  const handleGlucoseOverlay = (e) => {
    e.preventDefault();
    setGlucoseShow(true);
  };
  const handlePulseOverlay = (e) => {
    e.preventDefault();
    setPulseShow(true);
  };
  const handleCholOverlay = (e) => {
    e.preventDefault();
    setCholesterolShow(true);
  };
  const handleBpOverlay = (e) => {
    e.preventDefault();
    setBpShow(true);
  };
  const handleAddPrescOverlay = (e) => {
    e.preventDefault();
    setAddPrescShow(true);
  };
  const handleSymptomsOverlay = (e) => {
    e.preventDefault();
    setSymptomsShow(true);
  };
  const handleAllergyOverlay = (e) => {
    e.preventDefault();
    setAllergyShow(true);
  };

  const ErrorScanPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}> {scanErr}</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const EditPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImageUpdate
            img={scanList[index].scan_details.image}
            imgUpdate={handleFile}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
          <Button className="btn btn-primary btn-col" onClick={props.onSave}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ImgEditPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImageUpdate
            img={testsList[index].labtest_reason.image}
            imgUpdate={handleFile}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
          <Button className="btn btn-primary btn-col" onClick={props.onSave}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ViewPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {flag ? (
            <ImageUpdate
              img={testsList[index].labtest_reason.image}
              imgUpdate={handleFile}
            />
          ) : (
            <ImageUpdate
              img={scanList[index].scan_details.image}
              imgUpdate={handleFile}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  let imgFile = null;

  function handleFile(childData) {
    imgFile = childData;
  }

  const reasonAddHandle = () => {
    setReasonAddShow(false);
    const tests = testsList;
    tests[cell].labtest_reason.reason = sendData;
    setTestsList(tests);
    setEditSuccess(true);
  };

  const ReasonAddPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <AddRemark update={handleSend} save={reasonAddHandle} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ReasonEditPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditRemark data={detailData} update={handleSend} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
          <Button className="btn btn-primary btn-col" onClick={props.onSave}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const callInstructionSubmit = (instrString) => {
    const tokenString = sessionStorage.getItem("usertoken");
    const dataToSend = {
      instruction: instrString,
    };
    let str = tokenString.replace(/["]+/g, "");
    axios
      .post(
        `${APIURL}/api/v1/patient/instructions/?patient_id=${id}`,
        dataToSend,
        {
          headers: {
            Authorization: "Token " + str,
            "Content-Type": "application/json",
          },
        }
      )

      .then((res) => {
        if (res.data.status === "success") {
          setSubmitInstructionsMsg("success");
          setModalInstructionsShow(true);
        } else {
          setSubmitMsg("error");
          setErrorSubmit(true);
        }
      })
      .catch((err) => {
        setSubmitMsg("error");
        setErrorSubmit(true);
      });
  };
  const handleSubmitInstructions = (e) => {
    if (instructions !== "" && instructions !== null) {
      const instrToSend = instructions.trim().split("\n");

      callInstructionSubmit(instrToSend);
    }
  };
  const handleDeleteRowTest = (index) => {
    setDeleteTestConfirmMsg("success");
    setDeleteTestConfirmModalShow("true");
    setTestIndexToDelete(index);
  };

  const deleteRow = (index) => {
    let newlist = [];
    if (index > testListCounter - 1) {
      newlist = testsList.filter((test, i) => {
        return i !== index;
      });

      setTestsList(newlist);
      if (newlist.length <= 0) {
        setTestsList([
          {
            id: "",
            test: "",
            date: "",
            labtest_reason: { details: "", image: "", reason: "" },
          },
        ]);
      }
    } else {
      handleDeleteRowTest(index);
    }
  };
  const renderEditableTest = (cellInfo) => {
    if (cellInfo.index > testListCounter - 1 || testListCounter === 0) {
      return (
        <div ref={wrapperRef}>
          <input
            style={{ backgroundColor: "#fafafa" }}
            value={testsList[cellInfo.index][cellInfo.column.id]}
            className="form-control"
            name="test"
            onChange={(e) => {
              e.preventDefault();

              setCurrentIndex(cellInfo.index);
              setCurrentColumn(cellInfo.column.id);
              setDisplaySuggestions1(true);
              setDisplay1(true);

              setSearch(e.target.value);

              const tests = testsList;
              tests[cellInfo.index].test = e.target.value;

              setTestsList(tests);
            }}
            autoComplete="off"
          />

          {currentIndex === cellInfo.index &&
            cellInfo.column.id === "test" &&
            display1 && (
              <div className="suggestions-box">
                {options.map((item, i) => {
                  return (
                    <div
                      className="scan-item"
                      key={i}
                      onClick={(e) => {
                        e.preventDefault();

                        setRender(!render);

                        const tests = testsList;
                        tests[cellInfo.index].test = item;

                        setTestsList(tests);
                        setDisplay1(false);
                      }}
                    >
                      <p className="search-item">{item}</p>
                    </div>
                  );
                })}
              </div>
            )}
        </div>
      );
    } else {
      return (
        <div>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col"
            name="date"
            value={testsList[cellInfo.index].date}
            onBlur={(e) => {
              const tests = testsList;
              tests[cellInfo.index].test = e.target.innerHTML;

              setTestsList(tests);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: testsList[cellInfo.index][cellInfo.column.id],
            }}
          />
        </div>
      );
    }
  };

  const handlePulse = () => {
    setAddPulse(true);
  };

  const handleSugar = () => {
    setAddSugar(true);
  };
  const handleBp = () => {
    setAddBp(true);
  };
  const handleChol = () => {
    setAddCholestrol(true);
  };

  const renderEditableTestDate = (cellInfo) => {
    if (cellInfo.index > testListCounter - 1) {
      const today = new Date();

      const offset = today.getTimezoneOffset();

      let dateUpdated = new Date(today.getTime() - offset * 60 * 1000);

      let DateExtracted = dateUpdated.toISOString().split("T")[0];
      return (
        <div>
          <input
            type="date"
            data-date={testsList[cellInfo.index].date}
            className="scan-date-col date-custom-format"
            min={DateExtracted}
            onChange={(e) => {
              e.preventDefault();
              let date = e.target.value;

              const tests = testsList;
              tests[cellInfo.index].date = date;

              setTestsList(tests);
              const m = moment(date, "YYYY-MM-DD").format("YYYY-MM-DD");

              setDateVal(m);
            }}
          />
        </div>
      );
    } else {
      return (
        <div>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-date-col"
            name="date"
            value={testsList[cellInfo.index].date}
            onBlur={(e) => {
              const tests = testsList;
              tests[cellInfo.index].date = e.target.innerHTML;

              setTestsList(tests);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: testsList[cellInfo.index][cellInfo.column.id],
            }}
          />
        </div>
      );
    }
  };

  const editTestImage = (pos) => {
    setCell(pos);
    const fileInput = document.getElementById("testImage");
    fileInput.click();
  };

  const uploadTestImage = (e) => {
    const file = e.target.files[0];
    const test = testsList;
    if (!file) {
      setUploadShow(!uploadShow);
      return false;
    }
    if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
      setValidShow(!validShow);
      return false;
    }
    if (file.size > 2e6) {
      setSizeShow(!sizeShow);
      return false;
    }
    reader.onload = (e) => {
      const img1 = new Image();
      img1.onload = () => {
        test[cell].labtest_reason.image = file;
        setTestsList(test);
        setIndex(cell);
        setTableRefresh(!tableRefresh);
      };
      img1.onerror = () => {
        setContentShow(!contentShow);

        return false;
      };
      img1.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };

  const renderTestImage = (cellInfo) => {
    if (cellInfo.index > testListCounter - 1) {
      if (
        testsList[cellInfo.index].labtest_reason.image === null ||
        testsList[cellInfo.index].labtest_reason.image === ""
      ) {
        return (
          <div>
            <input
              id="testImage"
              hidden="hidden"
              type="file"
              onChange={uploadTestImage}
            />

            <i
              onClick={() => {
                editTestImage(cellInfo.index);
              }}
              class="fas fa-image select-img"
            ></i>
          </div>
        );
      } else {
        return (
          <button
            onClick={() => {
              setImgEditShow(true);
              setIndex(cellInfo.index);
            }}
            className="btn btn-primary btn-col btn-square btn-smallest"
          >
            View/Edit
          </button>
        );
      }
    } else {
      if (
        testsList[cellInfo.index].labtest_reason.image === null ||
        testsList[cellInfo.index].labtest_reason.image === ""
      ) {
        return null;
      } else {
        return (
          <button
            onClick={() => {
              setViewShow(true);
              setIndex(cellInfo.index);
              setFlag(true);
            }}
            className="btn btn-primary btn-col btn-square btn-smallest"
          >
            View
          </button>
        );
      }
    }
  };

  const renderTestDetails = (cellInfo) => {
    if (cellInfo.index > testListCounter - 1) {
      return (
        <div>
          <textarea
            className="text-area"
            placeholder="Add Test Details"
            cols="100"
            rows="2"
            onChange={(e) => {
              e.preventDefault();
              let data = e.target.value;

              const tests = testsList;
              testsList[cellInfo.index].labtest_reason.details = data;

              setTestsList(tests);
            }}
          />
        </div>
      );
    } else {
      return (
        <textarea
          className="scan-text"
          value={testsList[cellInfo.index].labtest_reason.details}
          cols="100"
          rows="2"
        />
      );
    }
  };

  const handleReasonAdd = (index) => {
    setCell(index);
    setReasonAddShow(true);
  };

  const handleReasonEdit = (data, index) => {
    setDetailData(data);
    setCell(index);
    setReasonEditShow(true);
  };

  const renderTestReason = (cellInfo) => {
    if (cellInfo.index > testListCounter - 1) {
      if (
        testsList[cellInfo.index][cellInfo.column.id].reason === "" ||
        testsList[cellInfo.index][cellInfo.column.id].reason === null
      ) {
        return (
          <button
            onClick={() => handleReasonAdd(cellInfo.index)}
            className="btn btn-smallest add-remark-btn"
          >
            <i className="fas fa-plus"></i>
          </button>
        );
      } else {
        return (
          <button
            onClick={() =>
              handleReasonEdit(
                testsList[cellInfo.index][cellInfo.column.id].reason,
                cellInfo.index
              )
            }
            className="btn btn-primary btn-col btn-square btn-smallest"
          >
            View/Edit
          </button>
        );
      }
    } else {
      if (
        testsList[cellInfo.index][cellInfo.column.id].reason === "" ||
        testsList[cellInfo.index][cellInfo.column.id].reason === null
      ) {
        return null;
      } else {
        return (
          <button
            onClick={() =>
              handleView(testsList[cellInfo.index][cellInfo.column.id].reason)
            }
            className="btn btn-primary btn-col btn-square btn-smallest"
          >
            View
          </button>
        );
      }
    }
  };

  const AddRowTest = () => {
    const list = testsList;
    list.push({
      id: "",
      test: "",
      date: "",
      labtest_reason: { details: "", image: "", reason: "" },
    });

    setTestsList(list);
    setRender(true);
  };

  const columns = [
    {
      Header: <i className="fas fa-trash-alt"></i>,
      Cell: (props) => {
        return (
          <i
            className="fas fa-trash-alt trash-btn"
            onClick={() => {
              deleteRow(props.index);
            }}
          ></i>
        );
      },
      width: 60,
      sortable: false,
      filterable: false,
    },

    {
      Header: "TEST NAME",
      accessor: "test",
      Cell: renderEditableTest,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },
      width: 310,
      minWidth: 100,
      sortable: false,
    },

    {
      Header: "DATE (YYYY-MM-DD)",
      accessor: "date",
      Cell: renderEditableTestDate,
      sortable: false,
      width: 190,
    },
    {
      Header: "Test Details",
      accessor: "labtest_reason",
      Cell: renderTestDetails,
      sortable: false,
      filterable: false,
      width: 310,
    },
    {
      Header: "Reason",
      accessor: "labtest_reason",
      Cell: renderTestReason,
      sortable: false,
      filterable: false,
      width: 110,
    },
    {
      Header: <i class="fas fa-upload"></i>,
      accessor: "labtest_reason",
      Cell: renderTestImage,
      sortable: false,
      filterable: false,
      width: 100,
    },

    {
      Header: "ADD",

      Cell: (props) => {
        return props.index === testsList.length - 1 ? (
          <button
            className="btn btn-primary btn-col btn-col btn-col btn-small"
            onClick={AddRowTest}
          >
            +Add
          </button>
        ) : null;
      },
      width: 200,
      sortable: false,
      filterable: false,
    },
  ];

  const AddRowScan = () => {
    const list = scanList;
    list.push({
      id: "",
      test: "",
      date: "",
      scan_details: { details: "", image: "", reason: "" },
    });

    setScanList(list);
    setRender(true);
  };
  const handleDeleteRowScan = (index) => {
    setDeleteScanConfirmMsg("success");
    setDeleteScanConfirmModalShow("true");
    setScanIndexToDelete(index);
  };

  const DeleteScanConfirmPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Please confirm to delete Scheduled Scan</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Cancel
          </Button>

          <Button
            variant="danger"
            onClick={() => {
              setDeleteScanConfirmModalShow(false);
              handleDeleteScan(props.index);
              setScanIndexToDelete(null);
            }}
          >
            {" "}
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const DrNotesPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DrNotes {...props} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ObservationsPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 className="title-of-tasks header">Observation</h3>

          <AddObs id={id} handleAdd={handleAddObs} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            {" "}
            X Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const SizePopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">You have Exceeded the size limit 2MB</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const LoadPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Please select an Image</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ValidPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Please select a valid Image</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ContentPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Invalid Image Content</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const AddBpPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddBp id={id} submitbp={submitBpHandle} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const AddCholestrolPopUp = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddCholestrol id={id} submitcholestrol={submitCholHandle} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const AddSugarPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddSugar id={id} submitsugar={submitSugarHandle} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const AddPulsePopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddPulse id={id} submitpulse={submitPulseHandle} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const submitBpHandle = () => {
    setAddBp(false);
  };
  const submitCholHandle = () => {
    setAddCholestrol(false);
  };

  const submitSugarHandle = () => {
    setAddSugar(false);
  };
  const submitPulseHandle = () => {
    setAddPulse(false);
  };

  const handleDeleteScan = (index) => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    const dataToUpdate = {
      ts_id: scanList[index].id,
      patient_id: id,
    };

    axios
      .put(`${APIURL}/api/v1/doctor/patient-record/`, dataToUpdate, {
        headers: {
          Authorization: "Token " + str,
          "Content-Type": "application/json",
        },
      })

      .then((res) => {
        if (res.data.status === "success") {
          setReRender(!reRender);
        } else {
        }
      })
      .catch((err) => {
        setSubmitMsg("error");
      });
  };
  const deleteRowScan = (index) => {
    let newlist = [];
    if (index > scanListCounter - 1) {
      newlist = scanList.filter((scan, i) => {
        return i !== index;
      });

      setScanList(newlist);

      if (newlist.length <= 0) {
        setScanList([
          {
            id: "",
            test: "",
            date: "",
            scan_details: { details: "", image: "", reason: "" },
          },
        ]);
      }
    } else {
      handleDeleteRowScan(index);
    }
  };

  const handleMessagesOverlay = (e) => {
    e.preventDefault();
    setMessagesShow(true);
  };

  const renderEditableScan = (cellInfo) => {
    if (cellInfo.index > scanListCounter - 1 || scanListCounter === 0) {
      return (
        <div>
          <input
            style={{ backgroundColor: "#fafafa" }}
            value={scanList[cellInfo.index][cellInfo.column.id]}
            className="form-control"
            name="test"
            onChange={(e) => {
              e.preventDefault();

              setCurrentIndex(cellInfo.index);
              setCurrentColumn(cellInfo.column.id);
              setDisplaySuggestions2(true);
              setDisplay2(true);

              setSearchScan(e.target.value);

              const scans = scanList;
              scans[cellInfo.index].test = e.target.value;

              setScanList(scans);
            }}
            autoComplete="off"
          />

          {currentIndex === cellInfo.index &&
            cellInfo.column.id === "test" &&
            display2 && (
              <div className="suggestions-box">
                {scanSuggest.map((item, i) => {
                  return (
                    <div
                      className="scan-item"
                      key={i}
                      onClick={(e) => {
                        e.preventDefault();

                        setRender(!render);

                        const scans = scanList;
                        scans[cellInfo.index].test = item;

                        setScanList(scans);
                        setDisplay2(false);
                      }}
                    >
                      <p className="search-item">{item}</p>
                    </div>
                  );
                })}
              </div>
            )}
        </div>
      );
    } else {
      return (
        <div>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control scan-name-col"
            name="scan"
            value={scanList[cellInfo.index].scan}
            onBlur={(e) => {
              const scans = scanList;
              scans[cellInfo.index].scan = e.target.innerHTML;

              setScanList(scans);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: scanList[cellInfo.index][cellInfo.column.id],
            }}
          />
        </div>
      );
    }
  };

  const renderEditableScanDate = (cellInfo) => {
    if (cellInfo.index > scanListCounter - 1) {
      const today = new Date();

      const offset = today.getTimezoneOffset();

      let dateUpdated = new Date(today.getTime() - offset * 60 * 1000);

      let DateExtracted = dateUpdated.toISOString().split("T")[0];
      return (
        <div>
          <input
            type="date"
            data-date={scanList[cellInfo.index].date}
            className="scan-date-col date-custom-format"
            min={DateExtracted}
            onChange={(e) => {
              e.preventDefault();
              let date = e.target.value;

              const scans = scanList;
              scans[cellInfo.index].date = date;

              setScanList(scans);
              const m = moment(date, "YYYY-MM-DD").format("YYYY-MM-DD");

              setDateVal(m);
            }}
          />
        </div>
      );
    } else {
      return (
        <div>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control scan-date-col"
            name="date"
            value={scanList[cellInfo.index].date}
            onBlur={(e) => {
              const scans = scanList;
              scans[cellInfo.index].date = e.target.innerHTML;

              setScanList(scans);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: scanList[cellInfo.index][cellInfo.column.id],
            }}
          />
        </div>
      );
    }
  };

  let sendData = null;

  function handleSend(childData) {
    sendData = childData;
  }

  const addHandle = () => {
    setAddShow(false);
    const scans = scanList;
    scans[cell].scan_details.reason = sendData;
    setScanList(scans);
    setEditSuccess(true);
  };

  const AddPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <AddRemark update={handleSend} save={addHandle} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const RemarkViewPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <ViewRemark data={detailData} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const DetailPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditRemark data={detailData} update={handleSend} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
          <Button className="btn btn-primary btn-col" onClick={props.onSave}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const DetailsUpdateSuccessPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Updated sucessfully.Please Submit to Save Changes</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleAdd = (index) => {
    setCell(index);
    setAddShow(true);
  };

  const handleView = (data) => {
    setDetailData(data);
    setRemarkViewShow(true);
  };

  const handleEdit = (data, index) => {
    setDetailData(data);
    setCell(index);
    setEditShow(true);
  };

  const renderDetails = (cellInfo) => {
    if (cellInfo.index > scanListCounter - 1) {
      return (
        <div>
          <textarea
            className="text-area"
            placeholder="Add Scan Details"
            cols="100"
            rows="2"
            onChange={(e) => {
              e.preventDefault();
              let data = e.target.value;

              const scans = scanList;
              scanList[cellInfo.index].scan_details.details = data;

              setScanList(scans);
            }}
          />
        </div>
      );
    } else {
      return (
        <textarea
          className="scan-text"
          value={scanList[cellInfo.index].scan_details.details}
          cols="100"
          rows="2"
        />
      );
    }
  };

  const renderReason = (cellInfo) => {
    if (cellInfo.index > scanListCounter - 1) {
      if (
        scanList[cellInfo.index][cellInfo.column.id].reason === "" ||
        scanList[cellInfo.index][cellInfo.column.id].reason === null
      ) {
        return (
          <button
            onClick={() => handleAdd(cellInfo.index)}
            className="btn btn-smallest add-remark-btn"
          >
            <i className="fas fa-plus"></i>
          </button>
        );
      } else {
        return (
          <button
            onClick={() =>
              handleEdit(
                scanList[cellInfo.index][cellInfo.column.id].reason,
                cellInfo.index
              )
            }
            className="btn btn-primary btn-col btn-square btn-smallest"
          >
            View/Edit
          </button>
        );
      }
    } else {
      if (
        scanList[cellInfo.index][cellInfo.column.id].reason === "" ||
        scanList[cellInfo.index][cellInfo.column.id].reason === null
      ) {
        return null;
      } else {
        return (
          <button
            onClick={() =>
              handleView(scanList[cellInfo.index][cellInfo.column.id].reason)
            }
            className="btn btn-primary btn-col btn-square btn-smallest"
          >
            View
          </button>
        );
      }
    }
  };

  const editImage = (pos) => {
    setCell(pos);
    const fileInput = document.getElementById("imageInput");
    fileInput.click();
  };

  const uploadImage = (e) => {
    const file = e.target.files[0];
    const scans = scanList;
    if (!file) {
      setUploadShow(!uploadShow);
      return false;
    }
    if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
      setValidShow(!validShow);
      return false;
    }
    if (file.size > 2e6) {
      setSizeShow(!sizeShow);
      return false;
    }
    reader.onload = (e) => {
      const img1 = new Image();
      img1.onload = () => {
        scans[cell].scan_details.image = file;
        setScanList(scans);
        setIndex(cell);
        setTableRefresh(!tableRefresh);
      };
      img1.onerror = () => {
        setContentShow(!contentShow);

        return false;
      };
      img1.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };

  const renderImage = (cellInfo) => {
    if (cellInfo.index > scanListCounter - 1) {
      if (
        scanList[cellInfo.index].scan_details.image === null ||
        scanList[cellInfo.index].scan_details.image === ""
      ) {
        return (
          <div>
            <input
              id="imageInput"
              hidden="hidden"
              type="file"
              onChange={uploadImage}
            />

            <i
              onClick={() => {
                editImage(cellInfo.index);
              }}
              class="fas fa-image select-img"
            ></i>
          </div>
        );
      } else {
        return (
          <button
            onClick={() => {
              setPopupShow(true);
              setIndex(cellInfo.index);
            }}
            className="btn btn-primary btn-col btn-square btn-smallest"
          >
            View/Edit
          </button>
        );
      }
    } else {
      if (
        scanList[cellInfo.index].scan_details.image === null ||
        scanList[cellInfo.index].scan_details.image === ""
      ) {
        return null;
      } else {
        return (
          <button
            onClick={() => {
              setViewShow(true);
              setIndex(cellInfo.index);
            }}
            className="btn btn-success btn-square btn-smallest"
          >
            View
          </button>
        );
      }
    }
  };

  const columnsScans = [
    {
      Header: <i className="fas fa-trash-alt"></i>,
      Cell: (props) => {
        return (
          <i
            className="fas fa-trash-alt trash-btn"
            onClick={() => {
              deleteRowScan(props.index);
            }}
          ></i>
        );
      },
      width: 60,
      sortable: false,
      filterable: false,
    },
    {
      Header: "SCAN NAME",
      accessor: "test",
      Cell: renderEditableScan,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },
      width: 310,
      minWidth: 100,
      sortable: false,
    },
    {
      Header: "DATE (YYYY-MM-DD)",
      accessor: "date",
      Cell: renderEditableScanDate,
      sortable: false,
      width: 190,
    },

    {
      Header: "Scan Details",
      accessor: "scan_details",
      Cell: renderDetails,
      sortable: false,
      filterable: false,
      width: 310,
    },
    {
      Header: "Reason",
      accessor: "scan_details",
      Cell: renderReason,
      sortable: false,
      filterable: false,
      width: 110,
    },
    {
      Header: <i class="fas fa-upload"></i>,
      accessor: "scan_details",
      Cell: renderImage,
      sortable: false,
      filterable: false,
      width: 100,
    },
    {
      Header: "ADD",

      Cell: (props) => {
        return props.index === scanList.length - 1 ? (
          <button
            className="btn btn-primary btn-col btn-col btn-small"
            onClick={AddRowScan}
          >
            {" "}
            +Add
          </button>
        ) : null;
      },
      width: 200,
      sortable: false,
      filterable: false,
    },
  ];

  const renderTooltip2 = (props) => (
    <Tooltip className="my-tooltip" id="button-tooltip" {...props}>
      Can set new targets for the patient, displays active targets and past
      targets of the patient.
    </Tooltip>
  );

  const renderTooltip3 = (props) => (
    <Tooltip className="my-tooltip" id="button-tooltip" {...props}>
      Includes Vitals details, Chronic medical condition, Comorbidities , and
      add findings
    </Tooltip>
  );

  const renderTooltip4 = (props) => (
    <Tooltip className="my-tooltip" id="button-tooltip" {...props}>
      Vital data with graph and tabular details.
    </Tooltip>
  );

  const renderTooltip6 = (props) => (
    <Tooltip className="my-tooltip" id="button-tooltip" {...props}>
      Add the additional conditions occuring in a patient
    </Tooltip>
  );
  const renderTooltip7 = (props) => (
    <Tooltip className="my-tooltip" id="button-tooltip" {...props}>
      Vital Inferences derived from patient's data.
    </Tooltip>
  );
  const renderTooltip8 = (props) => (
    <Tooltip className="my-tooltip" id="button-tooltip" {...props}>
      Current Medications taken by the patient displayed here. Can also set new
      medication.
    </Tooltip>
  );
  const renderTooltip9 = (props) => (
    <Tooltip className="my-tooltip" id="button-tooltip" {...props}>
      Observations noted is displayed here. Can set new Observations.
    </Tooltip>
  );
  const renderTooltip10 = (props) => (
    <Tooltip className="my-tooltip" id="button-tooltip" {...props}>
      Symptoms noted is displayed here. can set Symptoms
    </Tooltip>
  );

  const renderTooltip12 = (props) => (
    <Tooltip className="my-tooltip" id="button-tooltip" {...props}>
      All past medical records of the patient
    </Tooltip>
  );
  const renderTooltip13 = (props) => (
    <Tooltip className="my-tooltip" id="button-tooltip" {...props}>
      Any Instructions for the patient to follow is displayed. Can set/update
      instructions.
    </Tooltip>
  );
  const renderTooltip14 = (props) => (
    <Tooltip className="my-tooltip" id="button-tooltip" {...props}>
      Set Test Schedules,Scan schedules and Next Visit for the patient
    </Tooltip>
  );

  const renderTooltip15 = (props) => (
    <Tooltip className="my-tooltip" id="button-tooltip" {...props}>
      Time based and Event based Inference - Impact analysis
    </Tooltip>
  );
  const renderTooltip20 = (props) => (
    <Tooltip className="my-tooltip" id="button-tooltip" {...props}>
      Add Allergy details of Patient
    </Tooltip>
  );
  const renderTooltip21 = (props) => (
    <Tooltip className="my-tooltip" id="button-tooltip" {...props}>
      Scan Details of Patient
    </Tooltip>
  );
  const renderTooltip22 = (props) => (
    <Tooltip className="my-tooltip" id="button-tooltip" {...props}>
      Test Details of Pateint
    </Tooltip>
  );
  const renderTooltip23 = (props) => (
    <Tooltip className="my-tooltip" id="button-tooltip" {...props}>
      Primary Medical Condition of Patient and Current Satge and its Duration
    </Tooltip>
  );

  const renderTooltip24 = (props) => (
    <Tooltip className="my-tooltip" id="button-tooltip" {...props}>
      Doctors can add notes here for future works.
    </Tooltip>
  );
  const renderTooltip25 = (props) => (
    <Tooltip className="my-tooltip" id="button-tooltip" {...props}>
      Frequent Medicines list will display here.
    </Tooltip>
  );
  const renderTooltip26 = (props) => (
    <Tooltip className="my-tooltip" id="button-tooltip" {...props}>
      Doctor can set and view the procedures assigned to patients..
    </Tooltip>
  );

  let summaryArray = summary.split(/[\r\n]+/);
  let displaySummary =
    summaryArray.length > 1
      ? summaryArray.map((line, i) => {
          if (line) {
            return (
              <>
                <h6 className="text-left ">
                  {" "}
                  {i + 1}.{line}{" "}
                </h6>
              </>
            );
          }
        })
      : "-No data to show-";

  let displaySummaryTable = (
    <div className="sub-section inferences-subsection">
      <div className="sub-section-header box-theme">
        <h6 className="sub-section-title">
          {" "}
          Inferences <span className="ref-text">(Ref: last 60 days) </span>
          <OverlayTrigger
            trigger="hover"
            rootClose
            placement="top"
            overlay={renderTooltip7}
          >
            <i className="fas fa-info-circle"></i>
          </OverlayTrigger>
        </h6>
      </div>
      <div className="sub-section-body">{displaySummary}</div>
    </div>
  );
  useEffect(() => {
    if (!isBooped) {
      return;
    }
    const timeoutId = window.setTimeout(() => {
      setIsBooped(false);
    }, timing);
    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [isBooped, timing]);

  return (
    <>
      <HospDocNavbar />
      <br />

      <div className=" flex-row-end">
        <div className=" text-left">
          
          <button
            className="btn btn-info btn-col back-btn"
            onClick={handleBack}
          >
            {" "}
            <i className="fas fa-chevron-left back-btn-icon"></i>Back{" "}
          </button>
        </div>

        <GeneralSearchBar {...props} />
      </div>

      <div className="patient-record-holder ">
        <div className="sticky-nav">
          <div className="left-nav">
            <ul className="stickynav-ul">
              <li
                onClick={() => {
                  handleClick(ref1);
                }}
              >
                {" "}
                Personal Data & Targets{" "}
                <OverlayTrigger
                  trigger="hover"
                  rootClose
                  placement="top"
                  overlay={renderTooltip2}
                >
                  <i className="fas fa-info-circle"></i>
                </OverlayTrigger>{" "}
              </li>
              <li
                onClick={() => {
                  handleClick(ref2);
                }}
              >
                {" "}
                Inference & Impact Analysis{" "}
                <OverlayTrigger
                  trigger="hover"
                  rootClose
                  placement="top"
                  overlay={renderTooltip15}
                >
                  <i className="fas fa-info-circle"></i>
                </OverlayTrigger>{" "}
              </li>
              <li
                onClick={() => {
                  handleClick(ref3);
                }}
              >
                {" "}
                Diagnostics Info{" "}
                <OverlayTrigger
                  trigger="hover"
                  rootClose
                  placement="top"
                  overlay={renderTooltip3}
                >
                  <i className="fas fa-info-circle"></i>
                </OverlayTrigger>{" "}
              </li>

              <li
                onClick={() => {
                  handleClick(ref4);
                }}
              >
                {" "}
                Current Medications{" "}
                <OverlayTrigger
                  trigger="hover"
                  rootClose
                  placement="top"
                  overlay={renderTooltip8}
                >
                  <i className="fas fa-info-circle"></i>
                </OverlayTrigger>{" "}
              </li>
              <li
                onClick={() => {
                  handleClick(ref5);
                }}
              >
                {" "}
                Observations{" "}
                <OverlayTrigger
                  trigger="hover"
                  rootClose
                  placement="top"
                  overlay={renderTooltip9}
                >
                  <i className="fas fa-info-circle"></i>
                </OverlayTrigger>
              </li>
              <li
                onClick={() => {
                  handleClick(ref6);
                }}
              >
                {" "}
                Symptoms{" "}
                <OverlayTrigger
                  trigger="hover"
                  rootClose
                  placement="top"
                  overlay={renderTooltip10}
                >
                  <i className="fas fa-info-circle"></i>
                </OverlayTrigger>
              </li>
              <li
                onClick={() => {
                  handleClick(ref7);
                }}
              >
                {" "}
                Allergy{" "}
                <OverlayTrigger
                  trigger="hover"
                  rootClose
                  placement="top"
                  overlay={renderTooltip20}
                >
                  <i className="fas fa-info-circle"></i>
                </OverlayTrigger>
              </li>
              <li
                onClick={() => {
                  handleClick(ref8);
                }}
              >
                Health Info & Medical records{" "}
                <OverlayTrigger
                  trigger="hover"
                  rootClose
                  placement="top"
                  overlay={renderTooltip12}
                >
                  <i className="fas fa-info-circle"></i>
                </OverlayTrigger>
              </li>

              <li
                onClick={() => {
                  handleClick(ref9);
                }}
              >
                {" "}
                Treatment Planner{" "}
                <OverlayTrigger
                  trigger="hover"
                  rootClose
                  placement="top"
                  overlay={renderTooltip14}
                >
                  <i className="fas fa-info-circle"></i>
                </OverlayTrigger>{" "}
              </li>
              {/* <li
                onClick={() => {
                  handleClick(ref11);
                }}
              >
                {" "}
                Procedures{" "}
                <OverlayTrigger
                  trigger="hover"
                  rootClose
                  placement="top"
                  overlay={renderTooltip26}
                >
                  <i className="fas fa-info-circle"></i>
                </OverlayTrigger>
              </li> */}
              <li
                onClick={() => {
                  handleClick(ref10);
                }}
              >
                {" "}
                Instructions{" "}
                <OverlayTrigger
                  trigger="hover"
                  rootClose
                  placement="top"
                  overlay={renderTooltip13}
                >
                  <i className="fas fa-info-circle"></i>
                </OverlayTrigger>{" "}
              </li>
              <li onClick={handleDrNotesOverlay}>
                {" "}
                Doctors Note{" "}
                <OverlayTrigger
                  trigger="hover"
                  rootClose
                  placement="top"
                  overlay={renderTooltip24}
                >
                  <i className="fas fa-info-circle"></i>
                </OverlayTrigger>{" "}
              </li>
              <li>
              {/* <span className="freq-link text-left"> */}
                <Link to={{ pathname: freq_link }}>
                  {" "}
                  {/* <div className=" box-4 box-dr-notes header-box1 freq-box sub-section-header"> */}
                    <h6 className="med-tests-link">
                      {" "}
                      Frequent Medicines/Tests Analyis{" "}
                      <span col="">
                        <OverlayTrigger
                          trigger="hover"
                          rootClose
                          placement="top"
                          overlay={renderTooltip25}
                        >
                          <i className="fas fa-info-circle"></i>
                        </OverlayTrigger>
                      </span>
                    </h6>
                  {/* </div> */}
                </Link>
              {/* </span> */}
              </li>
            </ul>
          </div>
        </div>

        <div className="right-section">
        {/* alanBtnComponent.current.removeButton(); */}
          <h4>
            <span
              className="title-of-tasks"
              id={gender === "female" ? "patient-female" : "patient-male"}
            >
              {" "}
              {name}{" "}
            </span>
            <span className="prime-header felixa-header">
              {" "}
              (Felixa ID: {id}{" "}
              {sessionStorage.getItem("hospId") !== ""
                ? " , Hospital ID:" + sessionStorage.getItem("hospId")
                : null}
              )
            </span>
          </h4>
          <br />

          {/*********top panel**********/}
          <div className="info section-1  zero-contentbox">
            <div className="box-2 visit-box  panel-box" onClick={() => {}}>
              <h6 className="prime-header ">
                {" "}
                Next Visit : <br />
                {next_visit ? <span> {next_visit}</span> : <span> ---</span>}
              </h6>
            </div>
            <div className="box-1 visit-box panel-box" onClick={() => {}}>
              <h6 className="prime-header">
                {" "}
                Upcoming Test : <br />
                {testsList.length > 0 ? (
                  <>
                    <span>{testsList[0].test ? testsList[0].test : "---"}</span>
                    <h6> {testsList[0].date}</h6>
                  </>
                ) : null}
              </h6>
            </div>
            <div className="box-4 visit-box panel-box" onClick={() => {}}>
              <h6 className="prime-header">
                {" "}
                Upcoming Scan : <br />
                {scanList.length > 0 ? (
                  <>
                    <span>{scanList[0].test ? scanList[0].test : "---"}</span>
                    <h6> {scanList[0].date}</h6>
                  </>
                ) : null}
              </h6>
            </div>
          </div>
          <div
            className="info section-1 each-section first-contentbox "
            ref={ref1}
          >
            <div className="second-tab sub-section ">
              <div className="sub-section-header box-theme">
                <h6 className="sub-section-title"> Personal Data </h6>
              </div>
              <div className="personal-details-sub-section">
                <div className="sub-section-img ">
                  <img
                    className="profile-picture"
                    src={imgUrl}
                    alt="Profile Photo"
                    width="120px"
                    height="auto"
                  />
                </div>
                <br />
                <br />

                <h6>
                  {" "}
                  Gender : <span className="user-details">{gender}</span>
                </h6>
                <h6>
                  {" "}
                  DOB: <span className="user-details"> {dob}</span>{" "}
                </h6>
                <h6>
                  {" "}
                  Age: <span className="user-details"> {age}</span>{" "}
                </h6>
                <h6 className="text-left">
                  {" "}
                  Weight:{" "}
                  <span>
                    {" "}
                    <b>{weight} kg</b>{" "}
                  </span>{" "}
                  {weight ? (
                    <i
                      style={{ justifySelf: "start", color: "#3468c2" , cursor:"pointer"}}
                      onClick={handleWeightAdd}
                      className="fas fa-edit"
                      aria-hidden="true"
                    ></i>
                  ) : (
                    <i
                      onClick={() => handleWeightAdd()}
                      style={{ justifySelf: "start", color: "#3468c2" }}
                      className="fas fa-plus"
                      aria-hidden="true"
                    ></i>
                  )}
                </h6>
                <h6>
                  {" "}
                  Height:{" "}
                  <span>
                    {" "}
                    <b>{height} cm</b>{" "}
                  </span>{" "}
                  {height ? (
                    <i
                      style={{ justifySelf: "start", color: "#3468c2",cursor:"pointer" }}
                      onClick={handleHeightAdd}
                      className="fas fa-edit iconStyle"
                    ></i>
                  ) : (
                    <i
                      onClick={() => handleHeightAdd()}
                      style={{ justifySelf: "start", color: "#3468c2" }}
                      className="fas fa-plus"
                    ></i>
                  )}
                </h6>

                <h6>
                  {" "}
                  Marital Status:{" "}
                  <span className="user-details">
                    {" "}
                    {maritalStatus ? "Married" : "Not Married"}
                  </span>
                </h6>
              </div>
            </div>

            <div className="second-tab sub-section imp-info-subsection">
              <div className="sub-section-header header-box1">
                <svg
                  width="1.2em"
                  height="1.2em"
                  viewBox="0 0 16 16"
                  className="bi bi-bookmark-fill"
                  fill="var(--red)"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5V2z"
                  />
                </svg>

                <h6 className="sub-section-title">
                  {" "}
                  Targets{" "}
                  <OverlayTrigger
                    trigger="hover"
                    rootClose
                    placement="top"
                    overlay={renderTooltip2}
                  >
                    <i className="fas fa-info-circle"></i>
                  </OverlayTrigger>
                </h6>
              </div>

              <div className="target-tab-content">
                <Suspense fallback={<div>Loading...</div>}>
                  <TargetBasic {...props} />
                </Suspense>

                <br />
                <span className="history-link" onClick={handleTargetOverlay}>
                  <button className="btn btn-primary btn-col btn-col btn-smallest btn-square">
                    {" "}
                    Goto Target Tables{" "}
                  </button>
                </span>
                <br />
              </div>
            </div>
          </div>

          <div className="each-section summary  section-diagnostic" ref={ref8}>
            <h5 className="sub-title head-strip">
              {" "}
              Inference & Impact Analysis{" "}
              <OverlayTrigger
                trigger="hover"
                rootClose
                placement="top"
                overlay={renderTooltip15}
              >
                <i className="fas fa-info-circle"></i>
              </OverlayTrigger>
            </h5>

            <div className="">
              {/**** Inferences/ Summary******/}

              <div className="">
                <div className="sub-section-body" ref={ref2}>
                  {summaryDone === "done" ? (
                    <Suspense fallback={<div>Loading...</div>}>
                      <Inference patientId={id} summary={displaySummaryTable} />
                    </Suspense>
                  ) : null}
                  <br />
                  <span
                    className="history-link"
                    onClick={handleInferenceOverlay}
                  >
                    <button className="btn btn-primary btn-col btn-col btn-smallest btn-square">
                      {" "}
                      View Details
                    </button>
                  </span>
                  <br />
                </div>
              </div>
            </div>
          </div>

          {/*****************Diagnostic section ************/}
          <div className="each-section summary  section-diagnostic" ref={ref3}>
    <h5 className="sub-title head-strip">
    {" "}
    Diagnostics Info{" "}
    <OverlayTrigger
        trigger="hover"
        rootClose
        placement="top"
        overlay={renderTooltip3}
    >
        <i className="fas fa-info-circle"></i>
    </OverlayTrigger>
    </h5>
    <div className="second-contentbox">
        <div className="sub-section vitals-subsection diagnostics">
            <div className="sub-section-header box-theme">
                <h6 className="sub-section-title">
                    {" "}
                    Vitals{" "}
                    <OverlayTrigger
                    trigger="hover"
                    rootClose
                    placement="top"
                    overlay={renderTooltip4}
                    >
                    <i className="fas fa-info-circle"></i>
                    </OverlayTrigger>
                </h6>
            </div>
            <div className="sub-section-body">
                <div className="vitals-colv">
                    <div className="vitals-row">
                        <div className="">
                            <br />
                            <button
                            className="btn btn-primary btn-col"
                            onClick={handleSugar}
                            >
                            +
                            </button>
                        </div>
                    <div
                        className="each-list-title each-tile box-4 colored-box colored-box-small"
                        id="vitals-tile-small"
                        onClick={handleGlucoseOverlay}
                    >
                        <span className="history-link">
                        <h6>Blood Sugar </h6>
                        </span>
                    </div>

                    <div
                        className="each-list-title each-tile box-4 colored-box colored-box-small"
                        id="vitals-tile-small"
                        onClick={handlePulseOverlay}
                    >
                        <span className="history-link">
                        <h6> Pulse </h6>
                        </span>
                    </div>
                    <div>
                        <br />
                        <button
                        onClick={handlePulse}
                        className="btn btn-primary btn-col"
                        >
                        +
                        </button>
                    </div>
                    </div>
                    <br />
                    <br />
                    <div className="vitals-row">
                    <div>
                        <br />
                        <button
                        className="btn btn-primary btn-col"
                        onClick={handleBp}
                        >
                        +
                        </button>
                    </div>
                    <div
                        className="each-list-title each-tile box-4 colored-box colored-box-small"
                        id="vitals-tile-small"
                        onClick={handleBpOverlay}
                    >
                        <span className="history-link">
                        <h6>Blood Pressure </h6>
                        </span>
                    </div>

                    <div
                        className="each-list-title each-tile box-4 colored-box colored-box-small"
                        id="vitals-tile-small"
                        onClick={handleCholOverlay}
                    >
                        <span className="history-link">
                        <h6> Cholesterol </h6>
                        </span>
                    </div>
                    <div>
                        <br />
                        <button
                        className="btn btn-primary btn-col"
                        onClick={handleChol}
                        >
                        +
                        </button>
                    </div>
                    </div>
                </div>
            </div>
        </div>

        {/******Primary/Chronic Medical Condition*******/}
        <div className="sub-section primarymed-subsection diagnostics">
            <div className="sub-section-header box-theme">
            <h6 className="sub-section-title">
                {" "}
                Primary/Chronic Medical Condition{" "}
                <OverlayTrigger
                trigger="hover"
                rootClose
                placement="top"
                overlay={renderTooltip23}
                >
                <i className="fas fa-info-circle"></i>
                </OverlayTrigger>
            </h6>
            </div>
            <div className="sub-section-body comorb-body-content">
            <Suspense fallback={<div>Loading...</div>}>
                <Chronic {...props} />
            </Suspense>
            </div>
        </div>
        <br />
        {/******Chronic/Other Medical Condition******END*/}
        {/******Comorbidities*******/}
        <div className="sub-section comorbidities-subsection diagnostics">
            <div className="sub-section-header box-theme">
            <h6 className="sub-section-title">
                {" "}
                Comorbidities{" "}
                <OverlayTrigger
                trigger="hover"
                rootClose
                placement="top"
                overlay={renderTooltip6}
                >
                <i className="fas fa-info-circle"></i>
                </OverlayTrigger>
            </h6>
            </div>
            <div className="sub-section-body comorb-body-content">
            <Suspense fallback={<div>Loading...</div>}>
                <Comorbidities {...props} />
            </Suspense>
            </div>
        </div>
        {/******Comorbidities******END*/}

        {/******Chronic/Other Medical Condition******END*/}
        {/******Comorbidities*******/}
        <div
            id="diagnostic-sec"
            className="sub-section comorbidities-subsection diagnostics"
        >
            <div className="sub-section-header box-theme">
            <h6 className="sub-section-title">
                {" "}
                Findings..{" "}
                <OverlayTrigger
                trigger="hover"
                rootClose
                placement="top"
                overlay={renderTooltip6}
                >
                <i className="fas fa-info-circle"></i>
                </OverlayTrigger>
            </h6>
            </div>
            <div className="sub-section-body comorb-body-content">
            <Suspense fallback={<div>Loading...</div>}>
                <Diagnosis id={id} />
            </Suspense>
            </div>
        </div>
    </div>
    <br />
</div>
<div
  className="each-section summary  section-diagnostic"
  ref={ref4}
>
  <h5 className="sub-title">
    {" "}
    Current Medications & Set Online Prescription{" "}
    <OverlayTrigger
      trigger="hover"
      rootClose
      placement="top"
      overlay={renderTooltip8}
    >
      <i className="fas fa-info-circle"></i>
    </OverlayTrigger>
  </h5>
  <div className="second-contentbox">
    {/******Prescription*******/}
    <Suspense fallback={<div>Loading...</div>}>
      <CurrentMedications1 id={id}   refresh={medRefresh}/>
    </Suspense>

    <span className="history-link" onClick={handleAddPrescOverlay}>
      <button className="btn btn-primary btn-col btn-col btn-small btn-square">
        {" "}
      Past Medication History
      </button>
    </span>
   
    <span className="history-link" onClick={handleAddPrescOverlay1}>
      <span className="history-link">
        <button
          style={{ marginLeft: "10px" }}
          className="btn btn-primary btn-col btn-col btn-small btn-square"
        >
          {" "}
          Add Prescription{" "}
        </button>
      </span>
      </span>
   

  </div>
</div>
{/*************/}

{/*******************************OBSERVATIONS************/}

<div
  className="each-section summary  section-diagnostic"
  ref={ref5}
>
  <h4 className="sub-title">
    {" "}
    Observations{" "}
    <OverlayTrigger
      trigger="hover"
      rootClose
      placement="top"
      overlay={renderTooltip9}
    >
      <i className="fas fa-info-circle"></i>
    </OverlayTrigger>
  </h4>

  <br />

  {/****placeholder only****/}
  <Suspense fallback={<div>Loading...</div>}>
    <ViewObs
      patientId={id}
      handleObs={handleObs}
      obs={observationsArray}
    />
  </Suspense>

  <span
    className="history-link"
    onClick={handleObservationsOverlay}
  >
    <button className="btn btn-primary btn-col btn-col btn-smallest btn-square mb-3">
      {" "}
      Add Observations{" "}
    </button>
  </span>

  {/******placeholder*** ENd*/}
</div>

{/*****************************OBS END ********************/}

<div
  className="each-section summary  section-diagnostic"
  ref={ref6}
>
  <h4 className="sub-title">
    {" "}
    Symptoms{" "}
    <OverlayTrigger
      trigger="hover"
      rootClose
      placement="top"
      overlay={renderTooltip10}
    >
      <i className="fas fa-info-circle"></i>
    </OverlayTrigger>
  </h4>

  <br />
  <Suspense fallback={<div>Loading...</div>}>
    <ViewSymptoms
      patientId={id}
      handleObs={handleObs}
      obs={symptoms}
    />
  </Suspense>

  <span className="history-link" onClick={handleSymptomsOverlay}>
    <button className="btn btn-primary btn-col btn-col btn-smallest btn-square">
      {" "}
      Add Symptoms{" "}
    </button>
  </span>

  {/******placeholder*** ENd*/}
</div>

<div
  className="each-section summary  section-diagnostic"
  ref={ref7}
>
  <h4 className="sub-title">
    {" "}
    Allergy
    <OverlayTrigger
      trigger="hover"
      rootClose
      placement="top"
      overlay={renderTooltip20}
    >
      <i className="fas fa-info-circle"></i>
    </OverlayTrigger>{" "}
  </h4>

  <br />
  <Suspense fallback={<div>Loading...</div>}>
    <ViewAller
      patientId={id}
      handleObs={handleObs}
      obs={allergyArray}
    />
  </Suspense>

  <span className="history-link" onClick={handleAllergyOverlay}>
    <button className="btn btn-primary btn-col btn-col btn-smallest btn-square">
      {" "}
      Add Allergy{" "}
    </button>
  </span>

  {/******placeholder*** ENd*/}
</div>

<br />


          {/**********************************/}
          <div className="each-section summary  section-diagnostic" ref={ref8}>
            <h5 className="sub-title head-strip">
              {" "}
              Health Info & Medical Records{" "}
              <OverlayTrigger
                trigger="hover"
                rootClose
                placement="top"
                overlay={renderTooltip12}
              >
                <i className="fas fa-info-circle"></i>
              </OverlayTrigger>
            </h5>
            <div className="second-contentbox">
              {/******nutr*******/}
              <div className="sub-section nutr-subsection ">
                <div className="sub-section-header box-theme">
                  <h6 className="sub-section-title">
                    {" "}
                    Nutrients & Fitness Info{" "}
                  </h6>
                </div>
                <div className="sub-section-body ">
                  <div className="history-boxes">
                    {/****box****/}
                    <div
                      className="each-list-title each-tile box-4 colored-box"
                      onClick={handleFoodHabitsOverlay}
                    >
                      <h5 className="each-title box-titles">
                        {" "}
                        <span className="history-link">
                          <h5>
                            {" "}
                            Nutrients / <br /> Food Habits{" "}
                          </h5>
                        </span>
                      </h5>
                    </div>
                    {/****box end****/}

                    {/****box****/}

                    <div className="each-list-title each-tile box-4 colored-box">
                      <h5 className="each-title box-titles">
                        {" "}
                        <Link
                          to="/in-progress"
                          style={{ textDecoration: "none" }}
                        >
                          <h5> Fitness Data </h5>
                        </Link>
                      </h5>
                    </div>

                    {/****box**end**/}
                  </div>
                </div>
              </div>
              {/******Nutr******END*/}

              {/******MED HISTORY *******/}
              <div className="sub-section medhistory-subsection">
                <div className="sub-section-header box-theme">
                  <h6 className="sub-section-title">
                    {" "}
                    Medical Records{" "}
                    <OverlayTrigger
                      trigger="hover"
                      rootClose
                      placement="top"
                      overlay={renderTooltip12}
                    >
                      <i className="fas fa-info-circle"></i>
                    </OverlayTrigger>
                  </h6>
                </div>
                <div className="sub-section-body ">
                  <div className="history-boxes">
                    {/****box****/}
                    <div>
                      <Link
                        to={{
                          pathname: `/lab-reports/${id}`,
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <div
                          className="each-list-title each-tile box-4 colored-box"
                          id="box-3"
                        >
                          <h5 className="each-title box-titles">
                            <h5> Lab Reports & Comparison </h5>
                          </h5>
                        </div>
                      </Link>
                    </div>

                    {/*****box *****/}

                    <div>
                      <Link
                        to={{
                          pathname: `/patient-prescriptions/${id}`,
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <div
                          className="each-list-title each-tile box-4 colored-box"
                          id="box-2"
                        >
                          <h5 className="each-title box-titles">
                            {" "}
                            <h5> Prescriptions </h5>
                            <h5> </h5>
                          </h5>
                        </div>
                      </Link>
                    </div>

                    {/*****BOX*****/}

                    <div>
                      {" "}
                      <Link
                        to={{
                          pathname: `/patient-discharge-summary/${id}`,
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <div className="each-list-title each-tile box-4 colored-box">
                          <h5 className="each-title box-titles">
                            <h5>
                              {" "}
                              Discharge <br />
                              Summary{" "}
                            </h5>
                          </h5>
                        </div>
                      </Link>
                    </div>
                    {/*****BOX ends****/}
                    {/*****BOX*****/}

                    <div>
                      {" "}
                      <Link
                        to={{
                          pathname: `/patient-scan-report/${id}`,
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <div
                          className="each-list-title each-tile colored-box box-4"
                          id="box-1"
                        >
                          {/* <i className="fa fa-medkit task-icon" id="task-icon-color"  aria-hidden="true"></i> */}

                          <h5 className="each-title box-titles"></h5>
                          <h5 style={{ fontWeight: "bolder" }}>
                            {" "}
                            Scan Reports{" "}
                          </h5>
                        </div>
                      </Link>
                    </div>

                    {/*****BOX ends****/}
                    {/*****BOX*****/}

                    <div>
                      {" "}
                      <Link
                        to={{
                          pathname: `/miscellaneous/${id}`,
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <div className="each-list-title each-tile box-4 colored-box">
                          {/* <i className="fa fa-medkit task-icon" id="task-icon-color"  aria-hidden="true"></i> */}

                          <h5 className="each-title box-titles"></h5>
                          <h5 style={{ fontWeight: "bolder" }}>
                            {" "}
                            Miscellaneous{" "}
                          </h5>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/******MED HISTORY --ends*******/}
            </div>
          </div>
          {/*************/}

          {/*****Schedule planners****/}
          <div className="each-section section-5 " ref={ref9}>
            <h5 className="sub-title head-strip">
              {" "}
              Treatment Planner{" "}
              <OverlayTrigger
                trigger="hover"
                rootClose
                placement="top"
                overlay={renderTooltip14}
              >
                <i className="fas fa-info-circle"></i>
              </OverlayTrigger>
            </h5>
            <div className="schedule-planners">
              {/************/}
              <br />

              <div style={{ gap: "10px" }} className="second-contentbox">
                <div className="next-visit-box">
                  <h5 className="font-weight-bold">Next Visit</h5>
                  <div className="d-flex flex-row justify-content-around">
                    <div className="d-flex flex-column align-self-center">
                      <h6>Select Doctor from list</h6>
                      <input
                        style={{ backgroundColor: "#fafafa" }}
                        onClick={() => {
                          setSearchAssign("");
                          setDisplayDoctor(true);
                        }}
                        value={doctorName}
                        className="form-control"
                        name="assignee"
                        onChange={(e) => {
                          e.preventDefault();

                          setDoctorName(e.target.value);
                          setDisplayDoctor(true);

                          setSearchAssign(e.target.value);
                        }}
                      />

                      {displayDoctor && (
                        <div className="suggestions-box-holder">
                          <button
                            className="btn-suggestion btn btn-secondary btn-smallest"
                            title="close"
                            onClick={() => setDisplayDoctor(false)}
                          >
                            {" "}
                            X{" "}
                          </button>

                          <div className="suggestions-box suggestions-assignee-box">
                            {assignList.map((item, i) => {
                              return (
                                <div
                                  className="scan-item"
                                  key={item.id}
                                  onClick={(e) => {
                                    e.preventDefault();

                                    setRender(!render);
                                    setDoctorId(item.id);
                                    setDoctorName(item.full_name);
                                    setDisplayDoctor(false);
                                  }}
                                >
                                  <div className="search-item each-option-suggestion">
                                    <h6 className="">ID: {item.id} </h6>
                                    <h6 className=""> {item.full_name}</h6>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>

                    <div
                      style={{ alignSelf: "flex-start" }}
                      className="each-list-title each-tile box-4 colored-box colored-box-small schedule-box"
                    >
                      <div>
                        <DatePicker
                          format="dd-MM-yyyy"
                          value={next}
                          onChange={(date) => {
                            handleNextVisit(date);
                          }}
                          minDate={new Date()}
                          clearIcon={null}
                        />
                        <button
                          className="delete-btn btn-smallest"
                          onClick={() => {
                            handleDeleteNextVisit1();
                          }}
                        >
                          {" "}
                          X{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <button
                style={{ alignSelf: "center" }}
                onClick={saveNextVisit}
                className="btn btn-primary btn-col btn-square btn-small"
              >
                Save Next Visit
              </button>
            </div>
            <br />

            <br />
            <br />

            <div className="each-section summary  section-diagnostic">
              <h4 className="sub-title">
                Test Schedules
                <OverlayTrigger
                  trigger="hover"
                  rootClose
                  placement="top"
                  overlay={renderTooltip22}
                >
                  <i className="fas fa-info-circle"></i>
                </OverlayTrigger>
              </h4>

              <br />

              <ReactTable
                columns={columns}
                data={testsList}
                defaultPageSize={5}
                resizable
                className="test-react-table"
              ></ReactTable>
              <br />

              {testsList.length < 1 ? (
                <button
                  className="btn btn-primary btn-col btn-col add-btn-react-table"
                  onClick={AddRowTest}
                >
                  {" "}
                  + Click to Add Data{" "}
                </button>
              ) : null}

              {deleteTestConfirmMsg === "success" ? (
                <DeleteTestConfirmPopup
                  show={deleteTestConfirmModalShow}
                  onHide={() => setDeleteTestConfirmModalShow(false)}
                  index={testIndexToDelete}
                />
              ) : (
                ""
              )}

              <div className="schedule-btn">
                <button
                  className="btn btn-primary btn-col btn-col btn-square btn-small"
                  onClick={handleSubmit}
                >
                  {" "}
                  Save and Submit{" "}
                </button>
              </div>
            </div>
            <br />

            <div className="each-section summary  section-diagnostic">
              <h4 className="sub-title">
                Scan Schedules
                <OverlayTrigger
                  trigger="hover"
                  rootClose
                  placement="top"
                  overlay={renderTooltip21}
                >
                  <i className="fas fa-info-circle"></i>
                </OverlayTrigger>
              </h4>

              <br />

              <ReactTable
                columns={columnsScans}
                data={scanList}
                defaultPageSize={5}
                resizable
                className="scan-react-table"
              ></ReactTable>
              <br />

              {deleteScanConfirmMsg === "success" ? (
                <DeleteScanConfirmPopup
                  show={deleteScanConfirmModalShow}
                  onHide={() => setDeleteScanConfirmModalShow(false)}
                  index={scanIndexToDelete}
                />
              ) : (
                ""
              )}

              <div className="schedule-btn">
                <button
                  className="btn btn-primary btn-col btn-col btn-square btn-small"
                  onClick={handleSubmit}
                >
                  {" "}
                  Save and Submit{" "}
                </button>
              </div>
            </div>

            {deleteNextVisitConfirmMsg === "success" ? (
              <DeleteNextVisitConfirmPopup
                show={deleteNextVisitConfirmModalShow}
                onHide={() => setDeleteNextVisitConfirmModalShow(false)}
              />
            ) : (
              ""
            )}
          </div>


            {/***************************************Procedure Section******************************/}
            {/* <div className="each-section summary section-2"  ref={ref11}>
            <div className="grid-procedure head-strip">
            <h5 className="sub-title head-strip">
              {" "}
              Procedure Table{" "}
              <OverlayTrigger
                trigger="hover"
                rootClose
                placement="top"
                overlay={renderTooltip26}
              >
                <i className="fas fa-info-circle"></i>
              </OverlayTrigger>
            </h5>
              <button
                onClick={() => setHistoryShow(true)}
                style={{ float: "right" }}
                className="btn btn-primary btn-col btn-square btn-small"
              >
                Procedure History
              </button>
            </div>

            <br />
            <br />
            <ReactTable
              columns={columnsProcedure}
              data={procedureList}
              defaultPageSize={5}
              resizable
              className="scan-react-table"
            ></ReactTable>
            <br />

            <button
              style={{ alignSelf: "center" }}
              className="btn btn-primary btn-col btn-col btn-square btn-small"
              onClick={handleSubmitProcedure}
            >
              Submit Procedure{" "}
            </button>
          </div> */}

          {/********* Procedure Ends******/}
          {/********* Set Reminders******/}
          <div className="each-section summary section-2" ref={ref10}>
            <h5 className="sub-title head-strip">
              {" "}
              Set Instructions{" "}
              <OverlayTrigger
                trigger="hover"
                rootClose
                placement="top"
                overlay={renderTooltip13}
              >
                <i className="fas fa-info-circle"></i>
              </OverlayTrigger>
            </h5>

            {/********instruction ****/}

            <div className="second-tab sub-section sub-section-dr-notes ">
              <div className="sub-section-header box-theme">
                <h6 className="sub-section-title"> Set Instruction</h6>
              </div>
              <div className="sub-section-body">
                <h6 date-instr>
                  {" "}
                  {instructionsDate
                    ? new Date(instructionsDate).toString().slice(0, 21)
                    : null}
                </h6>

                <textarea
                  className="text-area"
                  placeholder=""
                  cols="150"
                  rows="7"
                  value={instructions}
                  onChange={handleInstructions}
                />
              </div>

              <button
                className="btn btn-primary btn-col btn-col btn-square btn-small"
                onClick={handleSubmitInstructions}
              >
                {" "}
                Save Instructions{" "}
              </button>
              <br />
              <br />
            </div>
          </div>

          {testErr !== "nil" && testErr !== "false" ? (
            <ErrorPopup
              show={errorModalShow}
              onHide={() => setErrorModalShow(false)}
            />
          ) : null}

          {scanErr !== "nil" && scanErr !== "false" ? (
            <ErrorScanPopup
              show={errorScanShow}
              onHide={() => setErrorScanShow(false)}
            />
          ) : null}

          {drNotesShow ? (
            <DrNotesPopup
              show={drNotesShow}
              onHide={() => setDrNotesShow(false)}
              id={id}
            />
          ) : null}

          {nextErr !== "nil" ? (
            <ErrorNextPopup
              show={errorNextModalShow}
              onHide={() => setErrorNextModalShow(false)}
            />
          ) : null}

          {observationsShow ? (
            <ObservationsPopup
              show={observationsShow}
              onHide={() => setObservationsShow(false)}
              id={id}
            />
          ) : null}

          {foodHabitsShow ? (
            <FoodHabitsPopup
              show={foodHabitsShow}
              onHide={() => setFoodHabitsShow(false)}
              id={id}
            />
          ) : null}

{historyShow ? (
            <ProcedureHistoryPopup
              show={historyShow}
              onHide={() => {
                setHistoryShow(false);
              }}
            />
          ) : null}

          {targetShow ? (
            <TargetPopup
              show={targetShow}
              onHide={() => {
                setTargetShow(false);
                setRefresh(!refresh);
              }}
              id={id}
            />
          ) : null}

          {glucoseShow ? (
            <GlucosePopup
              show={glucoseShow}
              onHide={() => setGlucoseShow(false)}
              id={id}
            />
          ) : null}

          {bpShow ? (
            <BpPopup show={bpShow} onHide={() => setBpShow(false)} id={id} />
          ) : null}

          {pulseShow ? (
            <PulsePopup
              show={pulseShow}
              onHide={() => setPulseShow(false)}
              id={id}
            />
          ) : null}

          {cholesterolShow ? (
            <CholesterolPopup
              show={cholesterolShow}
              onHide={() => setCholesterolShow(false)}
              id={id}
            />
          ) : null}

          {addPrescShow ? (
            <AddPrescPopup
              show={addPrescShow}
              onHide={() => setAddPrescShow(false)}
              id={id}
            />
          ) : null}

          {inferenceShow ? (
            <InferencePopup
              show={inferenceShow}
              onHide={() => setInferenceShow(false)}
            />
          ) : null}

          {submitInstructionsMsg === "success" ? (
            <SubmitInstructionsPopUp
              show={modalInstructionsShow}
              onHide={() => {
                setModalInstructionsShow(false);
                setReRender(!reRender);
                setRefreshInstr(!refreshInstr);
                setSubmitInstructionsMsg("");
              }}
            />
          ) : submitMsg === "error" ? (
            <SubmitErrorPopup
              show={errorSubmit}
              onHide={() => {
                setErrorSubmit(false);
                setSubmitMsg("");
              }}
            />
          ) : (
            ""
          )}
          
{
          submitMsg1 === "error" ? (
            <SubmitErrorPopup1
              show={errorSubmit1}
              onHide={() => {
                setErrorSubmit1(false);
                setSubmitMsg1("");
              }}
            />
          ) : null
            }
          {deleteNextMsg !== "" ? (
            <DeletePopUp
              show={deleteNextModalShow}
              onHide={() => {
                setDeleteNextModalShow(false);
                setReRender(!reRender);

                setDeleteNextMsg("");
              }}
            />
          ) : (
            ""
          )}
          {submitMsg === "success" ? (
            <SubmitPopUp
              show={modalShow}
              onHide={() => {
                setModalShow(false);
                setReRender(!reRender);
                setErrorMessage("");
                setDoctorId("");
                 setDoctorName(doctorName);
                setNext_visit(null);
                setNext(null);
                setSearchAssign("");
                setSubmitMsg("");
              }}
            />
          ) : submitMsg === "error" ? (
            <SubmitErrorPopup
              show={errorSubmit}
              onHide={() => {
                setErrorSubmit(false);
                setSubmitMsg("");
              }}
            />
          ) : (
            ""
          )}

          {sizeShow ? (
            <SizePopUp
              show={sizeShow}
              onHide={() => {
                setSizeShow(false);
              }}
            />
          ) : null}

          {uploadShow ? (
            <LoadPopUp
              show={uploadShow}
              onHide={() => {
                setUploadShow(false);
              }}
            />
          ) : null}

          {validShow ? (
            <ValidPopUp
              show={validShow}
              onHide={() => {
                setValidShow(false);
              }}
            />
          ) : null}

          {contentShow ? (
            <ContentPopUp
              show={contentShow}
              onHide={() => {
                setContentShow(false);
              }}
            />
          ) : null}

          {popupShow ? (
            <EditPopup
              show={popupShow}
              onHide={() => {
                setPopupShow(false);
              }}
              onSave={() => {
                if (imgFile !== null) {
                  setPopupShow(false);
                  const scans = scanList;
                  scans[index].scan_details.image = imgFile;
                  setScanList(scans);
                  setEditSuccess(true);
                }
              }}
            />
          ) : null}

          {imgEditShow ? (
            <ImgEditPopup
              show={imgEditShow}
              onHide={() => {
                setImgEditShow(false);
              }}
              onSave={() => {
                if (imgFile !== null) {
                  setImgEditShow(false);
                  const tests = testsList;
                  tests[index].labtest_reason.image = imgFile;
                  setTestsList(tests);
                  setEditSuccess(true);
                }
              }}
            />
          ) : null}

          {remarkViewShow ? (
            <RemarkViewPopup
              show={remarkViewShow}
              onHide={() => {
                setRemarkViewShow(false);
              }}
            />
          ) : null}

          {editSuccess ? (
            <DetailsUpdateSuccessPopUp
              show={editSuccess}
              onHide={() => {
                setEditSuccess(false);
              }}
            />
          ) : null}

          {addShow ? (
            <AddPopup
              show={addShow}
              onHide={() => {
                setAddShow(false);
              }}
            />
          ) : null}

          {editShow ? (
            <DetailPopup
              show={editShow}
              onHide={() => {
                setEditShow(false);
              }}
              onSave={() => {
                if (sendData !== scanList[cell].scan_details.reason) {
                  setEditShow(false);
                  setEditSuccess(true);
                  const scans = scanList;
                  scans[cell].scan_details.reason = sendData;
                  setScanList(scans);
                }
              }}
            />
          ) : null}

          {viewShow ? (
            <ViewPopup
              show={viewShow}
              onHide={() => {
                setViewShow(false);
                setFlag(false);
              }}
            />
          ) : null}
  



          {reasonAddShow ? (
            <ReasonAddPopup
              show={reasonAddShow}
              onHide={() => {
                setReasonAddShow(false);
              }}
            />
          ) : null}

          {reasonEditShow ? (
            <ReasonEditPopup
              show={reasonEditShow}
              onHide={() => {
                setReasonEditShow(false);
              }}
              onSave={() => {
                if (sendData !== testsList[cell].labtest_reason.reason) {
                  setReasonEditShow(false);
                  setEditSuccess(true);
                  const tests = testsList;
                  tests[cell].labtest_reason.reason = sendData;
                  setTestsList(tests);
                }
              }}
            />
          ) : null}

          {messagesShow === false ? (
            <div
              className="dr-msgs-basic-sticky "
              onClick={handleMessagesOverlay}
              title="Message via Felixa Messenger"
            >
              <i className="fas fa-comments messenger-icon"></i>
            </div>
          ) : null}

          {/*
   <div className="dr-msgs-basic-sticky">
       <HospDrMessagesBasic patientId={id}/>

  </div>
  */}
        </div>
      </div>

      {messagesShow ? (
        <MessagesPopup show={messagesShow} onHide={handleTrack} id={id} />
      ) : null}
      {symptomsShow ? (
        <SymptomsPopup
          show={symptomsShow}
          onHide={() => setSymptomsShow(false)}
          // id ={id}
        />
      ) : null}

{deleteProcedureConfirmMsg==="success" ? 

<DeleteProcedureConfirmPopup
show={deleteProcedureConfirmModalShow}
onHide={() =>  setDeleteProcedureConfirmModalShow(false)}
index= {procedureIndexToDelete}
/>

 : 
   '' }   

      {allergyShow ? (
        <AllergyPopup
          show={allergyShow}
          onHide={() => setAllergyShow(false)}
          // id ={id}
        />
      ) : null}
      {weightAddShow ? (
        <WeightAddPopup
          show={weightAddShow}
          seteditweight={weight}
          onHide={() => {
            setWeightAddShow(false);
          }}
        />
      ) : null}

      {heightAddShow ? (
        <HeightAddPopup
          show={heightAddShow}
          seteditheight={height}
          onHide={() => {
            setHeightAddShow(false);
          }}
        />
      ) : null}

      {addPulse ? (
        <AddPulsePopup
          show={addPulse}
          onHide={() => {
            setAddPulse(false);
          }}
        />
      ) : null}
        {addPrescShow1 ? (
          <AddPrescPopup1
            show={addPrescShow1}
            onHide={() => setAddPrescShow1(false)}
            id={id}
          />
        ) : null}

      {addBp ? (
        <AddBpPopup
          show={addBp}
          onHide={() => {
            setAddBp(false);
          }}
        />
      ) : null}
      {addChol ? (
        <AddCholestrolPopUp
          show={addChol}
          onHide={() => {
            setAddCholestrol(false);
          }}
        />
      ) : null}

      {addSugar ? (
        <AddSugarPopup
          show={addSugar}
          onHide={() => {
            setAddSugar(false);
          }}
        />
      ) : null}
    </>
  );
}
export default PatientRecord;
