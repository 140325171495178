import React, { useState } from "react";
import axios from "axios";
import "./addheadertodumbdata.css";
import {Modal} from 'react-bootstrap';
import {APIURL} from "../../../Global";


function AddHeaderToDumbData({id,dumbData}) {
 
  //const [,setTest] = useState("");
  //const [,setResult] = useState("");
  //const [,setUnits] = useState("");
  //const [,setReferenceRange] = useState("");
  const [testCol, setTestCol] = useState("");
  const [resultCol, setResultCol] = useState("");
  const [unitsCol, setUnitsCol] = useState("");
  const [referenceRangeCol, setReferenceRangeCol] = useState("");
  const [submitmsg2, setSubmitmsg2] = useState("");
   const [modalShow2, setModalShow2] = useState(false);
   const [headerRow,] =useState([1]);
   const [updatedData,setUpdatedData] =useState(dumbData);


const SubmitPopUp2 = (props)=> {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Data submitted sucessfully!!</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
      
       {/* <Link to={parentURL}><Button className='btn btn-primary btn-col' > Goto {parentName} </Button></Link>
        */}
       
      </Modal.Footer>
    </Modal>
  );
}

const handleSave =(e)=>{
   
  if(testCol !== "" ){
    
    headerRow[testCol]="Test Name";
  }
   if(unitsCol !== ""){
   headerRow[unitsCol]= "Units";
 }
  if(referenceRangeCol !== ""){
    headerRow[referenceRangeCol] = "Reference Range";
  }
   if(resultCol !== ""){
     headerRow[resultCol] = "Result";
   }

  let newArray = dumbData;

 newArray.unshift(headerRow);

  setUpdatedData(newArray);


  handleSubmitData();
}

  const handleSubmitData = () => {
    

    const datatosend = {
      report_id: id,
      
      dumb_data: updatedData
    };
  if(testCol === "" && resultCol === "" && unitsCol=== "" && referenceRangeCol===""){
   setSubmitmsg2("error");
  }
  if(testCol === "" || resultCol === "" ){
    setSubmitmsg2("error");
  }
  else {
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); /* REGEX To remove double quotes from tokenstring */

  

    axios
      .post(
        `${APIURL}/api/v1/staff/lab-reports/headings/`,
        datatosend,
        { headers: {
      'Content-Type': 'application/json',
      "Authorization" :"Token " + v2}})
      .then((res) => {
        if (res.data.status === "success") {
         
          setSubmitmsg2("success");
          setModalShow2(true);
        } else {
          
          setSubmitmsg2("error");
        }
      })
      .catch((err) => {
        
        setSubmitmsg2("error");
      });
  }
}

  /*const handleChange = (e) => {
    e.preventDefault();
    
    

 
    if (e.target.name === "test") {
      setTest(e.target.value);
     

    }
    if (e.target.name === "units") {
      setUnits(e.target.value);
    }
    if (e.target.name === "result") {
      setResult(e.target.value);
    }
    if (e.target.name === "reference_range") {
      setReferenceRange(e.target.value);
    }
  };  */
   const handleColumn = (e) => {
    e.preventDefault();
    if (e.target.name === "testCol") {
      setTestCol(e.target.value);
     
    }
    if (e.target.name === "unitsCol") {
      setUnitsCol(e.target.value);
    }
    if (e.target.name === "resultCol") {
      setResultCol(e.target.value);
    }
    if (e.target.name === "reference_rangeCol") {
      setReferenceRangeCol(e.target.value);
    }
    
  };

  return (
    <div>
      <div className="add-row">
      
        <form className="add-form">
         
         <h5 className="title-of-page"> Add Header Row to Dumb Data </h5>
          <div className="inputs-div">
            
            <div className="header-and-col">
            <div 
             
              className="btn btn-secondary add-form-header-div add-header"
              
              ><span> Test Name</span> </div>
               <input
              type="text"
              name="testCol"
              className="form-control add-header"
              value={testCol}
              onChange={handleColumn}
              maxLength="150"
              placeholder="Col *- 0/1/2.."
            />
            </div>
             
              <div className="header-and-col">
             <div 
             
              className="btn btn-secondary add-form-header-div add-header"
              
              ><span> Units</span> </div>
               <input
              type="text"
              name="unitsCol"
              className="form-control add-header"
              value={unitsCol}
              onChange={handleColumn}
              maxLength="150"
              placeholder="Col- 0/1/2.."
            />
             </div>
              <div className="header-and-col">
             <div 
             
              className="btn btn-secondary add-form-header-div add-header"
              
              ><span> Result</span> </div>
              <input
              type="text"
              name="resultCol"
              className="form-control add-header"
              value={resultCol}
              onChange={handleColumn}
              maxLength="150"
              placeholder="Col *- 0/1/2.."
            />

             </div>
              <div className="header-and-col">
               <div 
             
              className="btn btn-secondary add-form-header-div add-header"
              
              ><span> Reference Range</span> </div>
               <input
              type="text"
              name="reference_rangeCol"
              className="form-control add-header"
              value={referenceRangeCol}
              onChange={handleColumn}
              maxLength="150"
              placeholder="Col - 0/1/2.."
            />

              </div>


{/*
            <input
              type="text"
              name="units"
              className="form-control add-header"
              value={units}
              onChange={handleChange}
              placeholder="Units"
            />

            <input
              type="text"
              name="result"
              className="form-control add-header"
              value={result}
              onChange={handleChange}
              placeholder="Result *"
            />

            <input
              type="text"
              name="reference_range"
              className="form-control add-header"
              value={referenceRange}
              onChange={handleChange}
              placeholder="Reference Range"
            />
         
          
      */}     
          
           </div>


{/*
           <div className="inputs-div">
            <input
              type="text"
              name="testCol"
              className="form-control add-header"
              value={testCol}
              onChange={handleColumn}
              placeholder="Col *- 0/1/2.."
            />

            <input
              type="text"
              name="unitsCol"
              className="form-control add-header"
              value={unitsCol}
              onChange={handleColumn}
              placeholder="Col- 0/1/2.."
            />

            <input
              type="text"
              name="resultCol"
              className="form-control add-header"
              value={resultCol}
              onChange={handleColumn}
              placeholder="Col *- 0/1/2.."
            />

            <input
              type="text"
              name="reference_rangeCol"
              className="form-control add-header"
              value={referenceRangeCol}
              onChange={handleColumn}
              placeholder="Col - 0/1/2.."
            />
          <button
            type="submit"
            onClick={handleSave}
            className="btn btn-primary btn-col"
          >
            +Add 
            </button>
          
           </div>

         */}
         <br/>
          <button
            type="submit"
            onClick={handleSave}
            className="btn btn-primary btn-col"
          >
            +Add 
            </button>
        </form>
      </div>

      <br />
      <div>
        {submitmsg2 === "success" ? (

          <SubmitPopUp2
        show={modalShow2}
        onHide={() => setModalShow2(false)}
      />

     
        ) : submitmsg2 === "error" ? (
          <h5 style={{ color: "red" }}>
            {" "}
            Error in Data submission.Please check again!{" "}
          </h5>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default AddHeaderToDumbData;
