import React, { useState } from 'react'
import Select from 'react-select';
import axios from "axios";
import { APIURL } from "../../../Global";
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';

function EditFeature(props) {

  const [data, setData] = useState(props.data)
  const [type, setType] = useState({ value: props.data.service_type, label: props.data.service_type.replace("_", " ") })
  const [unit, setUnit] = useState({ value: props.data.service_unit, label: props.data.service_unit })
  const [successShow, setSuccessShow] = useState(false)
  const [errorShow, setErrorShow] = useState(false)

  const serviceOptions = [{ value: 'general', label: 'General' },
  { value: 'service_request', label: 'Service Request' },
  { value: 'rental', label: 'Equipment Request' }
  ]

  const unitOptions = [{ value: 'days', label: 'Days' },
  { value: 'count', label: 'count' }
  ]

  const handleTitle = (e) => {
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;

    setData((current) => ({
      ...current, [key]: val
    }))
  }

  const handleServiceChange = (data) => {

    setType(data)
  }

  const handleUnitChange = (data) => {

    setUnit(data)
  }

  const handleRequest = (e) => {
    const val = e.target.value
    const key = e.target.name

    setData((current) => ({
      ...current, [key]: val === 'true' ? true : val === 'false' ? false : null
    }))

  }

  const saveChanges = (e) => {
    e.preventDefault();

    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); /* REGEX To remove double quotes from tokenstring */

    const dataToSend = {
      title2: data.title2,
      service_type: type.value,
      service_unit: unit.value,
      on_request_service: data.on_request_service,
      is_active: data.is_active
    }

    axios
      .put(`${APIURL}/api/v1/staff/update-cp-services/?id=${data.id}`, dataToSend, {
        headers: { Authorization: "Token " + v2 },
      })
      .then((res) => {
        if (res.data.status === "success") {

          setSuccessShow(true)
        } else {
          setErrorShow(true)
        }
      })
      .catch((err) => {
        setErrorShow(true)
      });

  }

  const SuccessPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className='text-success'>Updated Successfully</h4>

        </Modal.Body>
        <Modal.Footer>


          <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button>


        </Modal.Footer>
      </Modal>
    );
  }

  const ErrorPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}>Error in data submission.Please try again!</h5>

        </Modal.Body>
        <Modal.Footer>


          <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button>


        </Modal.Footer>
      </Modal>
    );
  }

  return (<>
    <h2 style={{ marginTop: '0' }} className="title-of-page">Edit Feature</h2>
    <div className='config-box fit-content'>

      <div className='justify-feature padding1' style={{ display: "flex" }}>
        <span>*Feature Name: </span>
        <input type='text' className='form-control title-input' name='title2' value={data.title2} onChange={handleTitle} />
      </div>
      <div className='justify-feature padding1' style={{ display: "flex" }}>
        <span>*Service Type : </span>
        <Select
          value={type}
          onChange={(data, e) => {
            handleServiceChange(data, e)
          }}
          options={serviceOptions}
          name='service_type'
          className="select-currency serv-width"
        />
      </div>

      <div className='justify-feature padding1' style={{ display: "flex" }}>
        <span>*Service Unit : </span>
        <Select
          value={unit}
          onChange={(data, e) => {
            handleUnitChange(data, e)
          }}
          options={unitOptions}
          name='service_unit'
          className="select-currency serv-width"
        />
      </div>

      <div className='justify-feature padding1' style={{ display: "flex" }}>
        <span>*On Request Service : </span>

        <div style={{ marginBottom: 'auto' }} className="form-group gender-radio-check col-lg-6 title-input">

          <input className='checkbox-class1' type='radio' id="true" name='on_request_service' value={true} checked={data.on_request_service === true} onChange={handleRequest} />
          <label htmlFor='true'>Yes</label>

          <input className='checkbox-class1' type='radio' id="false" name='on_request_service' value={false} checked={data.on_request_service === false} onChange={handleRequest} />
          <label htmlFor='false'>No</label>

        </div>
      </div>

      <div className='justify-feature padding1' style={{ display: "flex" }}>
        <span>*Active Feature : </span>

        <div style={{ marginBottom: 'auto' }} className="form-group gender-radio-check col-lg-6 title-input">

          <input className='checkbox-class1' type='radio' id="true" name='is_active' value={true} checked={data.is_active === true} onChange={handleRequest} />
          <label htmlFor='true'>Yes</label>

          <input className='checkbox-class1' type='radio' id="false" name='is_active' value={false} checked={data.is_active === false} onChange={handleRequest} />
          <label htmlFor='false'>No</label>

        </div>
      </div>
      <br />
      <div className='text-center'>
        <button onClick={saveChanges} className='btn btn-primary btn-col'>Save</button>

      </div>
      <br />
      {successShow ?
        <SuccessPopUp
          show={successShow}
          onHide={() => { setSuccessShow(false); props.modalClose(); }}
        />
        : null}

      {errorShow ?

        <ErrorPopUp
          show={errorShow}
          onHide={() => { setErrorShow(false) }}
        />
        : null}

    </div>
  </>
  )
}

export default EditFeature
