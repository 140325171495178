import React, {useState, useEffect} from 'react'
import axios from "axios";
import {APIURL} from "../../../Global";
import {useHistory} from 'react-router-dom';
import './doctorprofile.css';
import {Card , Button ,Modal} from 'react-bootstrap'
import ProofImage from './ProofImage';


function DoctorProfile(props) {
    const [id ,setId] =useState(props.location.id);
    const [img ,setImg] =useState()
    const [name ,setName] =useState('');
    const [desc ,setDesc] =useState('');
    const [estName ,setEstName]= useState('');
    const [estLocation ,setEstLocation]= useState('');
    const [ownEst, setOwnEst]= useState('');
    const [expYear ,setExpYear] =useState('')
    const [expMonth ,setExpMonth] =useState('')
    const [regNum, setRegNum]= useState('')
    const [regYear, setRegYear]= useState('')
    const [council, setCouncil]= useState('')
    const [qual ,setQual] =useState([])
    const [specs ,setSpecs] =useState([])
    const [work ,setWork] =useState([]);
    const [proof, setProof] =useState('');
    const [approveShow ,setApproveShow] =useState(false);
    const [approveFail ,setApproveFail] =useState(false);
    const [rejectShow ,setRejectShow] =useState(false);
    const [rejectFail ,setRejectFail] =useState(false);
    const [approveMsg ,setApproveMsg] =useState('');
    const [approveFailMsg ,setApproveFailMsg] =useState('');
    const [rejectMsg ,setRejectMsg] =useState('');
    const [rejectFailMsg ,setRejectFailMsg] =useState('');
    const [imgShow ,setImgShow] =useState(false);
    const [confirm ,setConfirm] =useState(false);
    const [photo ,setPhoto] =useState();
    const [hosp ,setHosp] =useState('');
    const [modalShow ,setModalShow] =useState(false);
    const [errorShow, setErrorShow]= useState(false)

    const history =useHistory();

const [keys,] = useState({'h': false,'d': false,'u': false,'r': false,'l': false,'i': false,'a': false,'p': false,'v': false,'t': false,'n': false});


useEffect(()=>{

window.onkeydown =(e) => {
 
 keys[e.key]=true

 if(e.altKey && keys['h']){
   history.push('/hospitallist')
 }
 if(e.altKey && keys['d']){
   history.push('/doctorslist')
 }
 if(e.altKey && keys['u']){
   history.push('/search-hospitals')
 }
 if(e.altKey && keys['r']){
   history.push('/rejectedlist')
 }
 if(e.altKey && keys['l']){
   history.push('/reportlist')
 }
 if(e.altKey && keys['i']){
   history.push('/addsynonyms')
 }
 if(e.altKey && keys['a']){
   history.push('/failedlist')
 }
 if(e.altKey && keys['p']){
   history.push('/prescriptionlist')
 }
 if(e.altKey && keys['v']){
   history.push('/written-prescriptionlist')
 }
 if(e.altKey && keys['t']){
   history.push('/testlist')
 }
 if(e.altKey && keys['n']){
  history.push('/nutrition')
}

}
window.onkeyup=(ev)=> {
 
 keys[ev.key]= false
}

return()=>{
 window.onkeydown=null
 window.onkeyup=null
}
},[]);

    const toggle = props.location.toggle

    const handleBack=(e)=>{
      history.goBack();
    }

    useEffect(()=> {
        if(id===undefined){

        }
        else{
            sessionStorage.setItem('id',id)
            sessionStorage.setItem('toggle',toggle)
        }        
    },[])
    useEffect(()=> {
      window.scrollTo(0,0)
  },[])
    useEffect(()=>{
    
        const id =sessionStorage.getItem('id')

        const tokenString = sessionStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
          );
          axios.get(`${APIURL}/api/v1/patient/user-details/?doctor_id=${id}`, {
            headers: { Authorization: "Token " + v2 },
       })
       .then((res) =>{
           if(res.data.status==='success'){
               const docData =res.data.doctor_details
               
               setPhoto(docData.photo)
               setImg(docData.id_proof_image)
               setName(docData.name);
               setDesc(docData.doctor_description)
               setSpecs(docData.specialization)
               setExpYear(docData.experience.year)
               setExpMonth(docData.experience.month)
               //const estDetails ='Establishment Name : '+docData.establishment[0].establishment_name + '\n \n' + 'Establishment Location :'+ docData.establishment[0].establishment_location
               setEstName(docData.establishment[0].establishment_name)
               setEstLocation(docData.establishment[0].establishment_location)
                //const prof = 'Registration Number: '+docData.profession[0].registration_number + '\n \n' + 'Registration Year :'+ docData.profession[0].registration_year  + '\n \n' + 'Council :'+ docData.profession[0].council
               setRegNum(docData.profession[0].registration_number)
               setRegYear(docData.profession[0].registration_year)
               setCouncil(docData.profession[0].council)    
               setQual(docData.education_qualifications[0].degree)
               let hosp= []
               const workHosp = docData.working_hospital
               workHosp.map((item,index)=>{
                 if(item.working_hospital_name!==''&& item.working_hospital_location!==''){
                   hosp.push(index+1+'.'+item.working_hospital_name + ' , ' + item.working_hospital_location +'  ')
                 }
               })
               setWork(hosp)
               setProof(docData.id_proof)
               setHosp(docData.connected_hospital)
               setOwnEst(docData.own_establishment)
           }
           else if(res.data.status==='error'){
             setId('')
             setModalShow(true)

           }
       })
       .catch((err) => {
        setErrorShow(true)
       })
    },[])
    const handleApprove = ()=> {
        const id =sessionStorage.getItem('id')

        const tokenString = sessionStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
          );
          const data ={
              doctor_id : id
          }
          axios.post(`${APIURL}/api/v1/staff/doctor-approve/`, data, {
            headers: { Authorization: "Token " + v2 },
       })
       .then((res) =>{
           if(res.data.status==='success'){
               setApproveMsg('success')
               setApproveShow(true)
           }
           else{
               setApproveFailMsg('Failed to submit data')
               setApproveFail(true)
           }
       })
       .catch((err) => {
        setApproveFailMsg('Failed to submit data')
        setApproveFail(true)
       })

    }

    const handleReject = ()=> {
        const id =sessionStorage.getItem('id')

        const tokenString = sessionStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
          );
          const data ={
              doctor_id : id,
              status : 'rejected'
          }
          axios.post(`${APIURL}/api/v1/staff/doctor-approve/`, data, {
            headers: { Authorization: "Token " + v2 },
       })
       .then((res) =>{
           if(res.data.status==='success'){
               setRejectMsg('succesfully rejected')
                setRejectShow(true)            
           }
           else{
               setRejectFailMsg('failed to reject')
                setRejectFail(true)
           }
       })
       .catch((err) => {
        setRejectFailMsg('failed to reject')
        setRejectFail(true)
       })

    }

    const ApprovalPopUp = (props)=> {
        return(
          <Modal
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
               backdrop="static"
                keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                 
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
      
              <h4 id="confirm-reject">Doctor approved Successfully</h4>
               
              </Modal.Body>
              <Modal.Footer>
               
                 <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
        
              </Modal.Footer>
            </Modal>
          )
      }

      const ApprovalFail = (props)=> {
        return(
          <Modal
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
               backdrop="static"
                keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                 
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
      
              <h5 style={{color: "red"}}> Error in Data Submission. Please try again! </h5>
               
              </Modal.Body>
              <Modal.Footer>
               
                 <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
        
              </Modal.Footer>
            </Modal>
          )
      }

      const RejectPopUp = (props)=> {
        return(
          <Modal
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
               backdrop="static"
                keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                 
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
      
              <h4 id="confirm-reject">Doctor rejected Successfully</h4>
               
              </Modal.Body>
              <Modal.Footer>
               
                 <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
        
              </Modal.Footer>
            </Modal>
          )
      }

      const RejectFail = (props)=> {
        return(
          <Modal
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
               backdrop="static"
                keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                 
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
      
              <h5 style={{color: "red"}}> Error in Data Submission. Please try again! </h5>
               
              </Modal.Body>
              <Modal.Footer>
               
                 <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
        
              </Modal.Footer>
            </Modal>
          )
      }

      const ConfirmPopUp = (props)=> {
        return(
          <Modal
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
               backdrop="static"
                keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                 
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
      
              <h4 id="confirm-reject">Are you sure?</h4>
               
              </Modal.Body>
              <Modal.Footer>
               
                 <Button variant="secondary" onClick={props.onHide}>No</Button>
                 <Button variant="danger" onClick={props.onReject}>Yes</Button>                          
        
              </Modal.Footer>
            </Modal>
          )
      }

      const ImgPopUp = (props)=> {
        return(
          <Modal
              {...props}
              size='lg'
              aria-labelledby="contained-modal-title-vcenter"
              centered
               backdrop="static"
                keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                 
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
      
              <ProofImage image={img} />
               
              </Modal.Body>
              <Modal.Footer>
               
                 <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
        
              </Modal.Footer>
            </Modal>
          )
      }

      const NoDataPopUp = (props)=> {
        return(
          <Modal
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
               backdrop="static"
                keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                 
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
      
              <h4 id="confirm-reject">Doctor Details not yet added</h4>
               
              </Modal.Body>
              <Modal.Footer>
               
                 <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
        
              </Modal.Footer>
            </Modal>
          )
      }

      const ErrorPopUp = (props)=> {
        return(
          <Modal
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
               backdrop="static"
                keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                 
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
      
              <h5 style={{color: "red"}}> Error in Data Retrieval. Please try again! </h5>
               
              </Modal.Body>
              <Modal.Footer>
               
                 <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
        
              </Modal.Footer>
            </Modal>
          )
      }

      
    return (<>
        <div className=" text-left">
        <br/>
          <button className="btn btn-info back-btn" onClick={handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back</button> 
          
    </div> 
    <div className="padding">
      
        
                <div className="card user-card-full">
                <div className='card-length'>    
                <Card className="mb-2" bg='info' border='success'>
  <Card.Img variant="top" src={photo} alt='Profile Picture' className="img-radius" />
  <Card.Body>
    <Card.Title style={{fontSize:'150%'}}><h4 className="title-of-tasks" >{name}</h4></Card.Title>
    <Card.Text >
    <p className="lead text-justify">{desc ? desc : <p className='lead text-justify'>Description of Doctor is not available at the moment</p>}</p>
    </Card.Text>
    
  </Card.Body>
</Card>
</div>
                        <div className="check1-class">
                            <div className="card-block">
                                <h6 className="m-b-20 p-b-5 b-b-default f-w-600 lead">Doctor Details</h6>
                                <div className="row">
                                    <div className="col-sm-6">
                                      <p className="m-b-10 f-w-600 p-size text-left">ID :  <span className='text-info span-size'>{sessionStorage.getItem('id')}</span></p>    
                                        <p></p>   
                                    </div>
                                    <div className="col-sm-6">
                                      <p className="m-b-10 f-w-600 p-size text-left">Name : <span className='text-info span-size'>{name===null?'Not Available':name}</span></p>
                                    </div>                                   
                                    <div className="col-sm-6">
                                      <p className="m-b-10 f-w-600 p-size text-left">Experience : <span className='text-info span-size'>{expYear!==null && expMonth!==null? `${expYear} year ${expMonth} month`: expYear!==null && expMonth===null? `${expYear} year`: expYear===null && expMonth!==null? `${expMonth} month`:'Not Available' }</span> </p>
                                        <p></p>  
                                    </div>
                                    <div className="col-sm-6">
                                      <p className="m-b-10 f-w-600 p-size text-left">Qualification : <span className='text-info span-size'>{qual===''?'Not Available':qual}</span></p>
                                    </div>
                                    <div className="col-sm-6">
                                        <p className="m-b-10 f-w-600 p-size text-left">Profession : 
                                          <span className='span-size'> Registration Number- <span className='text-info'>{regNum===null?'Not Available':regNum}</span></span><br/>
                                          <span className='span-size text-mg'>Registration Year- <span className='text-info'>{regYear===null?'Not Available':regYear}</span></span><br/>
                                          <span className='span-size text-mg'>Council- <span className='text-info'>{council===null?'Not Available':council}</span></span>
                                        </p>    
                                        <p></p> 
                                    </div>
                                    <div className="col-sm-6">
                                    <p className="m-b-10 f-w-600 p-size text-left">Establishment : 
                                          <span className='span-size'> Establishment Name- <span className='text-info'>{estName===''?'Not Available':estName}</span></span><br/>
                                          <span className='span-size text-mg1'>Establishment Location- <span className='text-info'>{estLocation===''?'Not Available':estLocation}</span></span><br/>
                                        </p>    
                                    </div>                                     
                                    <div style={{whiteSpace: 'pre-wrap'}} className="col-sm-6">
                                    <p className="m-b-10 f-w-600 p-size text-left">Working Hospitals : <span className='text-info span-size'>{work.length<1?'Not Available':work}</span></p>
                                    </div>     
                                    <div style={{whiteSpace: 'pre-wrap'}} className="col-sm-6">
                                    <p className="m-b-10 f-w-600 p-size text-left">Connected Hospitals : <span className='text-info span-size'>{hosp===''?'Not Available':hosp}</span></p>
                                        <p></p>
                                    </div>
                                    <div className="col-sm-6">
                                    <p className="m-b-10 f-w-600 p-size text-left">Specializations : <span className='text-info span-size'>{specs===null?'Not Available':specs}</span></p>
                                    </div>  
                                                               
                                    <div className="col-sm-6">
                                    <p className="m-b-10 f-w-600 p-size text-left">ID Proof : <h6 style={{display:'inline-block'}} className="text-info f-w-400">{proof}</h6>{'\n'}<img onClick={()=> setImgShow(true)} style={{display:'inline-block',cursor: 'pointer'}} src={img} alt='Img' className="img-size"/></p>
                                        
                                    </div>
                                    <div className="col-sm-6">
                                    <p className="m-b-10 f-w-600 p-size text-left">Own Establishment : <span className='text-info span-size'>{ownEst===true? <h6 style={{display:'inline-block'}}>Yes</h6> : ownEst===false? <h6 style={{display:'inline-block'}}>No</h6>:'Not Available'}</span></p>
                                        <p></p>
                                    </div>                                                                                                           
                                    
                                </div>                                
                            </div>
                            {
                              sessionStorage.getItem('toggle')==='true'?
                              <div>
                                <button style={{marginBottom:'80px'}} onClick={()=>setConfirm(true)} className="btn btn-danger">Reject</button>{'  '}
                                <button style={{marginBottom:'80px'}} onClick={handleApprove} className="btn btn-primary btn-col">Approve</button> 
                              </div>
                              :
                              null 
                            }
                                                      
                        </div>
                    
                </div>
    {
        approveMsg!=='' ? 
        <ApprovalPopUp 
            show={approveShow}
            onHide= {() => {setApproveShow(false); setApproveMsg(''); history.goBack() }}/> : null
    }

{
        approveFailMsg!=='' ? 
        <ApprovalFail 
            show={approveFail}
            onHide={() => {setApproveFail(false); setApproveFailMsg(''); }}/> : null
    }

{
        rejectMsg!=='' ? 
        <RejectPopUp 
            show={rejectShow}
            onHide={() => {setRejectShow(false); setRejectMsg(''); history.goBack()  }}/> : null
    }

{
        rejectFailMsg!=='' ? 
        <RejectFail 
            show={rejectFail}
            onHide={() => {setRejectFail(false); setRejectFailMsg(''); }}/> : null
    }

{
        confirm ? 
        <ConfirmPopUp 
            show={confirm}
            onHide={() => {setConfirm(false) }}
            onReject={() => {handleReject(); setConfirm(false) }}/> : null
    }

{
        imgShow ? 
        <ImgPopUp 
            show={imgShow}
            onHide={() => {setImgShow(false) }}/> : null
    }

{
        modalShow ? 
        <NoDataPopUp 
            show={modalShow}
            onHide={() => {setModalShow(false); history.goBack()  }}/> : null
    }

     {
            errorShow ? 
        <ErrorPopUp
            show={errorShow}
            onHide= { ()=> {setErrorShow(false); history.goBack()
                           }} />
            : null
        }       
        
    </div>
</>

    )
}

export default DoctorProfile
