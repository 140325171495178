import React, { useState, useEffect,useRef } from 'react';
import axios from 'axios';
import { APIURL } from "../../Global";
import ListGroup from 'react-bootstrap/ListGroup'
import PrescriptionTable from './PrescriptionTable';
import './prescriptionlist.css';
import moment from "moment"


function PrescriptionList(props) {
	const [display, setDisplay] = useState(false)
	const [prescList, setPrescList] = useState([]);
	const [prescription, setPrescription] = useState([])
	const ref1 = useRef();
   

	useEffect(() => {
		const tokenString = sessionStorage.getItem('usertoken');

		let v2 = tokenString.replace(
			/["]+/g,
			""
		); /* REGEX To remove double quotes from tokenstring */

		axios.get(`${APIURL}/api/v1/hospital/patient-medicines-prescription/?patient_id=${props.patientId}`,
			{
				headers: { Authorization: "Token " + v2 }
			})
			.then(res => {
				if (res.data.status === "success") {
					setPrescList(res.data.data);

				}
			})
	}, []);
	
	
	const displayPresc = (presc) => {
		setDisplay(true)
		setPrescription(presc)
		prescription.date_formatted = moment(prescription.date, "YYYY-MM-DD").format("DD-MM-YYYY");
		handleClick(ref1);
	}
	const handleClick = (ref) => {
		// ref.current.scrollIntoView({
		//   behavior: "smooth",
		//   block: "center",
		// });
	  };
	return (<>
		<div className='row' style={{}}>
			<div className='index col-sm-2' style={{ height: '600px', overflowY: 'scroll', marginLeft: '2px' }}>
				{prescList.map((presc, index) => {
					return (
						<ListGroup className='border-primary' style={{ borderRadius: "5px", padding: '2px' }} variant="flush">
							<ListGroup.Item><h5><a href='#' onClick={() => displayPresc(presc)}><b>ID: {presc.all_prescriptions[0].prescription_id}</b></a></h5>
								<small> Dr {presc.doctor_name}&nbsp;&nbsp;&nbsp; {presc.date}</small>
							</ListGroup.Item>

						</ListGroup>
					)
				})}
			</div>
			{display ?
				<div className='col-md-9 ' style={{ marginLeft: '10px' }} ref={ref1}><PrescriptionTable prescdata={prescription.all_prescriptions}  /></div>

				: null}
		</div>
	</>
	)
}
export default PrescriptionList;