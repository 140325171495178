import React, {useState, useEffect} from "react";

import "./labreports.css";
import { Link,useHistory} from "react-router-dom";
import axios from "axios";
import HospDocNavbar from "../HospDocNavbar/HospDocNavbar";
import PatientLabReport from "../HospPatientLabReport/HospPatientLabReport";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import Pagination from "react-js-pagination";
import "./pagination.css";
import { Dropdown } from 'react-bootstrap';
import {APIURL} from "../../Global";

function LabReportsList(props){
  const [reportList,setReportList] = useState([]);
   const [patientId,] =useState(props.match.params.id);
   const [reportShow,setReportShow]=useState(false)
   const [reportId,setReportId] = useState();
  const [activePage,setActivePage] = useState(1);
      const [order,setOrder]=useState("desc")
      const [dropDownOpen,setDropDownOpen]=useState(false);
      const [refresh,setRefresh]= useState(false);
      const [totalPages,setTotalPages]=useState(1);
      const [selected,setSelected]=useState([]);
      const [selected_Ids,setSelected_Ids] =useState([]);
      const [errorMsg,setErrorMsg]=useState();
const [errorShow,setErrorShow]=useState(false);
      const storedId = sessionStorage.getItem('hospId')
  let history=useHistory();

  const [keys,] = useState({'p': false,'a': false,'n': false,'m': false,'l': false,'s': false,});


  useEffect(()=>{
  
    window.onkeydown =(e) => {
      
      keys[e.key]=true
  
      if(e.altKey && keys['p']){
        history.push('/patients-directory')
      }
      if(e.altKey && keys['a']){
        history.push('/appointment')
      }
      if(e.altKey && keys['n']){
        history.push('/doctor/notifications')
      }
      if(e.altKey && keys['m']){
        history.push('/message')
      }
      if(e.altKey && keys['l']){
        history.push('/doctor/analytics')
      }
      if(e.altKey && keys['s']){
        history.push('/service-requestlist')
      }
  
  
    }
    window.onkeyup=(ev)=> {
      
      keys[ev.key]= false
    }
  
    return()=>{
      window.onkeydown=null
      window.onkeyup=null
    }
  },[]); 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
useEffect(()=>{

  const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  /* REGEX To remove double quotes from tokenstring */
          
    
     
  
    axios.get(`${APIURL}/api/v1/patient/lab-reports/?src=website&patient_id=${patientId}&sort_by=${order}&page=${activePage}&per_page=20`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
    

  
   
      if(res.data.status==="success"){
   
      let repList= res.data.results;
     let selArray=[];
     selArray= repList.map((item,index)=>{
      let repId=item.id;

       return{
         id: repId,
         status:false
       }
     });

    
     setSelected(selArray);

     setReportList(res.data.results);
     setTotalPages(res.data.total_pages);
    
      

      }else{
       
      }


    })
    .catch(err=>{

 
})
 

 },[activePage,order,refresh]);

const handleReportOverlay=(e,reportId)=>{
 e.preventDefault();
 setReportShow(true);
 setReportId(reportId);

}

    const handlePageChange=(pageNumber)=> {
   
    setActivePage(pageNumber);
    setRefresh(!refresh);
    }

    const onSort = async order=>{
  await setOrder(order);
  
  await setRefresh(!refresh);
}
const ReportPopup=(props)=> {
 
 
  return (
    <Modal
      {...props}
     
       aria-labelledby="example-custom-modal-styling-title"
       backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
      
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        {/* <h4 className="title-of-tasks"> Lab Report {props.reportid}  </h4> */}
         <h5 className="text-muted"> Felixa ID {props.patientid}</h5>
         <h5 className="text-muted">{storedId!==''? 'Hospital ID ' + storedId: null}</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PatientLabReport {...props} />
       
     </Modal.Body>
     <Modal.Footer>
       
        
       <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>
       
       
      </Modal.Footer>
    </Modal>
  );
}

const ErrorPopup =(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 style={{color: "red"}}> {errorMsg} </h5>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Ok</Button>

       
       

      </Modal.Footer>
    </Modal>
  );
}

const handleSelected=(e,rep_id)=>{


 const updatedSelected= selected.length>0 ? selected.map((item,index)=>{
  if(item.id===rep_id){
    return({id: item.id,
         status:!item.status
  })
  }
  else{
    return({
      id: item.id,
      status:item.status
    })
  }
 }) : [];

 const updated_Ids= [];
 const IDArray=updatedSelected.length>0 ? updatedSelected.forEach((item,index)=>{
  if(item.status===true){
    updated_Ids.push(item.id)
  }
 
 }) : null;

 setSelected(updatedSelected);
 setSelected_Ids(updated_Ids);
}
const display = reportList.map((report,index)=>{
                  return (<div className="each-box-thumbnail">

                    <div className="flex-row">
                     <span><input className="form-check-input" type="checkbox"  id="rep"  name="rep" 
                     checked={selected[index].status}
                     onChange={(e)=>handleSelected(e,report.id)}/> </span>

                       <div key={index} className="thumbnail-div ">
                       {/*
                         <Link to={url} {...props}>  <div className="list-item"> */}

                        
                        <div className="list-item">
                       {/* <Link to={{
                    pathname: `/patient-lab-report`,
                    patientId: patientId,
                    reportId: report.id
                            }} >  */}
          <span className="history-link" onClick={(e)=>{handleReportOverlay(e,report.id)}} >
                            <div className="row-table-title">
          <h5 className="each-list-link">
            {index + 1}. Report {report.id}  
           </h5>
             <p> Date: {report.date} </p>
         
         
             </div>
             

              <table className="thumbnail-table ">
                      <tr className=" table-header-small">
                        <th> Test</th>
                        <th> Result</th>
                        <th> Units</th>
                        <th> Reference Range</th>
                        
                        
                      </tr>
                      
                    {report.tests? report.tests.map(item=>{
                     return  <tr className=" table-row-small">
                               <td> {item.test} </td>
                               <td> {item.result} </td>
                               <td> {item.units} </td>
                               <td> {item.reference_range} </td>
                              

                              
                            </tr>
                      }) 
                      : null}
                   </table>
                  {/* </Link> */}
                  </span>
                   </div> 
                       </div>

                       </div>

                       {reportShow && reportId===report.id? 
                       <ReportPopup 
        show={reportShow}
        onHide={() =>  setReportShow(false)}
        reportid ={reportId}
        patientid= {patientId}
         />

: null }

{ }
                       </div>
                    )

                });

const handleGenerateReport=(e)=>{

  e.preventDefault();
  if(selected_Ids.length<2){
  
    setErrorMsg("Please select atleast 2 Reports to compare!")
    setErrorShow(true)
  }
  else{
 
    history.push({pathname: `/comparison-report`,
                  state: {sel: selected_Ids}})

  }
}
  return(<>
    <HospDocNavbar/><br/>
  <div className="text-left">
    <Link to={`/patient-record/${patientId}`}><span className="btn btn-info" >  <i className="fas fa-angle-double-left"></i> Back</span></Link>
    </div>
    <div className="container"><br/>
      

    <h2 className="title-of-tasks"> Lab Reports </h2>
    <div className='flex-row'>
     <h5 className="title-of-tasks text-center hospId-display"> Felixa ID {patientId}</h5>
     <h5 className="title-of-tasks text-center">{storedId!==''? 'Hospital ID ' + storedId: null}</h5>
    </div>
     <div className="buttons-wrapper-presc container">
       
          <button className="btn btn-secondary" onClick={()=>{setRefresh(!refresh)}}>
            Refresh List <i className="fas fa-sync-alt"></i>{" "}
          </button>


<Dropdown>
  <Dropdown.Toggle variant="secondary" id="dropdown-basic">
    Sort 
  </Dropdown.Toggle>

  <Dropdown.Menu>
    <Dropdown.Item onClick={()=>{onSort("asc")}}>Ascending</Dropdown.Item>
    <Dropdown.Item  onClick={()=>{onSort("desc")}}>Descending</Dropdown.Item>
  
  </Dropdown.Menu>
</Dropdown>

</div>
      
  <div className="columns-holder">
    {reportList.length>0 ? display : <h5 style={{color: "red",padding:"30px"}}> Sorry, No Lab Reports to show! </h5> }
  </div>

{totalPages>1 ?
  <Pagination
          activePage={activePage}
          itemsCountPerPage={20}
          totalItemsCount={totalPages*20}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          disabledClass = "disabled-class"
        />
        : null}
    </div>
<br/>
          
  {reportList.length>0 ? <>
          <div className="generate-btn-div"> 
              <button className="btn btn-primary btn-col  " onClick={handleGenerateReport}>Generate Comparison Report </button>
           
              <br/>
                </div>
              <h6 style={{color:"grey"}}>**Select checkboxes of the required Reports and click 'Generate Comparison Report'</h6>
              </>
              : null}
<br/>
{errorMsg!=="" && errorMsg!==null ?
 <h6 style={{color:"red"}}> {errorMsg}</h6>
: null} 


  {errorMsg!=='' && errorMsg!==null ?
    <ErrorPopup
        show={errorShow}
        onHide={() =>  {setErrorShow(false);
                        setErrorMsg('');
                       }}
       
      />: ''
    }
    <br/>
    </>)


}



export default LabReportsList;