import React, { useEffect, useState } from "react";
import Chart from "chart.js";
import "./glucosevitals.css";
import axios from "axios";
import DatePicker from "react-date-picker";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import { APIURL } from "../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';

let myChart;

function GlucoseVitals(props) {
  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
      .toISOString()
      .split("T")[0]
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000)
      .toISOString()
      .split("T")[0]
  );
  const timezoneOffset = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [patientId] = useState(props.id);

  const [inference, setInference] = useState([]);
  const [inferenceLoad, setInferenceLoad] = useState("");
  const [glucDatesArray, setGlucDatesArray] = useState([]);
  const [updateRender, setUpdateRender] = useState(false);
  const [glucoseList, setGlucoseList] = useState([]);
  const [nonFasting, setNonFasting] = useState([]);
  const [fasting, setFasting] = useState([]);
  const [random, setRandom] = useState([]);
  const [unit, setUnit] = useState();
  const [load, setLoad] = useState("");
  const [errorSubmit,setErrorSubmit]=useState(false);
  const [errorMsg,setErrorMsg]=useState("");
  const [deleteIndex, setDeleteIndex]= useState('');
  const [deleteShow, setDeleteShow]= useState(false)
  const [submitMsg,setSubmitMsg] = useState("");
  const [modalShow,setModalShow]=useState(false);
  const [glucoDeleteRowValue, setGlucoDeleteRowValue] = useState(0);
  const [refreshKey, setRefreshKey] = useState(0);
  useEffect(() => {
    const ctx = document.getElementById("myChart-glucose").getContext("2d");

    if (myChart) {
      myChart.destroy();
    }
    myChart = new Chart(ctx, {
      type: "line",
      data: {
        labels: glucDatesArray,
        datasets: [
          {
            label: "NonFasting Blood Sugar",
            data: nonFasting,
            backgroundColor: "purple",

            pointBorderWidth: 2,
            pointHoverRadius: 5,
            fill: false,
            borderColor: "purple",
          },
          {
            label: "Fasting Blood Sugar",
            data: fasting,
            backgroundColor: "green",

            pointBorderWidth: 2,
            pointHoverRadius: 5,
            fill: false,
            borderColor: "green",
          },
          {
            label: "Random Blood Sugar",
            data: random,
            backgroundColor: "red",

            pointBorderWidth: 2,
            pointHoverRadius: 5,
            fill: false,
            borderColor: "red",
          },
        ],
        tooltips: {
          backgroundColor: "purple",
        },
      },

      options: {
        scales: {
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: `Blood Sugar Values  ${
                  unit ? "(" + unit + ")" : null
                } `,
              },

              ticks: {
                beginAtZero: true,
              },
            },
          ],
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Date and Time",
              },
            },
          ],
        },
        legend: {
          position: "right",
          labels: {
            fontColor: "#000",
          },
        },
      },
    });
  }, [glucDatesArray, nonFasting, fasting, random, unit,updateRender]);

  useEffect(() => {
    handleGraph();
    
  }, [updateRender]);

  useEffect(() => {
    handleTableData();
   
  }, [updateRender]);

  const handleTableData = () =>
  {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    setLoad("Loading Data...");

    axios
      .get(
        `${APIURL}/api/v1/doctor/patient-glucose/?patient_id=${patientId}&start_date=${startDate}&end_date=${endDate}&timeZone=${timezoneOffset}&sort_by=asc`,
        {
          headers: {
            Authorization: "Token " + str,
          },
        }
      )
      .then((res) => {
     
        if (res.data.status === "success") {
          setLoad("");
          let arr = res.data.results;

          let check = [];
          let check1 = [];
          let check2 = [];

          arr.map((i) => {
            if (i.glucose_type === "glucose") {
              check.push(i.value);
              check1.push(null);
              check2.push(null);
            } else if (i.glucose_type === "glucose_fasting") {
              check.push(null);
              check2.push(null);
              check1.push(i.value);
            } else if (i.glucose_type === "glucose_random") {
              check.push(null);
              check1.push(null);
              check2.push(i.value);
            }
          });

          setNonFasting(check);
          setFasting(check1);
          setRandom(check2);

          let newArrayDates = [];
          arr.map((result) => {
            newArrayDates.push(result.date.slice(0, 16).split("T").join("\n"));
          });
          setGlucDatesArray(newArrayDates);

          setGlucoseList(res.data.results);
         
          let unit1 = null;
          if (res.data.results.length > 0) {
            unit1 = res.data.results[0].unit;
          } else {
            unit1 = null;
          }

          setUnit(unit1);
        } else {
          setLoad("Error in Fetching Data");
        }
      })
      .catch((err) => {
        setLoad("Error in Fetching Data");
      });
  }

const handleGraph=()=>{
  const tokenString = sessionStorage.getItem("usertoken");

  let str = tokenString.replace(/["]+/g, "");

  setInferenceLoad("Loading Data...");

  axios
    .get(
      `${APIURL}/api/v1/patient/sugar-inferences/?patient_id=${patientId}&date_from=${startDate}&date_to=${endDate}&timeZone=${timezoneOffset}`,
      {
        headers: {
          Authorization: "Token " + str,
        },
      }
    )
    .then((res) => {
      if (res.data.status === "success") {
        setInferenceLoad("");
        setInference(res.data.data);
      } else {
        setInferenceLoad("Error in Fetching Data");
      }
    })
    .catch((err) => {
      setInferenceLoad("Error in Fetching Data");
    });
}
 


  const callDelete = () => {


    let idToDelete=glucoDeleteRowValue;
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");
    const headers = {
      Authorization: "Token " + str,
      "Content-Type": "application/json",
    };

    axios
      .delete(`${APIURL}/api/v1/patient/tests/${idToDelete}`, {
        data: { patient_id: patientId },
        headers: { Authorization: "Token " + str },
      })

      .then((res) => {
        
        if (res.status == "200") {
          
          setSubmitMsg("Deleted Successfully");
          setModalShow(true);
        //  setInferenceLoad("");
          setRefreshKey(1);
 
        } else {
          setErrorMsg("in Data Submission. Please try again!");
          setErrorSubmit(true);
        }
              
       setDeleteShow(false);
      })
      .catch((err) => {
        ;
      });

    //      axios.delete(`${APIURL}/api/v1/patient/tests/${idToDelete}`, data,

    //    { headers

    //     })

    //      .then(res=>{
    //     ;
    //        if(res.status==="success"){

    //         setSubmitMsg("Deleted Successfully")
    //         setModalShow(true)
    //         setInferenceLoad('')
    //         setGlucoseList("")

    //    }
    //    else{
    //      setErrorMsg('in Data Submission. Please try again!')
    //      setErrorSubmit(true)

    //    }

    //    setDeleteIndex('');
    //    setDeleteShow(false);

    //      })
    //      .catch(err=>{
    //        ;
    //         setErrorMsg('in Data Submission. Please try again!')
    //         setErrorSubmit(true)
    //  })
  };

  const SubmitPopUp=({show,onHide})=> {
 

    return (
      <Modal
        show
        onHide
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
         backdrop="static"
          keyboard={false}
          centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>{submitMsg}</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
          
         <Button onClick={onHide}>Ok</Button>
         
         
        </Modal.Footer>
      </Modal>
    );
  }
  
  
  // const deleteRow=(index)=>{
    

  
  // }
  
  const DeleteConfirmPopup =(props)=>{
      ;
  
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Are you sure you want to delete?</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Cancel</Button>
  
          <Button variant="danger" onClick={callDelete}> Confirm</Button>
  
         
  
        </Modal.Footer>
      </Modal>
    );
  }

  const handleGo = (e) => {
    e.preventDefault();
    setUpdateRender(!updateRender);
  };

  const handleFromDate = (date) => {
    if (date !== null) {
      const offset = date.getTimezoneOffset();

      let dateUpdated = new Date(date.getTime() - offset * 60 * 1000);

      let DateExtracted = dateUpdated.toISOString().split("T")[0];

      setStartDate(DateExtracted);
    }
  };

  const handleToDate = (date) => {
    if (date !== null) {
      const offset = date.getTimezoneOffset();

      let dateUpdated = new Date(date.getTime() - offset * 60 * 1000);

      let DateExtracted = dateUpdated.toISOString().split("T")[0];

      setEndDate(DateExtracted);
    }
  };

  const renderGlucose = (cellInfo) => {
    const date4 = glucoseList;
    const date5 = [...date4].reverse();
    return <h6> {date5[cellInfo.index][cellInfo.column.id]} </h6>;
  };

  const renderType = (cellInfo) => {
    const date4 = glucoseList;
    const date5 = [...date4].reverse();
    const data = date5[cellInfo.index][cellInfo.column.id];

    return data === "glucose" ? (
      <h6>Non Fasting Blood Sugar</h6>
    ) : data === "glucose_fasting" ? (
      <h6>Fasting Blood Sugar</h6>
    ) : data === "glucose_random" ? (
      <h6>Random Blood Sugar</h6>
    ) : null;
  };
  const renderDate = (cellInfo) => {
    const date4 = glucoseList;
    const date5 = [...date4].reverse();
    return (
      <h6> {date5[cellInfo.index].date.slice(0, 16).split("T").join("\n")} </h6>
    );
  };
  const renderFitData = (cellInfo) => {
    const data = glucoseList;
    const fitData = [...data];

    return (
      <h6> {fitData[cellInfo.index][cellInfo.column.id] ? "Yes" : "No"} </h6>
    );
  };
  const handleDeleteClick = (cellInfo) => {

     const date4 = glucoseList;
     const date5 = [...date4].reverse();
    // ;
    let testID =date5[cellInfo.index][cellInfo.column.id];

    setDeleteShow(true)
    setGlucoDeleteRowValue(testID)

    //setDeleteIndex(props)
  }

  const columnsGlucose = [
    {
      Header:    <>
      <h6>Data and</h6>
      <h6>Time </h6>
    </>, accessor: "date",
      Cell: renderDate,
      width: 120,
      minWidth: 50,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },
    },
    {
      Header: "Test Type",
      accessor: "glucose_type",
      Cell: renderType,
      width: 170,
      minWidth: 50,
    },
    {
      Header: (
        <>
          <h6>Blood Sugar</h6>
          <h6>(mg/dL)</h6>
        </>
      ),
      accessor: "value",
      Cell: renderGlucose,
      width: 100,
      minWidth: 80,
    },
    {
      Header:    <>
      <h6>Smart Device</h6>
      <h6>Data </h6>
    </>,
      accessor: "is_wearables",
      Cell: renderFitData,
      width: 130,
      minWidth: 50,
    },
    // {
    
    //   accessor: "test_id",
    //   //Cell: renderFitData,
    //   show: false,
    // },
    {
      Header: <span className="table-header-tooltip" data-toggle = "tooltip" 
                   data-placement = "top" title = "Delete Row"><i className="fas fa-trash-alt"></i></span>,
                   accessor: "test_id",
      Cell: props=>{
        
        return (
      

        <i  className="fas fa-times close-button"    onClick={() => handleDeleteClick(props) }     ></i>
        )},
      sortable:false,
      filterable:false,
      minWidth:50,
      width:50
       
    }
  ];
  const Glucose = (
    <ReactTable
      columns={columnsGlucose}
      data={glucoseList}
      defaultPageSize={10}
      className="gluco-table"
     
    ></ReactTable>
  );

  const inferenceData =
    inferenceLoad === "" ? (
      inference !== undefined ? (
        <div className="inference-container analysis-subsection">
          <div className="inference-container-header box-theme">
            <h6 className="inference-container-title"> Analysis</h6>
          </div>
          <div className="inference-container-body">
            <h5 className="text-success font-weight-bold">
              {inference.summary}
            </h5>
            <h6 className="text-muted font-weight-bold">
              Maximum Sugar Level :{" "}
            </h6>{" "}
            <p>
              {inference.max_sugar}mg/dL {inference.max_sugar_date}
            </p>
            <br />
            <h6 className="text-muted font-weight-bold">
              Minimum Sugar Level :{" "}
            </h6>{" "}
            <p>
              {inference.min_sugar}mg/dL {inference.min_sugar_date}
            </p>
            <br />
            <h6 className="text-muted font-weight-bold">
              Sugar Deviation :{" "}
            </h6>{" "}
            <p>{inference.sugar_sd}</p>
          </div>
        </div>
      ) : (
        <h6 style={{ color: "red" }}> No data to show! </h6>
      )
    ) : inferenceLoad === "Error in Fetching Data" ? (
      <h6 style={{ color: "red" }}>{inferenceLoad}</h6>
    ) : (
      <h6 style={{ color: "grey" }}>{inferenceLoad}</h6>
    );

  return (
    <>
      <div className="food-habits-page">
        <div className="food-habits-header">
          <h2 className="title-of-tasks"> Blood Sugar </h2>
          {/* <h3 className=""> Patient  ID : {patientId} </h3><br/><br/> */}

          <div className="calender-pickers-from-to">
            <div className="span-date">
              <span> From </span>

              <DatePicker
                value={new Date(startDate)}
                onChange={(date) => {
                  handleFromDate(date);
                }}
                maxDate={new Date()}
              />
            </div>
            <div className="span-date">
              <span> To </span>

              <DatePicker
                value={new Date(endDate)}
                onChange={(date) => {
                  handleToDate(date);
                }}
                maxDate={new Date()}
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary btn-col"
              onClick={handleGo}
            >
              {" "}
              Check{" "}
            </button>
          </div>
        </div>
        <div className="food-habits-sections">
          <div>
            <h5 className="title-of-lists"> Graph </h5>
            <div className="chart-div-glucose">
              <canvas id="myChart-glucose" width="800" height="400"></canvas>
            </div>
          </div>

          <div className="food-table">
            <h5 className=" title-of-lists"> Table </h5>
            {load === "" ? (
              glucoseList.length > 0 ? (
                <>{Glucose}</>
              ) : (
                <h6 style={{ color: "red" }}> No data to show! </h6>
              )
            ) : load === "Error in Fetching Data" ? (
              <h6 style={{ color: "red" }}>{load}</h6>
            ) : (
              <h6 style={{ color: "grey" }}>{load}</h6>
            )}
          </div>

          <div className="food-table">
            <h5 className=" title-of-lists"> Inference </h5>

            {inferenceData}
          </div>
        </div>
      </div>
      {submitMsg!=="" && submitMsg!== null ? 
          <SubmitPopUp
        show={modalShow}
        onHide={() =>  {
          handleGraph();
          handleTableData();
          setModalShow(false);
                        setSubmitMsg('');
                      }}

       
      /> : null }
       
    <DeleteConfirmPopup
        show={deleteShow}
        onHide={() =>  {setDeleteShow(false);
                       }}
       
      />: ''

      
    
    </>
  );
}

export default GlucoseVitals;
