import React, {useState} from 'react';
import "./sda.css";
import { Link } from "react-router-dom";
import axios from "axios";
import {APIURL} from "../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';

function Sda({data, freeCount, activeCount, handle}){

     const [userId, setUserId]= useState('')
     const [confirmShow, setConfirmShow]= useState(false)
     const [successShow, setSuccessShow]= useState(false)
     const [errorShow, setErrorShow]= useState(false)
     const [errorMsg, setErrorMsg]= useState('')
     const [removeConfirm, setRemoveConfirm]= useState(false)
     const [removeSuccess, setRemoveSuccess]= useState(false)
     const [removeError, setRemoveError]= useState(false)
     const [removeErrorMsg, setRemoveErrorMsg]= useState('')
     const [licRemove, setLicRemove]= useState(false)
     const [licSuccess, setLicSuccess]= useState(false)
     const [,setLicFail]= useState(false)
     const [licMsg, setLicMsg]= useState('')
  
  const create_link=`create-sda/${data.id}`;
 
  const suspend_link=`suspend-sda/${data.id}`;
  const change_password_link=`changepassword-sda/${data.id}`;
  const update_link = `update-sda/${data.id}`;
  const revoke_suspension_link = `revoke-sda/${data.id}`;
  // const delete_link = `deleteSda/${data.id}`;
  const [deleteShow, setDeleteShow] = useState(false)
  const [deleteIndex, setDeleteIndex] = useState('');

  const ConfirmPopUp = (props)=> {
     return(
       <Modal
           {...props}
           size="lg"
           aria-labelledby="contained-modal-title-vcenter"
           centered
            backdrop="static"
             keyboard={false}
         >
           <Modal.Header closeButton>
             <Modal.Title id="contained-modal-title-vcenter">
              
             </Modal.Title>
           </Modal.Header>
           <Modal.Body>
   
           <h4 id="confirm-reject">Are you sure to add this user as a Free User ?</h4>
            
           </Modal.Body>
           <Modal.Footer>
   
              <Button variant="secondary" onClick={props.onHide}>No</Button>
              <Button variant="danger" onClick={props.onCall}>Yes</Button>          
     
           </Modal.Footer>
         </Modal>
       )
   }

   const SubmitPopUp=(props)=> {
     return (
       <Modal
         {...props}
         size="lg"
         aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
           keyboard={false}
           centered
       >
         <Modal.Header>
           <Modal.Title id="contained-modal-title-vcenter">
            
           </Modal.Title>
         </Modal.Header>
         <Modal.Body>
           <h4 className='text-success'>Added User as a Free User</h4>
          
         </Modal.Body>
         <Modal.Footer>
          
           
          <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
          
         
         </Modal.Footer>
       </Modal>
     );
   }

   const ErrorPopUp=(props)=> {
     return (
       <Modal
         {...props}
         size="lg"
         aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
           keyboard={false}
           centered
       >
         <Modal.Header>
           <Modal.Title id="contained-modal-title-vcenter">
            
           </Modal.Title>
         </Modal.Header>
         <Modal.Body>
         <h5 style={{color: "red"}}>{errorMsg}</h5>
          
         </Modal.Body>
         <Modal.Footer>
          
           
          <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
          
         
         </Modal.Footer>
       </Modal>
     );
   }

   const RemovePopUp = (props)=> {
     return(
       <Modal
           {...props}
           size="lg"
           aria-labelledby="contained-modal-title-vcenter"
           centered
            backdrop="static"
             keyboard={false}
         >
           <Modal.Header closeButton>
             <Modal.Title id="contained-modal-title-vcenter">
              
             </Modal.Title>
           </Modal.Header>
           <Modal.Body>
   
           <h4 className='text-danger'>Are you sure to remove this user from Free User ?</h4>
            
           </Modal.Body>
           <Modal.Footer>
   
              <Button variant="secondary" onClick={props.onHide}>No</Button>
              <Button variant="danger" onClick={props.onCall}>Yes</Button>          
     
           </Modal.Footer>
         </Modal>
       )
   }

   const RemovalSuccess=(props)=> {
     return (
       <Modal
         {...props}
         size="lg"
         aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
           keyboard={false}
           centered
       >
         <Modal.Header>
           <Modal.Title id="contained-modal-title-vcenter">
            
           </Modal.Title>
         </Modal.Header>
         <Modal.Body>
           <h4 className='text-success'>Removed User from Free User</h4>
          
         </Modal.Body>
         <Modal.Footer>
          
           
          <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
          
         
         </Modal.Footer>
       </Modal>
     );
   }

   const RemoveFail=(props)=> {
     return (
       <Modal
         {...props}
         size="lg"
         aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
           keyboard={false}
           centered
       >
         <Modal.Header>
           <Modal.Title id="contained-modal-title-vcenter">
            
           </Modal.Title>
         </Modal.Header>
         <Modal.Body>
         <h5 style={{color: "red"}}>{removeErrorMsg}</h5>
          
         </Modal.Body>
         <Modal.Footer>
          
           
          <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
          
         
         </Modal.Footer>
       </Modal>
     );
   }

   const LicConfirmPopUp = (props)=> {
     return(
       <Modal
           {...props}
           size="lg"
           aria-labelledby="contained-modal-title-vcenter"
           centered
            backdrop="static"
             keyboard={false}
         >
           <Modal.Header closeButton>
             <Modal.Title id="contained-modal-title-vcenter">
              
             </Modal.Title>
           </Modal.Header>
           <Modal.Body>
   
           <h4 className='text-danger'>Are you sure to remove this user from the Licence ?</h4>
            
           </Modal.Body>
           <Modal.Footer>
   
              <Button variant="secondary" onClick={props.onHide}>No</Button>
              <Button variant="danger" onClick={props.onCall}>Yes</Button>          
     
           </Modal.Footer>
         </Modal>
       )
   }

   const LicSuccessPopUp=(props)=> {
     return (
       <Modal
         {...props}
         size="lg"
         aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
           keyboard={false}
           centered
       >
         <Modal.Header>
           <Modal.Title id="contained-modal-title-vcenter">
            
           </Modal.Title>
         </Modal.Header>
         <Modal.Body>
           <h4 className='text-success'>Removed User from Licence</h4>
          
         </Modal.Body>
         <Modal.Footer>
          
           
          <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
          
         
         </Modal.Footer>
       </Modal>
     );
   }

   const LicFailPopUp=(props)=> {
     return (
       <Modal
         {...props}
         size="lg"
         aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
           keyboard={false}
           centered
       >
         <Modal.Header>
           <Modal.Title id="contained-modal-title-vcenter">
            
           </Modal.Title>
         </Modal.Header>
         <Modal.Body>
         <h5 style={{color: "red"}}>{errorMsg}</h5>
          
         </Modal.Body>
         <Modal.Footer>
          
           
          <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
          
         
         </Modal.Footer>
       </Modal>
     );
   }

  const handleUser = ()=> {
       
     const tokenString = sessionStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
          );
          const data ={
              is_free_user: true,
              user_id: userId
          }
          axios.post(`${APIURL}/api/v1/hospital/hospital-licenses/`, data, {
            headers: { Authorization: "Token " + v2 },
       })
       .then((res) =>{
           if(res.data.status==='success'){
          
                setSuccessShow(true)
           }
           else{
                setErrorMsg(res.data.message)
                setErrorShow(true)
           }
       })
       .catch((err) => {
          setErrorMsg('Error in data submission.Please check again!')
            setErrorShow(true)
       })
  }
	
  const handleRemove = ()=> {
       
     const tokenString = sessionStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
          );
          const data ={
              user_id: userId
          }
          axios.put(`${APIURL}/api/v1/hospital/hospital-licenses/`, data, {
            headers: { Authorization: "Token " + v2 },
       })
       .then((res) =>{
           if(res.data.status==='success'){
          
                setRemoveSuccess(true)
           }
           else{
                setRemoveError(true)
                setRemoveErrorMsg(res.data.message)
           }
       })
       .catch((err) => {
          setRemoveErrorMsg('Error in data submission.Please check again!')
          setRemoveError(true)
       })
  }

  const handleLicense = ()=> {
       
     const tokenString = sessionStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
          );
          const data ={
              user_id: userId
          }
          axios.put(`${APIURL}/api/v1/hospital/hospital-licenses/`, data, {
            headers: { Authorization: "Token " + v2 },
       })
       .then((res) =>{
           if(res.data.status==='success'){
          
                setLicSuccess(true)
           }
           else{
                setLicFail(true)
                setLicMsg(res.data.message)
           }
       })
       .catch((err) => {
          setLicMsg('Error in data submission.Please check again!')
          setLicFail(true)
       })
  }
  const handleDeletePopUp = (id) => {
    //  e.preventDefault();
    setDeleteIndex(id);
    setDeleteShow(true);
  }
  
  const DeleteConfirmPopup = (props) => {
    
    
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
       
      >
        
        <Modal.Body >
        
          <h4 className="title-of-page"> Delete User  </h4>
       <h5 className="title-of-page" > Please Confirm to Delete the User ID {deleteIndex}</h5><br/>
       
  <div className="form-group " style={{marginLeft:"120px"}}>
         <button type="button" className="btn btn-danger btn-cancel"onClick={props.onHide}> Cancel </button> 
          <button type="submit" className="btn btn-primary btn-col" onClick={() => { handleDelete(deleteIndex) }} >Confirm </button>
          </div>
        </Modal.Body>
        
      </Modal>
    );
  }
  const handleDelete = (item) => {
    
    
    const tokenString = sessionStorage.getItem('usertoken');
  
    let str = tokenString.replace(/["]+/g, '');
  
    //  const dataToSend = {
    //   "id" : item
    // }
                        
    axios.post(`${APIURL}/api/v1/hospital/hospital-user-delete/?user_id=${deleteIndex}&role=sda`,  {},
    {
      headers: {
  
        "Authorization": 'Token ' + str,
        'Content-Type': 'application/json'
      }   
     })
      .then(res => {
  
  
        if (res.data.status === "success") {
          //setModalShow(true);
           handle();
          // setRefresh(!refresh)
  
  
  
        }
        else {
          console.log('else called ');
         
          //  setErrorMsg(res.data.message);
          //             setErrorSubmit(true);
        }
  
  
        setDeleteIndex('');
        setDeleteShow(false);
  
      })
      .catch(err => {
        console.log("catch called");
        // setSubmitMsg("error");
        // setErrorSubmit(true);
      })
  
  }

	return(
    <>
		<div className="doctor-card">
		   
        <div className="card-header" >
        <h6 className="dr-id"> ID : {data.id} </h6>
             <h5> {data.name} </h5> 
        </div>
         

         <div className="card-content">    
              <h6> {data.qualification}</h6>
              <h6><span className="key-labels"> Username </span>: {data.user_name}</h6>
             {/* <h6><span className="key-labels"> Specialization </span>: {data.specializations}</h6>
             <h6><span className="key-labels"> Department </span>: {data.department} </h6><br/> */}
    <br/>

             <h6>
                       {data.is_app_user?
                        <div> 
                   <span style={{color:"var(--green)"}} className="bold-class"> Valid User Credentials</span>
                  
                </div> 

                        
                : 
                 
               <div>
                    <span style={{color:"var(--red)"}} className="bold-class"> No User Credentials </span> 
                   
                </div> }
</h6>


             <h6> <span className="key-labels"> Status </span>: {data.is_suspended ? 
                
                    <span style={{color:"var(--red)"}}> Suspended </span> 
                   
              
                : 
                 
                   <span style={{color:"var(--green)"}}> Authorized </span>
                  
                
              } </h6>
             

             

             <div className="contact-div">
              <i className="fa fa-phone key-labels" aria-hidden="true"></i>{data.phone}<br/>
               <i className="fa fa-envelope key-labels" aria-hidden="true"></i> {data.email} 
                  
            </div>
              <br/>
            <div className='license-status'>
               <h6> <span className="key-labels"> Licence Status </span>: {data.is_free_user ? 
                
                         <span className='text-info user-status'>Free</span> 
               
          
                    : data.is_free_user===false && data.has_license===false ?
             
                         <span className='text-danger user-status'>Not Paid</span>
                    
                    : data.has_license? 

                         <span className='text-success user-status'>Paid</span>
                    
                    : null
               } </h6>
               {
                    data.is_app_user===false?
                    <span className='text-info no-cred'>Please generate the User Credentials</span> 
                    : null
               }
               {data.is_app_user && (freeCount- activeCount)>0 && data.is_free_user===false && data.has_license===false ?
                    
                    <Link onClick={()=>{ setConfirmShow(true); setUserId(data.sda_id)}}>Add as free user |</Link> 
                    :
                    null}
     
               {data.is_app_user && data.is_free_user ?
                         
                    <Link onClick={()=>{ setRemoveConfirm(true); setUserId(data.sda_id)}}>Remove free user |</Link> 
                    :
                    null}
     
               {data.is_app_user && data.has_license===false && data.is_free_user===false ?
                         
                    <Link to={{pathname:'doctor-license', id:data.sda_id, flag:'sda'}}>Add Licence |</Link> 
                    :
                    null}   
     
               {data.is_app_user && data.has_license===true ?
                         
                    <Link onClick={()=>{ setLicRemove(true); setUserId(data.sda_id) }}>Remove Licence |</Link> 
                    :
                    null}  

                  {/********************
               {data.is_app_user ?
                         
                    <Link to={{pathname:'license-details', id:data.doctor_id}}>Licence Details |</Link> 
                    :
                    null}

                    ***************/}
               </div>

            <div className="card-content-divider"></div>
            <div className="bottom-links">

            {data.is_suspended ?
              <Link to={revoke_suspension_link}> Authorize |</Link>  
              :

            <Link to={suspend_link}> Suspend |</Link> }

           {data.is_app_user?

                        <Link to={change_password_link}>Change Password |</Link> 
                        :
                        <span className="temp-disabled"> Change Password| </span>}

            {data.is_app_user? 
                <span className="temp-disabled"> Generate User Credentials|</span>
                :

            <Link to={{
     pathname: create_link,
     state: `${data.name}`
}}  > Generate User Credentials |</Link>}
            <Link to={update_link}> Edit Details |</Link>

            <Link   onClick={() => {handleDeletePopUp(data.id) }} 
           className="delete-warning"
             >Delete User </Link>

            {/*
             <div className="icons-bottom">
              <Link to={update_link}> <i className="fas fa-edit"></i></Link>
               <i className="far fa-trash-alt"></i>
             </div>

            */}
            </div>
      </div>
            
            
		</div>

          {
        confirmShow ? 
        <ConfirmPopUp 
            show={confirmShow}
            onCall={() => { handleUser();  setConfirmShow(false) }}
            onHide={() => setConfirmShow(false) }/> : null
    }

{successShow ? 

<SubmitPopUp
show={successShow}
onHide={() =>  {setSuccessShow(false);
                handle();}}
/> 
: null}

{errorMsg!=='' ? 

<ErrorPopUp
show={errorShow}
onHide={() =>  {setErrorShow(false)}}
/> 
: null}

{
        removeConfirm ? 
        <RemovePopUp 
            show={removeConfirm}
            onCall={() => { handleRemove();  setRemoveConfirm(false) }}
            onHide={() => setRemoveConfirm(false) }/> : null
    }

{removeSuccess ? 

<RemovalSuccess
show={removeSuccess}
onHide={() =>  {setRemoveSuccess(false);
                handle();}}
/> 
: null}

{removeErrorMsg!=='' ? 

<RemoveFail
show={removeError}
onHide={() =>  {setRemoveError(false)}}
/> 
: null}

{
        licRemove ? 
        <LicConfirmPopUp 
            show={licRemove}
            onCall={() => { handleLicense();  setLicRemove(false) }}
            onHide={() => setLicRemove(false) }/> : null
    }

{licSuccess ? 

<LicSuccessPopUp
show={licSuccess}
onHide={() =>  {setLicSuccess(false);
                handle();}}
/> 
: null}

{licMsg!=='' ? 

<LicFailPopUp
show={licMsg}
onHide={() =>  {setLicMsg(false)}}
/> 
: null}
{
        deleteShow ?
          <DeleteConfirmPopup
            show={deleteShow}
            onHide={() => {
              setDeleteShow(false);
              setDeleteIndex('');
              handle();
            }}

          /> : ''
      }

</>
		)
}

export default Sda;