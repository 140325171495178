import React, { useEffect, useState } from "react";
import axios from "axios";

import "./addsynonyms.css";
import { Link,useHistory } from "react-router-dom";
import Pagination from "react-js-pagination";
import "./pagination.css";
import { Dropdown } from 'react-bootstrap';
import {APIURL} from "../../../Global";

function AddSynonyms() {
  const [pendingDataList, setPendingDataList] = useState([]);
  const [activePage,setActivePage] = useState(1);
  const [order,setOrder] = useState("asc");
  const [totalPage,setTotalPage] = useState(1);
  const [sort,setSort] = useState("sort")
  const [plan,setPlan]= useState("package")

  const history =useHistory();

  const [keys,] = useState({'h': false,'d': false,'u': false,'r': false,'l': false,'a': false,'p': false,'v': false,'t': false,'n': false});
  
  
  useEffect(()=>{
  
  window.onkeydown =(e) => {
   
   keys[e.key]=true
  
   if(e.altKey && keys['h']){
     history.push('/hospitallist')
   }
   if(e.altKey && keys['d']){
     history.push('/doctorslist')
   }
   if(e.altKey && keys['u']){
     history.push('/search-hospitals')
   }
   if(e.altKey && keys['r']){
     history.push('/rejectedlist')
   }
   if(e.altKey && keys['l']){
     history.push('/reportlist')
   }
   if(e.altKey && keys['a']){
     history.push('/failedlist')
   }
   if(e.altKey && keys['p']){
     history.push('/prescriptionlist')
   }
   if(e.altKey && keys['v']){
     history.push('/written-prescriptionlist')
   }
   if(e.altKey && keys['t']){
     history.push('/testlist')
   }
   if(e.altKey && keys['n']){
    history.push('/nutrition')
  }
  
  }
  window.onkeyup=(ev)=> {
   
   keys[ev.key]= false
  }
  
  return()=>{
   window.onkeydown=null
   window.onkeyup=null
  }
  },[]);


 useEffect(() => {
 handleRefresh();

},[order]);

useEffect(() => {
window.scrollTo(0, 0);
},[]);
  useEffect(() => {
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); /* REGEX To remove double quotes from tokenstring */

 

    axios
      .get(`${APIURL}/api/v1/staff/lab-reports/new/?status=failed&reason=header_mismatch&sort_by=${order}&per_page=10&page=1`, {
        headers: { Authorization: "Token " + v2 },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setTotalPage(res.data.total_pages);
          const datas = res.data.pending_verifications;

          setPendingDataList(datas);
        } else {
          
        }
      })
      .catch((err) => {
       
      });
  }, []);



    const handlePageChange= async pageNumber=> {
   
    setActivePage(pageNumber);
  
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); /* REGEX To remove double quotes from tokenstring */

   
    if(plan !== 'package' && plan !== 'All'){
      axios
      .get(`${APIURL}/api/v1/staff/lab-reports/new/?status=failed&reason=header_mismatch&sort_by=${order}&per_page=10&page=${pageNumber}&plan=${plan}`, {
        headers: { Authorization: "Token "+ v2 },
      })
       .then((res) => {
        if (res.data.status === "success") {
          const datas = res.data.pending_verifications;

          setPendingDataList(datas);
        } else {
        
        }
      })
      .catch((err) => {
       
      });
    }

    else {
      axios
      .get(`${APIURL}/api/v1/staff/lab-reports/new/?status=failed&reason=header_mismatch&sort_by=${order}&per_page=10&page=${pageNumber}`, {
        headers: { Authorization: "Token "+ v2 },
      })
       .then((res) => {
        if (res.data.status === "success") {
          const datas = res.data.pending_verifications;

          setPendingDataList(datas);
        } else {
        
        }
      })
      .catch((err) => {
       
      });
    }
    
  }

  const tokenString = sessionStorage.getItem("usertoken");

  let v2 = tokenString.replace(
    /["]+/g,
    ""
  ); /* REGEX To remove double quotes from tokenstring */

 

  let dataDisplay = pendingDataList.length>0 ? pendingDataList.map((data, index) => {
    let idval = data.report.id;
    let serialNum = activePage*10 - 10;
    return (
      <div key={index} className="list-item">
        
         <Link to={{pathname:`/report-headings/${idval}` ,
             parentUrl: "/addsynonyms", 
              parentName: "HeadingsMismatch Report List" }}> 
              

          {" "}
          <h5 className="each-list-link">
             {serialNum + index + 1}. {data.patient.name} _ Report ID {data.report.id}{" "}
          </h5>
        </Link>
        <div className="details">
          <p> Patient Id: {data.patient.id} </p>
          <p> Date : {data.uploaded_at} </p>
          <span>Package : {data.patient.package}</span>
        </div>
      </div>
    );
  }) : null;

  const handleRefresh = () => {
   
    if(plan !== 'package' && plan !== 'All'){
      axios
      .get(
        `${APIURL}/api/v1/staff/lab-reports/new/?status=failed&reason=header_mismatch&sort_by=${order}&per_page=10&page=${activePage}&plan=${plan}`,
        { headers: { Authorization: "Token " + v2 } }
      )
      .then((res) => {
        if (res.data.status === "success") {
          const datas = res.data.pending_verifications;

          setPendingDataList(datas);
        } else {
          
        }
      })
      .catch((err) => {
              });
    }

    else {
      axios
      .get(
        `${APIURL}/api/v1/staff/lab-reports/new/?status=failed&reason=header_mismatch&sort_by=${order}&per_page=10&page=${activePage}`,
        { headers: { Authorization: "Token " + v2 } }
      )
      .then((res) => {
        if (res.data.status === "success") {
          const datas = res.data.pending_verifications;

          setPendingDataList(datas);
        } else {
          
        }
      })
      .catch((err) => {
              });
    }
    
  };
 

 const onSort = (order)=>{
setOrder(order);
 
}

const onFilter = (data)=> {
  const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); /* REGEX To remove double quotes from tokenstring */

 

    axios
      .get(`${APIURL}/api/v1/staff/lab-reports/new/?status=failed&reason=header_mismatch&sort_by=${order}&per_page=10&page=1&plan=${data}`, {
        headers: { Authorization: "Token " + v2 },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setTotalPage(res.data.total_pages);
          const datas = res.data.pending_verifications;

          setPendingDataList(datas);
          setActivePage(1);
        } else {
          
        }
      })
      .catch((err) => {
       
      });
}

  return (
    <div className="container">
      <div className="dashboard-navigation">
        <div>
          {" "}
          <Link to="/dashboard">
            {" "}
            <i className="fas fa-home dasboard-icon"> </i>Dashboard{" "}
          </Link>
        </div>

        <div>
          {" "}
          <i className="fas fa-angle-double-right angle-icon"></i>{" "}
        </div>
        <div>
          {" "}
          <Link to="/addsynonyms"> HeadingsMismatch Report List</Link>{" "}
        </div>
      </div>

      <div className="container failedlist-parentcontainer">
        <h2 className="title-of-page">Headings Mismatch- Lab Report List</h2>
 
       <div className="buttons-wrapper">
          <button className="btn btn-secondary" onClick={handleRefresh}>
            Refresh List <i className="fas fa-sync-alt"></i>{" "}
          </button>
<Dropdown>


<Dropdown.Toggle variant="secondary" id="dropdown-basic">
  {sort}
</Dropdown.Toggle>

<Dropdown.Menu>
  <Dropdown.Item onClick={()=>{onSort("asc") ; setSort("Sort Ascending")}}>Ascending</Dropdown.Item>
  <Dropdown.Item  onClick={()=>{onSort("desc"); setSort("Sort Decending")}}>Descending</Dropdown.Item>

</Dropdown.Menu>
</Dropdown>

<Dropdown>

<Dropdown.Toggle variant="secondary" id="dropdown-basic">
  {plan}
</Dropdown.Toggle>

<Dropdown.Menu>
<Dropdown.Item  onClick={()=>{onFilter("");setPlan("All")}}>All</Dropdown.Item>
  <Dropdown.Item  onClick={()=>{onFilter("basic");setPlan("Basic")}}>Basic</Dropdown.Item>
  <Dropdown.Item  onClick={()=>{onFilter("silver");setPlan("Silver")}}>Silver</Dropdown.Item>
  <Dropdown.Item onClick={()=>{onFilter("gold") ;setPlan("Gold")}}>Gold</Dropdown.Item>
  <Dropdown.Item onClick={()=>{onFilter("platinum") ;setPlan("Platinum")}}>Platinum</Dropdown.Item>

</Dropdown.Menu>
</Dropdown>

</div>


        <div className="failed-list-section">
          <div className="container">
            <div className="test-reports-display-list">
              {dataDisplay ? (
                dataDisplay
              ) : (
                <div style={{ color: "red", fontSize: "25px",height:"200px",padding: "40px" }}>
              <h4>No more Pending list to show!!</h4>
            </div>
              )}
             

             <Pagination
          activePage={activePage}
          itemsCountPerPage={10}
          totalItemsCount={totalPage*10}
          pageRangeDisplayed={totalPage}
          onChange={handlePageChange}
          disabledClass = "disabled-class"
        />




              
            </div>

            {/*
      
      
                                <button type="submit" className="next-btn">Next</button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
export default AddSynonyms;
