import React, { useState, useEffect } from "react";

import "./hospital.css";
import { useHistory } from "react-router-dom";

import HospitalNavbar from "../HospitalNavbar/HospitalNavbar";
import pic from "../../images/illustration_dr_cropped.JPG";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function Hospital() {
  const { t } = useTranslation();

  const [hospData] = useState(
    JSON.parse(sessionStorage.getItem("hosp_data"))
      ? JSON.parse(sessionStorage.getItem("hosp_data"))
      : null
  );
  const [modalShow, setModalShow] = useState(false);
  const Hospital_name = hospData ? hospData.name : null;
  //const Hospital_specializations = hospData? hospData.specializations : null;

  const location = hospData ? hospData.location : null;

  const history = useHistory();

  const [keys] = useState({
    t: false,
    a: false,
    b: false,
    o: false,
    n: false,
    s: false,
    l: false,
    p: false,
    c: false,
    h: false,
    i:false,
    g:false,
    q:false,
    r:false,
    u:false,
    v:false,
    m:false
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.onkeydown = (e) => {
      keys[e.key] = true;

      if (e.altKey && keys["t"]) {
        history.push("/doctors-list");
      }
      if (e.altKey && keys["a"]) {
        history.push("/hospital/add");
      }
      if (e.altKey && keys["b"]) {
        history.push("/bdo-list");
      }
      if (e.altKey && keys["o"]) {
        history.push("/hospital/add-bdo");
      }
      if (e.altKey && keys["g"]) {
        history.push("/sda-list");
      }
      if (e.altKey && keys["i"]) {
        history.push("/hospital/add-sda");
      }
      if (e.altKey && keys["q"]) {
        history.push("/pharmacyusers-list");
      }
      if (e.altKey && keys["r"]) {
        history.push("/hospital/add-Pharm-user");
      }
      if (e.altKey && keys["u"]) {
        history.push("/labusers-list");
      }
      if (e.altKey && keys["v"]) {
        history.push("/hospital/add-lab-user");
      }
      if (e.altKey && keys["n"]) {
        history.push("/emergency-number");
      }
      if (e.altKey && keys["s"]) {
        history.push("/service-request-list");
      }
      if (e.altKey && keys["l"]) {
        history.push("/license-details");
      }
      if (e.altKey && keys["p"]) {
        history.push("/license-payment");
      }
      if (e.altKey && keys["c"]) {
        history.push("/hosp-calllog");
      }
      if (e.altKey && keys["h"]) {
        history.push("/payment-history");
      }
      if (e.altKey && keys["m"]) {
        history.push("/equipment-details");
      }
    };
    window.onkeyup = (ev) => {
      keys[ev.key] = false;
    };

    return () => {
      window.onkeydown = null;
      window.onkeyup = null;
    };
  }, []);

  const ShortcutPopup = ({ onHide }) => {
    return (
      <Modal
        show
        onHide
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h4>Shortcuts</h4>
            <br />
            <span className="shortcut-class">alt+t </span>{" "}
            <i className="fas fa-arrow-right arrow"></i>{" "}
            <span className="text-muted direct">{t("Doctors List")}</span>
            <br />
            <br />
            <span className="shortcut-class">alt+a </span>{" "}
            <i className="fas fa-arrow-right arrow"></i>{" "}
            <span className="text-muted direct">{t("Add Doctor Details")} </span>
            <br />
            <br />
            <span className="shortcut-class">alt+b </span>{" "}
            <i className="fas fa-arrow-right arrow"></i>{" "}
            <span className="text-muted direct">{t("BDO List")}</span>
            <br />
            <br />
            <span className="shortcut-class">alt+o </span>{" "}
            <i className="fas fa-arrow-right arrow"></i>{" "}
            <span className="text-muted direct">{t("Add BDO Details")}</span>
            <br />
            <br />
            <span className="shortcut-class">alt+g </span>{" "}
            <i className="fas fa-arrow-right arrow"></i>{" "}
            <span className="text-muted direct">{t("SDA List")}</span>
            <br />
            <br />
            <span className="shortcut-class">alt+i </span>{" "}
            <i className="fas fa-arrow-right arrow"></i>{" "}
            <span className="text-muted direct">{t("Add SDA Details")}</span>
            <br />
            <br />
            <span className="shortcut-class">alt+q </span>{" "}
            <i className="fas fa-arrow-right arrow"></i>{" "}
            <span className="text-muted direct">{t("Pharmacy Users List")}</span>
            <br />
            <br />
            <span className="shortcut-class">alt+r </span>{" "}
            <i className="fas fa-arrow-right arrow"></i>{" "}
            <span className="text-muted direct">{t("Add Pharmacy User Details")}</span>
            <br />
            <br />
            <span className="shortcut-class">alt+u </span>{" "}
            <i className="fas fa-arrow-right arrow"></i>{" "}
            <span className="text-muted direct">{t("Lab Users List")}</span>
            <br />
            <br />
            <span className="shortcut-class">alt+v </span>{" "}
            <i className="fas fa-arrow-right arrow"></i>{" "}
            <span className="text-muted direct">{t("Add Lab User Details")}</span>
            <br />
            <br />
            <span className="shortcut-class">alt+n </span>{" "}
            <i className="fas fa-arrow-right arrow"></i>{" "}
            <span className="text-muted direct">{t("Emergency Number")}</span>
            <br />
            <br />
            <span className="shortcut-class">alt+s </span>{" "}
            <i className="fas fa-arrow-right arrow"></i>{" "}
            <span className="text-muted direct">{t("Service Fulfillment")}</span>
            <br />
            <br />
            <span className="shortcut-class">alt+l </span>{" "}
            <i className="fas fa-arrow-right arrow"></i>{" "}
            <span className="text-muted direct">{t("License Details")}</span>
            <br />
            <br />
            <span className="shortcut-class">alt+p </span>{" "}
            <i className="fas fa-arrow-right arrow"></i>{" "}
            <span className="text-muted direct">{t("Purchase Licence")}</span>
            <br />
            <br />
            <span className="shortcut-class">alt+c </span>{" "}
            <i className="fas fa-arrow-right arrow"></i>{" "}
            <span className="text-muted direct">{t("Emergency Call Log")}</span>
            <br />
            <br />
            <span className="shortcut-class">alt+h </span>{" "}
            <i className="fas fa-arrow-right arrow"></i>{" "}
            <span className="text-muted direct">{t("Payment History")}</span>
            <br />
            <br />
            <span className="shortcut-class">alt+m </span>{" "}
            <i className="fas fa-arrow-right arrow"></i>{" "}
            <span className="text-muted direct">{t("Equipment Details")}</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      <HospitalNavbar />
      <div className="hospital-dashboard-container">
        {/*     <HospitalJumbotron /> 


     <div className="jumbotron jumbotron-fluid jumbo-div">
  <div className=" jumbo-content">
    <div className="captions">

         <h1 className="title-of-hosp"> {Hospital_name} </h1>
        
        {/*<h3 className="additional-text">{Hospital_specializations} </h3> 
        <h3 className="additional-text">{location} </h3>
    </div>
  

  
  </div>
</div>
*/}

        {/*<div className="seperator"> </div> */}

        <div className="section-drs">
          <div className="horizontal">
            {/*<div className="illustration">
<img src={d} width="150px" height="auto" /> 
</div>
*/}
          </div>

          <div className="">
            <div className="drs-management-section">
              <h1 className="additional-text "> {Hospital_name} </h1>
              <h3 className="subtitle-of-page">{location} </h3>
              <div className="task-div">
                <div className="parent-of-lists">
                  <div
                    onClick={() => history.push("/doctors-list")}
                    className="each-list-title col-lg-2"
                  >
                    <i
                      className="fa fa-address-card task-icon"
                      id="task-icon-color"
                      aria-hidden="true"
                    ></i>
                    <h5 className="each-title"> {t("Doctors List")}</h5>
                  </div>

                  <div
                    onClick={() => history.push("/hospital/add")}
                    className="each-list-title col-lg-2"
                  >
                    <i
                      className="fa fa-user-md task-icon"
                      id="task-icon-color"
                      aria-hidden="true"
                    ></i>
                    <h5 className="each-title"> {t("Add Doctor Details")}</h5>
                  </div>

                  <div
                    onClick={() => history.push("/bdo-list")}
                    className="each-list-title col-lg-2"
                  >
                    <i
                      className="fa fa-address-card task-icon"
                      id="task-icon-color"
                      aria-hidden="true"
                    ></i>
                    <h5 className="each-title"> {t("BDO List")}</h5>
                  </div>

                  <div
                    onClick={() => history.push("/hospital/add-bdo")}
                    className="each-list-title col-lg-2"
                  >
                    <i
                      className="fa fa-user-md task-icon"
                      id="task-icon-color"
                      aria-hidden="true"
                    ></i>
                    <h5 className="each-title"> {t("Add BDO Details")}</h5>
                  </div>

                  <div
                    onClick={() => history.push("/sda-list")}
                    className="each-list-title col-lg-2"
                  >
                    <i
                      className="fa fa-address-card task-icon"
                      id="task-icon-color"
                      aria-hidden="true"
                    ></i>
                    <h5 className="each-title"> {t("SDA List")}</h5>
                  </div>

                  <div
                    onClick={() => history.push("/hospital/add-sda")}
                    className="each-list-title col-lg-2"
                  >
                    <i
                      className="fa fa-user-md task-icon"
                      id="task-icon-color"
                      aria-hidden="true"
                    ></i>
                    <h5 className="each-title"> {t("Add SDA Details")}</h5>
                  </div>

<div onClick={()=> history.push('/pharmacyusers-list')} className="each-list-title col-lg-2">
           <i className="fa fa-address-card task-icon" id="task-icon-color" aria-hidden="true"></i>
            <h5 className="each-title">
              {" "}

                Pharmacy Users List 

            </h5>

           
          </div>
          

          <div onClick={()=> history.push('/hospital/add-Pharm-user')} className="each-list-title col-lg-2">
          <i className="fa fa-user task-icon" id="task-icon-color" aria-hidden="true" ></i>
            <h5 className="each-title">
              {" "}

                Add Pharmacy User Details

            </h5>
            
          </div>

          <div onClick={()=> history.push('/labusers-list')} className="each-list-title col-lg-2">
           <i className="fa fa-address-card task-icon" id="task-icon-color" aria-hidden="true"></i>
            <h5 className="each-title">
              {" "}

                Lab Users List 

            </h5>

           
          </div>
          

          <div onClick={()=> history.push('/hospital/add-lab-user')} className="each-list-title col-lg-2">
          <i className="fa fa-user task-icon" id="task-icon-color" aria-hidden="true" ></i>
            <h5 className="each-title">
              {" "}

                Add Lab User Details

            </h5>
            
          </div>
                  <div
                    onClick={() => history.push("/emergency-number")}
                    className="each-list-title col-lg-2"
                  >
                    <i
                      className="fas fa-phone task-icon"
                      id="task-icon-color"
                      aria-hidden="true"
                    ></i>

                    <h5 className="each-title"> {t("Emergency Number")}</h5>
                  </div>

                  <div
                    onClick={() => history.push("/service-request-list")}
                    className="each-list-title col-lg-2 box-theme"
                  >
                    <i
                      className="fas fa-ambulance"
                      id="task-icon-color"
                      aria-hidden="true"
                    ></i>

                    <h5 className="each-title">
                      {" "}
                      <h4> {t("Service Fulfillment")} </h4>
                    </h5>
                  </div>

                  <div
                    onClick={() => history.push("/rental-request-list")}
                    className="each-list-title col-lg-2 box-theme"
                  >
                    <i
                      className="fas fa-wheelchair"
                      id="task-icon-color"
                      aria-hidden="true"
                    ></i>

                    <h5 className="each-title">
                      {" "}
                      <h4> {t("Equipment fulfillment")} </h4>
                    </h5>
                  </div>
                  <div
                    onClick={() => history.push("/license-details")}
                    className="each-list-title col-lg-2 box-theme"
                  >
                    <i
                      className="fas fa-certificate"
                      id="task-icon-color"
                      aria-hidden="true"
                    ></i>

                    <h5 className="each-title">
                      {" "}
                      <h4> {t("Licence Details")} </h4>
                    </h5>
                  </div>

                  <div
                    onClick={() => history.push("/license-payment")}
                    className="each-list-title col-lg-2 box-theme"
                  >
                    <i
                      className="far fa-credit-card"
                      id="task-icon-color"
                      aria-hidden="true"
                    ></i>

                    <h5 className="each-title">
                      {" "}
                      <h4> {t("Purchase Licence")} </h4>
                    </h5>
                  </div>

                  <div
                    onClick={() => history.push("/hosp-calllog")}
                    className="each-list-title col-lg-2"
                  >
                    <i
                      className="fas fa-list-ul"
                      id="task-icon-color"
                      aria-hidden="true"
                    ></i>

                    <h5 className="each-title"> {t("Emergency Call Log")}</h5>
                  </div>

                  <div
                    onClick={() => history.push("/payment-history")}
                    className="each-list-title col-lg-2"
                  >
                    <i
                      className="fas fa-clipboard-list"
                      id="task-icon-color"
                      aria-hidden="true"
                    ></i>

                    <h5 className="each-title"> {t("Payment History")}</h5>
                  </div>

                  <div
                    onClick={() => history.push("/equipment-details")}
                    className="each-list-title col-lg-2"
                  >
                    <i
                      className="fas fa-edit"
                      id="task-icon-color"
                      aria-hidden="true"
                    ></i>

                    <h5 className="each-title"> {t("Equipment Details")}</h5>
                  </div>

                  {/*
           <div className="each-list-title col-lg-3">
           <i className="fas fa-users task-icon task-icon-small" id="task-icon-color"></i>
         
            <h5 className="each-title">
              {" "}
             <Link to="/in-progress">
                Customer Engagement
               </Link>
            </h5>
           

           
          </div>
           */}

                  {/*
          <div className="each-list-title temp-disabled">
          <i className="fa fa-user-plus task-icon task-icon-small" aria-hidden="true"></i>
            <h5 className="each-title">
              {" "}
           
                 Create App User
                
            </h5>
          
          </div>

           <div className="each-list-title temp-disabled">
           <i className="fa fa-id-badge task-icon" aria-hidden="true"></i>
            <h5 className="each-title">
              {" "}
              
                Manage Authorization
              
            </h5>
           
          </div>
              */}
                </div>
              </div>
              <p className="short-align" title="shortcuts">
                <i
                  onClick={() => setModalShow(true)}
                  className="fas fa-map-signs"
                ></i>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="illustration">
        <img src={pic} width="400px" height="auto" alt="illustration" />
      </div>

      {modalShow ? (
        <ShortcutPopup
          show={modalShow}
          onHide={() => {
            setModalShow(false);
          }}
        />
      ) : null}
    </>
  );
}
export default Hospital;
