import React,{useState, useEffect} from 'react';
import {Link, useHistory} from "react-router-dom";
import PharmacyUser from './PharmacyUser';

import "./pharmacylist.css";
import axios from 'axios';
import HospitalNavbar  from "../HospitalNavbar/HospitalNavbar";
import {APIURL} from "../../Global";

function PharmacyUserList(){
const [pharmUsersList,setPharmUserList] = useState([]); 
const [freeCount, setFreeCount]= useState();
const [activeCount, setActiveCount]= useState()
   const [errorMsg, setErrorMsg]= useState("");
   const [refresh, setRefresh]= useState(false)
	
   const history =useHistory();

   const [keys,] = useState({'a': false,'b': false,'o': false,'n': false,'s': false,'l': false,'p': false,'c': false,'h': false});

   const handleBack = (e) => {
    history.goBack();
  }; 

  useEffect(()=> {
    window.scrollTo(0,0)
  },[])
  
useEffect(()=>{

  window.onkeydown =(e) => {
    
    keys[e.key]=true

    if(e.altKey && keys['a']){
      history.push('/hospital/add')
    }
    if(e.altKey && keys['b']){
      history.push('/bdo-list')
    }
    if(e.altKey && keys['o']){
      history.push('/hospital/add-bdo')
    }
    if(e.altKey && keys['n']){
      history.push('/emergency-number')
    }
    if(e.altKey && keys['s']){
      history.push('/service-request-list')
    }
    if(e.altKey && keys['l']){
      history.push('/license-details')
    }
    if(e.altKey && keys['p']){
      history.push('/license-payment')
    }
    if(e.altKey && keys['c']){
      history.push('/hosp-calllog')
    }
    if(e.altKey && keys['h']){
      history.push('/payment-history')
    }

  }
  window.onkeyup=(ev)=> {
    
    keys[ev.key]= false
  }

  return()=>{
    window.onkeydown=null
    window.onkeyup=null
  }
},[]);
  
 useEffect(() => {
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(/["]+/g,""); 

   
    axios
      .get(`${APIURL}/api/v1/hospital/pharmacy-user/`, {
        headers: { 'Content-Type': 'application/json',
                     Authorization: "Token " +v2},
      })
      .then((res) => {
        
          const datas = res.data;
        
          
          setPharmUserList(datas.pharmacy_users_data);
       
      })
      .catch((err) => {
        setErrorMsg("error");
      });
  }, [refresh]); 

  const refreshHandle= ()=> {
    setRefresh(!refresh)
  }

	return(<div className="pharmlist-page">
    <HospitalNavbar />
		<br />
      <div className="flex-head">
        <button
          className="btn btn-info topleft-single-btn"
          onClick={handleBack}
        >
          {" "}
          <i className="fas fa-chevron-left back-btn-icon"></i>Back
        </button>
</div>

		<h2 className="title-of-page"> Pharmacy Users List </h2>
    <div className="quick-links">
      <Link to="/hospital/add-Pharm-user"><button type="button" className="btn btn-primary btn-col pharm-btn"> + Add Pharmacy User to List </button></Link>
      

</div>

		{pharmUsersList.length!== 0  ? (<div>
		<div className="drs-container">
		{pharmUsersList.map((data,index)=>{
			return <PharmacyUser key={index} data={data} freeCount={freeCount} activeCount={activeCount} handle={refreshHandle}   />
		})}
         </div>
       
        <div className="quick-links">
		  

       </div>

       </div>)
       :
       <h5 style={{color: "red" ,margin: "20px"}}>Pharmacy Users List is empty </h5>
   }

    {errorMsg=== "error" ?
       <h5 style={{ color: "red" }}>
            
            Error in data fetching.Please try again!
          </h5>
      :
      null}
		</div>
		);
}

export default PharmacyUserList;