import React ,{useState} from 'react'
import axios from "axios";
import {APIURL} from "../../Global";
import { useHistory } from "react-router-dom";
import './hospprofile.css'
import {Button ,Modal } from 'react-bootstrap';

function LocationUpdate() {

    const[location ,setLocation] =useState('')
    const [show, setShow]= useState(false);
    const [modalShow ,setModalShow] =useState(false);
    const [submitMsg,setSubmitMsg] = useState("");
    const [errorMsg,setErrorMsg]=useState("");
    const [errorSubmit,setErrorSubmit]=useState(false);

    const history =useHistory();

    const [error,setError]=useState({
      location:"",
       });

    const handleChange=(e)=>{
        e.preventDefault();
        const val=e.target.value;  
        const key=e.target.name;
      
          if(key==='location'){
            setLocation(val)
          }  
        }

        const  validate = () => {
          //let input = email;
          let errors = {};
          let isValid = true;
    
          if (location==="" ) {
            isValid = false;
            errors["location"] = "Please enter a location";
          }
      
          setError(currentstate=>({
            ...currentstate,...errors
          }))
      
          return isValid;
      }

      const handleContinue =async() => {
    
        if( validate() ){
          submitAddress()
        }
      }

        const submitAddress = (e) =>{
            const tokenString= sessionStorage.getItem('usertoken');
             
             let str= tokenString.replace(/["]+/g, '') 
          
             const data = {
               location : location
             }
          
             axios.put(`${APIURL}/api/v1/hospital/hospital-profile/` ,data, { headers: {
          
              "Authorization" : 'Token '+str  }})
            .then(res=>{
            
                  
              if(res.data.status==="success"){
                
                setSubmitMsg("success");
                setShow(true)
               setLocation()
               
            
              }else{
                setErrorMsg(res.data.message);
                setErrorSubmit(true);
              
               
              }
            
            })
            .catch(err=>{
              setErrorMsg("error");
  
              setErrorSubmit(true);
            
            })
          
          }

          const ConfirmPopUp = (props)=> {
            return(
              <Modal
                  {...props}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                   backdrop="static"
                    keyboard={false}
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                     
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
          
                  <h4 id="confirm-reject">You will be Logged Out after Updating Hospital Location. Are you sure to Continue ?</h4>
                   
                  </Modal.Body>
                  <Modal.Footer>
    
                     <Button variant="secondary" onClick={props.onHide}>No</Button>
                     <Button variant="danger" onClick={props.onCall}>Yes</Button>          
            
                  </Modal.Footer>
                </Modal>
              )
          }


          const SuccessPopUp = ({onHide}) => {
            return(
            <Modal
                show
                onHide
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                 backdrop="static"
                  keyboard={false}
              >
                <Modal.Header >
                  <Modal.Title id="contained-modal-title-vcenter">
                   
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h4 id="confirm-reject">Updated Successfully</h4>
                 
                </Modal.Body>
                <Modal.Footer>
                 
                   <Button variant="secondary" onClick={onHide}>Ok</Button>          
          
                </Modal.Footer>
              </Modal>
            )
          }
          
          
          const SubmitErrorPopup =(props)=>{
              
          
            return (
              <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                 backdrop="static"
                  keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                   
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h5 style={{color: "red"}}>Error in Data Submission. Please try again! </h5>
                 
                </Modal.Body>
                <Modal.Footer>
                 
                   <Button variant="secondary" onClick={props.onHide}>Ok</Button>
          
                 
                 
          
                </Modal.Footer>
              </Modal>
            );
          }



    return (
        <div>
            <div className="item"><input className="form-control" type="text" maxLength="20" name="location" placeholder="Location" onChange={handleChange}/>

                {error.location ? <div className="error-validation-msg"> {error.location}</div> : null}

            </div>
            <br/>
            <div className="form-button">
                <button  onClick={()=> setModalShow(true)} className="btn btn-primary btn-col" >Update Location</button>
            </div>

            {
        modalShow ? 
        <ConfirmPopUp 
            show={modalShow}
            onCall={() => { handleContinue();  setModalShow(false) }}
            onHide={() => setModalShow(false) }/> : null
    }

            {
         submitMsg==="success" ?
        <SuccessPopUp
            show={show}
            onHide= { ()=>{
              setShow(false)     
              setSubmitMsg('');
              history.push('/login')
            } } />
            : null
      }

{errorMsg!=='' ?
    <SubmitErrorPopup
        show={errorSubmit}
        onHide={() =>  {setErrorSubmit(false);
                       setErrorMsg('');}}
       
      />: ''
    }


        </div>
    )
}

export default LocationUpdate
