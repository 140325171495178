import React from "react";

import "./testreportlist.css";
import { Link } from "react-router-dom";
import axios from "axios";
import Pagination from "react-js-pagination";
import "./pagination.css";
import { Dropdown } from 'react-bootstrap';
import {APIURL} from "../../../Global";

class TestReportList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datalist: [],
       activePage: 1,
      order: "asc",
      dropdownOpen : false,
      total_page:1,
      sort: "sort",
      keys : {
        'h': false,
        'd': false,
        'u': false,
        'r': false,
        'i': false,
        'a': false,
        'p': false,
        'v': false,
        't': false,
        'n': false
       },
      package:"package",

    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); /* REGEX To remove double quotes from tokenstring */

   

    axios
      .get(`${APIURL}/api/v1/staff/lab-reports/new/?sort_by=${this.state.order}&per_page=10&page=1`, {
        headers: { Authorization: "Token "+ v2 },
      })
      .then((res) => {
        if (res.data.status === "success") {
          this.setState({total_page:res.data.total_pages})
          const datas = res.data.pending_verifications;

          this.setState({ datalist: datas });
        } else {

        }
      })
      .catch((err) => {

      });

        //////* Shotcuts ********/ 

    window.onkeydown =(e) => {
    
      this.state.keys[e.key]=true

      if(e.altKey && this.state.keys['h']){
        this.props.history.push('/hospitallist')
      }
      if(e.altKey && this.state.keys['d']){
        this.props.history.push('/doctorslist')
      }
      if(e.altKey && this.state.keys['u']){
        this.props.history.push('/search-hospitals')
      }
      if(e.altKey && this.state.keys['r']){
        this.props.history.push('/rejectedlist')
      }
      if(e.altKey && this.state.keys['i']){
        this.props.history.push('/addsynonyms')
      }
      if(e.altKey && this.state.keys['a']){
        this.props.history.push('/failedlist')
      }
      if(e.altKey && this.state.keys['p']){
        this.props.history.push('/prescriptionlist')
      }
      if(e.altKey && this.state.keys['v']){
        this.props.history.push('/written-prescriptionlist')
      }
      if(e.altKey && this.state.keys['t']){
        this.props.history.push('/testlist')
      }
      if(e.altKey && this.state.keys['n']){
        this.props.history.push('/nutrition')
      }
  
  
    }
    window.onkeyup=(ev)=> {
      
      this.state.keys[ev.key]= false
    }
  }

  componentWillUnmount(){ 
    window.onkeydown=null
    window.onkeyup=null
 
}
// useEffect(() => {
//   window.scrollTo(0, 0);
// }, []);

  render() {
    const tokenStr = sessionStorage.getItem("usertoken");

    let v2 = tokenStr.replace(
      /["]+/g,
      ""
    ); /* REGEX To remove double quotes from tokenstring */

   
    let dataDisplay = this.state.datalist.map((data, index) => {
      let idval = data.report.id;
      let url = `/report/${idval}`;
       let serialNum = this.state.activePage*10 - 10;
      return (
        <div className="list-item">
          <h5 className="each-list-link">
            {serialNum + index + 1}. <Link to={url}>{data.patient.name} - Lab Report ID_{data.report.id} </Link>
          </h5>
          <div className="details">
            <p> Patient Id: {data.patient.id} </p>
            <p> Date : {data.uploaded_at} </p>
            <span>Package : {data.patient.package}</span>
          </div>
        </div>
      );
    });

    const handlePageChange=(pageNumber)=> {
   
    this.setState({activePage: pageNumber});
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); /* REGEX To remove double quotes from tokenstring */

   
    if(this.state.package !== 'package' && this.state.package !== 'All'){
      axios
      .get(`${APIURL}/api/v1/staff/lab-reports/new/?sort_by=${this.state.order}&per_page=10&page=${pageNumber}&plan=${this.state.package}`, {
        headers: { Authorization: "Token "+ v2 },
      })
      .then((res) => {
        if (res.data.status === "success") {
          const datas = res.data.pending_verifications;

          this.setState({ datalist: datas });
        } else {

        }
      })
      .catch((err) => {

      });
    }

    else {
      axios
      .get(`${APIURL}/api/v1/staff/lab-reports/new/?sort_by=${this.state.order}&per_page=10&page=${pageNumber}`, {
        headers: { Authorization: "Token "+ v2 },
      })
      .then((res) => {
        if (res.data.status === "success") {
          const datas = res.data.pending_verifications;

          this.setState({ datalist: datas });
        } else {

        }
      })
      .catch((err) => {

      });
    }
    
  }


    const refresh = () => {
      if(this.state.package !== 'package' && this.state.package !== 'All'){

        axios
        .get(`${APIURL}/api/v1/staff/lab-reports/new/?sort_by=${this.state.order}&per_page=10&page=${this.state.activePage}&plan=${this.state.package}`, {
          headers: { Authorization:  "Token " + v2 },
        })
        .then((res) => {
          if (res.data.status === "success") {
            const datas = res.data.pending_verifications;

            this.setState({ datalist: datas });
          } else {

          }
        })
        .catch((err) => {

        });

      }

      else {
        axios
        .get(`${APIURL}/api/v1/staff/lab-reports/new/?sort_by=${this.state.order}&per_page=10&page=${this.state.activePage}`, {
          headers: { Authorization:  "Token " + v2 },
        })
        .then((res) => {
          if (res.data.status === "success") {
            const datas = res.data.pending_verifications;

            this.setState({ datalist: datas });
          } else {

          }
        })
        .catch((err) => {

        });
      }
      
    };




const onSort = async order=>{
  await this.setState({order: order});

  
  await refresh();
}

const onFilter = (data)=> {
  /*const datas = this.state.datalist.filter((item)=> { 
    return item.patient.package === data
  })

  this.setState({datalist: datas})*/


  const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); /* REGEX To remove double quotes from tokenstring */

   

    axios
      .get(`${APIURL}/api/v1/staff/lab-reports/new/?sort_by=${this.state.order}&per_page=10&page=1&plan=${data}`, {
        headers: { Authorization: "Token "+ v2 },
      })
      .then((res) => {
        if (res.data.status === "success") {
          this.setState({total_page:res.data.total_pages})
          const datas = res.data.pending_verifications;

          this.setState({ datalist: datas , activePage: 1 });
        } else {

        }
      })
      .catch((err) => {

      });

}

    return (
      <div className="container displaylist-container ">
        <div className="dashboard-navigation">
          <div>
            {" "}
            <Link to="/dashboard">
              {" "}
              <i className="fas fa-home dasboard-icon"> </i>Dashboard{" "}
            </Link>
          </div>

          <div>
            {" "}
            <i className="fas fa-angle-double-right angle-icon"></i>{" "}
          </div>
          <div>
            {" "}
            <Link to="/reportlist"> LabReportList </Link>{" "}
          </div>
        </div>
        <div className="header-display">
          <h2 className="title-of-page"> Lab Report List</h2>


         <div className="buttons-wrapper">
          <button className="btn btn-secondary" onClick={refresh}>
            Refresh List <i className="fas fa-sync-alt"></i>{" "}
          </button>
<Dropdown>

  <Dropdown.Toggle variant="secondary" id="dropdown-basic">
    {this.state.sort}
  </Dropdown.Toggle>

  <Dropdown.Menu>
    <Dropdown.Item onClick={()=>{onSort("asc") ;this.setState({sort:"Sort Ascending"})}}>Ascending</Dropdown.Item>
    <Dropdown.Item  onClick={()=>{onSort("desc");this.setState({sort:"Sort Decending"})}}>Descending</Dropdown.Item>
  
  </Dropdown.Menu>
</Dropdown>

 <Dropdown>

  <Dropdown.Toggle variant="secondary" id="dropdown-basic">
    {this.state.package}
  </Dropdown.Toggle>

  <Dropdown.Menu>
  <Dropdown.Item  onClick={()=>{onFilter("");this.setState({package:"All"})}}>All</Dropdown.Item>
    <Dropdown.Item  onClick={()=>{onFilter("basic");this.setState({package:"Basic"})}}>Basic</Dropdown.Item>
    <Dropdown.Item  onClick={()=>{onFilter("silver");this.setState({package: "Silver"})}}>Silver</Dropdown.Item>
    <Dropdown.Item onClick={()=>{onFilter("gold") ;this.setState({package: "Gold"})}}>Gold</Dropdown.Item>
    <Dropdown.Item onClick={()=>{onFilter("platinum") ;this.setState({package: "Platinum"})}}>Platinum</Dropdown.Item>
  
  </Dropdown.Menu>
</Dropdown>

</div>  


        </div>

        <div className="test-reports-display-list">
          {dataDisplay.length ? (
            dataDisplay
          ) : (
           <div style={{ color: "red", fontSize: "25px",height:"200px",padding: "40px" }}>
              <h4>No more Pending list to show!!</h4>
            </div>
          )}
        </div>

        <Pagination
          activePage={this.state.activePage}
          itemsCountPerPage={10}
          totalItemsCount={this.state.total_page*10}
          pageRangeDisplayed={this.state.total_page}
          onChange={handlePageChange}
          disabledClass = "disabled-class"
        />

        {/*
      
      
                                <button type="submit" className="next-btn">Next</button> */}
      </div>
    );
  }
}

export default TestReportList;
