import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PharmacyNavbar from "./PharmacyNavbar/PharmacyNavbar";
import pic from "../../images/illustration_dr_cropped.JPG";
import SearchPatient from "./SearchPatient";
import "../../components/Hospital/hospital.css";

function PharmacyDashboard() {
  const [pharmacyData] = useState(
    JSON.parse(sessionStorage.getItem("pharmacy_data"))
      ? JSON.parse(sessionStorage.getItem("pharmacy_data"))
      : null
  );

  const hospitalName = pharmacyData ? pharmacyData.hospital_name : null;

  return (
    <>
      <PharmacyNavbar />
      <br />
      <h1 className="additional-text "> {hospitalName} </h1>
      <br />


      <SearchPatient />

      <div className="hospital-dashboard-container">

      </div>

      <div className="illustration">
        <img src={pic} width="400px" height="auto" alt="illustration" />
      </div>


    </>
  );
}
export default PharmacyDashboard;
