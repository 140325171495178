import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import "./searchhosp.css";
import Pagination from "react-js-pagination";
import "./pagination.css";
import { Button, Modal } from 'react-bootstrap';
import { ToggleButton } from 'react-bootstrap';
import { ToggleButtonGroup } from 'react-bootstrap';


import { APIURL } from "../../../Global";
function SearchHospital() {

  const [list, setList] = useState([]);

  const [searchTerm, setSearchTerm] = useState('');
  

  const [refresh, setRefresh] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [enableShow, setEnableShow] = useState(false);
  const [disableShow, setDisableShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [id, setId] = useState();
  const [param, setParam] = useState('');
  const [load, setLoad] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [totalRecords, setTotalRecords] = useState(0)
  const [defaultUser, setDefaultUser]=useState("individual_doctors")

  const [category, setCategory] = useState(defaultUser);
  //let resultDisplay=null;

  const history = useHistory();

  const [keys,] = useState({ 'h': false, 'd': false, 'r': false, 'l': false, 'i': false, 'a': false, 'p': false, 'v': false, 't': false, 'n': false });

  useEffect(()=> {
    window.scrollTo(0,0)
},[])
  useEffect(() => {

    window.onkeydown = (e) => {

      keys[e.key] = true

      if (e.altKey && keys['h']) {
        history.push('/hospitallist')
      }
      if (e.altKey && keys['d']) {
        history.push('/doctorslist')
      }
      if (e.altKey && keys['r']) {
        history.push('/rejectedlist')
      }
      if (e.altKey && keys['l']) {
        history.push('/reportlist')
      }
      if (e.altKey && keys['i']) {
        history.push('/addsynonyms')
      }
      if (e.altKey && keys['a']) {
        history.push('/failedlist')
      }
      if (e.altKey && keys['p']) {
        history.push('/prescriptionlist')
      }
      if (e.altKey && keys['v']) {
        history.push('/written-prescriptionlist')
      }
      if (e.altKey && keys['t']) {
        history.push('/testlist')
      }
      if (e.altKey && keys['n']) {
        history.push('/nutrition')
      }

    }
    window.onkeyup = (ev) => {

      keys[ev.key] = false
    }

    return () => {
      window.onkeydown = null
      window.onkeyup = null
    }
  }, []);



  const handleSearchChange = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value)
    setCurrentPage(1)
  }



  const handleChangeRadio = (val) => {
    setCurrentPage(1)
    setCategory(val);

  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  useEffect(() => {
    setList("")
    setLoad('Loading...')
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    );

    axios.get(`${APIURL}/api/v1/staff/disable_delete_search/?q=${searchTerm}&category=${category}&per_page=10&page=${currentPage}`, {
      headers: { Authorization: "Token " + v2 },
    })
      .then((res) => {
        if (res.data.status === 'success') {
          let data = res.data.result

          

          if (data.length > 0) {
            setTotalPages(res.data.result[0].pagination.total_pages)
          setTotalRecords(res.data.result[0].pagination.total_records)
            if (data[0].category === 'individual_doctors') {
              setList(data[0].individual_doctors)
            }
            else if (data[0].category === 'hospitals') {
              setList(data[0].hospitals)
            }
            else if (data[0].category === 'patient') {
              setList(data[0].patient)
            }
          }
          else {

            setList([])
            setLoad('No list to show')
          }

        }
        else {
          setLoad('No results found')
        }
      })
      .catch(() => {
        setLoad('Error in Fetching Data')
      })



  }, [searchTerm, category, refresh, currentPage])

  const handleSearch = (e) => {
    e.preventDefault();

    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    );

    axios.get(`${APIURL}/api/v1/staff/disable_delete_search/?q=${searchTerm}&category=${category}&per_page=10&page=${currentPage}`, {
      headers: { Authorization: "Token " + v2 },
    })
      .then((res) => {
        if (res.data.status === 'success') {
          let data = res.data.result

          if (data.length > 0) {
            if (data[0].category === 'individual_doctors') {
              setList(data[0].individual_doctors)
            }
            else if (data[0].category === 'hospitals') {
              setList(data[0].hospitals)
            }
            else if (data[0].category === 'patient') {
              setList(data[0].patient)
            }
          }
          else {

            setList([])
            setLoad('No results found')
          }

        }
        else {
          setLoad('No results found')
        }
      })
      .catch((err) => {
        setLoad('Error in Fetching Data')
      })

  }

  const handleRefresh = () => {
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    );

    axios
      .get(
        `${APIURL}/api/v1/staff/disable_delete_search/?q=${searchTerm}&category=${category}&per_page=10&page=${currentPage}`,
        { headers: { Authorization: "Token " + v2 } }
      )
      .then((res) => {
        if (res.data.status === "success") {

          let data = res.data.result

          if (data.length > 0) {
            if (data[0].category === 'individual_doctors') {
              setList(data[0].individual_doctors)
            }
            else if (data[0].category === 'hospitals') {
              setList(data[0].hospitals)
            }
            else if (data[0].category === 'patient') {
              setList(data[0].patient)
            }
          }
          else {

            setList([])
            setLoad('No results found')
          }

 
        } else {
          setLoad('No results found')
        }
      })
      .catch((err) => {
        setLoad('Error in Fetching Data')
      });
  };

  const handleDeleteModal = (e, id, type) => {
    e.preventDefault();
    setId(id)
    setParam(type)
    setModalShow(true)
  }

  const handleDisable = (e, id, type, action) => {
    e.preventDefault();
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    );
    let data = {}
    if (type === 'doctor') {
      if (action === 'Disable') {
        data = {
          account_status: 'Disable',
          user_id: id
        }
      }
      else if (action === 'Enable') {
        data = {
          account_status: 'Active',
          user_id: id
        }
      }

    }
    else if (type === 'hospital') {
      if (action === 'Disable') {
        data = {
          account_status: 'Disable',
          hospital_email: id
        }
      }
      else if (action === 'Enable') {
        data = {
          account_status: 'Active',
          hospital_email: id
        }
      }
    }

    axios.post(`${APIURL}/api/v1/staff/disable_delete_account/`, data, {
      headers: { Authorization: "Token " + v2 },
    })
      .then((res) => {
        if (res.data.status === 'success') {
          if (type === 'doctor') {
            if (action === 'Disable') {
              setDisableShow(true)
            }
            else if (action === 'Enable') {
              setEnableShow(true)
            }
          }
          else if (type === 'hospital') {
            if (action === 'Disable') {
              setDisableShow(true)
            }
            else if (action === 'Enable') {
              setEnableShow(true)
            }
          }


        }
        else {

        }
      })
      .catch((err) => {

      })
  }


  const handleDelete = () => {

    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    );
    let data = {}
    if (param === 'user') {
      data = {
        account_status: 'Delete',
        user_id: id
      }
    }
    else if (param === 'hospital') {
      data = {
        account_status: 'Delete',
        hospital_email: id
      }
    }
    axios.post(`${APIURL}/api/v1/staff/disable_delete_account/`, data, {
      headers: { Authorization: "Token " + v2 },
    })
      .then((res) => {
        if (res.data.status === 'success') {

          setDeleteShow(true)
        }
       
      })
     
  }

  const ConfirmPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h4 className='text-danger'>Are you sure to delete this user ?</h4>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>No</Button>
          <Button variant="danger" onClick={props.onCall}>Yes</Button>

        </Modal.Footer>
      </Modal>
    )
  }

  const EnablePopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h4 className='text-success'>User enabled successfully</h4>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Ok</Button>

        </Modal.Footer>
      </Modal>
    )
  }

  const DisablePopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h4 className='text-success'>User disabled successfully</h4>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Ok</Button>

        </Modal.Footer>
      </Modal>
    )
  }
  const DeletePopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h4 className='text-success'>User deleted successfully</h4>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Ok</Button>

        </Modal.Footer>
      </Modal>
    )
  }


  let display = list.length > 0 ? list.map((data, index) => {

    if (category === 'individual_doctors') {
      const type = 'doctor';
      let action = '';

      return (
        <div key={data.id}>
          <Link style={{ textDecoration: 'none' }} to={{ pathname: `/doctor-details/${data.id}`, id: data.id }}>
            <div className='list-item'>

              {" "}
              <h5 className="each-list-link">
                {(currentPage - 1) * 10 + index + 1}.{data.name}{" "}
            
              </h5>

              <p className='detail-style'>ID : {data.id}</p>
              <div className="details">
                <button id='button-align' type="button" onClick={(e) => { handleDisable(e, data.id, type, action); }} className={data.account_status === 'ACTIVE' ? 'btn btn-small btn-square btn-primary btn-col' : data.account_status === 'SUSPEND' ? 'btn btn-small btn-square btn-secondary' : 'btn btn-small btn-square btn-info'}>{data.account_status === 'ACTIVE' ? action = 'Disable' : data.account_status === 'SUSPEND' ? action = 'Enable' : null} </button>
                <button id='button-align' type="button" onClick={(e) => handleDeleteModal(e, data.id, 'user')} className="btn btn-small btn-square btn-danger">Delete</button>
              </div>

            </div>
          </Link>
        </div>
      )
    }
    else if (category === 'hospitals') {
      const type = 'hospital'
      let action = '';
      return (
        <div key={data.id}>
          <Link style={{ textDecoration: 'none' }} to={{ pathname: `/hospital-details/${data.id}`, id: data.id, flag: true }}>
            <div className='list-item'>
              {" "}
              <h5 className="each-list-link">
                {(currentPage - 1) * 10 + index + 1}.{data.name}{" "}
             
              </h5>

              <p className='detail-style'>ID : {data.id}</p>

              <div className="details">
                {
                  data.account_status ?
                    <button id='button-align' type="button" onClick={(e) => { handleDisable(e, data.email, type, action); }} className={data.account_status === 'ACTIVE' ? 'btn btn-small btn-square btn-primary btn-col' : data.account_status === 'SUSPEND' ? 'btn btn-small btn-square btn-secondary' : 'btn btn-small btn-square btn-info'}>{data.account_status === 'ACTIVE' ? action = 'Disable' : data.account_status === 'SUSPEND' ? action = 'Enable' : null}</button>
                    : null
                }

                <button id='button-align' type="button" onClick={(e) => handleDeleteModal(e, data.email, 'hospital')} className="btn btn-small btn-square btn-danger">Delete</button>
              </div>

            </div>
          </Link>
        </div>
      )
    }

    else if (category === 'patient') {
      const type = 'doctor'
      let action = '';
      return (
        <div key={data.id}>
          <Link style={{ textDecoration: 'none' }} to={{ pathname: `/patient-details/${data.id}`, id: data.id }}>
            <div className='list-item'>
              {" "}
              <h5 className="each-list-link">
                {(currentPage - 1) * 10 + index + 1}.{data.name}{" "}
               </h5>

              <p className='detail-style'>ID : {data.id}</p>

              <div className="details">
                <button id='button-align' type="button" onClick={(e) => handleDisable(e, data.id, type, action)} className={data.account_status === 'ACTIVE' ? 'btn btn-small btn-square btn-primary btn-col' : data.account_status === 'SUSPEND' ? 'btn btn-small btn-square btn-secondary' : 'btn btn-small btn-square btn-info'}>{data.account_status === 'ACTIVE' ? action = 'Disable' : data.account_status === 'SUSPEND' ? action = 'Enable' : null}</button>
                <button id='button-align' type="button" onClick={(e) => handleDeleteModal(e, data.id, 'user')} className="btn btn-small btn-square btn-danger">Delete</button>
              </div>

            </div>
          </Link>
        </div>
      )

    }


  }) : <h5 className='text-muted'>{load}</h5>

  return (
    <div className="container displaylist-container">
      <div className="dashboard-navigation">
        <div>
          {" "}
          <Link to="/dashboard">
            {" "}
            <i className="fas fa-home dasboard-icon"> </i>Dashboard{" "}
          </Link>
        </div>

        <div>
          {" "}
          <i className="fas fa-angle-double-right angle-icon"></i>{" "}
        </div>
        <div>
          {" "}
          <Link to="/search-hospitals"> Users List </Link>{" "}
        </div>
      </div>

      <div className="container failedlist-parentcontainer">
        <h2 className="title-of-page">Users List</h2>

        <form onSubmit={handleSearch}>
          <div className="search-div">
            <input
              className="form-control search-input"
              type="text"
              placeholder="Search a User"
              value={searchTerm}
              maxLength="30"
              onChange={handleSearchChange}
            />
            <button disabled={searchTerm === ''} type="submit" className="btn btn-primary btn-col search-btn">
              {" "}
              <i className="fas fa-search"></i>{" "}
            </button>
          </div>
        </form>
        <div className="header-display">

          <ToggleButtonGroup type="radio" name="options" defaultValue={category} onChange={handleChangeRadio}>
            <ToggleButton id="tbg-radio-1" value={'individual_doctors'} className="btn btn-light toggle-btns toggle-btn-1">
              Doctor
            </ToggleButton>
            <ToggleButton id="tbg-radio-2" value={'hospitals'} className="btn btn-light toggle-btns toggle-btn-2">
              Hospital
            </ToggleButton>
            <ToggleButton id="tbg-radio-2" value={'patient'} className="btn btn-light toggle-btns toggle-btn-4">
              Patient
            </ToggleButton>
          </ToggleButtonGroup>

          <button style={{ marginLeft: '10px' }} className="btn btn-secondary" onClick={handleRefresh}>
            Refresh List <i className="fas fa-sync-alt"></i>
          </button>

        </div>

        <div className="failed-list-section">
          <div className="container">
            <div className="test-reports-display-list">
              {display}
            </div>

          </div>

        </div>
      </div>

      {
        modalShow ?
          <ConfirmPopUp
            show={modalShow}
            onCall={() => { handleDelete(); setModalShow(false) }}
            onHide={() => setModalShow(false)} /> : null
      }

      {enableShow ?
        <EnablePopUp
          show={enableShow}
          onHide={() => { setEnableShow(false); setRefresh(!refresh) }}
        />

        : null}

      {disableShow ?
        <DisablePopUp
          show={disableShow}
          onHide={() => { setDisableShow(false); setRefresh(!refresh) }}
        />

        : null}

      {deleteShow ?
        <DeletePopUp
          show={deleteShow}
          onHide={() => { setDeleteShow(false); setRefresh(!refresh) }}
        />

        : null}

      <br />
      <br />
      {totalPages > 0 ?
        <div className="pagn">

          <Pagination
            activePage={currentPage}
            itemsCountPerPage={10}
            totalItemsCount={totalRecords}
            pageRangeDisplayed={totalPages}
            onChange={handlePageChange}
            disabledClass="disabled-class"
          />
        </div>
        : null}

    </div>
  )
}

export default SearchHospital
