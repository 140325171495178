import React,{useEffect,useState} from 'react';
import FullCalendar, { formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

import "./calender.css";
import axios from "axios";
import {APIURL} from "../../Global";
import moment from 'moment'
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import Appointments from "../AppointmentPatientData/AppointmentPatientData2"

export default function Calender(props){
const [weekendsVisible,setWeekendsVisible]=useState(true);
const [currentEvents,setCurrentEvents]=useState([]);
const [render,setRender]=useState(false);
const [bookingsCount,setBookingsCount]=useState(0);
const [display,setDisplay]=useState(false);
const [infoDate,setInfoDate]=useState();

let eventGuid = 1;
  
useEffect(()=>{

   const tokenString = sessionStorage.getItem("usertoken");
  
      let v2 = tokenString.replace(
        /["]+/g,
        ""
      ); 
  
     
  
      axios
        .get(`${APIURL}/api/v1/doctor/appointments/`, {
          headers: { Authorization: "Token "+ v2 },
        })
        .then((res) => {
          if (res.data.status === "success") {
            
            
            // this.setState({total_page:res.data.total_pages})
            const datas = res.data.appointments;
      let newCount=0;
             let currentEv= datas.map((item,index)=>{
               newCount=newCount+ item.no_of_patients
         
              let startDate=new Date(item.date);
              let str;
              if(item.no_of_patients>1){
                str=" patients"
              }
              else{
                str=" patient"
              }
return {
      id: createEventId(),
     title: item.no_of_patients + str,
     start: item.date
}
})  
              setBookingsCount(newCount);
            setCurrentEvents(currentEv);
            setRender(!render)
          } else {
            
          }
        })
        .catch((err) => {
         
        });

},[])

const createEventId=()=> {
  return String(eventGuid++)
}


const DisplayPopup=(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <h5 style={{color:"green"}}>{props.msg} </h5>
        
        
     <Appointments date={infoDate} />
       
      </Modal.Body>
      <Modal.Footer>
         
         <Button variant="secondary" onClick={props.onHide}>Ok</Button>

         

       
       

      </Modal.Footer>
    </Modal>
  );
}


       const renderSidebar=()=> {
    return (
      <div className='calender-app-sidebar'>
        <div className='calender-app-sidebar-section'>
         
        </div>
        
        <div className='calender-app-sidebar-section'>
          <h2>All Appointments </h2>
          <h2>( {currentEvents.length} days, {bookingsCount} bookings )</h2>
          <ul>
            {currentEvents.map(renderSidebarEvent)}
          </ul>
        </div>
        <div className='calender-app-sidebar-section'>
          <label>
            <input
              type='checkbox'
              checked={weekendsVisible}
              onChange={handleWeekendsToggle}
            ></input>
            toggle weekends
          </label>
        </div>
      </div>
    )
  

  }
 
    


    const handleWeekendsToggle = () => {
    setWeekendsVisible(!weekendsVisible)
  }

  const handleDateSelect = (selectInfo) => {
    let title = prompt('Please enter a new title for your event')
    let calendarApi = selectInfo.view.calendar

    calendarApi.unselect() // clear date selection

    if (title) {
      calendarApi.addEvent({
        id: createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay
      })
    }
  }

  const handleEventClick = (clickInfo) => {
   
       let dateFormat=moment(clickInfo.event.start).format("YYYY-MM-DD");
    setDisplay(true)
    setInfoDate(dateFormat);

  
   
  }

   const handleClick = (event) => {
    
       let dateFormat=moment(event.start).format("YYYY-MM-DD");
    setDisplay(true)
    setInfoDate(dateFormat);

  {/*
      history.push(`/appointment/${dateFormat}`) 
*/}
   
  }


  const handleEvents = (events) => {
    setCurrentEvents(events)
  }

const renderEventContent=(eventInfo)=>{
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  )
}

const renderSidebarEvent=(event)=> {
  return (
   <div onClick={()=>handleClick(event)}> <li key={event.id}>
      <b>{formatDate(event.start, {year: 'numeric', month: 'short', day: 'numeric'})} </b>
      <i>- {event.title}</i>
    </li>
    </div>
  )
}

    return (
      <div className='calender-app calender-holder'>
        {renderSidebar()}
        <div className='calender-app-main'>
         <FullCalendar
            plugins={[dayGridPlugin]}
            headerToolbar={{
              left: 'prev today',
              center: 'title',
              right: 'next'
            }}
            initialView='dayGridMonth'
            editable={false}
            selectable={false}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={weekendsVisible}
          eventClick={handleEventClick}
            select={handleDateSelect}
           
               events={[...currentEvents]}
           
          />
        </div>
        {display===true ?
    <DisplayPopup
        show={display}
        infoDate={infoDate}
        onHide={() => { setDisplay(false);
                        setInfoDate(null);
                       setRender(!render)}}
       
      />: ''}

      </div>
    )
  

 }


