import React, { useState, useEffect,useRef } from 'react'
import axios from "axios";
import { APIURL } from "../../Global";
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import "./searchpatient.css";
import PrescriptionList from "./PrescriptionList"
function SearchPatient() {
  const [searchTerm, setSearchTerm] = useState('')
  const [patient, setPatient] = useState([])
  const [display, setDisplay] = useState(false)
  const [patientDetails, setPatientDetails] = useState()
  const [patientDetails1, setPatientDetails1] = useState(false)
  const initialRender = useRef(true)
  useEffect(() => {

    if (initialRender.current) {
      initialRender.current = false
    }
    else if (searchTerm === "") {
      setPatient([])
    }
    else {

      const tokenString = sessionStorage.getItem("usertoken");

      let v2 = tokenString.replace(
        /["]+/g,
        ""
      ); /* REGEX To remove double quotes from tokenstring */


      axios
        .get(`${APIURL}/api/v1/hospital/hospital-all-payment/search/?q=${searchTerm}&per_page=10`, {
          headers: { Authorization: "Token " + v2 },
        })
        .then((res) => {
          if (res.data.status === "success") {
            setPatient(res.data.results.data)
            setDisplay(true)
            setPatientDetails1(false)

          }
          else {

          }
        }).catch((error) => {

        })

    }

  }, [searchTerm])

  const handleSearch = () => {

    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); /* REGEX To remove double quotes from tokenstring */


    axios
      .get(`${APIURL}/api/v1/hospital/hospital-all-payment/search/?q=${searchTerm}&per_page=10`, {
        headers: { Authorization: "Token " + v2 },
      })
      .then((res) => {
        if (res.data.status === "success") {
         // setPatientDetails1(false)
          setPatient(res.data.results.data)
          setDisplay(true)
          document.body.addEventListener("click", bodyClickHandler);
        }
      })
  }
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    document.body.addEventListener("click", bodyClickHandler);
  };
  const handleClick = (e, item) => {
    e.preventDefault();
    initialRender.current = true
    setSearchTerm(item.name)
    setPatientDetails(item)
    setPatientDetails1(true)
    setDisplay(false)
  }

 
  const bodyClickHandler = (e) => {
    if (e.target.parentNode.dataset.name !== "patient-suggestions") {
      document.body.removeEventListener("click", bodyClickHandler);
      setDisplay(false);
    }
  }
  return (<>
    <div className='payment-search'>
      <div className='flex-col justify-content-start'>

        <div style={{ width: "100%" }} className='flex-row'>

          <input
            style={{ width: '70%' }}
            className="form-control"
            type="text"
            maxLength="100"
            placeholder="Search Patient(Enter Name/File/CPR)"
            value={searchTerm}
            onChange={handleSearchChange}
          />

          <button disabled={searchTerm === ""} onClick={handleSearch} className="btn btn-primary btn-col search-btn">
            {" "}
            <i className="fas fa-search"></i>{" "}
          </button>

        </div>
        {display &&
          <div data-name="patient-suggestions" className='suggest-display adjust-dimensions' style={{ width: '73%' }}>
            {
              patient.length > 0 ? patient.map((item) => {
                return (
                  <p onClick={(e) => handleClick(e, item)} className='suggest-item text-secondary'>{item.name}</p>
                )
              }) : <h6 className='text-muted'>No Patients found !!</h6>
            }
          </div>
        }
        <br />
      </div>
      <br />
      {patientDetails1? 
         
        <Container>
          <Row className='patient_nav' >
            <Col ><h6><i class="fas fa-user fa-lg"></i><span>&nbsp; &nbsp;</span><b>{patientDetails.name}</b></h6></Col>
            <Col ><h6><i class="fas fa-phone fa-lg"></i><span>&nbsp; &nbsp;</span><b>{patientDetails.mobile_number}</b></h6></Col>
            <Col ><h6><i class="fas fa-file fa-lg"></i><span>&nbsp; &nbsp;</span><b>{patientDetails.file_number}</b></h6></Col>
          </Row>
          <br/>
           <PrescriptionList patientId={patientDetails.id} />  
        </Container>
        :null }

    </div>
  </>
  )
}
export default SearchPatient