import React, { useState } from "react";
import Select from "react-select";
import axios from "axios";
import { APIURL } from "../../../Global";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import DatePicker from "react-date-picker";

function EditTarget(props) {
  const [data, setData] = useState(props.data);

  //       target_name :  String
  // min_value : Float
  // max_value :Float
  // tolerance :   Float                       comparison : choices
  // unit :  String
  // age_group : integer (FK)
  // description: String

  const [error, setError] = useState({
    target_name: "",
    min_value: "",
    max_value: "",
    description: "",
    tolerance: "",
    unit: "",
    age_group: "",
    comparison: "",
  });
const [comparison,setComparison]=useState({value:props.data.comparison, label: props.data.comparison})
  const comparisonoptions = [
    { value: "greater", label: "greater" },
    { value: "lower", label: "lower" },
    { value: "equal", label: "equal" },
  ];

  const [successShow, setSuccessShow] = useState(false);
  const [errorShow, setErrorShow] = useState(false);
  const [completeShow, setCompleteShow] = useState(false);
  const [errorValidate, setErrorValidate] = useState(false);

  //     const serviceOptions = [{ value:'general', label:'General' },
  //     { value:'service_request', label:'Service Request' },
  //     { value:'rental', label:'Equipment Request' }
  // ]

  const CompletePopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="text-success"> Successfully Saved</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-secondary" onClick={props.onHide}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const SuccessPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="text-success"> Click Proceed to Edit target.</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-secondary" onClick={props.onHide}>
            Not Now
          </Button>
          <Button className="btn btn-primary btn-col" onClick={saveChanges}>
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleError = () => {
    setErrorValidate(false);
    setSuccessShow(false);
  };

  const ErrorPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}>
            Error in data submission.Please try again!
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const ErorValidatePopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}>All feilds must required</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={handleError}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const handleTitle = (e) => {
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;
    setData((current) => ({
      ...current,
      [key]: val,
    }));
  };

  const handleSave = () => {
    saveChanges();
  };
  const handleServiceChange = (data) => {
    setData((current) => ({
      ...current,
      comparison: data,
    }));
  };

  //  const handleUnitChange= (data) => {

  //   setData( current => ({
  //       ...current , unit: data
  //   }))
  // }

  // const handleRequest = (e)=> {
  //  const val = e.target.value
  //  const key = e.target.name

  //  setData((current) => ({
  //      ...current , [key] : val==='true'? true : val==='false'? false : null
  //  }))

  // }

  // const handleYear = (date)=> {
  //   setYear(date);

  //     let dateUpdated= date;
  //       if(date!==null){
  //     const offset = date.getTimezoneOffset();

  //     dateUpdated = new Date(date.getTime() - (offset*60*1000))

  //     let DateExtracted = dateUpdated.toISOString().split('T')[0];

  //     /*let dateString= DateExtracted.toString(); */
  //     setYearData(DateExtracted);

  //     }
  // }

  // const handleExpiry = (date)=> {
  //   setExpiry(date);

  //     let dateUpdated= date;
  //       if(date!==null){
  //     const offset = date.getTimezoneOffset();

  //     dateUpdated = new Date(date.getTime() - (offset*60*1000))

  //     let DateExtracted = dateUpdated.toISOString().split('T')[0];

  //     /*let dateString= DateExtracted.toString(); */
  //     setExpiryData(DateExtracted);

  //     }
  // }

  const validate = () => {
    ;
    let input = data;
    let errors = {};
    let isValid = true;
    setError({});

    //   target_name:'',
    //   min_value:'',
    //   max_value:'',
    //   description:'',
    //  tolerance:'',
    //  unit:'',
    // age_group:'',
    // comparison :''
    if (!input["target_name"] || input["target_name"] === "") {
      isValid = false;
      errors["target_name"] = "Please enter Target Name";
    }
    if (!input["min_value"] || input["min_value"] === "") {
      isValid = false;
      errors["min_value"] = "Please enter a value";
    }
    if (!input["max_value"] || input["max_value"] === "") {
      isValid = false;
      errors["max_value"] = "Please enter a value";
    }
    if (!input["description"] || input["description"] === "") {
      isValid = false;
      errors["description"] = "Please enter any description";
    }
    if (!input["tolerance"] || input["tolerance"] === "") {
      isValid = false;
      errors["tolerance"] = "Please enter tolerance value";
    }
    if (!input["unit"] || input["unit"] === "") {
      isValid = false;
      errors["unit"] = "Please enter unit";
    }
    if (!input["age_group"] || input["age_group"] === "") {
      isValid = false;
      errors["age_group"] =
        "Please enter age group  1.Infant,2.kids,3.teen,4.young,5.adult,5.elder";
    }
    if (!input["comparison"] || input["comparison"] === "") {
      isValid = false;
      errors["comparison"] = "Please select comparison";
    }

    setError((currentstate) => ({
      ...currentstate,
      ...errors,
    }));

    return isValid;
  };

  const saveChanges = () => {
   
    console.log(validate());
    if (validate()) {
      const tokenString = sessionStorage.getItem("usertoken");

      let v2 = tokenString.replace(
        /["]+/g,
        ""
      ); /* REGEX To remove double quotes from tokenstring */

      const dataToSend = {
        target_name: data.target_name,
        min_value: data.min_value,
        max_value: data.max_value,
        tolerance: data.tolerance,
        comparison: data.comparison.value,
        unit: data.unit,
        age_group: data.age_group,
        description: data.description,
      };

      axios
        .put(
          `${APIURL}/api/v1/staff/master-target-detail/${data.id}/`,
          dataToSend,
          {
            headers: { Authorization: "Token " + v2 },
          }
        )
        .then((res) => {
          if (res.data.status === "success") {
            setCompleteShow(true);
            setData({
              target_name: "",
              min_value: "",
              max_value: "",
              description: "",
              tolerance: "",
              unit: "",
              age_group: "",
              comparison: "",
            });
          } else {
            setErrorShow(true);
          }
        })
        .catch((err) => {
          setErrorShow(true);
        });
      setSuccessShow(false);
    } 
  };

  return (
    <>
      {/* target_name :
min_value :
max_value :
tolerance :                                  comparison :
unit :                      
age_group :
description: 
   */}
      <h2 style={{ marginTop: "0" }} className="title-of-page">
        Edit Target
      </h2>
      <div style={{ maxHeight: "1000px" }} className="config-box fit-content">
        <div className="feature-data pad-features">
          <div className="flex-col justify-feature">
            <span className="align-rental">*Target Name: </span>
            <input
              type="text"
              className="form-control title-flex"
              name="target_name"
              value={data.target_name}
              onChange={handleTitle}
            />
            {error.target_name ? (
              <div className="error-validation-msg error-feature-inventory">
                {error.target_name}
              </div>
            ) : null}
          </div>

          <div className="flex-col justify-feature">
            <span className="align-rental">*Minimum Value : </span>
            <input
              type="number"
              className="form-control title-flex"
              name="min_value"
              min="0"
              max="100"
              value={data.min_value}
              onChange={handleTitle}
            />
            {error.min_value ? (
              <div className="error-validation-msg error-feature-inventory">
                {error.min_value}
              </div>
            ) : null}
          </div>
          <div className="flex-col justify-feature">
            <span className="align-rental">*Maximum Value : </span>
            <input
              type="number"
              className="form-control title-flex"
              name="max_value"
              min="0"
              max="100"
              value={data.max_value}
              onChange={handleTitle}
            />
            {error.max_value ? (
              <div className="error-validation-msg error-feature-inventory">
                {error.max_value}
              </div>
            ) : null}
          </div>
          <div className="flex-col justify-feature">
            <span className="align-rental">*Tolerance: </span>
            <input
              type="text"
              className="form-control title-flex"
              name="tolerance"
              value={data.tolerance}
              onChange={handleTitle}
            />
            {error.tolerance ? (
              <div className="error-validation-msg error-feature-inventory">
                {error.tolerance}
              </div>
            ) : null}
          </div>
          <div className="flex-col justify-feature">
            <span className="align-rental">*Unit: </span>
            <textarea
              type="text"
              className="form-control title-flex"
              name="unit"
              value={data.unit}
              onChange={handleTitle}
            />
            {error.unit ? (
              <div className="error-validation-msg error-feature-inventory">
                {error.unit}
              </div>
            ) : null}
          </div>

          <div className="flex-row name-content">
            <span className="align-rental">*Comparison: </span>
            <Select
            defaultValue={comparison}
              onChange={(data, e) => {
                handleServiceChange(data, e);
              }}
              options={comparisonoptions}
              name="comparison"
              className="select-currency select-flex"
            />
            {error.comparison ? (
              <div className="error-validation-msg error-feature">
                {error.comparison}
              </div>
            ) : null}
          </div>
          <div className="flex-col justify-feature">
            <span className="align-rental">*Age Group Number: </span>
            <textarea
              type="text"
              className="form-control title-flex"
              name="age_group"
              value={data.age_group}
              onChange={handleTitle}
            />
            {error.age_group ? (
              <div className="error-validation-msg error-feature-inventory">
                {error.age_group}
              </div>
            ) : null}
          </div>
          <div className="flex-col justify-feature">
            <span className="align-rental">*Description: </span>
            <textarea
              type="text"
              className="form-control title-flex"
              name="description"
              value={data.description}
              onChange={handleTitle}
            />
            {error.description ? (
              <div className="error-validation-msg error-feature-inventory">
                {error.description}
              </div>
            ) : null}
          </div>
        </div>

        {/* <div className='flex-row name-content'>
                <span>Equipment Unit : </span>
                <Select
                            value={data.unit}
                            onChange={(data,e)=>{handleUnitChange(data,e)
                            }}
                            options={unitOptions}
                            name='service_unit'
                            className="select-currency serv-width"
                        />
            </div>

            <div className='flex-row name-content'>
                <span>On Request Service : </span>

                <div style={{marginBottom:'auto'}} className="form-group gender-radio-check col-lg-6 title-input">
                    <div className="form-control">
                        <input className='checkbox-class1' type='radio' id="true" name='request'  value= {true} checked={data.request === true} onChange={handleRequest} />
                        <label htmlFor='true'>Yes</label>

                        <input className='checkbox-class1' type='radio' id="false"  name='request'  value= {false} checked={data.request === false} onChange={handleRequest} />
                        <label htmlFor='false'>No</label>
                    </div>
                </div>
            </div>

            <div className='flex-row name-content'>
                <span>Active Feature : </span>
                
                <div style={{marginBottom:'auto'}} className="form-group gender-radio-check col-lg-6 title-input">
                    <div className="form-control">
                        <input className='checkbox-class1' type='radio' id="true" name='active'  value= {true} checked={data.active === true} onChange={handleRequest} />
                        <label htmlFor='true'>Yes</label>

                        <input className='checkbox-class1' type='radio' id="false"  name='active'  value= {false} checked={data.active === false} onChange={handleRequest} />
                        <label htmlFor='false'>No</label>
                    </div>
                </div>
            </div> */}

        <div className="text-center">
          <button onClick={handleSave} className="btn btn-primary btn-col mb-2">
            Save
          </button>
        </div>
        {successShow ? (
          <SuccessPopUp
            show={successShow}
            onProceed={() => {
              setSuccessShow(false);
              props.modalClose();
            }}
            onHide={() => {
              setSuccessShow(false);
              props.notProceedClose();
            }}
          />
        ) : null}

        {errorShow ? (
          <ErrorPopUp
            show={errorShow}
            onHide={() => {
              setErrorShow(false);
            }}
          />
        ) : null}
        {errorValidate ? (
          <ErorValidatePopup
            show={errorValidate}
            onHide={() => {
              setErrorValidate(false);
            }}
          />
        ) : null}

        {completeShow ? (
          <CompletePopUp
            show={completeShow}
            onHide={() => {
              setCompleteShow(false);
            }}
          />
        ) : null}
      </div>
    </>
  );
}

export default EditTarget;
