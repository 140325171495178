import React,{useState,useEffect} from "react";

import axios from "axios";
import {APIURL} from "../../Global";
import "./analytics.css";


import {Modal} from 'react-bootstrap';

function Analytics() {
  const [reports,setReports]=useState([]);
  const [showPopup,setShowPopup]=useState(false);
  const [URL,setURL]=useState();
  const [reportTitle,setReportTitle]=useState()
const [msg,setMsg]=useState("")
   
 
useEffect(()=>{
  const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  /* REGEX To remove double quotes from tokenstring */
          
  
     

     axios.get(`${APIURL}/api/v1/analytics/metabase/`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
  
     
   
      if(res.data.status ==="success"){
          
     
      setReports(res.data.reports);
   

          }else{
       
      }


    })
    .catch(err=>{
 
 
})
 

},[])

const ReportPopup=(props)=>{
    

  return (
    <Modal
      {...props}
      aria-labelledby="example-custom-modal-styling-title"
       backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
        className="nutrients-modal"

      
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        
        <div className="each-analytics-header header-modal box-theme caps"><h4>{reportTitle}</h4></div>
     
        
        <div className="">
           
                <iframe className="iframe-div" src={URL}></iframe>
            
        </div>


       
      </Modal.Body>
      <Modal.Footer>
         
        
         

       
       

      </Modal.Footer>
    </Modal>
  );
}

/*const display= reports.length>0 ? reports.map((report,index)=>{
  return (
     <div className="" key={index}> 
     <div className="each-analytics-header box-theme"><h4><i className="fas fa-table"></i>{index+1} . {report.title}</h4></div>
       
       <div className="">
           
                <iframe className="iframe-div" src={report.url}></iframe>
            
        </div>



      </div>
     )
}) : null */

const handleShow=(reportURL,report_title)=>{
  setURL(reportURL)
  setShowPopup(true)
  setMsg("valid")
  setReportTitle(report_title)

}
const display2= reports.length>0 ? reports.map((report,index)=>{
  let srcURL=report.url
  return (
     <div className="col-lg-5" key={index}> 
     <div className="each-analytics-header header-click box-theme text-left caps" onClick={()=>handleShow(srcURL,report.title)} ><span ><i className="fas fa-table"></i> {report.title}</span></div>
       
       

      </div>
     )
}) :
 <h5 style={{color:"grey"}}> Sorry, you have no Analytics data at the moment !</h5>;
  return (
   <> 
   


   <h2 className="title-of-tasks"> Analytics & Reports </h2>
    <div className=" analytics-holder">
    {display2}
    </div>


    
 {msg==="valid"?
    <ReportPopup
        show={showPopup}
       
        onHide={() => { setShowPopup(false);
                        setURL(null);
                        setMsg("invalid")
                      }}
       
      />: null}
   </>
  );
}

export default Analytics;
