import React,{useState,useEffect} from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";

import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import {APIURL} from "../../Global";
import RazorPay from "../RazorPay/RazorPay";
import HospitalNavbar  from "../HospitalNavbar/HospitalNavbar";
import "./licencepayment.css";
import LicensePricing from "../DoctorPayment/LicensePricing"
function LicencePayment() {
	const [tenure,setTenure]=useState(1);
    const [userCount,setUserCount]=useState(1);
     const [submitMsg,setSubmitMsg] = useState("");
      const [errorMsg,setErrorMsg]=useState("");
      const [orderData,setOrderData]=useState(null);
      const [,setOrderId]=useState(null);
      const [successSubmit,setSuccessSubmit]=useState(false);
    const [errorSubmit,setErrorSubmit]=useState(false);
    const [price, setPrice]= useState(0)
    const [tax, setTax]= useState(0)
  
     const [hospData,] =useState(JSON.parse(sessionStorage.getItem('hosp_data'))? JSON.parse(sessionStorage.getItem('hosp_data')) : null);
    const hospitalId= hospData? hospData.hospital_id : null;

useEffect(()=>{
     window.scrollTo(0, 0);
   },[])
function SuccessPopup(props){
    setTimeout(props.onHide , 2000);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <h5 style={{color:"green"}}>{props.msg} </h5>
        <RazorPay orderId={orderData.order_id} orderAmount={orderData.amount} orderCurrency={orderData.currency} orderTenure={tenure} orderCount={userCount}/>
        
        
       
      </Modal.Body>
      <Modal.Footer>
         {/*
         <Button variant="secondary" onClick={props.onHide}>Cancel</Button>

       */}

         

       
       

      </Modal.Footer>
    </Modal>
  );
}

function ErrorPopup(props){
 
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <h5 style={{color:"red"}}>{props.msg} </h5>
        
        
       
      </Modal.Body>
      <Modal.Footer>
         
         <Button variant="secondary" onClick={props.onHide}>Close</Button>

         

       
       

      </Modal.Footer>
    </Modal>
  );
}


	const handleTenure=(e)=>{
		setTenure(e.target.value)
	}
	const handleUserCount=(e)=>{
		setUserCount(e.target.value)
	}
	const handleSubmit=()=>{
		const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;
          
  const dataToSend={
    hospital_id:hospitalId,
     tenure: tenure,
     user_count:userCount

  }    
  
    axios.post(`${APIURL}/api/v1/payment/create-order/?hospital_id=${hospitalId}`,dataToSend, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
     

   
   
      if(res.data.status==="success"){
                                     
       
          setOrderData(res.data);
       setOrderId(res.data.order_id)
setSubmitMsg(res.data.message ? res.data.message : "Order initiated successfully!");

          setSuccessSubmit(true);


      }else{
        
        setErrorMsg(res.data.message ? res.data.message : "Error in submission!");
              setErrorSubmit(true);
                
      }


    })
    .catch(err=>{
   
  setErrorMsg("Error in submission!");
  
              setErrorSubmit(true);
               
})
	}
const history =useHistory();


const [keys,] = useState({'d': false,'a': false,'b': false,'o': false,'n': false,'s': false,'l': false,'c': false,'h': false});


useEffect(()=>{

window.onkeydown =(e) => {
 
 keys[e.key]=true

 if(e.altKey && keys['d']){
   history.push('/doctors-list')
 }
 if(e.altKey && keys['a']){
   history.push('/hospital/add')
 }
 if(e.altKey && keys['b']){
   history.push('/bdo-list')
 }
 if(e.altKey && keys['o']){
   history.push('/hospital/add-bdo')
 }
 if(e.altKey && keys['n']){
   history.push('/emergency-number')
 }
 if(e.altKey && keys['s']){
   history.push('/service-request-list')
 }
 if(e.altKey && keys['l']){
   history.push('/license-details')
 }
 if(e.altKey && keys['c']){
   history.push('/hosp-calllog')
 }
 if(e.altKey && keys['h']){
   history.push('/payment-history')
 }

}
window.onkeyup=(ev)=> {
 
 keys[ev.key]= false
}

return()=>{
 window.onkeydown=null
 window.onkeyup=null
}
},[]);

  const handleBack=(e)=>{
    
        history.goBack();
      
}

const getPrice= (child)=> {
  setPrice(child[0])
  setTax(child[1])
}

  return (<>
     <HospitalNavbar /><br/>
    <div className=" flex-between">
        
          <button className="btn btn-info back-btn" onClick={handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back</button> 
        <Link to={{pathname:'license-details', flag:"others-back" }}><button type="button" style={{marginLeft:"10px"}} className="btn btn-info dr-btn"> View Licence Details</button></Link>

    </div> 
    
    <div className="container page-container">
    <h2 className="title-of-page"> Purchase Licence Details </h2>
   <div className="flex-row">

   <div className="license-holder">
   <div className="license-header"> <h5> Licence pricing </h5></div>
   <div className="flex-col licence-purchase-holder">
        


     <LicensePricing getPrice={getPrice}/>
     </div>
      </div>


     <div className="license-holder">




  <div className="notfn-header"> <h5> Purchase Licence </h5></div>

      <div className="flex-col licence-purchase-holder">
        

       <span className="flex-row"><h5 className="sub-titles-tables"> Number of paid Users (Dr/BDO/SDA) : </h5>
        <input className="input" value={userCount} min={1} placeholder="users count" type="number" onChange={handleUserCount}/>
        </span>
    <br/>
       <span className="flex-row"><h5 className="sub-titles-tables">Tenure (number of months) :</h5>
            <input className="form-group" value={tenure} min={1} placeholder="tenure" type="number" onChange={handleTenure}/>
        </span>
        <br/>
        <span className='total-amount'>Total Amount : {(((price/100)*tax)+ price)*userCount*tenure}</span>
<br/>
        
        <button className="btn btn-primary btn-col " onClick={handleSubmit} >Continue </button>
        
      </div>
     </div>
     </div>
       {submitMsg !== "" ?
    <SuccessPopup
        show={successSubmit}
        msg={submitMsg}
        onHide={() => { setSuccessSubmit(false);
                        setSubmitMsg('');
                      }}
       
      />: ''}
      {errorMsg !== "" ?
    <ErrorPopup
        show={errorSubmit}
        msg={errorMsg}
        onHide={() => { setErrorSubmit(false);
                        setErrorMsg('');
                      }}
       
      />: ''}
    </div>
    <div className="notes-info italics-text">
    
    <h6> All users will have free access during Free usage period (if any). </h6>
    <h6> Validity of any Licence bought will be calculated only from the date when Free usage period ends.</h6>
    <h6  > To add Doctors and BDOs to licence as free/paid user , goto <Link to="/doctors-list">Doctors list</Link> and <Link to="/bdo-list">BDO list</Link> , click on "Add Free User" or "Add Licence" links under Licence status in details cards .
    </h6>

    


    </div>
    </>
  );
}

export default LicencePayment;
