import React, {useState, useEffect} from 'react'
import axios from "axios";
import {APIURL} from "../../Global";
import {Modal} from 'react-bootstrap';
import DatePicker from "react-datepicker";
import {Button} from 'react-bootstrap';
import "./targetset.css";

function EditDate(props) {

    const {data, id} =props;
    const [toDate, setToDateValue]= useState(data.to_date)
    const [modalShow, setModalShow]=useState(false)
    const [errorShow, setErrorShow]= useState(false)
    
    const handleSave = ()=> {
        const tokenString= sessionStorage.getItem('usertoken');
   
        let str= tokenString.replace(/["]+/g, '');
         
         console.log('THE DATA', data);
         console.log('THE PATIENTR', props.id, id);
       const dataToUpdate = 
           {
               "to_date": toDate,
               "patient_target_id": data.patient_target_id
           }
     
     
       
         axios.put(`${APIURL}/api/v1/patient/patient-target/V2/?patient_id=${id}`,dataToUpdate, { headers: {
           
           "Authorization" : 'Token '+str,
           'Content-Type': 'application/json'  }
     
     
         })
     
         .then(res=>{
        
       
           if(res.status==200){
                setModalShow(true)
         
          
       }
       else{
        setErrorShow(true)
        
       }
     
         })
         .catch(err=>{
       
            setErrorShow(true)
     })

    }


    const SubmitPopup =(props)=>{
    

        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 className='text-success'>Date saved Successfully !!</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>
            </Modal.Footer>
          </Modal>
        );
      }

      const ErrorPopup =(props)=>{
    

        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 style={{color: "red"}}> Error in data submission.Please check again!</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>
      
             
             
      
            </Modal.Footer>
          </Modal>
        );
      }

    return (
        <div className='flex-col'>  
         <div className="comorbidities-body-content">
        <input
          type="date"
          maxLength="100"
          placeholder="Edit To Date"
          className="form-control"
          data-date={toDate}
          value={toDate}
          onChange={(e)=>{
          
            e.preventDefault();
            setToDateValue(e.target.value)
          } }
        />
        <button
          type="submit"
          className="btn btn-smallest btn-square btn-primary btn-col btn-col btn-add-comorbidity"
          onClick={handleSave}
        >
          {" "}
          Save{" "}
        </button>
      </div>
      {
      modalShow ? 
      <SubmitPopup
        show={modalShow}
        onHide= {() => { setModalShow(false); props.onHide();    
        }}
      /> : null
    }

{
      errorShow ? 
      <ErrorPopup
        show={errorShow}
        onHide= {() => { setErrorShow(false)     
        }}
      /> : null
    }

        </div>



    )
}

export default EditDate
