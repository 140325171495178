import React from 'react';
import 'react-table-v6/react-table.css'
import "./missingheaderreport.css"
import axios from 'axios';
import {Link } from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import {APIURL} from "../../../Global";



function SubmitPopUp(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       backdrop="static"
        keyboard={false}
        centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Data submitted sucessfully!!</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
        

        <Link to="/reportlist"><Button className='btn btn-primary btn-col' > Goto Report List </Button></Link>

       
      </Modal.Footer>
    </Modal>
  );
}


function SaveDraft(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Data saved sucessfully!!</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
        

        <Link to="/reportlist"><Button className='btn btn-primary btn-col' > Goto Report List </Button></Link>
        <Button className='btn btn-primary btn-col' onClick={props.onHide}>Continue</Button>
       
      </Modal.Footer>
    </Modal>
  );
}

function SaveDraftError(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 id="error" >Error in Draft saving!! </h4> <br/>
         <h5 id="grey-text">   **Test and Result fields are mandatory</h5>
       
      </Modal.Body>
      <Modal.Footer>
       
        

       
        <Button className='btn btn-primary btn-col' onClick={props.onHide}> Close </Button>
       
      </Modal.Footer>
    </Modal>
  );
}



class MissingHeaderReport extends React.Component{
  constructor(props){
    super(props);
    this.state={
      tests:[],
      images:[],
      date: '',
      recordId:"",
      isLocked: "null",
      submitmsg: "",
      timer: '',
      dateMsg: '',
      modalShow:false,
      savedraftmsg:"",
      modaldraftShow:false,
      modalDraftError:false,
        rejectSuccessModalShow: false,
      rejectSuccessMsg:"",
      rejectConfirmMsg:"",
      rejectConfirmModalShow: false,
      
      d2:[["a","b","c"],["e","g","h"]],
      dumb:[]

     
     
    }
  }

  
  componentDidMount(){
   
      const tokenString= sessionStorage.getItem('usertoken');
   
   let v2= tokenString.replace(/["]+/g, '')  /* REGEX To remove double quotes from tokenstring */
          
  
   this.setState({recordId: this.props.match.params.id});
  
   
 axios.get(`${APIURL}/api/v1/staff/lab-reports/${this.props.match.params.id}`, {headers: {"Authorization" : 'Token '+v2
     }})
 .then(res=>{
   if(res.data.status === "success"){
   
      
      this.setState({
            images: res.data.data.images,
            tests: res.data.data.tests,
            date: res.data.data.date,
          
           dumb:JSON.parse(res.data.data.dumb_data)
          });

 
      //call lock
      axios.post(`${APIURL}/api/v1/staff/lab-reports/lock/`, {report_id: this.props.match.params.id} , { headers: {
      'Content-Type': 'application/json',
      "Authorization" : 'Token '+v2 }})
  .then(res => {
    if(res.data.status==="success"){

    this.setState({isLocked: "true"})
    this.setState({timer: 5});

    
   
  
   

  }
  else {

    this.setState({isLocked: "false"})
  }
}).catch(err=>{

})

//call unlock

setTimeout(()=>{
axios.post(`${APIURL}/api/v1/staff/lab-reports/unlock/`, {report_id: this.props.match.params.id} , { headers: {
       'Content-Type': 'application/json',
      "Authorization" : 'Token '+v2 }})
  .then(res => {
    if(res.data.status==="success"){

    this.setState({isLocked: "false"})

  }
  else {

    
  }
}).catch(err=>{

})

}, 300000);







      /* */

    }
    else {


    }

}
  )
    .catch(err =>{

    
    
  })

    
 


    
  }

 render(){


  
   const tokenString= sessionStorage.getItem('usertoken');
   
   let v2= tokenString.replace(/["]+/g, '')  /* REGEX To remove double quotes from tokenstring */
          
 

  const {recordId}=this.state;

 const display= this.state.dumb.map(data=>{
    return <div className="outer"> 
           {data.map(row=>{
           return <div className="row-inner">
                  <h6 > {row}</h6>
                  </div>
        
                })}
          <br/>
          </div>
      });


      

   const RejectConfirmPopup =(props)=>{
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 id="confirm-reject">Please confirm to reject the report</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Cancel</Button>

        <Button variant="danger" onClick={()=>{this.setState({rejectConfirmModalShow: false});
                                                               handleReject();}}> Confirm</Button>

       
      </Modal.Footer>
    </Modal>
  );
}

 const RejectSuccessPopup =(props)=>{
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header >
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Report rejected successfully!</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
        

        <Link to="/reportlist"><Button className='btn btn-primary btn-col' > Goto Report List</Button></Link>

       
      </Modal.Footer>
    </Modal>
  );
}



const handleRejectConfirm = (e)=>{
   e.preventDefault();
    this.setState({rejectConfirmMsg:'success'});
    this.setState({rejectConfirmModalShow: true});  
}
  
  const handleReject =() =>{
 
    

    const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '') ;

       axios.post(`${APIURL}/api/v1/staff/lab-reports/${this.props.match.params.id}/`, {status : "rejected"} , { headers: {
      'Content-Type': 'application/json',
      "Authorization" : 'Token '+str }})
  .then(res => {
    if(res.data.status==="success"){
   
    this.setState({rejectSuccessMsg:'success'});
    this.setState({rejectSuccessModalShow: true});  
    
   
  
   

  }
  else {
  
     this.setState({rejectSuccessMsg:'error'});
    
  }
}).catch(err=>{

   this.setState({rejectSuccessMsg:'error'});
})


}






 
 const handleSaveDraft =(e)=>{
    e.preventDefault();
let dateUpdated= this.state.date;

if(dateUpdated!== '' &&  dateUpdated!== null){
  this.setState({dateMsg : "valid"});

    const dataToSend = {
  date: this.state.date,
  tests: this.state.tests,
  is_draft: true
  
}
var JSONData = JSON.stringify(dataToSend);

  axios.post(`${APIURL}/api/v1/staff/lab-reports/${recordId}/`, JSONData , { headers: {
     'Content-Type': 'application/json',
      "Authorization" : 'Token '+v2}})
  .then(res => {

    if(res.data.status==="success"){

    this.setState({savedraftmsg:'success'});
    this.setState({modaldraftShow: true});  
  }
  else{
    this.setState({savedraftmsg:'error'});
    this.setState({modalDraftError: true}); 
    
  }
}).catch(err=>{

this.setState({savedraftmsg:'error'});
 this.setState({modalDraftError: true}); 
})
}

else{
  this.setState({dateMsg: "invalid"});
}
}


 
const handleDate=(e)=>{
  e.preventDefault();
  if(e.target.value !== null){
      this.setState({date: e.target.value})
  }
}




const deleteRow=(index)=>{
  const newlist = this.state.tests.filter((test,i)=>{
    return i!== index
  })

  this.setState({tests:newlist})
}


const handleSubmit=(e)=>{
  e.preventDefault();
  
let dateUpdated= this.state.date;

if(dateUpdated!== '' &&  dateUpdated!== null){
  this.setState({dateMsg : "valid"});



const dataToSend = {
  date: this.state.date,
  tests: this.state.tests,
  
}
var JSONData = JSON.stringify(dataToSend);

  axios.post(`${APIURL}/api/v1/staff/lab-reports/${recordId}/`, JSONData , { headers: {
     'Content-Type': 'application/json',
      "Authorization" : 'Token '+v2}})
  .then(res => {
    if(res.data.status==="success"){

   
    this.setState({submitmsg:'success'});
     this.setState({modalShow: true});
  }
  else{
    this.setState({submitmsg:'error'});
   
  }
}).catch(err=>{

  this.setState({submitmsg:'error'})
})
}
else{
  this.setState({dateMsg: "invalid"});
}
}

const renderEditable=(cellInfo)=> {
    return (
      <div
        style={{ backgroundColor: "#fafafa" }}
        contentEditable
        suppressContentEditableWarning
        onBlur={e => {
          const tests = [...this.state.tests];
          tests[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
          
          this.setState({ tests});
        }}
        dangerouslySetInnerHTML={{
          __html: this.state.tests[cellInfo.index][cellInfo.column.id]
        }}
      />
    );
  }

/* const columns = [
{
  Header: "TEST NAME",
  accessor: "test_name",
  Cell: renderEditable,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
  width:250,
  minWidth:100,
   sortable:false

},
{
  Header: "UNITS",
  accessor: "units",
  Cell: renderEditable,
   sortable:false

},
{
  Header: "RESULT",
  accessor: "result",
  Cell: renderEditable,
   sortable:false

},
{
  Header: "REFERENCE RANGE",
  accessor: "reference_range",
  Cell: renderEditable,
  width:180,
  sortable:false,



},
{
  Header: "DELETE",
  Cell: props=>{
    return (
    <button className="delete-btn" 
       onClick={()=>{deleteRow(props.index)}}> X </button>
    )
  },
  sortable:false,
  filterable:false
}




] */

const handleNewRow=(e)=>{
  e.preventDefault();
     const newTests = this.state.tests;
    newTests.push({test_name:""});
    this.setState({ tests: newTests });

}


  return (
  
   
   

   
  
    <div className="">
     
    <div className="dashboard-navigation">
        <div> <Link to="/dashboard"> <i className="fas fa-home dasboard-icon"> </i>Dashboard </Link></div>
       
       <div> <i className="fas fa-angle-double-right angle-icon"></i> </div>
        <div> <Link to="/noheader-list"> Missing-Header List </Link> </div>
        <div> <i className="fas fa-angle-double-right angle-icon"></i> </div>
        <div>  Missing Header-Lab Report </div>
        </div>


     {this.state.isLocked=== "true" ? 
     <span style={{backgroundColor: "red", color:"white", padding: "5px 10px", width:"100vw",borderRadius:"10px"}}> This file is currently locked by you (for 5 minutes)!! </span>
     :  this.state.isLocked=== "false" ?
     <span style={{backgroundColor: "orange", color:"white", padding: "5px 10px", width:"80vw",borderRadius:"10px"}}> This file is currently Unlocked !! </span>
     
:
      ''}

    <h2 className="title-of-page">Lab Report ID-{this.state.recordId} Verification</h2>
     
    <div className="additional-btn"> 
         <button className="btn btn-danger  reject-btn" type="submit" onClick={handleRejectConfirm}>Reject Report</button>   
        <button className="btn btn-secondary" type="submit" onClick={handleSaveDraft}>Save Draft</button>
          <button className="btn btn-primary btn-col" type="submit" onClick={handleSubmit}>Submit Verified data </button></div>
     <br/>
     <div className="top">
       
         <div className="date-drawn-div form-group" id="date-color">
         <label id="datedrawn"> Verify Report Date * <br/>(YYYY-MM-DD) :  </label>
         <input type="text" maxLength="30" id="datedrawn" placeholder="YYYY-MM-DD" className="" value={this.state.date} onChange={handleDate}/>
         </div>

         

    </div>
    {this.state.dateMsg === "invalid" ?
      <div > <h5 style={{color: "red"}}> *Please enter Verify Date field! </h5> </div>
      : ''}
      

     <div className="seperator"> </div> 
      
     <div className="dashboard-display">

         
         <div className="">
         <h5 className="title-of-page">Scanned Report </h5>
         {this.state.images.map(pic=>{
          return <div className="scanned-images">
                 <img src={pic} width="500px" height="auto" alt="scanned images" />
                
                  </div>
         })}
         
         </div>
      

       
     

      

         <div className="">
         {/*
         <h5 className="title-of-page"> Table to verify and Edit Data </h5>
         <ReactTable 
         columns={columns}
         data={this.state.tests}
         defaultPageSize = {10}
         filterable
         resizable
         noDataText={"Please wait while the Lab Report data is loading..."}
         >

         </ReactTable>

       */}
   

      <button type="button" className="btn btn-primary btn-col add-row-btn" onClick={handleNewRow}> Click to Add New Row </button>
      

      </div>
     
       
       <div className="bottom-section">

        <br/>
         {/*<div className="seperator"> </div> */}
         
             {display}


      <button className="btn btn-danger  reject-btn" type="submit" onClick={handleRejectConfirm}>Reject Report</button>   
      <button className="btn btn-secondary  save-draft-btn" type="submit" onClick={handleSaveDraft}>Save Draft</button>
      
      <button className=" btn btn-primary btn-col " type="submit" onClick={handleSubmit}>Submit Verified data </button>
     
     <br/>
       
<br/>
        {this.state.savedraftmsg==="success" ? 
          <SaveDraft
        show={this.state.modaldraftShow}
        onHide={() =>  this.setState({modaldraftShow: false})}
      />
         :  this.state.savedraftmsg==="error"? 
         
        
         <SaveDraftError
        show={this.state.modalDraftError}
        onHide={() =>  this.setState({modalDraftError: false})}
      />
         

            : ''
          }

     
         {this.state.submitmsg==="success" ? 
          <SubmitPopUp
        show={this.state.modalShow}
        onHide={() =>  this.setState({modalShow: false})}
      />
         :  this.state.submitmsg==="error"? <h5 style={{color: "red"}}> Error in Data submission.Please check again! </h5>
            : ''
          }
       
        {this.state.rejectConfirmMsg==="success" ? 

          <RejectConfirmPopup
        show={this.state.rejectConfirmModalShow}
        onHide={() =>  this.setState({rejectConfirmModalShow: false})}
      />

           : 
             '' }

      

       {this.state.rejectSuccessMsg==="success" ? 

          <RejectSuccessPopup
        show={this.state.rejectSuccessModalShow}
        onHide={() =>  this.setState({rejectSuccessModalShow: false})}
      />

           :   this.state.rejectSuccessMsg==="error" ? 
             (<div>
                <br/>
              <h5 style={{color: "red"}}> Error in Report rejection.Please check again! </h5>
              </div>) 
               : ''}


   </div>
    </div>

     

     </div>



    
          

      
      
   
  );
}
}
export default MissingHeaderReport;