import React ,{useState} from 'react'
import axios from "axios";
import {APIURL} from "../../Global";
import './profile.css'
import {Button ,Modal } from 'react-bootstrap';

function GenderUpdate(props) {
    const [gender,setGender] = useState('');
    const [show, setShow]= useState(false);
    const [submitMsg,setSubmitMsg] = useState("");
    const [errorMsg,setErrorMsg]=useState("");
    const [errorSubmit,setErrorSubmit]=useState(false);

    const [error,setError]=useState({
        gender:''
       });

       const  handleGenderRadioButton=(e)=>{
        let genderOpted = e.target.value;
        setGender(genderOpted)
       }

       const  validate = () => {
        //let input = email;
        let errors = {};
        let isValid = true;
  
        if (gender==="" ) {
          isValid = false;
          errors["gender"] = "Please select a gender";
        }
    
        setError(currentstate=>({
          ...currentstate,...errors
        }))
    
        return isValid;
    }

    const handleContinue =async(e) => {
      e.preventDefault();
  
      if( validate() ){
        submitGender()
      }
    }

       const submitGender = (e) =>{
        const tokenString= sessionStorage.getItem('usertoken');
         
         let str= tokenString.replace(/["]+/g, '') 
      
         const data = {
           gender : gender
         }
      
         axios.put(`${APIURL}/api/v1/account/profile/` ,data, { headers: {
      
          "Authorization" : 'Token '+str  }})
        .then(res=>{
        
              
          if(res.data.status==="success"){

           setSubmitMsg("success");
           setShow(true)
           setGender()
           
        
          }else{
            setErrorMsg(res.data.message);
            setErrorSubmit(true);
          
           
          }
        
        })
        .catch(err=>{
          setErrorMsg("error");
  
          setErrorSubmit(true);

        
        })
      
      }

      const ConfirmPopUp = ({show,onHide}) => {
        return(
        <Modal
            show
            onHide
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header >
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4 id="confirm-reject">Updated Successfully</h4>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={onHide}>Ok</Button>          
      
            </Modal.Footer>
          </Modal>
        )
      }
      
      
      const SubmitErrorPopup =(props)=>{
          
      
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 style={{color: "red"}}>Error in Data Submission. Please try again! </h5>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>
      
             
             
      
            </Modal.Footer>
          </Modal>
        );
      }


    return (
        <div className='flex-phone'>
            <div className="form-control gender-div">

{/* <span>Gender:</span> */}
  <input 
     type="radio" 
     id="male" 
     name="gender" 
     value="male"
     checked={gender === "male"}
     onChange={handleGenderRadioButton}
    
         />
  <label htmlFor="male">Male</label>

  <input 
    type="radio"
    id="female"
     name="gender" 
     value="female"
     checked={gender === "female"}
    onChange={handleGenderRadioButton} />

  <label htmlFor="female">Female</label>

  <input 
      type="radio"
      id="other" 
      name="gender"
      value="other"
      checked={gender === "other"}
      onChange={handleGenderRadioButton} 
         />
  <label htmlFor="other">Other</label>

  {error.gender ? <div className="error-validation-msg"> {error.gender}</div> : null}
</div>

<div className="form-button">
                <button style={{marginLeft:'10px'}} onClick={handleContinue} className="btn btn-primary btn-col" >Update Gender</button>
            </div>


            {
         submitMsg==="success" ?
        <ConfirmPopUp
            show={show}
            onHide= { ()=>{
              setShow(false)     
              setSubmitMsg('');
              props.handle();
            } } />
            : null
      }

{errorMsg!=='' ?
    <SubmitErrorPopup
        show={errorSubmit}
        onHide={() =>  {setErrorSubmit(false);
                       setErrorMsg('');}}
       
      />: ''
    }

        </div>
    )
}

export default GenderUpdate
