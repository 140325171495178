import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import 'flag-icon-css/css/flag-icon.min.css'

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({

    supportedLngs: ['en','es','ar'],
    fallbackLng: "en",
    detection: {
      order: ['sessionStorage','cookie', 'htmlTag',  'querystring', 'sessionStorage', 'navigator', 'path', 'subdomain'],
    },
    caches: ['sessionStorage'],
    backend: {
      loadPath: 'assets//locales/{{lng}}/translation.json',
    },
    react: { useSuspense: false }
  });

ReactDOM.render(
  <React.StrictMode>

      <App />

   
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
