import React, { useEffect, useState } from "react";
import axios from "axios";
import "./reportimagedisplay.css";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import DumbDataTable from '../DumbDataTable/DumbDataTable';
import SynonymsTable from "../SynonymsTable/SynonymsTable";
import {APIURL} from "../../../Global";
import ImageScale from '../DisplayDetails/ImageScale'

function ReportImageDisplay({id,parentURL,parentName}) {
 const [rotation,setRotation]=useState(0);
 const [rotationArray, setRotationArray]= useState([])
 const [scaleValue,]=useState(1);
  
  const [images, setImages] = useState([]);
  
 const [dumbData,setDumbData]= useState([]);
 const [zoomShow, setZoomShow]= useState(false);
 const [pdf ,setPdf]= useState('')
 const [isPdf, setIsPdf]= useState(false)
  

  useEffect(() => {
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); /* REGEX To remove double quotes from tokenstring */

 

    const val = id;
    axios
      .get(`${APIURL}/api/v1/staff/lab-reports/${val}/`, {
        headers: { Authorization: "Token "+v2 },
      })
      .then((res) => {
        if (res.data.status === "success") {

          let imgLength= res.data.data.images.length;
 
          let rotationArr=new Array(imgLength).fill(null).map(()=>(0));
          setRotationArray(rotationArr)

          if(res.data.data.is_pdf===true){
            
            setPdf(res.data.data.images[0])
            setIsPdf(true)
          }

          setImages(res.data.data.images);
          setDumbData(JSON.parse(res.data.data.dumb_data));
          if(res.data.data.dumb_data=== []){
            
          }
        } else {

        }
      })
      .catch((err) => {

      });
  }, [id]);
  

  const handleRotateLeft2=(i)=>{
    setRotation([])
    let newRotation = rotationArray[i] - 90;
   
    if(newRotation >= 360){
      newRotation =0;
    }if(newRotation === -360){
      newRotation =0;
    }
    let newArray=rotationArray;
    newArray[i]=newRotation;
    setRotationArray(newArray)
  }

const handleSaveImage=(e)=>{
  e.preventDefault();
  const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '') ;
const dataImage={
  image_id: id,
  rotate_angle: rotation,
  key: "lab_report"
}
       axios.post(`${APIURL}/api/v1/staff/image_rotate/`, dataImage , { headers: {
      'Content-Type': 'application/json',
      "Authorization" : 'Token '+str }})
  .then(res => {
    if(res.data.status==="success"){
   
    
    
   
  
   

  }
  else {
  

    
  }
}).catch(err=>{

  })



}


const handleZoom=()=>{
  setZoomShow(true)
}


function ZoomPopUp(props) {
  return (
    <Modal
      {...props}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
       backdrop="static"
        keyboard={false}
        centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        
       <ImageScale img={images}/>

      </Modal.Body>

      <Modal.Footer>
      <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
} 
          
   

   


  return (
    <div className="image-dumbdata-div">
   
    <div className="images-div">
    <h5 className="title-of-page">Scanned Report </h5>
      {isPdf===false? images.map((pic, index) => {
        return (
          <div key={index} className="scanned-images">
                 <div className="img-holder-inside-header">
                 <img src={pic} width="400px" height="auto" alt="scanned images" style={{transform:`rotate(${rotationArray[index]}deg) scale(${scaleValue}` }}/>
                 </div>
                 <div className="btns-img-handle">
                 <button className="btn btn-primary btn-col btn-rotate" onClick={()=>handleRotateLeft2(index)}> Rotate <i className="fa fa-undo" aria-hidden="true"></i> </button>
                 <button className="btn btn-primary btn-col btn-rotate" onClick={handleZoom}> Zoom <i className="fas fa-search-plus"></i> </button>
                  <button className="btn btn-primary btn-col" onClick={handleSaveImage}> Save Image  </button>
                  </div>
                  </div> 
        );
      }): <div style={{width:'700px', height:'650px'}}>
      <object data={pdf} type="application/pdf" width="100%" height="100%">
</object></div>  }
      </div>
      <br/>
   
   <div className="flex-column-tables">

     <DumbDataTable dataDumb={dumbData} id={id} parentName={parentName} parentURL={parentURL}/>
     <br/>
     <SynonymsTable />

  </div>

  { zoomShow? 
<ZoomPopUp 
      show={zoomShow}
      onHide={()=> setZoomShow(false)}
      
    />

: null  }

    </div>
   
  );
}

export default ReportImageDisplay;
