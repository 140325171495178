
import React, { useState, useEffect } from 'react'
import axios from "axios";
import { APIURL } from "../../Global";
import HospitalNavbar from "../HospitalNavbar/HospitalNavbar";
import { useHistory } from 'react-router-dom'
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import { ToggleButton } from 'react-bootstrap';
import { ToggleButtonGroup } from 'react-bootstrap';

function RemotePayments() {

  const [load, setLoad] = useState('')
  const [paymentData, setPaymentData] = useState([])
  const [serviceData, setServiceData] = useState([])
  const [rentalData, setRentalData] = useState([])
  const [category, setCategory] = useState('Care Package')
  const history = useHistory();

  const [keys,] = useState({ 'd': false, 'a': false, 'b': false, 'o': false, 'n': false, 's': false, 'l': false, 'p': false, 'c': false });


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {

    window.onkeydown = (e) => {

      keys[e.key] = true

      if (e.altKey && keys['d']) {
        history.push('/doctors-list')
      }
      if (e.altKey && keys['a']) {
        history.push('/hospital/add')
      }
      if (e.altKey && keys['b']) {
        history.push('/bdo-list')
      }
      if (e.altKey && keys['o']) {
        history.push('/hospital/add-bdo')
      }
      if (e.altKey && keys['n']) {
        history.push('/emergency-number')
      }
      if (e.altKey && keys['s']) {
        history.push('/service-request-list')
      }
      if (e.altKey && keys['l']) {
        history.push('/license-details')
      }
      if (e.altKey && keys['p']) {
        history.push('/license-payment')
      }
      if (e.altKey && keys['c']) {
        history.push('/hosp-calllog')
      }

    }
    window.onkeyup = (ev) => {

      keys[ev.key] = false
    }

    return () => {
      window.onkeydown = null
      window.onkeyup = null
    }
  }, []);

  const handleBack = (e) => {
    history.goBack();
  }

  const handleChangeRadio = (val) => {
    setCategory(val);
  }

  useEffect(() => {
    setLoad('Loading Data...')
    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')




    axios.get(`${APIURL}/api/v1/hospital/remote_patient_payments/`, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {

        if (res.data.status === "success") {

          setLoad('')
          setPaymentData(res.data.care_programs)
          setServiceData(res.data.service_request)
          setRentalData(res.data.rental_request)
        }
        else {
          setLoad('Error in Fetching Data')
        }

      })
      .catch(err => {
        setLoad('Error in Fetching Data')
      })


  }, [])

  const renderTable = (cellInfo) => {
    return (
      <div>{paymentData[cellInfo.index][cellInfo.column.id]}</div>
    )
  }

  const renderDate = (cellInfo) => {
    let dateString = paymentData[cellInfo.index][cellInfo.column.id]
    let dateObject = new Date(dateString);

    const updatedDate = dateObject.toString();
    return (
      <div >
        <h6> {updatedDate.slice(4, 16)} </h6>
        <h6>{updatedDate.slice(16, 24)}</h6>
      </div>
    )
  }

  const renderMode = (cellInfo) => {
    return (
      <div>{paymentData[cellInfo.index][cellInfo.column.id] ? 'Desk' : 'App'}</div>
    )
  }


  const renderServiceTable = (cellInfo) => {
    return (
      <div>{serviceData[cellInfo.index][cellInfo.column.id]}</div>
    )
  }

  const renderServiceDate = (cellInfo) => {
    let dateString = serviceData[cellInfo.index][cellInfo.column.id]
    let dateObject = new Date(dateString);

    const updatedDate = dateObject.toString();
    return (
      <div >
        <h6> {updatedDate.slice(4, 16)} </h6>
        <h6>{updatedDate.slice(16, 24)}</h6>
      </div>
    )
  }

  const renderRentalTable = (cellInfo) => {
    return (
      <div>{rentalData[cellInfo.index][cellInfo.column.id]}</div>
    )
  }

  const renderRentalDate = (cellInfo) => {
    let dateString = rentalData[cellInfo.index][cellInfo.column.id]
    let dateObject = new Date(dateString);

    const updatedDate = dateObject.toString();
    return (
      <div >
        <h6> {updatedDate.slice(4, 16)} </h6>
        <h6>{updatedDate.slice(16, 24)}</h6>
      </div>
    )
  }


  const columns = [
    {
      Header: "Patient ID",
      accessor: "patient_id",
      Cell: renderTable,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)"
      },
      sortable: true,
      filterable: true,
      width: 140

    },

    {
      Header: "Patient Name",
      accessor: "patient_name",
      Cell: renderTable,
      sortable: true,
      filterable: true,
      width: 180

    },
    {
      Header: "Care Program ID",
      accessor: "careprogram_id",
      Cell: renderTable,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)"
      },
      sortable: true,
      filterable: true,
      width: 160

    },
    {
      Header: "Care Program Name",
      accessor: "careprogram_name",
      Cell: renderTable,
      sortable: true,
      filterable: true,
      width: 180

    },
    {
      Header: "Amount",
      accessor: "amount_paid",
      Cell: renderTable,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)"
      },
      sortable: true,
      filterable: true,
      width: 140

    },
    {
      Header: "Payment Date",
      accessor: "paid_on",
      Cell: renderDate,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)"
      },
      sortable: true,
      filterable: true,
      width: 200

    },
    {
      Header: "Payment Mode",
      accessor: "offline_payment",
      Cell: renderMode,
      sortable: true,
      filterable: true,
      width: 200

    }]

  const columns1 = [
    {
      Header: "Patient ID",
      accessor: "patient_id",
      Cell: renderServiceTable,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)"
      },
      sortable: true,
      filterable: true,
      width: 140

    },

    {
      Header: "Patient Name",
      accessor: "patient_name",
      Cell: renderServiceTable,
      sortable: true,
      filterable: true,
      width: 180

    },
    {
      Header: "Service ID",
      accessor: "service_id",
      Cell: renderServiceTable,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)"
      },
      sortable: true,
      filterable: true,
      width: 160

    },
    {
      Header: "Service Name",
      accessor: "service_name",
      Cell: renderServiceTable,
      sortable: true,
      filterable: true,
      width: 180

    },
    {
      Header: "Amount",
      accessor: "amount_paid",
      Cell: renderServiceTable,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)"
      },
      sortable: true,
      filterable: true,
      width: 140

    },
    {
      Header: "Payment Date",
      accessor: "paid_on",
      Cell: renderServiceDate,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)"
      },
      sortable: true,
      filterable: true,
      width: 200

    }]

  const columns2 = [
    {
      Header: "Patient ID",
      accessor: "patient_id",
      Cell: renderRentalTable,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)"
      },
      sortable: true,
      filterable: true,
      width: 140

    },

    {
      Header: "Patient Name",
      accessor: "patient_name",
      Cell: renderRentalTable,
      sortable: true,
      filterable: true,
      width: 180

    },
    {
      Header: "Equipment ID",
      accessor: "rental_id",
      Cell: renderRentalTable,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)"
      },
      sortable: true,
      filterable: true,
      width: 160

    },
    {
      Header: "Equipment Name",
      accessor: "rental_name",
      Cell: renderRentalTable,
      sortable: true,
      filterable: true,
      width: 180

    },
    {
      Header: "Amount",
      accessor: "amount_paid",
      Cell: renderRentalTable,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)"
      },
      sortable: true,
      filterable: true,
      width: 140

    },
    {
      Header: "Payment Date",
      accessor: "paid_on",
      Cell: renderRentalDate,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)"
      },
      sortable: true,
      filterable: true,
      width: 200

    }]


  const display = <ReactTable
    columns={columns}
    data={paymentData}
    defaultPageSize={10}
    className='calllog-table'
    resizable
  ></ReactTable>

  const display1 = <ReactTable
    columns={columns1}
    data={serviceData}
    defaultPageSize={10}
    className='calllog-table'
    resizable
  ></ReactTable>

  const display2 = <ReactTable
    columns={columns2}
    data={rentalData}
    defaultPageSize={10}
    className='calllog-table'
    resizable
  ></ReactTable>

  return (
    <>
      <HospitalNavbar /><br />
      <div className=" text-left">

        <button className="btn btn-info back-btn" onClick={handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back</button>
      </div>

      <div className="">
        <h2 className="title-of-page"> Payment History</h2><br />
        <div className="text-center">
          <div className='mb-5'>
            <ToggleButtonGroup type="radio" name="options" defaultValue={category} onChange={handleChangeRadio}>
              <ToggleButton id="tbg-radio-1" value={'Care Package'} className="btn btn-light toggle-btns toggle-btn-1">
              Care Package
              </ToggleButton>
              <ToggleButton id="tbg-radio-2" value={'Service Request'} className="btn btn-light toggle-btns toggle-btn-2">
                Service Request
              </ToggleButton>
              <ToggleButton id="tbg-radio-2" value={'Equipment Request'} className="btn btn-light toggle-btns toggle-btn-4">
                Equipment Request
              </ToggleButton>



            </ToggleButtonGroup>
          </div>
          <div className="service-table-holder flex-col"> {load === '' ? category === 'Care Package' ? display : category === 'Service Request' ? display1 : category === 'Equipment Request' ? display2 : null
            : load === 'Error in Fetching Data' ? <h6 style={{ color: "red" }}>{load}</h6> : <h6 style={{ color: "grey" }}>{load}</h6>}
          </div>


        </div>
      </div>
    </>

  )
}

export default RemotePayments
