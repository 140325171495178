import React,{useState,useEffect,useRef,useContext} from 'react';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import "./presc.css";
import "./presctable.css";
import "./reacttable.css"
import axios from 'axios';
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import Select from 'react-select';

import {APIURL} from "../../Global";
import {MedContext} from "../../contexts/MedContext";
import AddRemark from '../ServiceRequests/AddRemark';
import EditRemark from './EditRemark';





function AddPrescription(props){
  const [,setDisplaySuggestions] = useState(false);

  const [medListCounter,setMedListCounter]=useState(0);
  const [currentIndex,setCurrentIndex]=useState('');
  const [,setCurrentColumn] = useState('');
  const [display,setDisplay]=useState(false);

  const [search,setSearch]=useState("");
  const [options,setOptions] = useState([]);
  const [period, setPeriod]= useState('')
  const wrapperRef=useRef(null);
  //const [testsList,setTestsList]=useState([{ id:"",test : "", date: ""}]);



const [medRender,setMedRender]=useContext(MedContext);
	const [medList,setMedList]=useState([{
                 drug: "",
                 strength: '',
                 form: { 
                     value: null,
                     label:null
                   },
                 route: { 
                     value: null,
                     label:null
                   },
                 dosage: "",
                 frequency: { 
                     value: null,
                     label:null
                   },
                 duration: { 
                     value: null,
                     label: null
                   },
                 intake_count: { 
                     value: null,
                     label:null
                   },
                 intake_time: { 
                     value: null,
                     label:null
                   },
                 remarks: ""
                 	}]);
	const [render,setRender]=useState(true);
	const [errorMsg,setErrorMsg]=useState('');
	const [submitMsg,setSubmitMsg]=useState("");
	const [modalShow,setModalShow] =useState(false);
  const [validationError,setValidationError]=useState('noError');
      const [validateError,setValidateError]=useState("noError");
  const [errorModalShow,setErrorModalShow]=useState(false);
const [errorSubmitModalShow,setErrorSubmitModalShow]=useState(false);
const [,setUpdatedList] =useState([]);
     const [refreshValidate,setRefreshValidate]=useState(false);
  const [addShow, setAddShow]= useState(false)
  const [viewShow, setViewShow]= useState(false)
  const [cell, setCell]= useState('')
  const [remarkData, setRemarkData]= useState('')
  const [poppShow, setPopupShow]= useState(false)

	useEffect(()=>{
		setMedList(medList)
	},[render,medList]);

  useEffect(()=>{
  
  },[refreshValidate])
  useEffect(()=>{

    const tokenString= sessionStorage.getItem('usertoken');
     
     let str= tokenString.replace(/["]+/g, '')  
            
      
      
      axios.get(`${APIURL}/api/v1/doctor/medicine-search/?per_page=50&q=${search}`, { headers: {
        
        "Authorization" : 'Token '+str  }})
      .then(res=>{
       const medicines=[]
        if(res.data.status==="success"){
        
          const medicine=res.data.patients
          medicine.map((person, index) => (medicines.push(person["medicine_name"])))
        
        setOptions(medicines);
        if(medicines.length>0){
            
          setMedListCounter(medicines.length);
       }
  
        }
  
  
      })
      .catch(err=>{
  
  })
  
  
  },[search]);
  
  /*useEffect(()=>{
    const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '');

    if(validationError=== "noError" && updatedList.length>0){
    
     const medListToSend= updatedList.map(item=>{
     if(item.form!=="" && item.route!==""  && item.intake_time!==""){
      
      return {
                 drug: item.drug,
                 strength: item.strength,
                 form: item.form.value,
                 route: item.route.value,
                 dosage: item.dosage,
                 frequency: item.frequency.value,
                 duration: item.duration.value,               
                 intake_count: item.intake_count.value,
                 intake_time: item.intake_time.value,
                 remarks: item.remarks
                

        }
      }
      else if( item.route!=="" && item.intake_time!==""){
      
      return {
                 drug: item.drug,
                 strength: item.strength,
                 
                 route: item.route.value,
                 dosage: item.dosage,
                 frequency: item.frequency.value,
                 duration: item.duration.value,               
                 intake_count: item.intake_count.value,
                 intake_time: item.intake_time.value,
                 remarks: item.remarks
                

        }
      }
      else if(item.form!==""  && item.intake_time!==""){
      
      return {
                 drug: item.drug,
                 strength: item.strength,
                 form: item.form.value,
                 
                 dosage: item.dosage,
                 frequency: item.frequency.value,
                 duration: item.duration.value,               
                 intake_count: item.intake_count.value,
                 intake_time: item.intake_time.value,
                 remarks: item.remarks
                

        }
      }
      else if(item.form!=="" && item.route!=="" ){
      
      return {
                 drug: item.drug,
                 strength: item.strength,
                 form: item.form.value,
                 route: item.route.value,
                 dosage: item.dosage,
                 frequency: item.frequency.value,
                 duration: item.duration.value,               
                 intake_count: item.intake_count.value,
                
                 remarks: item.remarks
                

        }
      }
      else if(item.form!=="" ){
      
      return {
                 drug: item.drug,
                 strength: item.strength,
                 form: item.form.value,
                 
                 dosage: item.dosage,
                 frequency: item.frequency.value,
                 duration: item.duration.value,               
                 intake_count: item.intake_count.value,
                
                 remarks: item.remarks
                

        }
      }
      else if(item.route!==""){
      
      return {
                 drug: item.drug,
                 strength: item.strength,
                
                 route: item.route.value,
                 dosage: item.dosage,
                 frequency: item.frequency.value,
                 duration: item.duration.value,               
                 intake_count: item.intake_count.value,
              
                 remarks: item.remarks
                

        }
      }
      else if(item.intake_time!==""){
      
      return {
                 drug: item.drug,
                 strength: item.strength,
                 
                 dosage: item.dosage,
                 frequency: item.frequency.value,
                 duration: item.duration.value,               
                 intake_count: item.intake_count.value,
                 intake_time: item.intake_time.value,
                 remarks: item.remarks
                

        }
      }
      else {
      
      return {
                 drug: item.drug,
                 strength: item.strength,
                 
                 dosage: item.dosage,
                 frequency: item.frequency.value,
                 duration: item.duration.value,               
                 intake_count: item.intake_count.value,
                 
                 remarks: item.remarks
                

        }
      }
      

      })
      ;


  const dataToSend = {
    patient_id: id,
    medicines: medListToSend
  }
   
    axios.post(`${APIURL}/api/v1/doctor/patient-prescriptions/`, dataToSend,
    { headers: {
      'Content-Type': 'application/json',
      "Authorization" : 'Token '+str  }})
       .then(res=>{
    

   

      if(res.data.status==="success"){
      
      setSubmitMsg("success");
      setModalShow(true);
    

      }else{
        setErrorMsg("error");
        setErrorSubmitModalShow(true);
      }


    })
    .catch(err=>{
  
  setErrorMsg("error");
  setErrorSubmitModalShow(true);
})
 

 }
},[validationError,updatedList])

*/
const id = props.id;
const frequencyOptions = [
      {value: 1 , label: 'Daily'},
       { value: 2 , label: 'Weekly'},
        {value: 3 , label: 'Monthly'},
         {value: 4 , label: 'Alternate days'},
          {value: 5 , label: 'Weekly twice'},
           {value: 6 , label: 'Weekly Thrice'}
    ];
const formOptions = [

      {value: 'Tablets' , label: 'Tablets'},
       { value: 'Capsules' , label: 'Capsules'},
        {value: 'Chewable tablets' , label: 'Chewable tablets'},
        {value: 'Powders',label:'Powders'},
         {value: 'Solutions',label:'Solutions'},
          {value: 'Emulsions',label:'Emulsions'},
           {value: 'Suspensions',label:'Suspensions'},
            {value: 'Lotions',label:'Lotions'},
         {value: 'Creams',label:'Creams'},
          {value: 'Ointments',label:'Ointments'},
           {value: 'Effervescent granules',label:'Effervescent granules'},
           {value:'Aerosols', label: 'Aerosols'},
            {value:'Gases', label: 'Gases'},
             {value:'Suppositories', label: 'Suppositories'},
               {value:'Injections', label: 'Injections'},

    ];

 
    const durationOptions=[

      {value: 1 , label: 'Days'},
      {value: 7 , label: 'Weeks'},
      {value: 30 , label: 'Months'},
      
];
const routeOptions= [
       {value: 'Oral',label:'Oral'},
       {value: 'Topical-Skin',label:'Topical-skin'},
       {value: 'Topical-Eyes',label:'Topical-Eyes'},
       {value: 'Topical-Ears',label:'Topical-Ears'},
       {value: 'Inhalation',label:'Inhalation'},
       {value: 'Injections',label:'Injections'},
       {value: 'Vaginal',label:'Vaginal'},
       {value: 'Anal',label:'Anal'},
       {value: 'Sub-lingual', label: 'Sub-lingual'}

]

const intakeTimeOptions = [
      {value: 0 , label: 'N/A'},
      {value: 1 , label: 'Before food'},
      {value: 2 , label: 'After food'}
     
]
const intakeCountOptions = [
      {value: [0,0,0,1] , label: '0-0-0-1'},
      {value: [0,0,1,0] , label: '0-0-1-0'},
       {value: [0,0,1,1] , label: '0-0-1-1'},
        {value: [0,1,0,0] , label: '0-1-0-0'},
         {value: [0,1,0,1] , label: '0-1-0-1'},
          {value: [0,1,1,0] , label: '0-1-1-0'},
           {value: [0,1,1,1] , label: '0-1-1-1'},
            {value: [1,0,0,0] , label: '1-0-0-0'},
           {value: [1,0,0,1] , label: '1-0-0-1'},
      {value: [1,0,1,0] , label: '1-0-1-0'},
       {value: [1,0,1,1] , label: '1-0-1-1'},
        {value: [1,1,0,0] , label: '1-1-0-0'},
         {value: [1,1,0,1] , label: '1-1-0-1'},
          {value: [1,1,1,0] , label: '1-1-1-0'},
           {value: [1,1,1,1] , label: '1-1-1-1'}
           
    ];



const deleteRow=(index)=>{
  const newList = medList.filter((elt,i)=>{
    return i!== index
  })
setMedList(newList);
 
}

const AddRow=()=>{
  const list = medList;
  list.push({drug:"", strength: "",
                      form: { 
                     value: null,
                     label:null
                   },
                    duration:  { 
                     value: null,
                     label:null
                   },
                     route: { 
                     value: null,
                     label:null
                   },
                      dosage: "",
                      frequency: { 
                     value: null,
                     label:null
                   },
                     intake_count: { 
                     value: null,
                     label:null
                   },
                   intake_time: { 
                     value: null,
                     label:null
                   }
                   });
  
  setMedList(list);
  setRender(!render);
  
}

/*const handleNewRow=(e)=>{
  e.preventDefault();
     const newMed = medList;
    newMed.push({drug:""});
    setMedList(newMed);

}*/




const renderEditableTest=(cellInfo)=> {

  if(cellInfo.index > medListCounter-1 || medListCounter!==0){
 
    return (<div  ref={wrapperRef}>
      
      <input
        style={{ backgroundColor: "#fafafa" }}
        value= {medList[cellInfo.index][cellInfo.column.id]}
        className="form-control"        
       name="test"

        onChange={(e)=>{

           e.preventDefault();

setCurrentIndex(cellInfo.index);
                             setCurrentColumn(cellInfo.column.id);
                               setDisplaySuggestions(true);
                               setDisplay(true);
 

 

  setSearch(e.target.value);

  


 
  const tests = medList;
          tests[cellInfo.index].drug = e.target.value;
          
          
          setMedList(tests);
   } }
     autoComplete="off"
               
      />

       

         
        {currentIndex === cellInfo.index && cellInfo.column.id=== "drug" && display && (<div className="suggestions-box">
             {options.map((item,i)=>{
         
    return(
      <div className="scan-item" 
          
            key={i} 
            onClick={(e)=>{ e.preventDefault();
             
              setRender(!render);
              
                                      const List = medList;
                                      List[cellInfo.index].drug= item;
                                       
                                 
                                      setMedList(List);
                                      setDisplay(false);

                                      }}
            >
      <p className="search-item">{item}</p>
      </div>
      )
  }) 
           }

        </div>)
      }
      

      </div>
        

    )
  }
  else{
    return (<div>
      
      <div
        style={{ backgroundColor: "#fafafa" }}
        
        className="form-control test-name-col"        
       name="date"
        
        
       
        value={medList[cellInfo.index].date}
       
        onBlur={(e)=>{const tests = medList;
          tests[cellInfo.index].drug = e.target.innerHTML;
          
          setMedList(tests);

        

       }}
        
        
     autoComplete="off"
      dangerouslySetInnerHTML={{
          __html: medList[cellInfo.index][cellInfo.column.id]
        }}
      />

       

         
       
        </div>)

  }
  }



const renderEditable=(cellInfo)=> {
    return (
      <div
        style={{ backgroundColor: "#fafafa" }}
        className="form-control"
        contentEditable
        suppressContentEditableWarning
        onBlur={e => {
          const med = medList;
          med[cellInfo.index][cellInfo.column.id] = e.target.innerHTML.trim().replace(/&nbsp;/g, '')
      .replace(/&amp;/g, '&')
      .replace(/&gt;/g, '>')
      .replace(/&lt;/g, '<');
          setMedList(med);
        }}
        dangerouslySetInnerHTML={{
          __html: medList[cellInfo.index][cellInfo.column.id]
        }}
        onPaste={ evt => {
  evt.preventDefault()

  const text = evt.clipboardData.getData('text/plain')
  document.execCommand('insertHTML', false, text)
}}
        onKeyPress={evt => {
              const keyCode = evt.keyCode || evt.which;

              if (keyCode === 13) {
                evt.returnValue = false;
                if (evt.preventDefault) evt.preventDefault()
              } }}
      />
    );
  }

  const handleNumber = e => {
    e.preventDefault();

    const val = e.target.value

    if(val.length <= 4){
        setPeriod(e.target.value)    
    }
    else return false

}

  const SuccessPopup=(props)=>{
    

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h5 style={{color:"green"}}>Updated Remarks Successfully. Please Submit to Save changes. </h5>
          
          
       
         
        </Modal.Body>
        <Modal.Footer>
           
           <Button variant="secondary" onClick={props.onHide}>Ok</Button>
  
           
  
         
         
  
        </Modal.Footer>
      </Modal>
    );
  }


  let sendData = null

function handleSend(childData){
  sendData = childData
  const med =medList
  med[cell].remarks= sendData
  setMedList(med)
}

const addHandle= ()=> {
  setAddShow(false)
  setPopupShow(true)
}

const AddPopup=(props)=>{   

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <AddRemark update={handleSend} save={addHandle}/>

      </Modal.Body>
      <Modal.Footer>
         
        <Button variant="secondary" onClick={props.onHide}>Close</Button>
       

      </Modal.Footer>
    </Modal>
  );
}

  const handleAdd =(index)=> {
    setCell(index)
    setAddShow(true)
  }

  const handleView =(data,index) => {
    setRemarkData(data)
    setCell(index)
    setViewShow(true)
  }

  const ViewPopup=(props)=>{
    

    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
          dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <EditRemark data={remarkData} update={handleSend}/>
  
        </Modal.Body>
        <Modal.Footer>
           
          <Button variant="secondary" onClick={props.onHide}>Close</Button>
          <Button className='btn btn-primary btn-col' onClick={props.onSave}>Update</Button>
         
  
        </Modal.Footer>
      </Modal>
    );
  }

  const renderRemarks=(cellInfo)=> {
    return (medList[cellInfo.index].remarks===''|| medList[cellInfo.index].remarks===null || medList[cellInfo.index].remarks=== undefined ? <button onClick={()=> handleAdd(cellInfo.index)} className='btn btn-smallest add-remark-btn'><i className="fas fa-plus"></i></button>
            :
            <button onClick={()=> handleView(medList[cellInfo.index][cellInfo.column.id], cellInfo.index)} className='btn btn-primary btn-col btn-square btn-smallest'>View/Edit</button> )

  }


const renderFrequency=(cellInfo)=> {
    return (
    	<>

    <Select
        value={medList[cellInfo.index].frequency}
        onChange={(data)=>{
             const List = medList;
             List[cellInfo.index].frequency = data;
            
             
             
                setMedList(List);
             setRender(!render);
            }}
        options={frequencyOptions}
        className="select-frequency"
      />

      
      </>
    );
  }


const renderIntakeCount=(cellInfo)=> {
    return (
    	<>

    <Select
        value={medList[cellInfo.index].intake_count}
        onChange={(data)=>{
             const List = medList;
             List[cellInfo.index].intake_count = data;
          
              setMedList(List);
              
             setRender(!render);
            }}
        options={intakeCountOptions}
        className="select-intake"
      />

      
      </>
    );
  }

const renderIntakeTime=(cellInfo)=> {
    return (
      <>

    <Select
        value={medList[cellInfo.index].intake_time}
        onChange={(data)=>{
             const List = medList;
             List[cellInfo.index].intake_time = data;
          
              setMedList(List);
              
             setRender(!render);
            }}
        options={intakeTimeOptions}
        className="select-intake"
      />

      
      </>
    );
  }
  const renderDuration=(cellInfo)=> {
    return (
      <>
  <input type='number' min='0'  className='form-control' style={{width:'80px'}} value={period} onChange={handleNumber}  />
    <Select
        value={medList[cellInfo.index].duration}
        onChange={(data)=>{
             const List = medList;
             List[cellInfo.index].duration = data;
          
              setMedList(List);
              
             setRender(!render);
            }}
        options={durationOptions}
        className="select-intake dur-period"
      />

      
      </>
    );
  }
const renderRoute=(cellInfo)=> {
    return (
      <>

    <Select
        value={medList[cellInfo.index].route}
        onChange={(data)=>{
             const List = medList;
             List[cellInfo.index].route = data;
          
              setMedList(List);
              
             setRender(!render);
            }}
        options={routeOptions}
        className="select-intake"
      />

      
      </>
    );
  }

const renderForm=(cellInfo)=> {
    return (
      <>

    <Select
        value={medList[cellInfo.index].form}
        onChange={(data)=>{
             const List = medList;
       
             List[cellInfo.index].form = data;
          
              setMedList(List);
              
             setRender(!render);
            }}
        options={formOptions}
        className="select-intake"
      />

      
      </>
    );
  }

const columns = [
{
  Header: "DRUG*",
  accessor: "drug",
  Cell: renderEditableTest,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
  
  minWidth:100,
   sortable:false,
   filterable:false,
   width:200

},
{
  Header: <span className="table-header-tooltip" data-toggle = "tooltip" 
               data-placement = "top" title = "Strength">STR.</span>,
  accessor: "strength",
  
  width:90,
  Cell: renderEditable,
   sortable:false,
   filterable:false

},
{
  Header: "FORM",
  accessor: "form",
  
  width:200,
  Cell: renderForm,
   sortable:false,
   filterable:false

   
},
{
  Header: "ROUTE",
  accessor: "route",
  minWidth:120,
  width:150,
  Cell: renderRoute,
  sortable:false,
  filterable:false

 


},
{
  Header: "DOSAGE",
  accessor: "dosage",
  minWidth:80,
  width:100,
  Cell: renderEditable,
  sortable:false,
  filterable:false

 


},{
  Header: "FREQUENCY*",
  accessor: "frequency",
  Cell: renderFrequency,
  sortable:false,
  width:130,
  filterable:false



},
{
  Header: "DURATION*",
  accessor: "duration",
  Cell: renderDuration,
  sortable:false,
  width:200,
  filterable:false




},
{
  Header: <span className="table-header-tooltip" data-toggle = "tooltip" 
               data-placement = "top" title = "INTAKE COUNT">COUNT*</span>,
  accessor: "intake_count",
  Cell: renderIntakeCount,
  sortable:false,
  width:110,
  minWidth:100,
  filterable:false




},
{
  Header: <span className="table-header-tooltip" data-toggle = "tooltip" 
               data-placement = "top" title = "INTAKE TIME">TIME</span>,
  accessor: "intake_time",
  Cell: renderIntakeTime,
  sortable:false,
  width:110,
  minWidth:100,
  filterable:false




},
{
  Header: "REMARKS",
  accessor: "remarks",
  Cell: renderRemarks,
  sortable:false,
  width:110,
  minWidth:80,
  filterable:false




},

{
  Header: <span className="table-header-tooltip" data-toggle = "tooltip" 
               data-placement = "top" title = "Delete Row"><i className="fas fa-trash-alt"></i></span>,
  Cell: props=>{
    return (
    <button className="delete-btn" 
       onClick={()=>{deleteRow(props.index)}}> <i className="fas fa-trash-alt"></i> </button>
    )
  },
  sortable:false,
  filterable:false,
  minWidth:50,
  width:50
},
{
  Header: <span className="table-header-tooltip" data-toggle = "tooltip" 
               data-placement = "top" title = "Add a New Row">ADD</span> ,
  Cell: props=>{
    return (
    props.index === medList.length- 1 ? 
    <button className="btn btn-primary btn-col btn-small" onClick={AddRow}> + Row</button> 
    : null 
    )
  },
  sortable:false,
  filterable:false,
  minWidth:50,
  width:100
}



]

const ErrorPopup =(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 style={{color: "red"}}> {validationError}</h5>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Ok</Button>

       
       

      </Modal.Footer>
    </Modal>
  );
}
const ErrorSubmitPopup =(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 style={{color: "red"}}> Error in Data Submission</h5>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Ok</Button>

       
       

      </Modal.Footer>
    </Modal>
  );
}

const callSubmit=()=>{
  const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '');

    if(validateError=== "noError" && medList.length>0){


     const medListToSend= medList.map(item=>{
     const form1= item.form.value;
     const route1=item.route.value;
     const freq1=item.frequency.value;
     const duration1=item.duration.value * period;
     const count1=item.intake_count.value;
   const time1=item.intake_time.value;
      
      return {
                 drug: item.drug,
                 strength: item.strength,
                 form: form1,
                 route: route1,
                 dosage: item.dosage,
                 frequency: freq1,
                 duration: duration1,               
                 intake_count:count1,
                intake_time:time1,
                 remarks: item.remarks
                

        }
      
      
      
      })
      ;




  const dataToSend = {
    patient_id: id,
    medicines: medListToSend
  }
   
    axios.post(`${APIURL}/api/v1/doctor/patient-prescriptions/`, dataToSend,
    { headers: {
      'Content-Type': 'application/json',
      "Authorization" : 'Token '+str  }})
       .then(res=>{
    

   

      if(res.data.status==="success"){
      
      setSubmitMsg("success");
      setModalShow(true);
    

      }else{
        setErrorMsg("error");
        setErrorSubmitModalShow(true);
      }


    })
    .catch(err=>{
  
  setErrorMsg("error");
  setErrorSubmitModalShow(true);
})
 

 }

}


/************************************/
	const callValidate=()=>{
    setValidationError("noError");
   let isValid=true;

  const newList= medList;
     const filteredList = newList.filter((item,i)=>{

    return !(item.drug==="" && item.strength==="" && item.form ==="" && item.frequency==="" && item.route==="" && item.intake_count.length<1 && item.intake_time.length<1 && item.duration==="" && item.dosage==="")
  })
  
  setUpdatedList(filteredList);

   


      for(const [index,item] of medList.entries()){
   
        setValidationError("noError");
        if(item.drug===""){
        setValidationError(`*Drug -is mandatory at Row ${index+1}`);
          setErrorModalShow(true);
          setValidateError("Error");
          isValid=false;
          break;
     

 
          
      }
      else if(item.frequency.value==="" || item.frequency.value===null ){
        setValidationError(`*Frequency - is mandatory at Row ${index+1}`);
          setErrorModalShow(true);
           setValidateError("Error");
          isValid=false;
           break;
 
      }
      else if(item.duration.value==="" || item.duration.value===null){
        setValidationError(`*Duration - is mandatory at Row ${index+1}`);
          setErrorModalShow(true);
           setValidateError("Error");
          isValid=false;
           break;
 
      }

      else if(period===""){
        setValidationError(`*Duration Number - is mandatory at Row ${index+1}`);
          setErrorModalShow(true);
           setValidateError("Error");
          isValid=false;
           break;
    
      }
      
      else if(item.intake_count.value==="" || item.intake_count.value=== null){
        setValidationError(`*Intake Count -is mandatory at Row ${index+1}`);
          setErrorModalShow(true);
           setValidateError("Error");
          isValid=false;
           break;
 
      }
     
      

    }

  return isValid;
    

}


/*************************/
const handleSubmit= async (e)=>{
  e.preventDefault();
  
 if(await callValidate()){

  callSubmit();


 }
}
const SubmitPopUp=(props)=> {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       backdrop="static"
        keyboard={false}
        centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Data submitted sucessfully!!</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
        
       <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button> 
       
       
      </Modal.Footer>
    </Modal>
  );
}


	return(<div className="overlay-body">


    <div className="add-presc-page"><br/>
   
    <br/><br/>
    <h2 className="title-of-tasks"> Add Prescription </h2>

<div className="presc-table-holder">
		<ReactTable 
         columns={columns}
         data={medList}
         defaultPageSize = {5}
         filterable
         resizable
         noDataText={"No data"}
         className='add-presc-table'
         >

         </ReactTable>
         <br/>
         <div>
        <h6 className="form-text text-muted"> **Drug, Duration, Frequency and Intake Count are mandatory fields. </h6> </div>

          {medList.length<1 ?   <button className="btn btn-primary btn-col add-btn-presc-table" onClick={AddRow}> + Click to Add Data </button>                  
                 : null}
          <br/>

        
      </div>
     


</div>

<button className="btn btn-primary btn-col" onClick={handleSubmit}> Submit Data </button>
<br/>
<br/>
   {errorMsg === "error" ?
    <ErrorSubmitPopup
        show={errorSubmitModalShow}
        onHide={() =>  setErrorSubmitModalShow(false)}
        
      />   : null } 



{(validateError=== "Error") ?
 <ErrorPopup
        show={errorModalShow}
        onHide={() =>  {setErrorModalShow(false);
                        setValidateError("noError");
                        setRefreshValidate(!refreshValidate);}}
        
      />

: null }

 {submitMsg==="success" ? 

          <SubmitPopUp
        show={modalShow}
        onHide={() =>  {setModalShow(false);
                          setMedRender(!medRender); 
                        setSubmitMsg('');
                      setMedList([{
                        drug: "",
                        strength: '',
                        form: { 
                            value: null,
                            label:null
                          },
                        route: { 
                            value: null,
                            label:null
                          },
                        dosage: "",
                        frequency: { 
                            value: null,
                            label:null
                          },
                        duration: { 
                            value: null,
                            label: null
                          },
                        intake_count: { 
                            value: null,
                            label:null
                          },
                        intake_time: { 
                            value: null,
                            label:null
                          },
                        remarks: ""
                          }])}}
      /> 
      : ""}

{
      addShow ? 
      <AddPopup
        show={addShow}
        onHide= {() => { setAddShow(false)     
        }}
      /> : null
    }

{
      viewShow ? 
      <ViewPopup
        show={viewShow}
        onHide= {() => { setViewShow(false)     
        }}
        onSave= {() => {
            setViewShow(false);
            setPopupShow(true)
      } } /> : null
    }

{poppShow ?
    <SuccessPopup
        show={poppShow}
        onHide={() => setPopupShow(false)}
       
      />: null}

</div>		)
}

export default AddPrescription;