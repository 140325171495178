import React, { useState, useEffect } from "react";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import "./service.css";
import { Link } from "react-router-dom";
import HospBdoNavbar from "../HospBdoNavbar/HospBdoNavbar";
import Select from "react-select";
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import axios from "axios";
import { APIURL } from "../../Global";
import { useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import EditRemark from "./EditRemark";
import AddRemark from "./AddRemark";
import EquipmentDetails from "./EquipmentDetails";
function RentalRequests() {
  const [render, setRender] = useState(true);
  const [reRender, setRerender] = useState(false);
  const [, setGetError] = useState("");
  const [tableData, setTableData] = useState([]);
  const [currentIndex1, setCurrentIndex1] = useState("");
  const [currentIndex, setCurrentIndex] = useState("");
  const [, setCurrentColumn] = useState("");
  const [, setCurrentColumn1] = useState("");
  const [display, setDisplay] = useState(false);
  const [display1, setDisplay1] = useState(false);
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState([]);
  const [, setOptionsDisplay] = useState([]);
  const [options1, setOptions1] = useState([]);
  const [, setOptionsDisplay1] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [remarkList, setRemarkList] = useState([]);
  const [backup, setBackup] = useState("");
  const [equipmentShow, setEquipmentShow] = useState("");
  const [equipmentData, setEquipmentData] = useState("");
  const [pId, setPId] = useState("");
  const [eqID, setEqID] = useState("");

  const [submitMsg, setSubmitMsg] = useState("");
  const [successSubmit, setSuccessSubmit] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [remarkData, setRemarkData] = useState("");
  const [cell, setCell] = useState("");
  const [updateSuccess, setUpdateSuccess] = useState(false);

  const [ReloadSaveUseEffect, setReloadSaveUseEffect] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let history = useHistory();

  const [keys] = useState({
    u: false,
    c: false,
    n: false,
    a: false,
    q: false,
    l: false,
  });

  useEffect(() => {
    window.onkeydown = (e) => {
      keys[e.key] = true;

      if (e.altKey && keys["u"]) {
        history.push("/directory");
      }
      if (e.altKey && keys["c"]) {
        history.push("/careplans-db");
      }
      if (e.altKey && keys["n"]) {
        history.push("/notifications");
      }
      if (e.altKey && keys["a"]) {
        history.push("/analytics");
      }
      if (e.altKey && keys["q"]) {
        history.push("/qrcode");
      }
      if (e.altKey && keys["l"]) {
        history.push("/call-log");
      }
    };
    window.onkeyup = (ev) => {
      keys[ev.key] = false;
    };

    return () => {
      window.onkeydown = null;
      window.onkeyup = null;
    };
  }, []);

  useEffect(() => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/hospital/rental-request-order/`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          let dataList = res.data.rental_data;

          let updatedList = [];
          let filteredList = dataList
            .filter((item) => {
              return !(
                item.status.toLowerCase() === "completed" ||
                item.status.toLowerCase() === "rejected"
              );
            })
            .sort((a, b) => {
              return new Date(b.order_date) - new Date(a.order_date);
            });
          console.log(filteredList);
          updatedList = filteredList.map((item) => {
            let status;
            let statusObj;
            if (item.status.toLowerCase() === "new") {
              status = "new";
              statusObj = { value: "new", label: "Nw" };
            } else if (item.status.toLowerCase() === "accepted") {
              status = "accepted";
              statusObj = { value: "accepted", label: "Accepted" };
            } else if (item.status.toLowerCase() === "ongoing") {
              status = "ongoing";
              statusObj = { value: "ongoing", label: "Ongoing" };
            } else if (item.status.toLowerCase() === "rejected") {
              status = "rejected";
              statusObj = { value: "rejected", label: "Rejected" };
            } else if (item.status.toLowerCase() === "completed") {
              status = "completed";
              statusObj = { value: "completed", label: "Completed" };
            } else if (item.status.toLowerCase() === "cancelled") {
              status = "cancelled";
              statusObj = { value: "cancelled", label: "Cancelled" };
            } else if (item.status.toLowerCase() === "pending") {
              status = "pending";
              statusObj = { value: "pending", label: "Pending" };
            } else if (item.status.toLowerCase() === "delivered") {
              status = "delivered";
              statusObj = { value: "delivered", label: "Delivered" };
            }

            let priority;
            let priorityObj;
            if (item.priority.toLowerCase() === "critical") {
              priority = "critical";
              priorityObj = { value: "critical", label: "Cr" };
            } else if (item.priority.toLowerCase() === "very high") {
              priority = "very high";
              priorityObj = { value: "very high", label: "V.h" };
            } else if (item.priority.toLowerCase() === "high") {
              priority = "high";
              priorityObj = { value: "high", label: "Hi" };
            } else if (item.priority.toLowerCase() === "medium") {
              priority = "medium";
              priorityObj = { value: "medium", label: "Md" };
            } else if (item.priority.toLowerCase() === "low") {
              priority = "low";
              priorityObj = { value: "low", label: "Lw" };
            }

            return {
              name: item.patient_name,
              date: item.order_date,
              order: item.service_title,
              priority: priority,
              priorityObj: priorityObj,
              ref: item.order_id,
              contact_number: item.contact_number,
              status: status,
              statusObj: statusObj,
              patient_id: item.patient_id,
              address: item.address,
              details: item.details,
              required_date: item.due_date,
              assigned_name: item.assignee !== null ? item.assignee : null,
              remarks: item.remarks ? item.remarks : null,
              remark_list: item.remarks_list,
              assignee_id: item.assignee_id,
              is_readed: item.is_readed,
              equipment: item.equipment,
              equipment_serial_no: item.equipment_serial_no,
            };
          });
          var arr = updatedList;
          var arrSortpush = [];
          var arrSortunshift = [];

          arr.forEach(function (el) {
            if (
              (el.assigned_name == null && el.priority == "critical") ||
              (el.priority == "high" &&
                el.status == "pending" &&
                el.status != "cancelled")
            ) {
              arrSortunshift.unshift(el);
            } else {
              arrSortpush.push(el);
            }
          });

          var arrSortunshiftByDate = arrSortunshift.sort(
            (a, b) => new Date(b.required_date) - new Date(a.required_date)
          );

          console.log("d", arrSortunshiftByDate);

          var sortConcat = arrSortunshiftByDate.concat(arrSortpush);

          setTableData(sortConcat);
        } else {
          setGetError(res.data.message);
        }
      })
      .catch((err) => {
        setGetError("Error in fetching data");
      });
  }, [reRender,ReloadSaveUseEffect]);

  useEffect(() => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(
        `${APIURL}/api/v1/hospital/service-request-order/search/?q=${search}`,
        {
          headers: {
            Authorization: "Token " + str,
          },
        }
      )
      .then((res) => {
        const assignee_List = [];
        if (res.data.status === "success") {
          const assigneeList = res.data.data;
          assigneeList.map((person, index) => assignee_List.push(person["id"]));

          setOptions(assigneeList);
          setOptionsDisplay(assignee_List);
        }
      })
      .catch((err) => {});
  }, [search]);

  useEffect(() => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/hospital/equipments-list/?on_service=false`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        const equipment_list = [];
        if (res.status == 200) {
          const equipmentlist = res.data;
          equipmentlist.map((person, index) =>
            equipment_list.push(person["id"])
          );

          setOptions1(equipmentlist);
          setOptionsDisplay1(equipment_list);
        }
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    setTableData(tableData);
  }, [render, tableData]);
  const statusOptions = [
    { value: "new", label: "New" },
    { value: "ongoing", label: "Ongoing" },
    { value: "accepted", label: "Accepted" },
    { value: "pending", label: "Pending" },
    { value: "cancelled", label: "Cancelled" },
    { value: "rejected", label: "Rejected" },
    { value: "completed", label: "Completed" },
    { value: "delivered", label: "Delivered" },
  ];
  const priorityOptions = [
    { value: "critical", label: "Cr" },
    { value: "very high", label: "V.h" },
    { value: "high", label: "Hi" },
    { value: "medium", label: "Md" },
    { value: "low", label: "Lw" },
  ];

  const SuccessPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h5 style={{ color: "green" }}>{props.msg} </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const SubmitErrorPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}>
            {" "}
            {errorMsg !== ""
              ? errorMsg
              : "Error in Data Submission. Please try again!"}{" "}
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  var sendData = null;

  function handleSend(childData) {
    sendData = childData;
  }

  const addHandle = () => {
    setModalShow(false);
    //setRender(!render);
    //saveChanges(cell)
    const data = tableData;
    data[cell].remarks = sendData;
    setUpdateSuccess(true);
    setBackup(sendData);
  };
  const handleClose = () => {
    setEquipmentShow(false);
    setRerender(!reRender);
  };

  const EquipmentPopup = (props) => {
    console.log("fff");

    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <EquipmentDetails
            data={props.propsEquipment}
            modalHandle={handleClose}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const AddPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <AddRemark update={handleSend} save={addHandle} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const EditPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditRemark data={remarkData} list={remarkList} update={handleSend} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
          <Button className="btn btn-primary btn-col" onClick={props.onSave}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const UpdatePopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h5 style={{ color: "green" }}>Please save to complete changes </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleAdd = (index) => {
    setCell(index);
    setModalShow(true);
  };

  const handleEdit = (data, list, index) => {
    setRemarkData(data);
    setRemarkList(list);
    setCell(index);
    setEditShow(true);
  };
  const renderRemarks3 = (cellInfo) => {
    if (
      tableData[cellInfo.index][cellInfo.column.id] === "" ||
      tableData[cellInfo.index][cellInfo.column.id] === null
    ) {
      return (
        <button
          onClick={() => handleAdd(cellInfo.index)}
          className="btn btn-smallest add-remark-btn"
        >
          <i className="fas fa-plus"></i>
        </button>
      );
    } else {
      return (
        <button
          onClick={() =>
            handleEdit(
              tableData[cellInfo.index][cellInfo.column.id],
              tableData[cellInfo.index].remark_list,
              cellInfo.index
            )
          }
          className="btn btn-primary btn-col btn-square btn-smallest"
        >
          View/Edit
        </button>
      );
    }
  };

  const renderAssigned3 = (cellInfo) => {
    return (
      <span>
        <input
          style={{ backgroundColor: "#fafafa" }}
          value={tableData[cellInfo.index].equipment_serial_no}
          className="form-control"
          name="equipment_serial_no"
          onChange={(e) => {
    
            e.preventDefault();

            const list = tableData;
            list[cellInfo.index].equipment_serial_no = e.target.value;

            setTableData(list);

            setCurrentIndex1(cellInfo.index);
            setCurrentColumn1(cellInfo.column.id);

            setDisplay(true);

            setSearch(e.target.value);
          }}
        />

        {currentIndex1 === cellInfo.index &&
          cellInfo.column.id === "equipment" &&
          display && (
            <div className="suggestions-box-holder">
              <button
                className="btn-suggestion btn btn-secondary btn-smallest"
                title="close"
                onClick={() => setDisplay(false)}
              >
                {" "}
                X{" "}
              </button>

              <div className="suggestions-box suggestions-assignee-box">
                {options1.map((item, i) => {
                  return (
                    <div
                      className="scan-item"
                      key={i}
                      onClick={(e) => {
                        e.preventDefault();

                        setRender(!render);

                        const List = tableData;
                        List[cellInfo.index].equipment_id = item.id;
                        List[cellInfo.index].equipment_serial_no = item.equipment_name;

                        setTableData(List);
                        setDisplay(false);
                      }}
                    >
                      <span className="search-item each-option-suggestion">
                        <h6 className="">ID: {item.id} </h6>
                        <h6 className=""> {item.equipment_name}</h6>
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
      </span>
    );
  };

  const renderAssigned2 = (cellInfo) => {
    return (
      <div>
        <input
          style={{ backgroundColor: "#fafafa" }}
          value={tableData[cellInfo.index].assigned_name}
          className="form-control"
          name="assignee"
          onChange={(e) => {
            e.preventDefault();

            const list = tableData;
            list[cellInfo.index].assigned_name = e.target.value;

            setTableData(list);

            setCurrentIndex(cellInfo.index);
            setCurrentColumn(cellInfo.column.id);

            setDisplay1(true);

            setSearch(e.target.value);
          }}
        />

        {currentIndex === cellInfo.index &&
          cellInfo.column.id === "assigned_name" &&
          display1 && (
            <div className="suggestions-box-holder">
              <button
                className="btn-suggestion btn btn-secondary btn-smallest"
                title="close"
                onClick={() => setDisplay1(false)}
              >
                {" "}
                X{" "}
              </button>

              <div className="suggestions-box suggestions-assignee-box">
                {options.map((item, i) => {
                  return (
                    <div
                      className="scan-item"
                      key={i}
                      onClick={(e) => {
                        e.preventDefault();

                        setRender(!render);

                        const List = tableData;
                        List[cellInfo.index].assigned_to = item.id;
                        List[cellInfo.index].assigned_name = item.name;

                        setTableData(List);
                        setDisplay1(false);
                      }}
                    >
                      <div className="search-item each-option-suggestion">
                        <h6 className="">ID: {item.id} </h6>
                        <h6 className=""> {item.name}</h6>
                        <h6 className="">{item.email} </h6>
                        <h6 className=""> {item.mobile_number}</h6>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
      </div>
    );
  };

  const renderTable = (cellInfo) => {
    return (
      <div className="fixed-co">
        {" "}
        {tableData[cellInfo.index][cellInfo.column.id]}
      </div>
    );
  };

  const renderName = (cellInfo) => {
    return (
      <>
        <div className="fixed-co">
          {tableData[cellInfo.index][cellInfo.column.id]}
        </div>
        <h6> (id : {tableData[cellInfo.index].patient_id} )</h6>
      </>
    );
  };

  const renderOverdue = (cellInfo) => {
    return (
      <div className="fixed-co">
        {" "}
        {tableData[cellInfo.index][cellInfo.column.id] === true ? "Yes" : "No"}
      </div>
    );
  };
  const renderId = (cellInfo) => {
    if (tableData[cellInfo.index][cellInfo.column.id]) {
      return (
        <div className="fixed-col">
          {" "}
          {tableData[cellInfo.index][cellInfo.column.id]}
        </div>
      );
    } else {
      return null;
    }
  };

  const viewEquipment = (data) => {
    console.log(data);
    // debugger;
    setEquipmentData(data);
    setEquipmentShow(true);
    //  // setStatus(allData.status);
    //  // setDateData(allData.date)
    //   //setPId(data.patient_id)
    //   setEqID(data.equipment)
  };
  const renderService = (cellInfo) => {
    return (
      <div>
        {" "}
        <span style={{ margin: "10px" }}>
          {tableData[cellInfo.index][cellInfo.column.id]}
        </span>
        <i
          style={{ color: "var(--theme-blue)" }}
          onClick={() => viewEquipment(tableData[cellInfo.index])}
          className="fas fa-info-circle"
        ></i>
      </div>
    );
  };
  const renderContact = (cellInfo) => {
    return (
      <div>
        {" "}
        <span style={{ margin: "10px" }}>
          {tableData[cellInfo.index][cellInfo.column.id]}
        </span>
        <OverlayTrigger
          trigger="click"
          rootClose
          placement="top"
          overlay={
            <Tooltip className="my-tooltip" id="button-tooltip">
              {tableData[cellInfo.index].address !== null
                ? tableData[cellInfo.index].address
                : "Not Available"}{" "}
              <br />
            </Tooltip>
          }
        >
          <h6 className="overlay-address">Address</h6>
        </OverlayTrigger>
      </div>
    );
  };
  /*const renderDetails=(cellInfo)=> {
  
    return (

     <div > <p > {tableData[cellInfo.index][cellInfo.column.id]}</p></div>
      
    );
  }*/

  const renderStatus2 = (cellInfo) => {
    return (
      <>
        <Select
          value={tableData[cellInfo.index].statusObj}
          onChange={(data) => {
            const List = tableData;

            List[cellInfo.index].statusObj = data;
            List[cellInfo.index].status = data.value;
            setTableData(List);
            setRender(!render);
          }}
          options={statusOptions}
          className={`status-select ${
            tableData[cellInfo.index].status === "new"
              ? "new-status"
              : tableData[cellInfo.index].status === "accepted"
              ? "accepted-status"
              : tableData[cellInfo.index].status === "ongoing"
              ? "ongoing-status"
              : tableData[cellInfo.index].status === "pending"
              ? "pending-status"
              : tableData[cellInfo.index].status === "cancelled"
              ? "cancelled-status"
              : tableData[cellInfo.index].status === "completed"
              ? "completed-status"
              : tableData[cellInfo.index].status === "delivered"
              ? "delivered-status"
              : tableData[cellInfo.index].status === "rejected"
              ? "rejected-status"
              : ""
          }`}
        />
      </>
    );
  };

  const renderPriority2 = (cellInfo) => {
    return (
      <>
        <Select
          value={tableData[cellInfo.index].priorityObj}
          onChange={(data) => {
            const List = tableData;

            List[cellInfo.index].priorityObj = data;
            List[cellInfo.index].priority = data.value;
            setTableData(List);
            setRender(!render);
          }}
          options={priorityOptions}
          className={`status-select ${
            tableData[cellInfo.index].priority === "critical"
              ? "critical-priority"
              : tableData[cellInfo.index].priority === "very high"
              ? "very-high-priority"
              : tableData[cellInfo.index].priority === "high"
              ? "high-priority"
              : tableData[cellInfo.index].priority === "medium"
              ? "medium-priority"
              : tableData[cellInfo.index].priority === "low"
              ? "low-priority"
              : ""
          }`}
        />
      </>
    );
  };

  /*const renderStatus=(cellInfo)=> {
  if(tableData[cellInfo.index][cellInfo.column.id]==="new"){
   return (

      <div ><span className="status new-status caps"> {tableData[cellInfo.index][cellInfo.column.id] }</span></div>
      
    );
  }
  else if(tableData[cellInfo.index][cellInfo.column.id]==="ongoing"){
    return (

      <div ><span className="status ongoing-status caps"> {tableData[cellInfo.index][cellInfo.column.id] }</span></div>
      
    );
  }
  if(tableData[cellInfo.index][cellInfo.column.id]==="accepted"){
    return (

      <div ><span className="status accepted-status caps"> {tableData[cellInfo.index][cellInfo.column.id]}</span></div>
      
    );
  }
  if(tableData[cellInfo.index][cellInfo.column.id]==="completed"){
    return (

      <div ><span className="status completed-status caps"> {tableData[cellInfo.index][cellInfo.column.id]}</span></div>
      
    );
  }
  if(tableData[cellInfo.index][cellInfo.column.id]==="rejected"){
    return (

      <div ><span className="status rejected-status caps"> {tableData[cellInfo.index][cellInfo.column.id]}</span></div>
      
    );
  }
  }




const renderPriority=(cellInfo)=> {
  if(tableData[cellInfo.index][cellInfo.column.id]==="critical"){
    return (

      <div > <div className="priority critical-priority caps">{tableData[cellInfo.index][cellInfo.column.id] }</div></div>
      
    );
  }
  else if(tableData[cellInfo.index][cellInfo.column.id]==="very high"){
    return (

      <div ><div className="priority very-high-priority caps"> {tableData[cellInfo.index][cellInfo.column.id] }</div></div>
      
    );
  }
  else if(tableData[cellInfo.index][cellInfo.column.id]==="high"){
    return (

      <div ><div className="priority high-priority caps"> {tableData[cellInfo.index][cellInfo.column.id] }</div></div>
      
    );
  }
  if(tableData[cellInfo.index][cellInfo.column.id]==="medium"){
    return (

      <div ><div className="priority medium-priority caps"> {tableData[cellInfo.index][cellInfo.column.id]}</div></div>
      
    );
  }
  if(tableData[cellInfo.index][cellInfo.column.id]==="low"){
    return (

      <div ><div className="priority low-priority caps"> {tableData[cellInfo.index][cellInfo.column.id]}</div></div>
      
    );
  }
  

  }*/

  /**************removed***details column*******************
{
  Header: "Details",
  accessor: "details",
  Cell: renderDetails,
   sortable:true,
    filterable:true,
   width:200

},



********************************/

  const saveChanges = (index) => {
    const rowToUpdate = tableData.filter((elt, i) => {
      return i === index;
    });

    callSave(rowToUpdate[0]);
  };
  const validate = (rowToValidate) => {
    let isValid = true;

    {
      /********************************************
  if((rowToValidate.assigned_to===null || rowToValidate.assigned_to==="" || rowToValidate.assigned_to==="undefined" || !rowToValidate.assigned_to) && (rowToValidate.assigned_name && (rowToValidate.assigned_name!==null || rowToValidate.assigned_name!==""))){

isValid=false;
 setErrorMsg("Error in Assignee field. Please select assignee from the suggestion list.")
  setErrorSubmit(true);
  }

********************************removed assignee validation *****/
    }
    if (!rowToValidate.status) {
      isValid = false;

      setErrorMsg("Please enter status");

      setErrorSubmit(true);
    }
    if (!rowToValidate.priority) {
      isValid = false;

      setErrorMsg("Please enter priority");

      setErrorSubmit(true);
    }

    return isValid;
  };

  const callSave = (rowToUpdate) => {
   

    if (validate(rowToUpdate)) {
      setReloadSaveUseEffect(false)
      const tokenString = sessionStorage.getItem("usertoken");

      let str = tokenString.replace(/["]+/g, "");
      const order_id = rowToUpdate.ref;
      const assignee = rowToUpdate.assigned_to;
      const equipment_id = rowToUpdate.equipment_id;
      const remarks =
        rowToUpdate.remarks !== "" && rowToUpdate.remarks === backup
          ? rowToUpdate.remarks
          : null;
      const status = rowToUpdate.status;
      const priority = rowToUpdate.priority;

      const dataToUpdate = {
        order_id,
        assignee,
        status,
        priority,
        remarks,
        equipment_id,
      };

      axios
        .post(`${APIURL}/api/v1/hospital/rental-request-order/`, dataToUpdate, {
          headers: {
            Authorization: "Token " + str,
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            setReloadSaveUseEffect(true)
            setSubmitMsg(
              res.data.message ? res.data.message : "Updated successfully!"
            );

            setBackup("");
            setSuccessSubmit(true);
            setTableData([]);
          } else {
            setErrorMsg(
              res.data.message ? res.data.message : "Error in submission"
            );
            setErrorSubmit(true);
          }
        })
        .catch((err) => {
          setErrorMsg("Error in submission");
          setErrorSubmit(true);
        });
    }
  };

  /*const callSaveRemarks=(rowToUpdate)=>{

  if(validate(rowToUpdate)){

  const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;
      const order_id=rowToUpdate.ref;
      const remarks= sendData
      const status= rowToUpdate.status;
      const assignee = rowToUpdate.assigned_to;
      const priority= rowToUpdate.priority;
   const dataToUpdate={
    order_id,
    remarks,
    status,
    assignee,
    priority
   }
   
    axios.post(`${APIURL}/api/v1/hospital/rental-request-order/`,dataToUpdate, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
        
      if(res.data.status==="success"){
    
      setSubmitMsg(res.data.message ? res.data.message : "Updated successfully!");

          setSuccessSubmit(true);
          setTableData([]);
   
   }
       else{
       

         setErrorMsg(res.data.message ? res.data.message : "Error in submission");
              setErrorSubmit(true);
              
      }
    


    })
    .catch(err=>{
  
   setErrorMsg( "Error in submission");
              setErrorSubmit(true);

})
}
}*/

  const renderDate = (cellInfo) => {
    let dateString = tableData[cellInfo.index][cellInfo.column.id];

    let dateObject = new Date(dateString);

    const updatedDate = dateObject.toString();
    return (
      <div>
        <h6> {updatedDate.slice(4, 16)} </h6>
        <h6> {updatedDate.slice(16, 24)}</h6>
      </div>
    );
  };

  const renderDueDate = (cellInfo) => {
    let dateString = tableData[cellInfo.index][cellInfo.column.id];
    let dateObject = new Date(dateString);

    const updatedDate = dateObject.toString();
    return (
      <div>
        <h6> {updatedDate.slice(4, 16)} </h6>
      </div>
    );
  };

  const columns = [
    {
      Header: "Id",
      accessor: "ref",
      Cell: renderId,

      sortable: true,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined
          ? String(row[id]).toLowerCase().startsWith(filter.value.toLowerCase())
          : true;
      },
      width: 50,
    },

    {
      Header: "Equipment request",
      accessor: "order",
      Cell: renderService,
      sortable: true,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined
          ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase())
          : true;
      },
      width: 200,
    },

    {
      Header: (
        <>
          <span>Order Date</span>
          <br />
        </>
      ),
      accessor: "date",
      Cell: renderDate,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },

      width: 120,
      filterable: true,
      sortable: true,
    },
    {
      Header: (
        <>
          <span>Required</span>
          <br />
          <span>Date</span>
        </>
      ),
      accessor: "required_date",
      Cell: renderDueDate,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },

      width: 100,
      filterable: true,
      sortable: true,
    },
    {
      Header: (
        <>
          <h6>Patient Name</h6>
        </>
      ),
      accessor: "name",
      Cell: renderName,
      sortable: true,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined
          ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase())
          : true;
      },
      width: 150,
    },
    {
      Header: "Contact",
      accessor: "contact_number",
      Cell: renderContact,
      sortable: false,
      filterable: true,
      width: 150,
    },
    {
      Header: "*Priority",
      accessor: "priorityObj",
      Cell: renderPriority2,
      sortable: true,
      filterable: true,
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (filter.value === "very high") {
          return row[filter.id].value === "very high";
        }
        if (filter.value === "high") {
          return row[filter.id].value === "high";
        }

        if (filter.value === "critical") {
          return row[filter.id].value === "critical";
        }
        if (filter.value === "medium") {
          return row[filter.id].value === "medium";
        }
        if (filter.value === "low") {
          return row[filter.id].value === "low";
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">All</option>
          <option value="critical">Cr</option>
          <option value="very high">V.h</option>
          <option value="high">Hi</option>
          <option value="medium">Md</option>
          <option value="low">Lw</option>
        </select>
      ),

      width: 120,
    },

    {
      Header: (
        <>
          <h6>*Status</h6>
        </>
      ),
      accessor: "statusObj",
      Cell: renderStatus2,
      sortable: true,
      filterable: true,
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (filter.value.toLowerCase() === "ongoing") {
          return row[filter.id].value === "ongoing";
        }
        if (filter.value.toLowerCase() === "accepted") {
          return row[filter.id].value === "accepted";
        }
        if (filter.value.toLowerCase() === "new") {
          return row[filter.id].value === "new";
        }
        if (filter.value.toLowerCase() === "rejected") {
          return row[filter.id].value === "rejected";
        }
        if (filter.value.toLowerCase() === "completed") {
          return row[filter.id].value === "completed";
        }
        if (filter.value.toLowerCase() === "delivered") {
          return row[filter.id].value === "delivered";
        }
        if (filter.value.toLowerCase() === "cancelled") {
          return row[filter.id].value === "cancelled";
        }
        if (filter.value.toLowerCase() === "pending") {
          return row[filter.id].value === "pending";
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">All</option>
          <option value="new">New</option>
          <option value="accepted">Accepted</option>
          <option value="ongoing">Ongoing</option>
          <option value="pending">Pending</option>
          <option value="completed">Completed</option>
          <option value="delivered">Delivered</option>
          <option value="cancelled">Cancelled</option>
          <option value="rejected">Rejected</option>
        </select>
      ),

      width: 160,
    },

    {
      Header: (
        <>
          <h6>Equipment</h6>
          <h6>(Select from the </h6>
          <h6>list only)</h6>
        </>
      ),
      accessor: "equipment",
      Cell: renderAssigned3,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },

      sortable: true,
      filterable: true,

      //  filterMethod:(filter, row, column) => {
      //   const id = filter.pivotId || filter.id
      //   return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
      // },
      width: 160,
    },
    {
      Header: (
        <>
          <h6>Assignee</h6>
          <h6>(Select from the </h6>
          <h6>list only)</h6>
        </>
      ),
      accessor: "assigned_name",
      Cell: renderAssigned2,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },

      sortable: true,
      filterable: true,

      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined
          ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase())
          : true;
      },
      width: 160,
    },

    {
      Header: (
        <>
          <h6>Notes/ </h6>
          <h6>Remarks</h6>
        </>
      ),
      accessor: "remarks",
      Cell: renderRemarks3,
      sortable: false,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined
          ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase())
          : true;
      },
      width: 120,
    },
    /*{
  Header: <><h6>Customer</h6>
             <h6>side Status</h6>
          </>,
  accessor: "patient_side_status",
  Cell: renderTable,
   sortable:true,
    filterable:true,
   filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                      return true;
                    }
                    if (filter.value === "requested") {
                      return row[filter.id] === "requested";
                    }
                    if (filter.value === "received") {
                      return row[filter.id]=== "received";
                    }
                    
                     if (filter.value === "returned") {
                      return row[filter.id] === "returned";
                    }
                     if (filter.value === "lost") {
                      return row[filter.id] === "lost";
                    }
                    
                  },
                  Filter: ({ filter, onChange }) =>
                    <select
                      onChange={event => onChange(event.target.value)}
                      style={{ width: "100%" }}
                      value={filter ? filter.value : "all"}
                    >
                      <option value="all">All</option>
                       <option value="requested">Requested</option>
                      <option value="received">Received</option>
                      <option value="returned">Returned</option>
                      <option value="lost">Lost</option>
                      
                    </select>,
                   
              
   width:120

},
{
  Header: "Amount",
  accessor: "amount",
  Cell: renderTable,
   sortable:true,
      
   width:100

},
{
  Header: <><h6>Due </h6>
             <h6>Amount</h6>
          </>,
  accessor: "due_amount",
  Cell: renderTable,
   sortable:true,
      
   width:100

},
{
  Header: <><h6>Is Overdue?</h6>
         
          </>,
  accessor: "over_due",
  Cell: renderOverdue,
   sortable:true,
     filterable:true,
   filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                      return true;
                    }
                    if (filter.value === "true") {
                      return row[filter.id] === true;
                    }
                    if (filter.value === "false") {
                      return row[filter.id]=== false;
                    }
                    
                  },
                  Filter: ({ filter, onChange }) =>
                    <select
                      onChange={event => onChange(event.target.value)}
                      style={{ width: "100%" }}
                      value={filter ? filter.value : "all"}
                    >
                      <option value="all">All</option>
                       <option value="true">Yes</option>
                      <option value="false">No</option>
                      
                    </select>,
                   
   width:100

},
{
  Header: <><h6>Additional </h6>
             <h6>days used</h6>
          </>,
  accessor: "extra_used_days",
  Cell: renderTable,
   sortable:true,
      
   width:100

},
{
  Header: <><h6>Rental </h6>
             <h6>From</h6>
          </>,
  accessor: "rental_from",
  Cell: renderDate,
   sortable:true,
      
   width:100

},
{
  Header: <><h6>Rental </h6>
             <h6>To</h6>
          </>,
  accessor: "rental_to",
  Cell: renderDate,
   sortable:true,
      
   width:100

},
{
  Header: <><h6>Rental </h6>
             <h6>Status</h6>
          </>,
  accessor: "rental_device_status",
  Cell: renderTable,
   sortable:true,
    filterable:true,
   filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                      return true;
                    }
                    if (filter.value === "pending") {
                      return row[filter.id] === "pending";
                    }
                    if (filter.value === "packing") {
                      return row[filter.id]=== "packing";
                    }
                    
                     if (filter.value === "dispatched") {
                      return row[filter.id] === "dispatched";
                    }
                     if (filter.value === "delivered") {
                      return row[filter.id] === "delivered";
                    }
                    if (filter.value === "u_returned") {
                      return row[filter.id] === "u_returned";
                    }
                     if (filter.value === "u_lost") {
                      return row[filter.id] === "u_lost";
                    }
                    
                  },
                  Filter: ({ filter, onChange }) =>
                    <select
                      onChange={event => onChange(event.target.value)}
                      style={{ width: "100%" }}
                      value={filter ? filter.value : "all"}
                    >
                      <option value="all">All</option>
                       <option value="pending">Pending</option>
                      <option value="packing">Packing</option>
                      <option value="dispatched">Dispatched</option>
                      <option value="delivered">Delivered</option>
                      <option value="u_returned">User-Returned</option>
                      <option value="u_lost">User-Lost</option>
                      
                    </select>,
                   
              
   width:140

},*/
    {
      Header: "Save ",
      Cell: (props) => {
        return (
          <button
            className="btn btn-primary btn-col btn-square btn-smallest"
            onClick={() => {
              saveChanges(props.index);
            }}
          >
            Save{" "}
          </button>
        );
      },
      sortable: false,
      filterable: false,
      minWidth: 50,
      width: 80,
    },
  ];

  const displayTable = (
    <ReactTable
      columns={columns}
      data={tableData}
      defaultPageSize={50}
      className="service-req-table"
      resizable
    ></ReactTable>
  );

  const handleBack = (e) => {
    history.goBack();
  };
  return (
    <>
      <HospBdoNavbar />
      <div className=" flex-row-end">
        <div className=" text-left">
          <br />
          <button className="btn btn-info back-btn" onClick={handleBack}>
            {" "}
            <i className="fas fa-chevron-left back-btn-icon"></i>Back
          </button>
        </div>
        <div className=" text-right">
          <br />
          <button
            className="btn btn-info back-btn"
            onClick={() => history.push("/rental-history")}
          >
            {" "}
            Equipment History
            <i className="fas fa-chevron-right back-btn-icon"></i>
          </button>
        </div>
      </div>
      <div className="">
        <div className="d-flex justify-content-center">
          <h2 className="title-of-tasks"> Equipment Fulfillment</h2>
          <button
            className="btn btn-info back-btn rental-btn-align"
            onClick={() => history.push("/rental-details")}
          >
            {" "}
            Equipment Tracking
            <i className="fas fa-chevron-right back-btn-icon"></i>
          </button>
        </div>
        <br />

        <div className="text-center">
          <div className="service-table-holder flex-col"> {displayTable}</div>
        </div>
      </div>
      <br />
      <br />

      <br />
      <br />
      <div className="info-notes-sr flex-row">
        <div className="info-div italics-text">
          <h6 className="">
            {" "}
            - Status: New, ongoing, accepted , pending and cancelled requests
            are listed here{" "}
          </h6>
          <h6 className="">
            {" "}
            - Completed, Cancelled and Rejected status requests are displayed in
            Rental History table in{" "}
            <Link to="/careplans-db">Care plans Dashboard</Link>{" "}
          </h6>
          <h6 className="">
            {" "}
            - Click on column header/title to sort the respective column in
            table{" "}
          </h6>
          <h6 className="">
            {" "}
            - Filter the result by searching in the input field at each column
            header{" "}
          </h6>
          <h6 className="">
            {" "}
            - Multisorting and multifiltering can be done at multiple columns{" "}
          </h6>
        </div>
        <div className="info-div info-div-2">
          <h6 className="bold-text">Status: </h6>
          <span>
            {" "}
            <span className="new-status-2">Nw </span> : New{" "}
          </span>
          <span>
            {" "}
            <span className="accepted-status-2">Ac </span> : Accepted{" "}
          </span>
          <span>
            {" "}
            <span className="ongoing-status-2">On </span> : Ongoing
          </span>
          <span>
            {" "}
            <span className="pending-status-2">Pen </span> : Pending{" "}
          </span>
          <span>
            {" "}
            <span className="cancelled-status-2">Can </span> : Cancelled{" "}
          </span>
          <span>
            {" "}
            <span className="completed-status-2">Com </span> : Completed{" "}
          </span>
          <span>
            {" "}
            <span className="rejected-status-2">Rej </span> : Rejected{" "}
          </span>
        </div>

        <div className="info-div info-div-2">
          <h6 className="bold-text"> Priority : </h6>
          <span>
            {" "}
            <h6>
              <span className="critical-priority-2"> Cr </span> : Critical{" "}
            </h6>
          </span>
          <span>
            {" "}
            <h6>
              {" "}
              <span className="very-high-priority-2">V.h </span> : Very High{" "}
            </h6>{" "}
          </span>
          <span>
            {" "}
            <h6>
              {" "}
              <span className="high-priority-2"> Hi </span> : High{" "}
            </h6>
          </span>
          <span>
            {" "}
            <h6>
              {" "}
              <span className="medium-priority-2"> Md </span> : Medium
            </h6>{" "}
          </span>
          <span>
            {" "}
            <h6>
              {" "}
              <span className="low-priority-2"> Lw </span> : Low{" "}
            </h6>
          </span>
        </div>
      </div>

      {errorMsg !== "" ? (
        <SubmitErrorPopup
          show={errorSubmit}
          onHide={() => {
            setErrorSubmit(false);
            setErrorMsg("");
            setTableData([]);
            setRerender(!reRender);
          }}
        />
      ) : (
        ""
      )}

      {modalShow ? (
        <AddPopup
          show={modalShow}
          onHide={() => {
            setModalShow(false);
          }}
        />
      ) : null}

      {editShow ? (
        <EditPopup
          show={editShow}
          onHide={() => {
            setEditShow(false);
          }}
          onSave={() => {
            if (sendData !== tableData[cell].remarks) {
              const data = tableData;
              data[cell].remarks = sendData;
              setTableData(data);
              setBackup(sendData);
              setEditShow(false);
              setRender(!render);
              setUpdateSuccess(true);
              //saveChanges(cell)
            }
          }}
        />
      ) : null}

      {submitMsg !== "" ? (
        <SuccessPopup
          show={successSubmit}
          msg={submitMsg}
          onHide={() => {
            setSuccessSubmit(false);
            setSubmitMsg("");
            setTableData([]);
            setRerender(!reRender);
          }}
        />
      ) : (
        ""
      )}

      {equipmentShow ? (
        <EquipmentPopup
          show={equipmentShow}
          propsEquipment={equipmentData}
          onHide={() => {
            setEquipmentShow(false);
          }}
        />
      ) : null}

      {updateSuccess ? (
        <UpdatePopup
          show={updateSuccess}
          onHide={() => {
            setUpdateSuccess(false);
          }}
        />
      ) : null}
    </>
  );
}

export default RentalRequests;
