import React,{useState} from "react";
import "./revokesuspension.css";
import axios from 'axios';
import { Link } from "react-router-dom";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import HospitalNavbar  from "../HospitalNavbar/HospitalNavbar";
import {APIURL} from "../../Global";

function SubmitPopUp(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Sucessfully revoked suspension!!</h4>
       
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>Close</Button>
        

        <Link to="/sda-list"><Button className='btn btn-primary btn-col' > Goto SDA List </Button></Link>

       
      </Modal.Footer>
    </Modal>
  );
}

function HospitalSdaRevokeSuspension(props) {

 const [modalShow, setModalShow] = useState(false);
  const [submitMsg, setSubmitMsg]= useState("");
   const id = props.match.params.id;

  
   const handleSubmit=(e)=>{
   	e.preventDefault();

   	const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); 

   
  const data = {
  	is_suspended : false
  }
  
    axios.put(`${APIURL}/api/v1/hospital/sda/${id}/`, data, {
        headers: { 'Content-Type': 'application/json',
                     Authorization: "Token " + v2},
      })
      .then((res) => {
        if (res.data.status === "success") {
           setModalShow(true);
         

          
        } else {
          setSubmitMsg("error");
        }
      })
      .catch((err) => {
        
          setSubmitMsg("error");
      });
  }

   
 return( 
 <>
 <HospitalNavbar />

 <div className="liner">

  </div>
    <div className="container">
       <form className="create-app-user-form col-lg-6" onSubmit={handleSubmit}>
       <h4 className="title-of-page"> Revoke Suspension </h4><br/>
       <h6> Please Confirm to Authorize/ Revoke suspension of SDA ID {id}   </h6><br/>
       


  <div className="form-group ">
         <button type="button" className="btn btn-danger btn-cancel"> <Link to="/sda-list" className="cancel-btn">Cancel </Link> </button> 
          <button type="submit" className="btn btn-primary btn-col">Confirm </button>
  </div>







       </form>

        <SubmitPopUp
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      {submitMsg=== "error" ?
       <h5 style={{ color: "red" }}>
            
            Error in data submission.Please try again!
          </h5>
      :
      null}

    </div>
 </>
);
}
export default HospitalSdaRevokeSuspension;