import React, { useState, useEffect } from "react";
import axios from "axios";
import { APIURL } from "../../Global";
import "./footer.css";
import logo from "../../images/felixacare/logo_512.png";
import { useTranslation } from "react-i18next";

function Footer() {
  const [backend, setBackend] = useState(null);
  const [webapp, setWebapp] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    axios
      .get(`${APIURL}/api/v1/account/app-config/`)
      .then((res) => {
        if (res.data.status === "success") {
          setBackend(res.data.backend_version);
          setWebapp(res.data.webapp_version);
        } else {
        }
      })
      .catch((err) => {});
  }, []);
  return (
    <footer>
      <div className="flex-row footer-row">
        <div className="flex-column-footer">
          <h6 className="">{t("Company")} </h6>
          <h6 className="text-muted">IntPurple Technologies LLP </h6>
          <h6 className="text-muted">
            A-307, UNITED CROSSANDRA. OPPO HORAMAVU LAKE.
          </h6>
          <h6 className="text-muted"> HORAMAVU AGARA. BANGALORE. </h6>
          <h6 className="text-muted"> KARNATAKA. 560043. INDIA</h6>
          <h6 className="text-muted">
            {" "}
            {t("Innovation | Software | Mobile App")}
          </h6>
          <a
            href="https://www.intpurple.com"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="website link"
          >
            <h6 className=""> www.intpurple.com </h6>
          </a>
          <h6 className="text-muted">
            <a
              href="mailto:Contact@IntPurple.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="mail id"
            >
              <i className="fas fa-envelope "></i>
            </a>{" "}
            Contact@IntPurple.com{" "}
          </h6>
          <a
            href="https://intpurple.com/felixacare-terms-and-conditions/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Terms and Conditions link"
            style={{ color: "var(--theme-blue)" }}
          >
            <h6 className=""> {t("Terms and Conditions")} </h6>
          </a>
          <a
            href="https://intpurple.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Privacy Policy link"
            style={{ color: "var(--theme-blue)" }}
          >
            <h6 className=""> {t("Privacy Policy")} </h6>
          </a>
        </div>
        <div className="flex-column-footer">
          <h6 className="">{t("Services")} </h6>
          <h6 className="text-muted"> {t("Care Packages")}</h6>
          <h6 className="text-muted"> {t("Health Monitoring")} </h6>
          <h6 className="text-muted"> {t("Medication Tracking")} </h6>
          <h6 className="text-muted"> {t("Doctor Connect")} </h6>
          <h6></h6>
          <h6 className="divider-lineSection"></h6>
          <h6></h6>
          <a
            href="https://analytics.felixacare.com/"
            target="_blank"
            aria-label="reporting tool link"
          >
            {t("Reporting Tool")}
          </a>
        </div>

        <div className="flex-column-footer">
          <h6 className=""> {t("Connects")} </h6>
          <h6 className="text-muted"> {t("Doctors")}</h6>
          <h6 className="text-muted"> {t("Hospitals")}</h6>
          <h6 className="text-muted"> {t("Patients")} </h6>
          <h6 className="text-muted"> {t("Caregivers")} </h6>
        </div>
        <div className="flex-column-footer">
          <a
            href="https://felixacare.com"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="logo- website link"
          >
            <img src={logo} className="logo-footer" alt="felixacare" />
          </a>
          <a
            href="https://felixacare.com"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="website link"
          >
            <h6 className=" logo-footer-url"> www.felixacare.com </h6>
          </a>
          <h6 className="">{t("Contact")} </h6>
          <h6 className="text-muted">
            <a
              href="tel:+91 75610 57363"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="contact 1"
            >
              <i className="fa fa-mobile mob-icon" aria-hidden="true"></i>
              <span className="mob"> +91 75610 57363 </span>
            </a>
            <br />
            <a
              href="tel:+91 9731066055"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="contact number 2"
            >
              <span className="mob2"> +91 9731066055 </span>
            </a>
          </h6>
          <h6 className="text-muted">
            <a
              href="mailto:felixacare@IntPurple.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="mail id"
            >
              <i className="fas fa-envelope "></i>
            </a>
            &nbsp;&nbsp;felixacare@IntPurple.com
          </h6>
        </div>
      </div>

      <div className="divider-line"></div>

      <div></div>
      <div className="flex-row footer-icons-row">
        <a
          aria-label="googleplay"
          href="https://play.google.com/store/apps/details?id=intp.purpc&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            alt="Get it on Google Play"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
            className="google-play"
          />
        </a>
        <a
          href="https://apps.apple.com/us/app/felixacare/id1583237260?itsct=apps_box_badge&amp;itscg=30200"
          target="_blank"
          className="app-store"
        >
          <img
            src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1634601600&h=f4b04e2a29dab2e21d7eeb8d8be72139"
            alt="Download on the App Store"
            className="app-store2"
          />
        </a>
        <a
          aria-label="logo - website link"
          href="https://felixacare.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={logo}
            className="logo-footer logo-footer-2"
            alt="felixacare"
          />
        </a>
        <a
          aria-label="linkedin"
          href="https://www.linkedin.com/company/intpurple/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-linkedin social-media-icons"></i>
        </a>

        <a
          aria-label="mail id"
          href="mailto:Contact@IntPurple.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fas fa-envelope social-media-icons"></i>
        </a>

        <a
          aria-label="fb"
          href="https://www.facebook.com/IntPurpleTech"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i
            className="fab fa-facebook-square social-media-icons"
            aria-hidden="true"
          ></i>
        </a>
        <a
          href="https://www.intpurple.com"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="website"
        >
          <i className="fa fa-globe social-media-icons"></i>
        </a>
      </div>

      <div className="flex-row copy-info">
        <p> {t("Copyright")} &copy; 2022 IntPurple Technologies LLP. </p>
      </div>
      <div className="flex-version-end">
        {backend !== null && webapp !== null ? (
          <h6 className="text-muted version-info">
            {" "}
            Ver: F{backend}/W{webapp}
          </h6>
        ) : null}
      </div>
    </footer>
  );
}

export default Footer;
