import React ,{useState, useEffect} from "react";
import { NavLink } from "react-router-dom";
import "./pharmacynavbar.css";

function PharmacyNavbar() {

 
  useEffect(()=>{

  },[])


  return (
    <nav className="pharmacy-navbar">
      <div className="btns">
          <button className="btn nav-btn">
          <NavLink exact className="inactive" activeClassName="active" to="/pharmacy">  <i className="fas fa-home"></i> HOME</NavLink>
        </button>
      </div>
    </nav>
  );
}

export default PharmacyNavbar;
