import React,{useState,useEffect} from "react";
import {Link,useHistory} from "react-router-dom";
import "./care.css";
import HospBdoNavbar from "../HospBdoNavbar/HospBdoNavbar";
//import {BdoLoginContext} from "../../contexts/BdoLoginContext";
import axios from "axios";
import {APIURL} from "../../Global";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';

import Select from 'react-select';
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import {ToggleButton} from 'react-bootstrap';
import {ToggleButtonGroup} from 'react-bootstrap';


function AddCareProgram() {
  
    //const [bdoData,] =useContext(BdoLoginContext);
    //const [,setShowAddUser]=useState(false);

   
      /* const [bdo_name,]= useState(bdoData ? bdoData.name : null);
    const doctor_specializations = hospBdoData ? hospBdoData.doctor_specializations : null; 
   const [department,]  = useState(bdoData ? bdoData.department : null);
   const [Id,] = useState(bdoData ? bdoData.user_id : null);
   const [hospitalName,]= useState(bdoData ? bdoData.hospital_username: null);
   const specializations = hospBdoData ? hospBdoData.specializations : null;
   const [location,] = useState(bdoData ? bdoData.location: null); */
   const [title,setTitle]=useState();
   const [price,setPrice]=useState();
   const [discount,setDiscount]=useState();
   const [tax,setTax]=useState();
 const [currency,setCurrency]=useState();
 const [pricingType,setPricingType]=useState();

 const [offerText,setOfferText]=useState("");
      const [,setGetError]=useState('');
   const [,setCommonFeatures]=useState([]);
   const [,setCommonFeaturesActive]=useState([]);
   const [featuresList,setFeaturesList]=useState([]);
   const [rentalList, setRentalList]= useState([])
   const[category, setCategory]= useState(1)
      const [,setFeaturesToValidate]=useState([]);
   const [refresh,setRefresh]=useState(false);
   const [render, setRender]= useState(false)
   /*const [customFeatures,setCustomFeatures]=useState([...new Array(5)].map(()=>({
      title: "",
      limit: "",
      is_default:false,
      extra_cost:""
   }))); */
   //const [count,setCount]=useState(20);
     const [error,setError]=useState({
    title:"",
    price: '',
    discount:"",
    currency: "",
    pricingType:"",
    tax:"",
    submit:"",
    features:""
   });
   const [errorMsg,setErrorMsg]=useState("");
   const [submitMsg,setSubmitMsg] = useState("");
 const [successSubmit,setSuccessSubmit]=useState(false);
    const [errorSubmit,setErrorSubmit]=useState(false);
    const [reRender,setReRender]=useState(false);
    const [toggle, setToggle]= useState(false)

const [errorLog,setErrorLog]=useState("");
 const [errorStatus,setErrorStatus]=useState(false);
   let history=useHistory();
   
   const [keys,] = useState({'u': false,'c': false,'n': false,'a': false,'s': false,'q': false,'l': false,});


   useEffect(()=>{
   
     window.onkeydown =(e) => {
       
       keys[e.key]=true
   
       if(e.altKey && keys['u']){
         history.push('/directory')
       }
       if(e.altKey && keys['c']){
         history.push('/careplans-db')
       }
       if(e.altKey && keys['n']){
         history.push('/notifications')
       }
       if(e.altKey && keys['a']){
         history.push('/analytics')
       }
       if(e.altKey && keys['s']){
         history.push('/service-requests')
       }
       if(e.altKey && keys['q']){
         history.push('/qrcode')
       }
       if(e.altKey && keys['l']){
         history.push('/call-log')
       }
   
   
     }
     window.onkeyup=(ev)=> {
       
       keys[ev.key]= false
     }
   
     return()=>{
       window.onkeydown=null
       window.onkeyup=null
     }
   },[]);

   
   let featuresTable=null;

useEffect(() => { window.scrollTo(0, 0) }, [])
   useEffect(()=>{

    featuresTable=<ReactTable 
         columns={columnsFeatures}
         data={category===1?featuresList : rentalList}
         pageSize={category === 1 ? featuresList.length : category === 2 ? rentalList.length : 10}
         resizable
        className="features-reacttable"    

            showPagination={false}          
                 
         >

         </ReactTable> 

   },[refresh,featuresList,category])


   useEffect(()=>{

    const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;


  
    axios.get(`${APIURL}/api/v1/hospital/care-programs/common-features/`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
     

   
   
      if(res.data.status==="success"){

       
let dataList=res.data.features;
        const newDataList= dataList.length>0 ? dataList.filter(item => {
          return item.service_type !== "rental"
        }).map((item)=>{
          return {
            title2:item.title2,
            id:item.id,
            is_active:true,
            on_request_service:item.on_request_service,
            service_type:item.service_type,
            service_unit:item.service_unit
          }
        }) : null;

        const rentalData= dataList.length>0 ? dataList.filter( item => {
            return item.service_type === "rental"
        }).map((item)=>{
          return {
            title2:item.title2,
            id:item.id,
            is_active:false,
            on_request_service:item.on_request_service,
            service_type:item.service_type,
            service_unit:item.service_unit
          }
        }) : null;

/********___________________*TO  BE UPDATED in backend -- extra cost________________*/
    /*    
  setCommonFeatures(res.data.features);
  setCommonFeaturesActive(res.data.features);
   setFeaturesList(res.data.features);
   */
   
   setCommonFeatures(newDataList);
   setCommonFeaturesActive(newDataList);
   setFeaturesList(newDataList);
   setRentalList(rentalData)
      }
        else{
        setGetError(res.data.message);
      }
      


    })
    .catch(err=>{

 setGetError("Error in fetching data");
})


},[reRender]);

const currencyOptions = [{value: 'inr' , label: 'INR'},
       { value: 'usd' , label: 'USD'}
            
    ];

  const pricingOptions = [ {value: 'one_month' , label: '1 month (Monthly)'},
                           {value: 'three_months' , label: '3 months (Qaurterly)'},
                          {value: 'six_months' , label: '6 months (Half-yearly)'},
                          {value: 'one_year' , label: '1 year (Yearly)'},
                          {value: 'life_time' , label: 'Life time'}
      
       
    ];

   
    const handleChangeRadio=(val)=>{
      setCategory(val);
     }

const SuccessPopup=(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header >
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <h5 style={{color:"green"}}>{submitMsg} </h5>
        
        
     
       
      </Modal.Body>
      <Modal.Footer>
         
         <Link to="/care-programs"><Button className='btn btn-primary btn-col'>Go to Care Packages list</Button></Link>

         

       
       

      </Modal.Footer>
    </Modal>
  );
}

const SubmitErrorPopup=(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 style={{color: "red"}}> {errorMsg!=='' ? errorMsg : "Error in Submission. Please try again!" } </h5>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Ok</Button>

       
       

      </Modal.Footer>
    </Modal>
  );
}

const ErrorPopup=(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span style={{color: "orange"}}> Please note that only <span style={{color:"var(--theme-blue)"}}> INR</span> is supported as currency at the moment.<br/> <br/>
        Kindly contact us at 
               <a href="mailto:Contact@IntPurple.com" target="_blank" rel="noopener noreferrer" aria-label="mail id"> Contact@IntPurple.com </a> 
     if you need any support. </span>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Ok</Button>

       
       

      </Modal.Footer>
    </Modal>
  );
}
const handleTitleChange=(e)=>{
  e.preventDefault();
  setTitle(e.target.value);
}

const handleDiscountChange=(e)=>{
  e.preventDefault();
  setDiscount(e.target.value);
}
const handleTaxChange=(e)=>{
  e.preventDefault();
  const val =e.target.value

  if(val.length<=3){
    setTax(val)
  } else{
    return false;
} 
}


const handleStandardPriceChange=(e)=>{
  e.preventDefault();
  setPrice(e.target.value);
}


const handleCurrencyChange=(data)=>{

if(data.value!=="inr"){
  setErrorLog("errorlog")
  setErrorStatus(true)
  setCurrency("")

}
else{
setCurrency(data);
}
}
const handlePricingChange=(data)=>{
  
  setPricingType(data);
}
const handleOfferTextChange=(e)=>{
  e.preventDefault();
  setOfferText(e.target.value);
}


const validate=()=>{
  let isValid = true;
  let errors = {};
setError({});

if(title==="" || title===null || title===undefined){
  isValid = false;
  errors["title"] = "Please enter Title";
}
if(price==="" || price===null || price===undefined){
  isValid = false;
  errors["price"] = "Please enter Price";
}
if(price!=="" && price!==null && price!==undefined && price<0){
  isValid = false;
  errors["price"] = "Please enter a valid Price";
}
if(discount==="" || discount===null || discount===undefined){
  isValid = false;
  errors["discount"] = "Please enter Discounted price";
}
if(discount!=="" && discount!==null && discount!==undefined && discount<0){
  isValid = false;
  errors["discount"] = "Please enter a valid Discounted price";
}
if(discount!=="" && discount!==null && discount!==undefined && parseInt(discount)>parseInt(price)){
  isValid = false;
  errors["discount"] = "Discounted price can't be greater than standard price";
}
if(currency==="" || currency===null || currency===undefined){
  isValid = false;
  errors["currency"] = "Please enter Currency";
}
if(pricingType==="" || pricingType===null || pricingType===undefined){
  isValid = false;
  errors["pricingType"] = "Please enter Price plan";
}
if(tax!==null && tax!==undefined){
  if(tax<0 || tax>100){
  isValid = false;
  errors["tax"] = "Tax % should be within 0-100 range";
}
}
if(isValid===false){
 errors["submit"] = "Error in submission!";
}

 setError(currentstate=>({
            ...currentstate,
            ...errors
          }));

      

      return isValid;

}

const validate2=(featuresToSend)=>{
  let isValid = true;
  let errors = {};
  setError({});
  
  
 let activelist= featuresToSend.filter((item,index)=>{
  return (item.is_active===true)
 })
if(activelist.length<=0){
     isValid = false;
  errors["features"] = `Minimum one feature is mandatory to create care plan `;
  
  }
  featuresToSend.reverse().map((item,index)=>{
      if(item.is_active===true){
    if((item.limit===null || item.limit==="" || item.limit===undefined) && (item.extra_cost===null || item.extra_cost==="" || item.extra_cost===undefined) ){
  isValid = false;
  errors["features"] = `Please enter *Free limit & *Extra price for feature ID ${featuresToSend.length - (index)}. (Or, click on Disable button to disable the feature)`;
  
}  
 if(item.limit==="0" && item.extra_cost==="0"){
  isValid = false;
  errors["features"] = `Free usage and Extra cost cannot be zero for feature ID ${featuresToSend.length - (index)}. (Or, click on Disable button to disable the feature)`;
  
}
if(item.extra_cost==="0"){
  isValid = false;
  errors["features"] = `Extra cost cannot be zero at feature ID ${featuresToSend.length - (index)}. `;
  
}
   
 if(item.extra_cost===null || item.extra_cost==="" || item.extra_cost===undefined){
  isValid = false;
  errors["features"] = `Please enter *Extra price for feature ID ${featuresToSend.length - (index)}. (Or, click on Disable button to disable the feature)`;
  
}
 if(item.extra_cost!==null && item.extra_cost!=="" && item.extra_cost<0){
  isValid = false;
  errors["features"] = `Please enter valid *Extra price for feature ID ${featuresToSend.length - (index)}. (Or, click on Disable button to disable the feature)`;
  
}
 if(item.limit===null || item.limit==="" || item.limit===undefined){
  isValid = false;
  errors["features"] = `Please enter *Free limit for feature ID ${featuresToSend.length - (index)}. (Or, click on Disable button to disable the feature)`;
  
}
  if(item.limit!==null && item.limit!=="" && item.limit<0){
  isValid = false;
  errors["features"] = `Please enter valid *Free limit for feature ID ${featuresToSend.length - (index)} . (Or, click on Disable button to disable the feature)`;
  
}
}
})
 



 setError(currentstate=>({
            ...currentstate,
            ...errors
          }));

      

      return isValid;

}


  const handleSubmitFeatures=(e)=>{
  e.preventDefault();

    const features = [...featuresList, ...rentalList]

  if(validate()){
    
try{

const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;


/**********

  let featuresToSend=featuresList.length>0 ? featuresList.filter((item,index)=>{
  return !(item.title==="" && item.limit==="" && item.extra_cost==="")
}) : [];
*******/

let featuresToSend=features.length>0 ? features.map((item,index)=>{
  if(item.is_active===true){
  return {
            feature:item.id,
            limit:item.limit,
            extra_cost:item.extra_cost,
            is_active:item.is_active
            
          }
        }
        else{
           return {
            feature:item.id,
            limit:"",
            extra_cost:"",
            is_active:false
            
          }
        }
}) : [];

setFeaturesToValidate(featuresToSend);
if(validate2(featuresToSend)){
let featuresToSendActiveOnly=featuresToSend.length>0 ? featuresToSend.filter((item,index)=>{
  return (item.is_active===true)
  
        
}) : [];
setToggle(true)
let dataToSend={
  title:  title,
  price:price,
  discounted_price:discount,
  currency: currency.value,
  tax_percentage: tax,
  pricing_type:pricingType.value,
  description: offerText? offerText: "Nil",
  features: featuresToSend.reverse()
}
  
    axios.post(`${APIURL}/api/v1/hospital/care-programs/`, dataToSend, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
     

   
   
      if(res.data.status==="success"){


      setSubmitMsg(res.data.message? res.data.message : "Care Package added successfully!");

          setSuccessSubmit(true);
         
   

      } else if(res.data.status==="error"){

        setToggle(false)
        setErrorSubmit(true);
                        setErrorMsg(res.data.message ? res.data.message : 'Error in submission!');
      }

        else{
        setToggle(false)
        setErrorSubmit(true);
                        setErrorMsg('Error in submission!');
      }
      


    })
    .catch(err=>{

    setToggle(false)
        setErrorSubmit(true);
                        setErrorMsg('Error in submission!');
})
}
}
catch(err){

   
        setErrorSubmit(true);
                        setErrorMsg('Error in submission!');
}
}



  
  }
   
 /* const handleAdd=(e)=>{
 e.preventDefault();

  }
  const handleAddUser=(e)=>{
  e.preventDefault();
  setShowAddUser(true);
  }
  const handleCancel=(e)=>{
  e.preventDefault();
  setShowAddUser(false);
  } */


/*const deleteRow=(i)=>{

const updatedList= featuresList.filter((item,index)=>{
  
 return i!==index;

   
 });

setFeaturesList(updatedList);
setRefresh(!refresh);
}
*/

/*
const handleOnChange=(e , cellInfo)=>{

      e.preventDefault();
      let list= featuresList;

  list[cellInfo.index][cellInfo.column.id] =e.target.value;
  setFeaturesList(list);
 

  }

*/

useEffect(()=>{
  setRentalList(rentalList)
},[rentalList,render])

useEffect(()=> {
  setFeaturesList(featuresList)
},[featuresList,render])

const disableFeature=i=>{

let list=featuresList;
  list[i].is_active=false;
  
  setFeaturesList(list);
  setRefresh(!refresh);
  

}

const enableFeature=i=>{

let list=featuresList;
  list[i].is_active=true;
 
  setFeaturesList(list);
  setRefresh(!refresh);

}

const disableRentalFeature=i=>{

  let list=rentalList;
    list[i].is_active=false;
    
    setRentalList(list);
    setRefresh(!refresh);
    
  
  }
  
  const enableRentalFeature=i=>{
  
  let list=rentalList;
    list[i].is_active=true;
   
    setRentalList(list);
    setRefresh(!refresh);
  
  }
  
  
const renderDisable=(cellInfo)=> {

  if(category === 1){
    if(featuresList[cellInfo.index].is_active===true){
      return (
    
     <button className="btn btn-danger btn-smallest btn-square" 
        onClick={()=>{disableFeature(cellInfo.index)}}> Disable </button>
     
     )
   }
   else if(featuresList[cellInfo.index].is_active===false){
     return (
    
     <button className="btn btn-primary btn-col btn-smallest btn-square" 
        onClick={()=>{enableFeature(cellInfo.index)}}> Enable </button>
     
     )
   }
  }
 
else if(category === 2){
  if(rentalList[cellInfo.index].is_active===true){
    return (
  
   <button className="btn btn-danger btn-smallest btn-square" 
      onClick={()=>{disableRentalFeature(cellInfo.index)}}> Disable </button>
   
   )
 }
 else if(rentalList[cellInfo.index].is_active===false){
   return (
  
   <button className="btn btn-primary btn-col btn-smallest btn-square" 
      onClick={()=>{enableRentalFeature(cellInfo.index)}}> Enable </button>
   
   )
 }
}
else {
  return null
}
 
}
const renderExtraCost=(cellInfo)=> {

  if(category === 1){
    if(featuresList[cellInfo.index].is_active===true){
      return (<>
 
    <input className="form-control" type="number" min={0}  name={cellInfo.column.id} 
     value={featuresList[cellInfo.index].extra_cost=== undefined ? '' : featuresList[cellInfo.index].extra_cost}
    onChange={(e)=>{
       e.preventDefault();
      let list= featuresList;
 
  list[cellInfo.index].extra_cost=e.target.value;
  setFeaturesList(list);
  setRender(!render)
 
    }
  }
  />
 
    </>
    )
  
  }
   else{
     return (<>
 
 
    <div className="bkgrnd-grey"><h6> N/A </h6></div>
      </>
    )
  }
  }
   
  else if(category === 2) {
    
    if(rentalList[cellInfo.index].is_active===true){
      return (<>
 
    <input className="form-control" type="number" min={0}  name={cellInfo.column.id} 
     value={rentalList[cellInfo.index].extra_cost=== undefined ? '' : rentalList[cellInfo.index].extra_cost}
    onChange={(e)=>{
       e.preventDefault();
      let list= rentalList;
 
  list[cellInfo.index].extra_cost=e.target.value;
  setRentalList(list);
  setRender(!render)
    }
  }
  />
 
    </>
    )
  
  }
   else{
     return (<>
 
 
    <div className="bkgrnd-grey"><h6> N/A </h6></div>
      </>
    )
  }
  }
  else {
    return null
  }
 }

 const renderLimit=(cellInfo)=> {

  if(category === 1){

    if(featuresList[cellInfo.index].is_active===true){
    
      return (<div className="flex-row flex-row-start flex-row-align-center">
 
    <input className="form-control free-usage-input" type="number" min={0}  name={cellInfo.column.id} 
     value={featuresList[cellInfo.index].limit=== undefined ? '' : featuresList[cellInfo.index].limit}
    onChange={(e)=>{
       e.preventDefault();
      let list= featuresList;
 
  list[cellInfo.index].limit =e.target.value;
  setFeaturesList(list);
  setRender(!render)
    }
  }
  />
  <h6 className="caps">{ (featuresList[cellInfo.index].service_unit)!=="count" ? featuresList[cellInfo.index].service_unit : null}
 </h6>
    </div>
    )
  }
  else{
     return (<>
 
    <div className="bkgrnd-grey"><h6> N/A </h6></div>
      </>
    )
  }

  }

  else if(category === 2) {

    if(rentalList[cellInfo.index].is_active===true){
    
      return (<div className="flex-row flex-row-start flex-row-align-center">
 
    <input className="form-control free-usage-input" type="number" min={0}  name={cellInfo.column.id} 
     value={rentalList[cellInfo.index].limit=== undefined ? '' : rentalList[cellInfo.index].limit}
    onChange={(e)=>{
       e.preventDefault();
      let list= rentalList;
 
  list[cellInfo.index].limit =e.target.value;
  setRentalList(list);
  setRender(!render)
    }
  }
  />
  <h6 className="caps">{ (rentalList[cellInfo.index].service_unit)!=="count" ? rentalList[cellInfo.index].service_unit : null}
 </h6>
    </div>
    )
  }
  else{
     return (<>
 
    <div className="bkgrnd-grey"><h6> N/A </h6></div>
      </>
    )
  }

  }  
  else return null
 }

 const renderFeature=(cellInfo)=> {

  if(category === 1){

    if(featuresList[cellInfo.index].is_active===true){
      return (
   
        <div className="bkgrnd-white"> {featuresList[cellInfo.index][cellInfo.column.id] ? <h6 className="feature-rt-td">{featuresList[cellInfo.index][cellInfo.column.id]}</h6> : null }</div>
        
      );
    }
    else{
      return (
   
        <div > {featuresList[cellInfo.index][cellInfo.column.id] ? <h6 className="feature-rt-td">{featuresList[cellInfo.index][cellInfo.column.id]}</h6> : null }</div>
        
      );
   
    }
  }
  else if(category === 2) {
    if(rentalList[cellInfo.index].is_active===true){
      return (
   
        <div className="bkgrnd-white"> {rentalList[cellInfo.index][cellInfo.column.id] ? <h6 className="feature-rt-td">{rentalList[cellInfo.index][cellInfo.column.id]}</h6> : null }</div>
        
      );
    }
    else{
      return (
   
        <div > {rentalList[cellInfo.index][cellInfo.column.id] ? <h6 className="feature-rt-td">{rentalList[cellInfo.index][cellInfo.column.id]}</h6> : null }</div>
        
      );
   
    }
  }
  else return null
}

/*const renderService=(cellInfo)=> {
 if(featuresList[cellInfo.index].is_active===true){
   
  return (

   <div className="bkgrnd-white"> {featuresList[cellInfo.index][cellInfo.column.id]===true ? <h6 className="feature-rt-td">Yes</h6> :featuresList[cellInfo.index][cellInfo.column.id]===false? <h6 className="feature-rt-td">No</h6>: null }</div>
    
  );
}
else{
  return (

   <div > {featuresList[cellInfo.index][cellInfo.column.id]===true ? <h6 className="feature-rt-td">Yes</h6> :featuresList[cellInfo.index][cellInfo.column.id]===false? <h6 className="feature-rt-td">No</h6>: null }</div>
    
  );

}
}*/

const renderServiceType=(cellInfo)=> {

  if(category === 1){
    if(featuresList[cellInfo.index].is_active===true){
   
      return (
    
       <div className="caps">  {(featuresList[cellInfo.index][cellInfo.column.id]).replace("_"," ")}</div>
        
      );
    }
    else{
      return (
    
    
       <div className="caps"> {(featuresList[cellInfo.index][cellInfo.column.id]).replace("_"," ")}</div>
        
      );
    
    }
  }
else if(category === 2) {
  if(rentalList[cellInfo.index].is_active===true){
   
    return (
  
     <div className="caps">  {(rentalList[cellInfo.index][cellInfo.column.id]).replace("_"," ")}</div>
      
    );
  }
  else{
    return (
  
  
     <div className="caps"> {(rentalList[cellInfo.index][cellInfo.column.id]).replace("_"," ")}</div>
      
    );
  
  }
}
else return null
}


/*const renderUnit=(cellInfo)=> {
 if(featuresList[cellInfo.index].is_active===true){
   
  return (

   <div className="caps">  {(featuresList[cellInfo.index][cellInfo.column.id])}</div>
    
  );
}
else{
  return (


   <div className="caps"> {(featuresList[cellInfo.index][cellInfo.column.id])}</div>
    
  );

}
}*/
const renderFeatureId=(cellInfo)=> {

  if(category === 1){
    if(featuresList[cellInfo.index].is_active===true){
      return (
   
        <div className="bkgrnd-white"><h6 className="feature-rt-td">{cellInfo.index+1} </h6></div>
        
      );
    }
    else{
      return (
   
        <div > {cellInfo.index+1}</div>
        
      );
   
    }
  }
  else if(category === 2) {
    if(rentalList[cellInfo.index].is_active===true){
      return (
   
        <div className="bkgrnd-white"><h6 className="feature-rt-td">{cellInfo.index+1} </h6></div>
        
      );
    }
    else{
      return (
   
        <div > {cellInfo.index+1}</div>
        
      );
   
    }
  }
  else return null
}   
  
  
const columnsFeatures=[
{
  Header: "ID",
  accessor: "id",
  Cell: renderFeatureId,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
  
  minWidth:70,
   sortable:false

},
{
  Header: "Feature",
  accessor: "title2",
  Cell: renderFeature,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
  
  minWidth:280,
   sortable:false

},{
  Header: <div className="header-features-table"><h6>*Free usage</h6>
   <h6>(in unit service)</h6>
           </div>,
  accessor: "limit",
  Cell: renderLimit,
   sortable:false,
  minWidth:180

},
/****
{
  Header: <div className="header-features-table"><h6>Unit</h6>
           </div>,
  accessor: "service_unit",
  Cell: renderUnit,
   sortable:false,
  minWidth:160

},
***/
{
  Header: <div className="header-features-table"><h6>*Extra Price</h6>
          <h6>(for additional</h6>
          <h6> unit Service)</h6> </div>,

  accessor: "extra_cost",
  Cell: renderExtraCost,
   sortable:false,
   minWidth:120,
   

},
/*
{
  Header: "Service Request",
  accessor: "on_request_service",
  Cell: renderService,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
  
  width:150,
   sortable:false

},
*/
{
  Header: "Service Type",
  accessor: "service_type",
  Cell: renderServiceType,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
  
  width:150,
   sortable:false

},
{
  Header: <div className="header-features-table"><h6>Enable/</h6>
          <h6>Disable </h6> </div>,
  accessor: "enable",
  Cell:renderDisable, 
   sortable:false,
   filterable:false
   

}
]

  featuresTable=<ReactTable 
         columns={columnsFeatures}
         data={featuresList}
        pageSize={category === 1 ? featuresList.length : category === 2 ? rentalList.length : 10}
         resizable
        className="features-reacttable"    
             showPagination={false}          
                          
                 
         >

         </ReactTable> 


const handleBack=(e)=>{
   history.goBack();
}
  
  return (
    <> <HospBdoNavbar />
    


       <div className="hospital-dashboard-container hosp-bdo-page">
       <br/>
        
   <div className="flex-head">
          <button className="btn btn-info topleft-single-btn" onClick={handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back</button> 
          <h2 className="title-bdo title-mg"> Add Care Package</h2>
    </div> 
    <br/>

    <div className=" features-subs ">

  {/*******************details **********/}
   <div className="pgm-details-box">
    <div className="pgm-details-header box-theme">
                               

                      <h6 className="sub-section-title pgm-header-title"> Care Package Details </h6>
                      </div>
   <br/>
   <div className="pgm-details-body">
   <h6 className="features-form text-left">*Care Package Title</h6>
   <input type="text" name="title" maxLength="150" className="form-control" placeholder="Eg: Cardiac " onChange={handleTitleChange}/><br/>
    
   {error.title ? <div className="error-validation-msg"> <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> {error.title}</div> : null}
     <h6 className="features-form text-left">*Currency</h6>
      <Select
        value={currency}
        onChange={(data)=>{handleCurrencyChange(data)
            }}
        options={currencyOptions}
        className="select-currency"
      />
      {error.currency ? <div className="error-validation-msg"> <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> {error.currency}</div> : null}
   <br/>
    <h6 className="features-form text-left">*Standard Price</h6>
    <input type="number" maxLength="10" name="price" className="form-control" placeholder="Eg: 1000" onChange={handleStandardPriceChange}/><br/>

      {error.price ? <div className="error-validation-msg"> <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> {error.price}</div> : null}
      <h6 className="features-form text-left">*Price after discount</h6>
      <input type="number" maxLength="10" name="discount" className="form-control" placeholder="Eg: 800" onChange={handleDiscountChange} /><br/>
     {error.discount ? <div className="error-validation-msg"> <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>  {error.discount}</div> : null}
     
    <h6 className="features-form text-left">Tax Percentage</h6>
      <input type="number" name="tax" min={0} max={100} className="form-control" placeholder="Eg: 15" value={tax} onChange={handleTaxChange} /><br/>
     {error.tax ? <div className="error-validation-msg"> <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> {error.tax}</div> : null}

 <h6 className="features-form text-left">*Price plan</h6>
    <Select
        value={pricingType}
        onChange={(data)=>{handlePricingChange(data)
            }}
        options={pricingOptions}
        className="select-currency"
      />
      {error.pricingType ? <div className="error-validation-msg"> <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> {error.pricingType}</div> : null}
   <br/>

 {/* <input type="text" name="currency" className="form-control" placeholder="*Currency" onChange={handleCurrencyChange} />*/}
        <br/>
        <h6 className="features-form text-left"> Offer/Info Text</h6>
        <textarea  name="offerText" className="text-area offer-text-area" placeholder="Add here.."  rows="5" onChange={handleOfferTextChange} />
        <br/>
   </div>
   </div>
  

    <div className="">

    <div className='mb-5'>
      <ToggleButtonGroup type="radio" name="options" defaultValue={1} onChange={handleChangeRadio}>
    <ToggleButton id="tbg-radio-1" value={1} className="btn btn-light toggle-btns toggle-btn-1">
    Service
    </ToggleButton>
    <ToggleButton id="tbg-radio-2" value={2} className="btn btn-light toggle-btns toggle-btn-2">
    Equipment
    </ToggleButton>
      
  </ToggleButtonGroup>
  </div>

     {featuresTable}
     <br/>

      {error.features ? <div className="error-validation-msg"> <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> {error.features}</div> : null}

     <br/>
     <h6 style={{color:"grey"}}> ** By default, all features are 'Enabled'. Make sure to fill all fields in a row if Feature is enabled. </h6>
     <h6 style={{color:"orange"}}> ** To disable any feature , click on 'Disable' button .</h6>
     
     </div>

 </div>

<br/>
<br/>

    <button type="submit" id='btn' disabled={toggle===true} className="btn btn-primary btn-col" onClick={handleSubmitFeatures}>Submit Care Package</button>
 <br/>
 <br/>
 <div>
 {error.submit ? <h6 style={{color: "red"}} > <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> {error.submit}</h6> : null}
</div>

<br/>
<br/>
<br/>

{submitMsg !=="" ?
    <SuccessPopup
        show={successSubmit}
        msg={submitMsg}
        onHide={() => { setSuccessSubmit(false);
                        setSubmitMsg('');
                       setReRender(!reRender)}}
       
      />: ''}

 {errorMsg!=='' ?
    <SubmitErrorPopup
        show={errorSubmit}
        onHide={() =>  {setErrorSubmit(false);
                        setErrorMsg('');
                       setReRender(!reRender)}}
       
      />: ''
    }
    {errorLog!=='' ?
    <ErrorPopup
        show={errorStatus}
        onHide={() =>  {setErrorStatus(false);
                        setErrorLog('');
                       setReRender(!reRender)}}
       
      />: ''
    }
</div>

    </>
  );
}
export default AddCareProgram;
