import React, { useState } from 'react';
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import moment from "moment";
const PrescriptionTable = ({ prescdata }) => {

	const [purchased, setPurchased] = useState(false);
	// prescdata.till_date_formatted = moment(prescdata.till_date, "YYYY-MM-DD").format("DD-MM-YYYY");

	const columns = [
	// 	{
	// 	Header: <div className="header-features-table"></div>,
	// 	accessor: "select",
	// 	minWidth: 40
	// },
	{
		Header: ' Id',
		accessor: ' id',
		minWidth: 70
	},
	{
		Header: 'Drug',
		accessor: 'drug',
		minWidth: 120
	},
	{
		Header: 'Strength',
		accessor: 'strength',
		minWidth: 90
	},
	{
		Header: 'Form',
		accessor: 'form',
		minWidth: 80
	},
	{
		Header: 'Route',
		accessor: 'route'
	},
	{
		Header: ' Dosage',
		accessor: ' dosage',
		minWidth: 80
	},
	{
		Header: 'Frequency',
		accessor: 'frequency'
	}, {
		Header: 'Duration (Days)',
		accessor: 'duration'
	},
	{
		Header: 'Till Date',
		accessor: 'till_date'
	},
	{
		Header: 'Intake Count',
		accessor: 'intake_count',
		minWidth: 70
	},
	{
		Header: 'Intake Time',
		accessor: 'intake_time',
		minWidth: 80
	},
	{
		Header: 'Remarks',
		accessor: 'remarks',
		minWidth: 120
	}

	]

	const getPurchased = (purchased) => {
		return <div className="header-features-table"><input style={{ marginLeft: "-0.05rem", position: 'sticky' }}
			type="checkbox"
			checked={purchased}
			className="form-check-input"
			id="rowcheck{item.id}"
			onChange={() => setPurchased(!purchased)}
		/></div>;
	}

	const getPresCol = (items) => {
		return items.map(item => {
			return {
				// select: getPurchased(purchased),
				id: item.id,
				drug: item.drug,
				strength: item.strength,
				form: item.form,
				route: item.route,
				dosage: item.dosage,
				frequency: item.frequency,
				duration: item.duration,
				till_date: item.till_date,
				intake_count: item.intake_count,
				intake_time: item.intake_time,
				remarks: item.remarks
			}
		})
	}

	const data = getPresCol(prescdata);

	return (
		<div id="patient-presc-page" className="patient-presc-page" ><br />


			{/* <h6 className="text-right overlay-subtitle dr-title" style={{ paddingRight: "10px" }} >Doctor : <span style={{ color: "grey" }}> {prescdata.doctor_name}</span></h6> */}
			<h6 className="text-right overlay-subtitle hosp-title" style={{ paddingRight: "10px" }}>Hospital : <span style={{ color: "grey" }}> {prescdata[0].hospital_name}</span></h6>
			<h6 className="text-right overlay-subtitle" style={{ paddingRight: "10px" }}>Date: <span style={{ color: "grey" }}>{prescdata[0].date ? prescdata[0].date : "n/a"}</span> </h6>
			<h5 className="title-of-tasks"> Prescription {prescdata[0].prescription_id} </h5>
			<div className="patient-presc-sections patient-presc-sections2">
				<div className="table-holder">
					<div>
						<ReactTable
							data={data}
							columns={columns}
							defaultPageSize={5}
							pageSizeOptions={[2, 4, 6]}
						/>
					</div>
				</div>

			</div>

		</div>

	);
}


export default PrescriptionTable;