import React, {useState, useEffect} from 'react'
import axios from "axios";
import {APIURL} from "../../../Global";
import {useHistory} from 'react-router-dom';
import '../RegisteredDoctors/doctorprofile.css'
import {Card , Button ,Modal} from 'react-bootstrap'
import ProofImage from '../RegisteredDoctors/ProofImage'


function PatientDetail(props) {
    const [id ,setId] =useState(props.location.id);
    const [img ,setImg] =useState()
    const [name ,setName] =useState('');
    const [userName ,setUserName] =useState('');
    const [email ,setEmail]= useState('');
    const [gender ,setGender]= useState('');
    const [dob, setDob]= useState('');
    const [location ,setLocation] =useState('')
    const [country, setCountry]= useState('')
    const [habit ,setHabit] =useState('')
    const [exercise, setExercise]= useState('')
    const [height, setHeight]= useState('')
    const [weight, setWeight]= useState('')
    const [phn ,setPhn] =useState('')
    const [hsp ,setHsp] =useState('')
    const [imgShow ,setImgShow] =useState(false);
    const [photo ,setPhoto] =useState();
    const [modalShow ,setModalShow] =useState(false);

    const history =useHistory();

const [keys,] = useState({'h': false,'d': false,'u': false,'r': false,'l': false,'i': false,'a': false,'p': false,'v': false,'t': false,'n': false});

useEffect(()=> {
  window.scrollTo(0,0)
},[])
useEffect(()=>{

window.onkeydown =(e) => {
 
 keys[e.key]=true

 if(e.altKey && keys['h']){
   history.push('/hospitallist')
 }
 if(e.altKey && keys['d']){
   history.push('/doctorslist')
 }
 if(e.altKey && keys['u']){
   history.push('/search-hospitals')
 }
 if(e.altKey && keys['r']){
   history.push('/rejectedlist')
 }
 if(e.altKey && keys['l']){
   history.push('/reportlist')
 }
 if(e.altKey && keys['i']){
   history.push('/addsynonyms')
 }
 if(e.altKey && keys['a']){
   history.push('/failedlist')
 }
 if(e.altKey && keys['p']){
   history.push('/prescriptionlist')
 }
 if(e.altKey && keys['v']){
   history.push('/written-prescriptionlist')
 }
 if(e.altKey && keys['t']){
   history.push('/testlist')
 }
 if(e.altKey && keys['n']){
  history.push('/nutrition')
}

}
window.onkeyup=(ev)=> {
 
 keys[ev.key]= false
}


return()=>{
 window.onkeydown=null
 window.onkeyup=null
}
},[]);
const handleBack=(e)=>{
  history.goBack();
}

    useEffect(()=> {
        if(id===undefined){

        }
        else{
            sessionStorage.setItem('id',id)
        }        
    },[])

    
    
    useEffect(()=>{
        const id =sessionStorage.getItem('id')

        const tokenString = sessionStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
          );
          axios.get(`${APIURL}/api/v1/patient/user-details/?patient_id=${id}`, {
            headers: { Authorization: "Token " + v2 },
       })
       .then((res) =>{
           if(res.data.status==='success'){
               const data =res.data.health_details
               
               setPhoto(data.photo)
               setImg(data.id_proof_image)
               setName(data.name);
               setUserName(data.username)
               setEmail(data.email)
               setGender(data.gender)
               setDob(data.dob)
               setPhn(data.phone_number)
               setLocation(data.address)
               setCountry(data.country)
               const patData = []
               const comorb = data.comorbidities
               comorb.map(item=> {
                patData.push(item + ',')
               })
               setHabit(patData)
               setExercise(data.exercise_level)
               setHeight(data.height)    
               setWeight(data.weight)
               setHsp(data.anytime_hospitalized)
           }
           else if(res.data.status==='error'){
             setId('')
             setModalShow(true)

           }
       })
       .catch((err) => {

       })
    },[])

      const ImgPopUp = (props)=> {
        return(
          <Modal
              {...props}
              size='lg'
              aria-labelledby="contained-modal-title-vcenter"
              centered
               backdrop="static"
                keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                 
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
      
              <ProofImage image={img} />
               
              </Modal.Body>
              <Modal.Footer>
               
                 <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
        
              </Modal.Footer>
            </Modal>
          )
      }

      const NoDataPopUp = (props)=> {
        return(
          <Modal
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
               backdrop="static"
                keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                 
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
      
              <h4 id="confirm-reject">Patient Details not yet added</h4>
               
              </Modal.Body>
              <Modal.Footer>
               
                 <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
        
              </Modal.Footer>
            </Modal>
          )
      }

      


    return (
      <>
      <div className=" text-left">
      <br/>
        <button className="btn btn-info back-btn" onClick={handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back</button> 
        
  </div> 
        
    <div className="padding">
        
                <div className="card-patient user-card-full">
                <div className='patientcard-length'>    
                <Card className="card-patient" bg='info' border='success'>
  <Card.Img variant="top" src={photo} alt='Profile Picture' className="img-radius" />
  <Card.Body>
    <Card.Title style={{fontSize:'150%'}}><h4 className="title-of-tasks" >{name}</h4></Card.Title>
    <Card.Text >

    </Card.Text>
    
  </Card.Body>
</Card>
</div>
                        <div className="check-patient">
                            <div className="card-block">
                                <h6 className="m-b-20 p-b-5 b-b-default f-w-600 lead">Patient Details</h6>
                                <div className="row">
                                    <div className="col-sm-6">
                                      <p className="m-b-10 f-w-600 p-size text-left">ID :  <span className='text-info span-size'>{sessionStorage.getItem('id')}</span></p>    
                                        <p></p>   
                                    </div>
                                    <div className="col-sm-6">
                                      <p className="m-b-10 f-w-600 p-size text-left">Name : <span className='text-info span-size'>{name===null?'Not Available':name}</span></p>
                                    </div>                                   
                                    <div className="col-sm-6">
                                      <p className="m-b-10 f-w-600 p-size text-left">Username : <span className='text-info span-size'>{userName!==null ? userName :'Not Available' }</span> </p>
                                        <p></p>  
                                    </div>
                                    <div className="col-sm-6">
                                      <p className="m-b-10 f-w-600 p-size text-left">Email : <span className='text-info span-size'>{email===null?'Not Available':email}</span></p>
                                    </div>
                                    <div className="col-sm-6">
                                      <p className="m-b-10 f-w-600 p-size text-left">Gender : <span className='text-info span-size'>{gender===null?'Not Available':gender}</span></p>
                                      <p></p> 
                                    </div>
                                    <div className="col-sm-6">
                                      <p className="m-b-10 f-w-600 p-size text-left">DOB : <span className='text-info span-size'>{dob===null?'Not Available':dob}</span></p>
                                    </div>
                                    <div className="col-sm-6">
                                      <p className="m-b-10 f-w-600 p-size text-left">Location : <span className='text-info span-size'>{location===null && country!==null? country: location!==null && country!==null ? location +", "+ country : null }</span></p>
                                      <p></p> 
                                    </div>
                                    <div className="col-sm-6">
                                      <p className="m-b-10 f-w-600 p-size text-left">Mobile Number : <span className='text-info span-size'>{phn===null?'Not Available':phn}</span></p>
                                    </div>
                                    <div className="col-sm-6">
                                      <p className="m-b-10 f-w-600 p-size text-left">Height : <span className='text-info span-size'>{height===null?'Not Available':height+"cm"}</span></p>
                                      <p></p> 
                                    </div>
                                    <div className="col-sm-6">
                                      <p className="m-b-10 f-w-600 p-size text-left">Weight : <span className='text-info span-size'>{weight===null?'Not Available':weight+"kg"}</span></p>
                                    </div>
                                    <div className="col-sm-6">
                                      <p className="m-b-10 f-w-600 p-size text-left">Comorbidities : <span className='text-info span-size'>{habit===[] ?'Not Available':habit}</span></p>
                                      <p></p> 
                                    </div>
                                    <div className="col-sm-6">
                                      <p className="m-b-10 f-w-600 p-size text-left">Exercise Level : <span className='text-info span-size'>{exercise===null?'Not Available':exercise}</span></p>
                                    </div>

                                    <div className="col-sm-6">
                                    <p className="m-b-10 f-w-600 p-size text-left">Previously Hospitalized : <span className='text-info span-size'>{hsp===true? <span style={{display:'inline-block'}}>Yes</span> : hsp===false? <span style={{display:'inline-block'}}>No</span>:'Not Available'}</span></p>
                                        <p></p>
                                    </div>                                                                                                           
                                    
                                </div>                                
                            </div>                           
                        </div>
                    
                </div>

{
        imgShow ? 
        <ImgPopUp 
            show={imgShow}
            onHide={() => {setImgShow(false) }}/> : null
    }

{
        modalShow ? 
        <NoDataPopUp 
            show={modalShow}
            onHide={() => {setModalShow(false); history.goBack()  }}/> : null
    }
            
        
    </div>

</>
    )
}

export default PatientDetail
