import React, { useState, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import "./login.css";
import "../../css/bootstrap.min.css";
import axios from "axios";
import "../../css/iofrm-style.css";
import "../../css/iofrm-theme4.css";
import { LoginStatusContext } from "../../contexts/LoginStatusContext";
import { HospLoginContext } from "../../contexts/HospitalLoginContext";
import { HospDrLoginContext } from "../../contexts/HospDrLoginContext";
import { LoginContext } from "../../contexts/LoginContext";
import { BdoLoginContext } from "../../contexts/BdoLoginContext";
import { Dropdown } from "react-bootstrap";
import { APIURL } from "../../Global";
import { useTranslation } from "react-i18next";
import { PharmacyLoginContext } from "../../contexts/PharmacyLoginContext";
import { LabUserLoginContext } from "../../contexts/LabUserLoginContext";

function Login() {
  const [loginUsername, setLoginUsername] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [, setIsLoggedIn] = useContext(LoginStatusContext);
  const [, setPharmacyData] = useContext(PharmacyLoginContext);
  const [, setLabData] = useContext(LabUserLoginContext);
  const [, setHospData] = useContext(HospLoginContext);
  const [, setHospDocData] = useContext(HospDrLoginContext);
  const [, setBdoData] = useContext(BdoLoginContext);

  const [error, setError] = useState(false);
  const [, setLoginDatas] = useContext(LoginContext);

  const { t } = useTranslation();

  useEffect(() => {
    sessionStorage.clear();
    sessionStorage.clear();
    setLoginDatas(null);
    setIsLoggedIn(false);
   
  }, []);
  let history = useHistory();
  const handleLogin = async (e) => {
    e.preventDefault();

    await apicall();
  };
    

  const handleChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;
    if (key === "loginUsername") {
      setLoginUsername(val);
    }
    if (key === "loginPassword") {
      setLoginPassword(val);
    }
  };
  const togglePasswordVisiblity = (e) => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const apicall = () => {
    axios
      .post(
        `${APIURL}/api/v1/account/login/`,
        {
          username: loginUsername,
          password: loginPassword,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          sessionStorage.setItem("pw", loginPassword);

          sessionStorage.setItem("logindatas", JSON.stringify(res.data));
          setLoginDatas(res.data);
          /* Store Token */
          sessionStorage.setItem("usertoken", JSON.stringify(res.data.token));
          sessionStorage.setItem("user_token", JSON.stringify(res.data.token));

          sessionStorage.setItem("usertype", res.data.user_type);
          sessionStorage.setItem("userid", res.data.user_id);
          sessionStorage.setItem("hospId", res.data.hospital_id);

          if (res.data.user_type === "staff") {
            history.push("./dashboard");
          } else if (
            res.data.user_type === "hospital" &&
            res.data.role === "bdo"
          ) {
            sessionStorage.setItem("role", res.data.role);
            sessionStorage.setItem("bdodata", JSON.stringify(res.data));
            setBdoData(res.data);
            if (res.data.hospital_license === false) {
              history.push({ pathname: "/bdo-invalid-license" });
            } else {
              history.push({ pathname: "/bdo" });
            }
          } else if (
            res.data.user_type === "pharmacy_user" &&
            res.data.role === "pharmacyuser"
          ) {
            sessionStorage.setItem("role", res.data.role);
            sessionStorage.setItem("pharmacy_data", JSON.stringify(res.data));
            setPharmacyData(res.data);
            history.push({ pathname: "/pharmacy" });
          } else if (
            res.data.user_type === "lab_user" &&
            res.data.role === "labuser"
          ) {
            sessionStorage.setItem("role", res.data.role);
            sessionStorage.setItem("labuser_data", JSON.stringify(res.data));
            setLabData(res.data);
            history.push({ pathname: "/labuser" });
          } else if (res.data.user_type === "hospital") {
            if (res.data.role === "sda") {
              history.push("./loggedin");
            }

            if (res.data.verification_status === "pending") {
              sessionStorage.setItem("hosp_data", JSON.stringify(res.data));
              setHospData(res.data);

              history.push({ pathname: "/verifyhospital" });
            }
            if (res.data.verification_status === "approved") {
              sessionStorage.setItem("hosp_data", JSON.stringify(res.data));
              setHospData(res.data);
              history.push({ pathname: "/hospital" });
            }
          } else if (res.data.user_type === "hospital_doctor") {
            sessionStorage.setItem("hosp_doc_data", JSON.stringify(res.data));
            setHospDocData(res.data);

            if (res.data.hospital_license === false) {
              history.push({ pathname: "/dr-invalid-license" });
            } else {
              history.push({ pathname: "/doctor" });
            }
          } else {
            history.push("./loggedin");
          }
        } else {
          setLoginUsername("");
          setLoginPassword("");
          setError(res.data.message);
        }
      })
      .catch(() => {
        setLoginUsername("");
        setLoginPassword("");
        setError("There was some error in login");
      });
  };

  return (
    <div className="form-body">
      {/*<div className="website-logo">
            <a href="index.html">
                <div className="logo">
                    <img className="logo-size" src="images/logo-light.svg" alt="">
                </div>
            </a>
        </div> */}
      <div className="row">
        <div className="img-holder">
          <div className="bg bg-2">
            <h4 className="grey-title tagline">
              {t("Remote Care Platform, It Is Easy And Quick")}
            </h4>
          </div>
          <div className="info-holder">
            <div className="captions-holder">
              <h5 className="captions"> {t("Easiest Remote Care Software Platform.")}</h5>
              <h5 className="captions">{t("Convenient Home Care Technology Solution.")}</h5>
              <h5 className="captions">{" "}{t("Intelligent Patient Monitoring Solution.")}</h5>
            </div>
          </div>
        </div>
        <div className="form-holder">
          <div className="form-content">
            <div className="form-items">
              <div className="page-links">
                <Link to="/login" className="active">
                  {t("Login")}
                </Link>
                <Link to="register">{t("Register")}</Link>
              </div>
              <form className="login-form" onSubmit={handleLogin}>
                <h3 className="form-heading"> {t("Login")} </h3>
                <input
                  className="form-control item"
                  type="text"
                  name="loginUsername"
                  placeholder={t("Username/E-mail Address")}
                  value={loginUsername}
                  maxLength="50"
                  onChange={handleChange}
                  required
                /><div  style={{display:"flex", backgroundColor:"#EBEFF8",height: "44px",width:"45%"}}>
                <input
                  className="form-control"
                  type={passwordType}
                  value={loginPassword}
                  name="loginPassword"
                  placeholder={t("Password")}
                  maxLength="30"
                  onChange={handleChange}
                  required
                />
                 <span style={{ float: "right", paddingTop:"3%",marginRight:"3%", color: 'black' }}>
                    {/* <button className="btn btn-outline-primary1" onClick={togglePasswordVisiblity}> */}
                    {passwordType === "password" ? (
                      <i
                        className="fa fa-eye-slash"
                        onClick={togglePasswordVisiblity}
                      ></i>
                    ) : (
                      <i
                        className="fa fa-eye"
                        onClick={togglePasswordVisiblity}
                      ></i>
                    )}
                    {/* </button> */}
                  </span>
                  </div>
                <div className="forgot-password">
                  <Link to="/forgot-password" className="active">
                    {t("Forgot password?")}
                  </Link>
                </div>

                <div className="form-button">
                  <button
                    id="submit"
                    type="submit"
                    className="btn btn-primary btn-col btn-login"
                  >
                    {t("Login")}
                  </button>

                  {error ? (
                    <div>
                      <p className="login-error" style={{ color: "red" }}>
                        {t("Error")}:{" "}
                        {error === "Invalid credentials"
                          ? t("Invalid credentials")
                          : error === "There was some error in login"
                          ? t("There was some error in login")
                          : error}{" "}
                        <br />
                        {t("Please try again!")}
                      </p>{" "}
                    </div>
                  ) : null}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
