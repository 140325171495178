import React,{useState,useEffect} from 'react';
import "./scan-report.css";
import axios from "axios";

import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import Pagination from "react-js-pagination";
import "./pagination.css";
import {APIURL} from "../../Global";
import HospDocNavbar from "../HospDocNavbar/HospDocNavbar";
import { Link, useHistory } from "react-router-dom";

function ScanReport(props){
  const [popup1,setPopup1]= useState(false);

    const [discList,setDiscList] = useState([]);
   const [patientId,] = useState(props.match.params.id);
   const [activePage,setActivePage] = useState(1);
      const [order,]=useState("desc")
      const [refresh,setRefresh]= useState(false);
      const [totalPages,setTotalPages]=useState(1);
      const [disSummarydata,setDisSummaryData] = useState([]);
      const [scanId ,setScanId]= useState(0);

      const storedId = sessionStorage.getItem('hospId')

      const history= useHistory();

      const [keys,] = useState({'p': false,'a': false,'n': false,'m': false,'l': false,'s': false,});
 
 
 useEffect(()=>{
 
   window.onkeydown =(e) => {
     
     keys[e.key]=true
 
     if(e.altKey && keys['p']){
       history.push('/patients-directory')
     }
     if(e.altKey && keys['a']){
       history.push('/appointment')
     }
     if(e.altKey && keys['n']){
       history.push('/doctor/notifications')
     }
     if(e.altKey && keys['m']){
       history.push('/message')
     }
     if(e.altKey && keys['l']){
       history.push('/doctor/analytics')
     }
     if(e.altKey && keys['s']){
       history.push('/service-requestlist')
     }
 
 
   }
   window.onkeyup=(ev)=> {
     
     keys[ev.key]= false
   }
 
   return()=>{
     window.onkeydown=null
     window.onkeyup=null
   }
 },[]);

 useEffect(() => {
  window.scrollTo(0, 0);
}, []);
      useEffect(()=>{
        
        

        const tokenString= sessionStorage.getItem('usertoken');
         
         let str= tokenString.replace(/["]+/g, '')  /* REGEX To remove double quotes from tokenstring */
                
          
        
          axios.get(`${APIURL}/api/v1/patient/medical-imaging/?src=website&patient_id=${patientId}&sort_by=${order}&per_page=10&page=${activePage}`, { headers: {
            
            "Authorization" : 'Token '+str  }})
          .then(res=>{
      
      
         
            if(res.data.status==="success"){
           
           setDiscList(res.data.results);
           setTotalPages(res.data.total_pages);

            
      
            }else{
             
            }
      
      
          })
          .catch(err=>{
       
      })
       
      
       },[activePage,order,refresh]);


        const disSummaryPopup=(props)=>{
          setDisSummaryData(props.images) 
          setScanId(props.id)
          setPopup1(true)

        }
         

      const handlePageChange=(pageNumber)=> {
        
     
        setActivePage({pageNumber});
        const tokenString = sessionStorage.getItem("usertoken");
        
    
        let v2 = tokenString.replace(
          /["]+/g,
          ""
        ); /* REGEX To remove double quotes from tokenstring */
    
       
    
        axios
        .get(`${APIURL}/api/v1/patient/medical-imaging/?src=website&patient_id=${patientId}&sort_by=${order}&per_page=10&page=${activePage}`, {
          headers: { Authorization: "Token "+ v2 },
        })
        .then((res) => {
          if (res.data.status === "success") {
            
            
            setTotalPages({total_page:res.data.total_pages})
            setDiscList(res.data.results)
  
          } else {

          }
        })
        .catch((err) => {

        });
    }
    const Popup =(props)=>{
      

     return(
       
          <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
       backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
       
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          <h5 className='text-muted'>Felixa ID {patientId} </h5>
          <h5 className="text-muted">{storedId!==''? 'Hospital ID ' + storedId: null}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
         <div className=" flex-center-col">
         <h3 className="title-of-tasks"> Scan Report ID : {scanId} </h3> <br/>
          {disSummarydata.map(img => (
            <div className=" flex-center-col">          
           {
             img.includes('.pdf')? <div style={{width:'600px', height:'550px'}}>
             <object data={img} type="application/pdf" width="100%" height="100%">
             </object></div>

              :   <img src={img} alt='Not Available' width="800px" height="auto"/> 
           }
          </div>
          ))}        
      </div>
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Ok</Button>
  
         
         
  
        </Modal.Footer>
      </Modal>)
      
     
    }
  
return(
  
<div>
  
  <HospDocNavbar/>
  <div className="container"><br/>
    <div className="text-left">
  <Link to={`/patient-record/${patientId}`}><span className="btn btn-info" >  <i className="fas fa-angle-double-left"></i> Go to Patient Record</span></Link>
  </div>



<h2 className="title-of-tasks"> Scan Report </h2>
<div className='flex-row'>
     <h5 className="title-of-tasks text-center hospId-display"> Felixa ID {patientId}</h5>
     <h5 className="title-of-tasks text-center">{storedId!==''? 'Hospital ID ' + storedId: null}</h5>
    </div>  
<div className="buttons-wrapper-presc container">
       
          <button className="btn btn-secondary" onClick={()=>{setRefresh(!refresh) }}>
            Refresh List <i className="fas fa-sync-alt"></i>{" "}
          </button>
          

</div>
<div>
     
    </div>

    {discList.map((person, index) => (
      
      
        <div>
          <div key={index} style={{cursor:'pointer'}} className="list-item"  >
          <div className="d-flex bd-highlight mb-3" onClick={() =>disSummaryPopup(person)}>
  <div style={{color: '#6c757d',fontWeight: '600',textTransform: 'capitalize', fontSize:'large'}} className="mr-auto p-2 bd-highlight">{index + 1}. Scan Report ID : {person.id}</div>
 
  <div className="p-2 bd-highlight">
  <div className="details">
  <p > Uploaded at: {new Date(person.uploaded_at).toString().slice(4,21)}</p>
  
    </div></div>
</div>



          {popup1===true?
            <popup 
show={popup1}
        
        onHide={popup1}
       
    /> :''} 

{popup1==="success" ? 

<popupp
show={popup1}
onHide={() =>  setPopup1(false)}
/>

 :'' }
    
 </div>


 </div>
 
 
 
 
))}




<Popup
show={popup1}
onHide={() =>  setPopup1(false)}
/>

<div className="test-reports-display-list">
          {discList.length ? (
            ""
          ) : (
           <div style={{ color: "red", fontSize: "25px",height:"200px",padding: "40px" }}>
              <h4>No Scan Reports to show!!</h4>
            </div>
          )}
        </div>


<Pagination
          activePage={activePage}
          itemsCountPerPage={10}
          totalItemsCount={totalPages*10}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          disabledClass = "disabled-class"
        />




</div>

</div>)

}

export default ScanReport



