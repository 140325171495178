import React, {useState, useEffect} from 'react'
import axios from "axios";
import {APIURL} from "../../../Global";
import { Link } from "react-router-dom";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import './featurelist.css'

import AddObservations1 from './AddObservations1';
import EditObservations from './EditObservations';
import EditSymptoms from './EditSymptoms';



import {ToggleButton} from 'react-bootstrap';
import {ToggleButtonGroup} from 'react-bootstrap';
import { expand } from 'urijs';
import AddSymptoms from './AddSymptoms';


function ObservationSymptomsBO() {

    const [featureList, setFeatureList]= useState([])
    const [rentalList, setRentalList]= useState([])
    const [editShow, setEditShow]= useState(false)
    const [editSymShow, setEditSymShow]= useState(false)

    const [current, setCurrent]= useState() 
    const [refresh, setRefresh]= useState(false)
    const [addShow, setAddShow]= useState(false)
    const [addRentalShow, setAddRentalShow]= useState(false)
    const [editRentalShow, setEditRentalShow]= useState(false)
    const [editInventoryShow, setEditInventoryShow]= useState(false)
    const [category, setCategory]= useState(1)
    const [expand, setExpand]= useState()
    const [modalShow, setModalShow]= useState(false)
    const [flag, setFlag]= useState(false)
    const [successShow, setSuccessShow] = useState(false)
    const [deleteShow, setDeleteShow] = useState(false)
    const [deleteIndex, setDeleteIndex] = useState('');
    const [deleteType, setDeleteType] = useState('');


   
    useEffect(()=> {
        window.scrollTo(0,0)
    },[])

    useEffect(() => {
      bindObservations();
     }, []);

     const bindObservations=()=>{
     const tokenString = sessionStorage.getItem("usertoken");
   
     let v2 = tokenString.replace(
       /["]+/g,
       ""
     ); /* REGEX To remove double quotes from tokenstring */
 
  
 
     axios
       .get(`${APIURL}/api/v1/staff/master-observations/`, {
         headers: { Authorization: "Token " + v2 },
       })
       .then((res) => {
         
         //console.log(res);
         if (res.status == 200) {
           
           const datas = res.data;
         
           setFeatureList(datas);
           //console.log(featureList)
           }

           // const filteredRentalData = datas.filter(item => {
           //   return item.service_type === 'rental'
           // })
           // setFeatureList(filteredData);
           //setRentalList(filteredRentalData);
           
           // if(flag){
           //   const idVal = filteredRentalData.length-1
           //   sessionStorage.setItem('rentalId',filteredRentalData[idVal].id)
           //   setModalShow(true)
           // }
         else {
           
         }
       })
       .catch((err) => {
        
       });
     }

     useEffect(() => {
      bindSymptoms();
      bindObservations();
     }, [refresh]);

     const bindSymptoms=()=>{
     const tokenString = sessionStorage.getItem("usertoken");
   
     let v2 = tokenString.replace(
       /["]+/g,
       ""
     ); /* REGEX To remove double quotes from tokenstring */
 
  
 
     axios
       .get(`${APIURL}/api/v1/staff/master-symptoms/`, {
         headers: { Authorization: "Token " + v2 },
       })
       .then((res) => {
         
         //console.log(res);
         if (res.status == 200) {
           
           const datas = res.data;
          
           setRentalList(datas);
           //console.log(featureList)
           }

           // const filteredRentalData = datas.filter(item => {
           //   return item.service_type === 'rental'
           // })
           // setFeatureList(filteredData);
           //setRentalList(filteredRentalData);
           
           // if(flag){
           //   const idVal = filteredRentalData.length-1
           //   sessionStorage.setItem('rentalId',filteredRentalData[idVal].id)
           //   setModalShow(true)
           // }
         else {
           
         }
       })
       .catch((err) => {
        
       });
     }




     const handleDeletePopUp = (id, type) => {
      //  e.preventDefault();
      setDeleteType(type);
      setDeleteIndex(id);
      setDeleteShow(true);
    }
  
    const handleDelete = (item) => {
  
  
      const tokenString = sessionStorage.getItem('usertoken');
  
      let str = tokenString.replace(/["]+/g, '');
  
      //  const dataToSend = {
      //   "id" : item
      // }
  
      axios.delete(`${APIURL}/api/v1/staff/master-observation-detail/${deleteIndex}/`, {
        headers: {
  
          "Authorization": 'Token ' + str
        }
      })
        .then(res => {
  
  
          if (res.status == 204) {
            setSuccessShow(true)
  
            // setRefresh(!refresh)
  
  
  
          }
          else {
            console.log('else called ');
            //  setErrorMsg(res.data.message);
            //             setErrorSubmit(true);
          }
  
  
          setDeleteIndex('');
          setDeleteShow(false);
  
        })
        .catch(err => {
          console.log("catch called");
          // setSubmitMsg("error");
          // setErrorSubmit(true);
        })
  
    }
  
    const DeleteConfirmPopup = (props) => {
  
  
      return (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
  
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4 id="confirm-reject">Are you sure you want to delete?</h4>
  
          </Modal.Body>
          <Modal.Footer>
  
            <Button variant="secondary" onClick={props.onHide}>Cancel</Button>
  
            <Button variant="danger"
              onClick={() => {
                if(deleteType === 'observation') {
                  handleDelete(deleteIndex);
                } else if (deleteType === 'symptom') {
                  handleDeleteSymp(deleteIndex);
                }
                 
                 }}
            >
              Confirm</Button>
  
  
  
          </Modal.Footer>
        </Modal>
      );
    }
    const SubmitPopUp = ({ onHide }) => {
      return (
        <Modal
          show
          onHide
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header >
            <Modal.Title id="contained-modal-title-vcenter">
  
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5 className='text-success'>
              {deleteType === 'observation' ? 'Observation Deleted  Successfully!!' : 'Symptom Deleted  Successfully!!'}
              </h5>
  
          </Modal.Body>
          <Modal.Footer>
  
            <Button variant="secondary" onClick={onHide}>Ok</Button>
  
          </Modal.Footer>
        </Modal>
      )
    }


    const handleDeleteSymp = (item) => {
  
  
      const tokenString = sessionStorage.getItem('usertoken');
  
      let str = tokenString.replace(/["]+/g, '');
  
      //  const dataToSend = {
      //   "id" : item
      // }
  
      axios.delete(`${APIURL}/api/v1/staff/master-symptom-detail/${deleteIndex}/`, {
        headers: {
  
          "Authorization": 'Token ' + str
        }
      })
        .then(res => {
  
  
          if (res.status == 204) {
            setSuccessShow(true)
  
            // setRefresh(!refresh)
  
  
  
          }
          else {
            console.log('else called ');
            //  setErrorMsg(res.data.message);
            //             setErrorSubmit(true);
          }
  
  
          setDeleteIndex('');
          setDeleteShow(false);
  
        })
        .catch(err => {
          console.log("catch called");
          // setSubmitMsg("error");
          // setErrorSubmit(true);
        })
  
    }
      const handleChangeRadio=(val)=>{
        setCategory(val);
       }

    const modalClose = () => {
      setEditShow(false)
      setRefresh(!refresh)
    }

    const addModalClose = () => {
      setAddShow(false)
      setRefresh(!refresh)
    }

    const rentalModalClose = () => {
      setAddRentalShow(false)
      setRefresh(!refresh)
      setFlag(true)
    }

    const notProceedClose = () => {
      setAddRentalShow(false)
      setRefresh(!refresh)
    }

    const rentalEditModalClose = () => {
      setEditRentalShow(false)
      setRefresh(!refresh)
    }

    const inventoryModalClose = () => {
      setModalShow(false)
      setFlag(false)
      setRefresh(!refresh)
    }

    const editInventoryModalClose = () => {
      setEditInventoryShow(false)
      setRefresh(!refresh)
    }

    const EditPopUpObs = (props) => {
      return(
        <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
   backdrop="static"
    keyboard={false}
    size='lg'
  >
  <Modal.Header closeButton>
    <Modal.Title id="contained-modal-title-vcenter">
    
     
    </Modal.Title>
  </Modal.Header>
  <Modal.Body>

   <EditObservations data={current} modalClose={modalClose} />
   
  </Modal.Body>
  <Modal.Footer>
   
  <Button className='btn btn-primary btn-col' onClick={handleObsClose}>Close</Button>     

  </Modal.Footer>
</Modal>
      )
  }

  const EditPopUpSym = (props) => {
    return(
      <Modal
      {...props}
      aria-labelledby="example-custom-modal-styling-title"
 backdrop="static"
  keyboard={false}
  size='lg'
>
<Modal.Header closeButton>
  <Modal.Title id="contained-modal-title-vcenter">
  
   
  </Modal.Title>
</Modal.Header>
<Modal.Body>

 <EditSymptoms data={current} modalClose={modalClose} />
 
</Modal.Body>
<Modal.Footer>
 
<Button className='btn btn-primary btn-col' onClick={handleSymClose}>Close</Button>     

</Modal.Footer>
</Modal>
    )
}

    const handleObsClose = () =>
    {
      bindObservations();
      setAddShow(false);
  setEditShow(false);

    }

    const handleSymClose = () =>
    {
      bindSymptoms();
      setEditSymShow(false);

      setAddRentalShow(false);
    }

    const AddPopUp = (props) => {
      return(
        <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
   backdrop="static"
    keyboard={false}
    size='lg'
  >
  <Modal.Header>
    <Modal.Title id="contained-modal-title-vcenter">
    
     
    </Modal.Title>
  </Modal.Header>
  <Modal.Body>

   <AddObservations1 modalClose={addModalClose} />
   
  </Modal.Body>
  <Modal.Footer>
   
     <Button className='btn btn-primary btn-col' onClick={handleObsClose}>Close</Button>   

  </Modal.Footer>
</Modal>
      )
  }

  const AddSymptomsPopUp = (props) => {
    return(
      <Modal
      {...props}
      aria-labelledby="example-custom-modal-styling-title"
 backdrop="static"
  keyboard={false}
  dialogClassName="modal-80w"
>
<Modal.Header>
  <Modal.Title id="contained-modal-title-vcenter">
  
   
  </Modal.Title>
</Modal.Header>
<Modal.Body>

 <AddSymptoms modalClose={rentalModalClose} notProceedClose={notProceedClose} />
 
</Modal.Body>
<Modal.Footer>
 
   <Button className='btn btn-primary btn-col' onClick={handleSymClose}>Close</Button>   

</Modal.Footer>
</Modal>
    )
}

 
const handleEditObs = (index) => {
  const list = featureList[index]
  setCurrent(list)
  setEditShow(true)
}

const handleEditSym = (index) => {
  const list = rentalList[index]
  setCurrent(list)
  setEditSymShow(true)
}




    const handleDetail = (index ) => {
      if(expand !== index){
        setExpand(index)
      }
      else{
        setExpand()
      }
    }

      const dataDisplay = category === 1 ? featureList.length >0 ? featureList.map((item,index) => {
          return (
            <div className='care-features' key={item.id}>
            <div className='feature-data'>
              <h5 className='feature-title'>{index+1}.{item.observation_name}</h5>
              <button style={{marginLeft:'auto'}} onClick={()=> handleEditObs(index)} disable = {true} className='btn btn-primary btn-col'>Edit</button>
              &nbsp;
              <button onClick={() => handleDeletePopUp(item.id, 'observation')} className='btn btn-danger delete-font'> Delete</button>

              
            </div>
              <div className='seperate-content'/>
              <div className='feature-data'>
              <h6 className='feature-request'>Observation Type: {item.observation_type}</h6>
              <h6 className='feature-service'>Observation Code : {item.observation_code}</h6>
              
            </div>
          </div>
          )
          
      }) : <div style={{ color: "red", fontSize: "25px",height:"200px",padding: "40px" }}>
              <h4>No Observation to show!!</h4>
           </div>
  : category === 2 ? rentalList.length >0 ? rentalList.map((item,index) => {
return (
  <div className='care-features' key={item.id}>
    
    <div className='feature-data'>
      <h5 className='feature-title'>{index+1}.{item.symptom_name}</h5>
      
      
      {/* <button onClick={()=> handleRentalEdit(index)} disable = {true} className='btn btn-primary btn-col'>Edit</button> */}
      <button style={{marginLeft:'auto'}} onClick={()=> handleEditSym(index)} disable = {true} className='btn btn-primary btn-col'>Edit</button>
      &nbsp;
      <button onClick={() => handleDeletePopUp(item.id, 'symptom')} className='btn btn-danger delete-font'> Delete</button>


    </div>
    {
      expand !==index ?
        <div className='seperate-content'/>
        : null
    }
      

      <div style={{cursor:'pointer'}} onClick={()=> handleDetail(index)}  className={ expand === index ?'feature-data mt-3' : 'feature-data'}>
        <h6 className='feature-request'>Symptom Name: {item.symptom_name}</h6>
        
        {/* <h6 className='feature-service'>Active : {item.is_active === true? 'Yes': item.is_active === false? 'No' : 'Nil'}</h6> */}
        {/* <h6 className='feature-service'><i className={expand===index ? "fas fa-sort-up detail-open" : "fas fa-sort-down detail-open"}></i>Inventory Details</h6> */}
    </div>
    {
      expand === index ? <>
          <div className='seperate-content'/>
            <div className='row-of-features'>
              <div className='col-of-features'>
                <h6>Symptom Type : {item.symptom_type}</h6>
                <h6>Symptom Code: {item.symptom_code}</h6>
                
               
               
              </div>

             
            </div>
            {/* <button onClick={()=>handleEditInventory(index)} style={{alignSelf:'flex-end'}} className='btn btn-primary btn-col'>Edit Inventory</button> */}

            {/* <button style={{marginLeft:'auto', marginRight:'100px'}} onClick={()=> handleEditSym(index)} disable = {true} className='btn btn-primary btn-col'>Edit</button> */}

        </>
        : null
    }
    
  </div>
)

          
      }) : <div style={{ color: "red", fontSize: "25px",height:"200px",padding: "40px" }}>
              <h4>No Symptoms!!</h4>
           </div>

        : <div style={{ color: "red", fontSize: "25px",height:"200px",padding: "40px" }}>
            <h4>No Observtions to show!!</h4>
          </div>


    return (
             <div className="container displaylist-container ">
        <div className="dashboard-navigation">
          <div>
            {" "}
            <Link to="/dashboard">
              {" "}
              <i className="fas fa-home dasboard-icon"> </i>Dashboard{" "}
            </Link>
          </div>

          <div>
            {" "}
            <i className="fas fa-angle-double-right angle-icon"></i>{" "}
          </div>
         
        </div>
        <div className="header-display">
          <h2 className="title-of-page"> Add Observations and Symptoms</h2>


         <div className="buttons-wrapper">
          {/* <button className="btn btn-secondary" onClick={()=> setRefresh(!refresh)}>
            Refresh List <i className="fas fa-sync-alt"></i>{" "}
          </button> */}
          {
            category === 1 ?
              <button className="btn btn-primary btn-col" onClick={()=> setAddShow(true)}>
                Add Observations <i class="fas fa-plus"></i>
              </button> 
              :
              category === 2 ?
              
              <button className="btn btn-primary btn-col" onClick={()=> setAddRentalShow(true)}>
                Add Symptoms <i class="fas fa-plus"></i>
              </button> 
              : null
          }
          
</div>  


        </div>

        <div className='mb-5'>
      <ToggleButtonGroup type="radio" name="options" defaultValue={category} onChange={handleChangeRadio}>
    <ToggleButton id="tbg-radio-1" value={1} className="btn btn-light toggle-btns toggle-btn-1">
   Observations
    </ToggleButton>
    <ToggleButton id="tbg-radio-2" value={2} className="btn btn-light toggle-btns toggle-btn-2">
    Symptoms
    </ToggleButton>
  
      </ToggleButtonGroup>
    </div>

        <div className="test-reports-display-list">

          <div className='feature-container'>
            {dataDisplay}
          </div>
          
        </div>


        {
      editShow ?
      <EditPopUpObs
        show={editShow}
        onHide={() => setEditShow(false)}
        /> : null
    }

{
        deleteShow ?
          <DeleteConfirmPopup
            show={deleteShow}
            onHide={() => {
              setDeleteShow(false);
              setDeleteIndex('');
            }}

          /> : ''
      }

      {
        successShow ?
          <SubmitPopUp
            show={successShow}
            onHide={() => { setSuccessShow(false); setRefresh(!refresh); }} />
          : null
      }
{
      editSymShow ?
      <EditPopUpSym
        show={editSymShow}
        onHide={() => setEditSymShow(false)}
        /> : null
    }


{
      addShow ?
      <AddPopUp
        show={addShow}
        onHide={() => setAddShow(false)}
        /> : null
    }

{
      addRentalShow ?
      <AddSymptomsPopUp
        show={addRentalShow}
        onHide={() => setAddRentalShow(false)}
        /> : null
    }





      </div>
    )


}

export default ObservationSymptomsBO
