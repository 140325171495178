import React,{useState} from 'react'
import Select from 'react-select';
import axios from "axios";
import {APIURL} from "../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import DatePicker from 'react-date-picker';

function AddEquipmenthosp(props) {

    const[data, setData]= useState({
        name:'',
        model:'',
        price:'',
        seriel:'',
        serviceprice:'',
        category:'',
        
        servicetype:{ value:'', label:'' },
        serviceunit:{ value:'', label:'' },
        service: null,
        active: null,
        manufacturename:'',
        year_of_manufacture: '',
        purchasedate:'',
        companyaddress:'',
        distributer:'',
        distributeraddress:'',
        make:'',
        expiry:'',
        description:''
    
        

    })

    const [error,setError]=useState({
        name:'',
        model:'',
        price:'',
        seriel:'',
        serviceprice:'',
        servicetype:'',
        serviceunit:'',
        service:'',
        active:'',
        year_of_manufacture:'',
        purchasedate:'',
        expiry:''




       });

    const [successShow, setSuccessShow] = useState(false)
    const [errorShow, setErrorShow] = useState(false)
    const [year, setYear]= useState()
    const [year1, setYear1]= useState()
    const [yearData, setYearData]= useState()
    const [yearData1, setYearData1]= useState()
    const [expiry, setExpiry]= useState()
    const [expiryData, setExpiryData]= useState()

    const serviceOptions = [{ value:'general', label:'General' },
    { value:'service_request', label:'Service Request' },
    { value:'rental', label:'Equipment Request' }
]

    const unitOptions = [{ value:'days', label:'Days' },
    { value: 'count', label: 'count' }
]

    const SuccessPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4 className='text-success'>Equipment Added Successfully.</h4>
             
            </Modal.Body>
            <Modal.Footer>
             
             <Button className='btn btn-secondary' onClick={props.onHide}>Ok</Button> 
             {/* <Button className='btn btn-primary btn-col' onClick={props.onProceed}>Proceed</Button>  */}
             
            </Modal.Footer>
          </Modal>
        );
      }
    
      const ErrorPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <h5 style={{color: "red"}}>Error in data submission.Please try again!</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
             
            
            </Modal.Footer>
          </Modal>
        );
      }

      const handleTitle = (e)=> {
        e.preventDefault();        
        const val=e.target.value;
        const key=e.target.name;
        setData( current => ({
            ...current , [key] : val
        }))
      }

      const handleServiceChange= (data) => {

        setData( current => ({
            ...current , servicetype: data
        }))
   }

   const handleUnitChange= (data) => {

    setData( current => ({
        ...current , serviceunit: data
    }))
  }

  const handleRequest = (e)=> {
   const val = e.target.value
   const key = e.target.name

   setData((current) => ({
       ...current , [key] : val==='true'? true : val==='false'? false : null
   }))

  }

  const handleYear = (date)=> {
    setYear(date);
      
      let dateUpdated= date;
        if(date!==null){
      const offset = date.getTimezoneOffset();
      
      dateUpdated = new Date(date.getTime() - (offset*60*1000))
      
      let DateExtracted = dateUpdated.toISOString();//.split('T')[0];
      
      /*let dateString= DateExtracted.toString(); */
      setYearData(DateExtracted);
      
      }
  }


  const handleYear1 = (date)=> {
    setYear1(date);
      
      let dateUpdated= date;
        if(date!==null){
      const offset = date.getTimezoneOffset();
      
      dateUpdated = new Date(date.getTime() - (offset*60*1000))
      
      let DateExtracted = dateUpdated.toISOString();//.split('T')[0];
      
      /*let dateString= DateExtracted.toString(); */
      setYearData1(DateExtracted);
      
      }
  }

  const handleExpiry = (date)=> {

    setExpiry(date);
      
      let dateUpdated= date;
        if(date!==null){
      const offset = date.getTimezoneOffset();
      
      dateUpdated = new Date(date.getTime() - (offset*60*1000))
      
      let DateExtracted = dateUpdated.toISOString();//.split('T')[0];
      
      /*let dateString= DateExtracted.toString(); */
      setExpiryData(DateExtracted);
      
      }
  }

  const validate=()=>{
    let input = data;
    let errors = {};
    let isValid = true;
  setError({});
  // name:'',
  // model:'',
  // price:'',
  // seriel:'',
  // serviceprice:'',
  // servicetype:'',
  // serviceunit:'',
  // service:'',
  // active:'',
  // year_of_manufacture:'',
  // purchasedate:'',
  // expiry:''

    if (!input["name"] || input["name"]==="" ) {
      isValid = false;
      errors["name"] = "Please enter Equipment Name";
    }
    if (!input["model"] || input["model"].value==="" ) {
      isValid = false;
      errors["model"] = "Please enter equipment model";
    }
    if (!input["price"] || input["price"].value==="" ) {
      isValid = false;
      errors["price"] = "Please enter equipment price";
    }

    if (!input["seriel"] || input["seriel"]==="" ) {
      isValid = false;
      errors["seriel"] = "Please enter equipment seial number";
    }
    if (!input["serviceprice"] || input["serviceprice"]==="" ) {
        isValid = false;
        errors["serviceprice"] = "Please enter equipment service price";
      }

      if (input["service"]===null ) {
        isValid = false;
        errors["service"] = "Please select on request service";
      }
      if (input["active"]===null ) {
        isValid = false;
        errors["active"] = "Please select active feature";
      }
      if (yearData === undefined ) {
        isValid = false;
        errors["year_of_manufacture"] = "Please enter Year of Manufacture";
      }
      if (yearData1 === undefined ) {
        isValid = false;
        errors["purchasedate"] = "Please enter purchase date";
      }
      if (expiryData === undefined ) {
        isValid = false;
        errors["expiry"] = "Please enter Expiry Date";
      }

      if (!input["servicetype"] || input["servicetype"].value==="" ) {
        isValid = false;
        errors["servicetype"] = "Please select a Service Type";
      }
      if (!input["serviceunit"] || input["serviceunit"].value==="" ) {
        isValid = false;
        errors["serviceunit"] = "Please select a Service Unit";
      }


     setError(currentstate=>({
          ...currentstate,
          ...errors
        }));
    

    return isValid;
}

  const saveChanges = (e)=> {

    e.preventDefault();

    if(validate()){
        const tokenString = sessionStorage.getItem("usertoken");
 
     let v2 = tokenString.replace(
       /["]+/g,
       ""
     ); /* REGEX To remove double quotes from tokenstring */

 
 
  const dataToSend = {
    equipment_name: data.name,                             
    model: data.model,                                                
    price:  data.price,                                          
    serial_number: data.seriel,                                           
    service_price: data.serviceprice,
    category: data.category,
    on_service:data.service,
    is_active:data.active,
    service_type:data.servicetype.value,
    service_unit: data.serviceunit.value,
    manufacturing_date: yearData,
    manufacture_name:data.manufacturename,
    expiry_date: expiryData,
    purchase_date:yearData1,
    distributor:data.distributer,
    company_address:data.companyaddress,
    distributor_address:data.distributeraddress,
    description:data.description


  }
 
     axios
       .post(`${APIURL}/api/v1/hospital/equipments-list/`, dataToSend , {
         headers: { Authorization: "Token " + v2 },
       })
       .then((res) => {
      
         if (res.status==200) {
           
           setSuccessShow(true)
         } else {
           setErrorShow(true)
         }
       })
       .catch((err) => {
         setErrorShow(true)
       });
    }

    

}

    return (
        <>

        <div class="myDiv">
    <h2 style={{marginTop:'0'}} className="title-of-page">Add Equipment and Details</h2>
    <h6 style={{marginLeft:'5'}} className="title-of-page">Please fill all mandatory feilds *</h6>
            
            <div className='feature-data pad-features'>
                <div className='flex-col justify-feature'>
                    <span className='align-rental'>Equipment Name * : </span>
                    <input type='text' className='form-control title-flex' name='name' value={data.name} onChange={handleTitle} />
                    {error.name ? <div className="error-validation-msg error-feature-inventory">{error.name}</div> : null}
                </div>
                <br/>

                <div className='flex-col justify-feature'>
                    <span className='align-rental'>Model * : </span>
                    <input type='text' className='form-control title-flex' name='model' value={data.model} onChange={handleTitle} />
                    {error.model ? <div className="error-validation-msg error-feature-inventory">{error.model}</div> : null}
                 </div>
                 <br/>
                 <div className='flex-col justify-feature'>
                    <span className='align-rental'>Equipment Price *: </span>
                    <input type='number' className='form-control title-flex' name='price' value={data.price} onChange={handleTitle} />
                    {error.price ? <div className="error-validation-msg error-feature-inventory">{error.price}</div> : null}
                 </div>
                 <div className='flex-col justify-feature'>
                    <span className='align-rental'>Serial Number *: </span>
                    <input type='text' className='form-control title-flex' name='seriel' value={data.seriel} onChange={handleTitle} />
                    {error.seriel ? <div className="error-validation-msg error-feature-inventory">{error.seriel}</div> : null}
                 </div>
                 <div className='flex-col justify-feature'>
                    <span className='align-rental'>Service Price *: </span>
                    <input type='number' className='form-control title-flex' name='serviceprice' value={data.serviceprice} onChange={handleTitle} />
                    {error.serviceprice ? <div className="error-validation-msg error-feature-inventory">{error.serviceprice}</div> : null}
                 </div>
                 <div className='flex-col justify-feature'>
                    <span className='align-rental'>Category : </span>
                    <input type='text' className='form-control title-flex' name='category' value={data.category} onChange={handleTitle} />
                    
                 </div>

           <div>

           </div>

           <div className='flex-col justify-feature'>
                    <span className='align-rental'>Type *: </span>
                    <Select
                            value={data.servicetype}
                            onChange={(data,e)=>{handleServiceChange(data,e)
                            }}
                            options={serviceOptions}
                            name='servicetype'
                            className="select-currency select-flex"
                    />
                  
                 </div>
                
                <div className='flex-col justify-feature'>
                    <span className='align-rental'>Equipment Unit *:  </span>

                <Select
                            value={data.serviceunit}
                            onChange={(data,e)=>{handleUnitChange(data,e)
                            }}
                            options={unitOptions}
                            name='serviceunit'
                            className="select-currency select-flex"
                        />
                     
                 </div>

          
            </div>
        
    
            <div className='feature-data pad-features'>
                <div className='flex-col justify-feature'>
                    <span className='align-rental'>On Request Service *:</span>

                <div style={{marginBottom:'auto'}} className="form-group gender-radio-check col-lg-6 title-flex">
                    <div className="form-control">
                        <input className='checkbox-class1' type='radio' id="true" name='service'  value= {true}  onChange={handleRequest} />
                        <label htmlFor='true'>Yes</label>

                        <input className='checkbox-class1' type='radio' id="false"  name='service'  value= {false} onChange={handleRequest} />
                        <label htmlFor='false'>No</label>
                    </div>
                </div>
             
                </div>

                <div className='flex-col justify-feature'>
                    <span className='align-rental'>Active Feature *: </span>
                
                <div style={{marginBottom: 'auto'}} className="form-group gender-radio-check col-lg-6 title-flex">
                    <div className="form-control">
                        <input className='checkbox-class1' type='radio' id="true" name='active'  value= {true} onChange={handleRequest} />
                        <label htmlFor='true'>Yes</label>

                        <input className='checkbox-class1' type='radio' id="false"  name='active'  value= {false} onChange={handleRequest} />
                        <label htmlFor='false'>No</label>
                    </div>
                </div>
               
                </div>

            </div>

          

            <div className='feature-data pad-features'>
                <div className='flex-col justify-feature'>
                    <span className='align-rental'>Manufacture Date *:</span>
                    <div className='form-control dob item title-flex'>
                        <DatePicker
                          value={year}
                          onChange={date=>{handleYear(date)}}                         
                          maxDate={new Date()}
                          
                     />
                     </div>
                 
                </div>

                <div className='flex-col justify-feature'>
                    <span className='align-rental'>Manufacture Name: </span>
                    <input type='text' className='form-control title-flex' name='manufacturename' value={data.manufacturename} onChange={handleTitle} />
                   
                 </div>
            </div>

            <div className='feature-data pad-features'>
                <div className='flex-col justify-feature'>
                    <span className='align-rental'>Purchase Date *: </span>
                    <div className='form-control dob item title-flex'>
                        <DatePicker
                          value={year1}
                          onChange={date=>{handleYear1(date)}}                         
                          maxDate={new Date()}
                          
                     />
                     </div>
                    
                </div>
                

                <div className='flex-col justify-feature'>
                    <span className='align-rental'>Expiry Date *: </span>
                    <div className='form-control dob item title-flex'>
                        <DatePicker
                          value={expiry}
                          onChange={date=>{handleExpiry(date)}}                         
                          minDate={new Date()}
                          
                     />
                     </div>
                    
                </div>
           

            <div className='feature-data pad-features'>
                <div className='flex-col justify-feature'>
                    <span className='align-rental'>Distributor : </span>
                    <input type='text' className='form-control title-flex' name='distributor' value={data.distributor} onChange={handleTitle} />
                   
                </div> 
                
     
                <div className='flex-col justify-feature'>
                    <span className='align-rental'>Company Address: </span>
                    <textarea type='text' className='form-control title-flex' name='companyaddress' value={data.companyaddress} onChange={handleTitle} />
                   
                </div> 
           
</div>
<br/>
<div className='feature-data pad-features'>
                <div className='flex-col justify-feature'>
                    <span className='align-rental'>Distributor Address : </span>
                    <textarea type='text' className='form-control title-flex' name='distributeraddress' value={data.distributeraddress} onChange={handleTitle} />
                   
                </div> 
           
          
                <div className='flex-col justify-feature'>
                    <span className='align-rental'>Description : </span>
                    <textarea type='text' className='form-control title-flex' name='description' value={data.description} onChange={handleTitle} />
                   
                </div> 
            </div>


         <br/>
         <br/>

            <div className='text-center'>
                <button onClick={saveChanges} className='btn btn-primary btn-col mb-2'>Save</button>
                
            </div>
            </div>
            {successShow ? 
<SuccessPopUp
show={successShow}
onProceed={() =>  {setSuccessShow(false); props.modalClose();}}
onHide={() =>  {setSuccessShow(false); props.notProceedClose(); }}
/> 
: null}

{errorShow ? 

<ErrorPopUp
show={errorShow}
onHide={() =>  {setErrorShow(false)}}
/> 
: null}
            
        </div>
        </>
    )
}

export default AddEquipmenthosp
