import React ,{useEffect}from "react";

import HospDocNavbar from "../HospDocNavbar/HospDocNavbar";
import { Link, useHistory } from "react-router-dom";
function UpdatedSoon() {
  let history = useHistory();
  const handleBack = (e) => {

    history.goBack();
 
    };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HospDocNavbar />
      <button
            className="btn btn-info btn-col back-btn" style={{marginLeft:"-90%",marginTop:"1%"}}
            onClick={handleBack}
          >
            {" "}
            <i className="fas fa-chevron-left back-btn-icon"></i>Back{" "}
          </button>
      <div className="container">
        <h2 className="title-of-page"> This Page will be updated soon </h2>
      </div>
    </>
  );
}
export default UpdatedSoon;
