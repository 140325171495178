import React,{useState, useEffect} from 'react';
import {Link, useHistory} from "react-router-dom";
import Doctor from "../Doctor/Doctor";

import "./doctorslist.css";
import axios from 'axios';
import HospitalNavbar  from "../HospitalNavbar/HospitalNavbar";
import {APIURL} from "../../Global";
import { useTranslation } from "react-i18next";



function DoctorsList(){
  const { t } = useTranslation();
const [doctorsList,setDoctorsList] = useState([]); 
const [freeCount, setFreeCount]= useState();
const [activeCount, setActiveCount]= useState()
   const [errorMsg, setErrorMsg]= useState("");
   const [refresh, setRefresh]= useState(false)
	
   const history =useHistory();

   const [keys,] = useState({'a': false,'b': false,'o': false,'n': false,'s': false,'l': false,'p': false,'c': false,'h': false});


   useEffect(()=> {
    window.scrollTo(0,0)
  },[])

  
useEffect(()=>{

  window.onkeydown =(e) => {
    
    keys[e.key]=true

    if(e.altKey && keys['a']){
      history.push('/hospital/add')
    }
    if(e.altKey && keys['b']){
      history.push('/bdo-list')
    }
    if(e.altKey && keys['o']){
      history.push('/hospital/add-bdo')
    }
    if(e.altKey && keys['n']){
      history.push('/emergency-number')
    }
    if(e.altKey && keys['s']){
      history.push('/service-request-list')
    }
    if(e.altKey && keys['l']){
      history.push('/license-details')
    }
    if(e.altKey && keys['p']){
      history.push('/license-payment')
    }
    if(e.altKey && keys['c']){
      history.push('/hosp-calllog')
    }
    if(e.altKey && keys['h']){
      history.push('/payment-history')
    }

  }
  window.onkeyup=(ev)=> {
    
    keys[ev.key]= false
  }

  return()=>{
    window.onkeydown=null
    window.onkeyup=null
  }
},[]);
  
 useEffect(() => {
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(/["]+/g,""); 

   
    axios
      .get(`${APIURL}/api/v1/hospital/doctors/`, {
        headers: { 'Content-Type': 'application/json',
                     Authorization: "Token " +v2},
      })
      .then((res) => {
        if (res.data.status === "success") {
          const datas = res.data.doctors;
        
          setFreeCount(res.data.free_user_count)
          setActiveCount(res.data.active_free_users)
          setDoctorsList(datas);
        } else {
          setErrorMsg("error");
        }
      })
      .catch((err) => {
        setErrorMsg("error");
      });
  }, [refresh]); 

  const refreshHandle= ()=> {
    setRefresh(!refresh)
  }

	return(<div className="drlist-page">
    <HospitalNavbar />
		

		<h2 className="title-of-page"> {t("Doctors List")} </h2>
    <div className="quick-links">
      <Link to="/hospital/add"><button type="button" className="btn btn-primary btn-col dr-btn"> + Add Doctor to List </button></Link>
       <Link to={{pathname:'license-details', flag:"dr-page" }}><button type="button" style={{marginLeft:"10px"}} className="btn btn-info dr-btn"> View Licence Details</button></Link>

</div>

		{doctorsList.length!== 0  ? (<div>
		<div className="drs-container">
		{doctorsList.map((data,index)=>{
			return <Doctor key={index} data={data} freeCount={freeCount} activeCount={activeCount} handle={refreshHandle}/>
		})}
         </div>
       
        <div className="quick-links">
		  <Link to="/hospital/add"><button type="button" className="btn btn-primary btn-col dr-btn"> + Add Doctor to List </button></Link>

       </div>

       </div>)
       :
       <h5 style={{color: "red" ,margin: "20px"}}>{t("Doctors List is empty")} </h5>
   }

    {errorMsg=== "error" ?
       <h5 style={{ color: "red" }}>
            
            Error in data fetching.Please try again!
          </h5>
      :
      null}
		</div>
		);
}

export default DoctorsList;