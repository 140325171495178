import React,{useState,useEffect} from "react";
import {Link,useHistory} from "react-router-dom";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import "./caredetails.css";
import HospBdoNavbar from "../HospBdoNavbar/HospBdoNavbar";
import axios from "axios";
import {APIURL} from "../../Global";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';


function CareProgramDetails(props) {
	const [careID,]=useState(props.match.params.id);
    //const [bdoData,] =useContext(BdoLoginContext);
 
       /*const [bdo_name,]= useState(bdoData ? bdoData.name : null);
    const doctor_specializations = hospBdoData ? hospBdoData.doctor_specializations : null; 
   const [department,]  = useState(bdoData ? bdoData.department : null);
   const [Id,] = useState(bdoData ? bdoData.user_id : null);
   const [hospitalName,]= useState(bdoData ? bdoData.hospital_username: null);
   const specializations = hospBdoData ? hospBdoData.specializations : null;
   const [location,] = useState(bdoData ? bdoData.location: null);*/
   const [title,setTitle]=useState();
   const [price,setPrice]=useState();
   const [discount,setDiscount]=useState();
 const [currency,setCurrency]=useState();
  const [isActive,setIsActive]=useState();
    const [isObsolete,setIsObsolete]=useState();
  const [taxAmount,setTaxAmount]=useState();
   const [pricingType,setPricingType]=useState();
 const [offerText,setOfferText]=useState();
      const [,setGetError]=useState('');
   const [commonFeatures,]=useState([]);
   //const [commonFeaturesActive,setCommonFeaturesActive]=useState([]);
   const [featuresList,setFeaturesList]=useState([]);
   const [refresh,setRefresh]=useState(false);
   const [confirmShow ,setConfirmShow]= useState(false);
   const [statusConfirm ,setStatusConfirm]= useState(false)
   const [successShow ,setSuccessShow]= useState(false);
   const [errorShow ,setErrorShow]= useState(false);
   const [errorMsg ,setErrorMsg]= useState('');
    const [,setSuccessMsg]= useState('')
   const [changeSuccess ,setChangeSuccess]= useState(false);
   const [changeError ,setChangeError]= useState(false);
const [activeSubs,setActiveSubs]=useState();
   const [customFeatures,]=useState([...new Array(5)].map(()=>({
      title: "",
      limit: "",
      is_default:false,
      extra_cost:""
   })));
const [careProgramId,]=useState(props.match.params.id);
   let history=useHistory();
   
   const [keys,] = useState({'u': false,'c': false,'n': false,'a': false,'s': false,'q': false,'l': false,});


   useEffect(()=>{
   
     window.onkeydown =(e) => {
       
       keys[e.key]=true
   
       if(e.altKey && keys['u']){
         history.push('/directory')
       }
       if(e.altKey && keys['c']){
         history.push('/careplans-db')
       }
       if(e.altKey && keys['n']){
         history.push('/notifications')
       }
       if(e.altKey && keys['a']){
         history.push('/analytics')
       }
       if(e.altKey && keys['s']){
         history.push('/service-requests')
       }
       if(e.altKey && keys['q']){
         history.push('/qrcode')
       }
       if(e.altKey && keys['l']){
         history.push('/call-log')
       }
   
   
     }
     window.onkeyup=(ev)=> {
       
       keys[ev.key]= false
     }
   
     return()=>{
       window.onkeydown=null
       window.onkeyup=null
     }
   },[]);
   
   let featuresTable=null;

   useEffect(()=>{
    
    featuresTable=<ReactTable 
         columns={columnsFeaturesList}
         data={featuresList}
          pageSize={featuresList.length>0 ? featuresList.length : 10}
         resizable
        className="features-reacttable"        
        showPagination={false}    
                 
         >

         </ReactTable> 

   },[commonFeatures,customFeatures,featuresList])

useEffect(() => { window.scrollTo(0, 0) }, [])

   useEffect(()=>{

    const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;


  
    axios.get(`${APIURL}/api/v1/hospital/care-programs/${careProgramId}/`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
     

   
   
      if(res.data.status==="success"){

       setTitle(res.data.data.title);
       setDiscount(res.data.data.discounted_price);
       setPrice(res.data.data.price);
       setCurrency(res.data.data.currency);
       setIsActive(res.data.data.is_active);
          setIsObsolete(res.data.data.is_obselete);
          setActiveSubs(res.data.data.active_subscribers)
         

       let priceType=null;

  if(res.data.data.pricing_type==="one_month"){
    priceType="1 month (Monthly)";
  }
   else if(res.data.data.pricing_type==="three_months"){
    priceType="3 months (Qaurterly";
  }
  else if(res.data.data.pricing_type==="six_months"){
    priceType="6 months (Half-yearly)";
  }
  else if(res.data.data.pricing_type==="one_year"){
    priceType="1 year (Yearly)";
  }
  else if(res.data.data.pricing_type==="life_time"){
    priceType="Life time";
  }
  setPricingType(priceType);

       setTaxAmount(res.data.data.tax_percentage)
       setOfferText(res.data.data.description);
     
       let fList=res.data.data.features;
       const activeList= fList.filter((item)=>{
        return item.is_active===true
       })

     
         setFeaturesList(activeList);

      }

        else{
        setGetError(res.data.message);
      }
      


    })
    .catch(err=>{

 setGetError("Error in fetching data");
})


},[refresh]);


const handleState= ()=> {

  const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;

   const dataToSend={
    id: careProgramId,
    is_active: isActive===true? false : true
   }
 
    axios.put(`${APIURL}/api/v1/hospital/care-programs/`,dataToSend, { headers: {
      
      "Authorization" : 'Token '+str,
      'Content-Type': 'application/json'  }


    })

    .then(res=>{
     
      if(res.data.status=== "success"){
     
        setChangeSuccess(true)
    
      }
      else{
     
        setChangeError(true)
          
      }
    })
    .catch(err=>{
 
      setChangeError(true)

})
}

const handleDelete= ()=> {

  const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;
   const dataToSend={
   
    is_obselete: true
   }
 
    axios.post(`${APIURL}/api/v1/hospital/care-programs/${careID}/`,dataToSend, { headers: {
      
      "Authorization" : 'Token '+str,
      'Content-Type': 'application/json'  }


    })

    .then(res=>{
     
      if(res.data.status=== "success"){
     
        setSuccessShow(true)
        setSuccessMsg(res.data.message ? res.data.message : "Care plan marked Obsolete")
        
      }
      else{
        setErrorMsg(res.data.message)
        setErrorShow(true)

      }
    })
    .catch(err=>{
      setErrorShow(true)

})
}

const handleSuccess =() =>{
  history.push('/care-programs')
}

const ConfirmPopup =(props)=> {
  return(
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
           backdrop="static"
            keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
  
         <h4 className="text-danger">Please Confirm to make Care Package obsolete.</h4>
           
            <h5 > (Current active subscribers count is {activeSubs} ) </h5>
         
          </Modal.Body>
          <Modal.Footer>
           
             <Button variant="secondary" onClick={props.onHide}>No</Button>   
             <Button variant="danger" onClick={props.onDelete}>Yes</Button>         
    
          </Modal.Footer>
        </Modal>
      )
}

const StatusPopup =(props)=> {
  return(
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
           backdrop="static"
            keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
        {
          isActive===true?  <h4 className="text-danger">Confirm Disable of Care Package</h4> :
                            <h4>Confirm Enable of Care Package</h4>
        }
         
           
          </Modal.Body>
          <Modal.Footer>
           
             <Button variant="secondary" onClick={props.onHide}>No</Button>   
             <Button variant="danger" onClick={props.onChange}>Yes</Button>         
    
          </Modal.Footer>
        </Modal>
      )
}

const SuccessPopup =(props)=> {
  return(
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
           backdrop="static"
            keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
  
         <h4 className="text-success">Care Package marked obsolete successfully</h4>
           
          </Modal.Body>
          <Modal.Footer>
           
             <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
    
          </Modal.Footer>
        </Modal>
      )
}

const ErrorPopup =(props)=> {
  return(
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
           backdrop="static"
            keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
  
          <h4 className="text-danger">{errorMsg!==''?errorMsg : <h4 className="text-danger">Error in Data Submission. Please try again!</h4>}</h4>
           
          </Modal.Body>
          <Modal.Footer>
           
             <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
    
          </Modal.Footer>
        </Modal>
      )
}

const StatusSuccess =(props)=> {
  return(
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
           backdrop="static"
            keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
  
         <h4 className="text-success">{isActive?'Care Package Disabled Successfully':'Care Package Enabled Successfully'}</h4>
           
          </Modal.Body>
          <Modal.Footer>
           
             <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
    
          </Modal.Footer>
        </Modal>
      )
}

const StatusError =(props)=> {
  return(
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
           backdrop="static"
            keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
  
          <h4 className="text-danger">Error in Data Submission. Please try again!</h4>
           
          </Modal.Body>
          <Modal.Footer>
           
             <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
    
          </Modal.Footer>
        </Modal>
      )
}

const renderTableId=(cellInfo)=> {
     
    return (

      <div > {cellInfo.index + 1} </div>
      
    );
  
  
  }





const renderTable=(cellInfo)=> {
     
    return (

      <div >  {featuresList[cellInfo.index][cellInfo.column.id] ? <h6 className="feature-rt-td">{featuresList[cellInfo.index][cellInfo.column.id]}</h6> : null }</div>
      
    );
  
  
  }

  const renderService=(cellInfo)=> {
    
     return (
  
      <div > {featuresList[cellInfo.index][cellInfo.column.id]===true ? <h6 className="feature-rt-td">Yes</h6> :featuresList[cellInfo.index][cellInfo.column.id]===false? <h6 className="feature-rt-td">No</h6>: null }</div>
       
     );
  }

  const renderServiceType=(cellInfo)=> {
  
    
   return (

    <div className="caps">  {(featuresList[cellInfo.index][cellInfo.column.id]).replace("_"," ")}</div>
     
   );
 
}

const renderLimit=(cellInfo)=> {
  
    
   return (

    <div className="caps feature-rt-td">  {featuresList[cellInfo.index].limit} {featuresList[cellInfo.index].service_unit!=="count" ? featuresList[cellInfo.index].service_unit : null}</div>
     
   );
 
}


  /*const renderDefault=(cellInfo)=> {
     
    return (

      <div > {featuresList[cellInfo.index].is_default ? <button className="btn btn-secondary btn-smallest btn-square btn-default"> Default </button>: <button className="btn btn-info btn-smallest btn-square btn-default"> Custom </button>}</div>
      
    );
  
  
  }*/
  const columnsFeaturesList= [
  {
  Header: "ID",
  accessor: "feature",
  Cell: renderTableId,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
  
  minWidth:60,
   sortable:false

},
{
  Header: "Feature",
  accessor: "title",
  Cell: renderTable,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
  
  minWidth:280,
   sortable:false

},{
   Header: <div className="header-features-table"><h6>Free usage</h6>
    <h6>(in unit service)</h6>
           </div>,
  accessor: "limit",
 
  Cell: renderLimit,
   sortable:false,
  minWidth:160

},
{
  Header: <div className="header-features-table"><h6>Extra Price</h6>
          <h6>(for additional</h6> 
           <h6>unit service)</h6></div>,
  accessor: "extra_cost",
  Cell: renderTable,
   sortable:false,
   minWidth:120,
   

},
/***
{
  Header: "Service Request",
  accessor: "on_request_service",
  Cell: renderService,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
  
  width:150,
   sortable:false

},
***/
{
  Header: "Service Type",
  accessor: "service_type",
  Cell: renderServiceType,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
  
  width:150,
   sortable:false

},
]

 

  featuresTable=<ReactTable 
         columns={columnsFeaturesList}
         data={featuresList}
          pageSize={featuresList.length>0 ? featuresList.length : 10}
         resizable
        className="features-reacttable"            
            showPagination={false}         
         >

         </ReactTable> 


const handleBack=(e)=>{
   history.goBack();
}
  
  return (
    <> <HospBdoNavbar />
    


       <div className="hospital-dashboard-container hosp-bdo-page">
       <br/>

   <div className="flex-head">
          <button className="btn btn-info topleft-single-btn" onClick={handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back</button> 
          <h2 className="title-bdo title-mg"> {title} </h2>
    </div> 
    <br/>

    <div className=" features-subs ">

  {/*******************details **********/}
   <div className="pgm-details-box">
    <div className="pgm-details-header box-theme">
                               

                      <h6 className="sub-section-title pgm-header-title"> Care Package Details </h6>
                      </div>
   <br/>
   <div className="care-pgm-details-body">
    <table className="featuresdetails-table">
    <tr>
    <td>
          <h6 className="features-form text-left">Standard Price:</h6>
      </td>

      <td>
         <span className="price-box">
            <span className="currency-uppercase"> {currency}</span>
             {price}
             </span>
      </td>
  </tr>

      <tr>
    <td>
    
       <h6 className="features-form text-left"> Price after dicount: </h6> 
   </td>

      <td>
  <span className="price-box"><span className="currency-uppercase"> {currency}</span> {discount} </span>
   </td>
  </tr>

      <tr>
    <td>
    <h6 className="features-form text-left"> Price plan : </h6>
    </td>

      <td>
      <span className="price-box">{pricingType} </span>
    </td>
  </tr>

      <tr>
    <td>
      <h6 className="features-form text-left"> Tax : </h6>
          </td>

      <td>
         <span className="price-box"> {taxAmount ? taxAmount +" %" : "nil"} </span>
          </td>
  </tr>
  <tr>
    <td>
      <h6 className="features-form text-left"> Offer/Info Text:</h6>
     </td>

      <td>
     <span className="">  <p className="text-area  price-box"> {offerText ? offerText : 'Nil' }</p></span>
   </td>
  </tr>

<tr>
    <td>
      <h6 className="features-form text-left"> Active Subscribers count:</h6>
     </td>

      <td>
     <span className="">  <p className="text-area  price-box"> {activeSubs}</p></span>
   </td>
  </tr>

      <tr>
    <td>
     <h6 className="features-form text-left"> Status (Active/ Inactive):</h6>
   </td>

      <td> 
     <span className="price-box"> {isActive=== true ? "Active" : "Inactive"} </span>
      </td>
  </tr>

  <tr>
    <td>
     <h6 className="features-form text-left " style={{color:"red"}}> Obsolete status:</h6>
   </td>
 
      <td> 
     <span> {isObsolete=== true ? <span className="price-box grey-box" style={{color:"red"}}> Is obsolete </span> : <span className="price-box"> Not obsolete </span>}</span>
      </td>
  </tr>


      
      
    </table>
    </div>
    
   </div>
  

    <div className="">
     {featuresTable}
     
     </div>

 </div>

<br/>
<br/>

    
<br/>
<br/>
<br/>


 <Link to={`edit/${careID}`}><button style={{marginRight:'10px'}} className="btn btn-primary btn-col" > Edit Care Package </button></Link>
 {isObsolete=== false ?
   <button style={{marginRight:'10px'}} onClick={()=>setStatusConfirm(true)} className={isActive===true? 'btn btn-secondary' : 'btn btn-info' } >{isActive===true? <span>Disable</span> : <span>Enable</span> }</button>
 : null}

  {isObsolete=== false ?
  <button onClick={()=> setConfirmShow(true)} className="btn btn-danger" >Mark Obsolete</button>
  : null}
<br/>
<br/>
  {isObsolete=== false ?
<div className="info-div italics-text"> 
   <h6>**Click on Enable/Disable button to make a Care Package  active/inactive for some time period.(Cannot add new subscriptions during inactive period)</h6><br/>
   <h6>**Click on Mark Obsolete button to make a Care Package Obsolete (Existing subscribers would continue unaffected, but cannot add any new subscriptions.)</h6>

</div> 
: null}
{
        confirmShow ? 
        <ConfirmPopup
        show={confirmShow}
        onHide={()=> setConfirmShow(false)}
        onDelete={()=>{ handleDelete(); setConfirmShow(false)}} /> : null
      } 

{
        statusConfirm ? 
        <StatusPopup
        show={statusConfirm}
        onHide={()=> setStatusConfirm(false)}
        onChange={()=>{handleState(); setStatusConfirm(false)}} /> : null
      } 

{
        successShow ? 
        <SuccessPopup
        show={successShow}
        onHide={handleSuccess} /> : null
      } 

{
        errorShow ? 
        <ErrorPopup
        show={errorShow}
        onHide={()=> setErrorShow(false)} /> : null
      } 

{
        changeSuccess ? 
        <StatusSuccess
        show={changeSuccess}
        onHide={()=>{setChangeSuccess(false); setRefresh(!refresh)}} /> : null
      } 

{
        changeError ? 
        <StatusError
        show={changeError}
        onHide={()=> setChangeError(false)} /> : null
      } 

</div>

    </>
  );
}
export default CareProgramDetails;
