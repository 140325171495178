import React,{useState ,useEffect} from "react";
import {Link,useHistory} from "react-router-dom";
import  axios from "axios";
import "./frequentmedstests.css"

import HospDocNavbar from "../HospDocNavbar/HospDocNavbar";
import {APIURL} from "../../Global";
function FrequentMedsTests(props) {
  
 
    const [medList,setMedList]=useState([]);
    const [,setTestList]=useState([]);
    const [errorMsg,setErrorMsg] = useState('');
    const [patientId,]=useState(props.match.params.id);
    const [testDetailsList,setTestDetailsList]=useState([]);

   let history = useHistory();
  
useEffect(()=>{

  const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  /* REGEX To remove double quotes from tokenstring */
          
    
     
  
    axios.get(`${APIURL}/api/v1/patient/regular-medicines-tests/?patient_id=${patientId}`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
    

  
      if(res.data.status==="success"){
      setMedList(res.data.frequent_medicines);
   
      let testObjectList=res.data.frequent_tests;
     let testArray=[];
     let testObjArray=[];
      for(const k in testObjectList){

     
        testObjArray.push(testObjectList[k])

        let testRow= testObjectList[k];
        for(const x in testRow){
     
          testArray.push(x);
        }
      }
     setTestList(testArray);
      setTestDetailsList(testObjArray);
   

      }else{
        setErrorMsg(res.data.message? res.data.msg : "Error in fetching data");
      }


    })
    .catch(err=>{
 
  setErrorMsg("Error in fetching data");
})
 
},[patientId]);




const displayMeds = medList.length>0 ? medList.map((item,index)=>{
             
                  return (<>
                       <div key={index} className="list-item freq-item">
                       {index+1}. {item}
                       </div>
                       </>
                       )
                }): <h6 style={{color:"grey"}}> No Data at the moment</h6>;

    

    const displayTestDetails=testDetailsList.length>0 ? testDetailsList.map((item,index)=>{
                for(const k in item){

                
                  
                  return (<>
                       <Link to={{ pathname: '/test-detail', 
                                   
                                   state: { name: k,
                                            arrayData:item[k] }}  } className="list-item freq-item"><div key={index}>
                         <h6>{k}  </h6>
                       
                       </div></Link>
                       </>
                       )
                }
                }): <h6 style={{color:"grey"}}> No Data at the moment</h6>;

 const handleBack=(e)=>{
  history.goBack();
}                                           

  return (<>
     <HospDocNavbar />
  <div className="search-patient-page">
    <h2 className="title-of-page"> Frequent Medicines & Lab Tests Analysis </h2>
     <div className=" text-left">
          <button className="btn btn-info back-btn" onClick={handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back</button> 
    </div> 
    <br/>


<div className="flex-freq-container">

{/*******************TESTS Details*****************/}

<div className="frequent-holder">
   <div className="freq-header"><h5> Lab Tests</h5></div>
<div className="search-section">
  
    {displayTestDetails}
    </div>
</div>

{/**********************/}


{/**************MED ****************/}
<div className="frequent-holder">
<div className="freq-header"><h5> Medicines </h5></div>

<div className="search-section">

  
    {displayMeds}
    </div>

</div>

{/*****************/}




</div>
    </div>
    {errorMsg!==""? (
    
                      <div>
                        <h6 style={{ color: "red" }}>
                          {errorMsg}
                        </h6>
                      </div>
                    ) : null}
                             
       

 </> );
}

export default FrequentMedsTests;
