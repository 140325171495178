import React, {useState,useEffect, useRef} from 'react'
import Select from 'react-select';
import axios from "axios";
import {APIURL} from "../../Global";
import Pagination from "react-js-pagination";
import DatePicker from 'react-date-picker';
import PhoneInput from 'react-phone-input-2';

function ServiceBooking() {

    const [serviceData,setServiceData]= useState([])
    const [serviceId, setServiceId]= useState("")
    const [searchTerm,setSearchTerm]= useState("")
    const [currentPage, setCurrentPage]= useState(1)
    const [priority, setPriority]= useState("")
    const [dueDate, setDueDate]= useState("")
    const [show, setShow]= useState(false)

    
    const priorityOptions = [ {value: 'critical' , label: 'Critical'},
    { value: 'very high' , label: 'Very High'},
       { value: 'high' , label: 'High'},
      { value: 'medium' , label: 'Medium'},
       { value: 'low' , label: 'Low'},

       
    ];

    useEffect(()=> {

        const tokenString = sessionStorage.getItem("usertoken");

        let str = tokenString.replace(/["]+/g, "");

        //const hospId = JSON.parse(sessionStorage.getItem('bdodata'))

  axios
    .get(`${APIURL}/api/v1/patient/service-list/`, {
      headers: {
        Authorization: "Token " + str,
      },
    })
    .then((res) => {

      if (res.data.status === "success") {
        setServiceData(res.data.hospital_services_list)
      }
    })
    .catch((err) => {

    });

    },[])

    const handleServiceSelect = (id) => {
      setShow(true)
      setServiceId(id)
    }
      
      const serviceDisplay = serviceData.map((value,index) => {
        return(
              <div id="csc-listing" onClick={ ()=> handleServiceSelect(value.id) } style={{cursor:"pointer",width:"90%"}} key ={value.id} className={serviceId === value.id ? 'list-item flex-row doc-select-bg':'list-item flex-row'}>               
                  <h5 className="each-list-link">{index+1}.{value.serviceName}</h5>  
                  <div style={{marginLeft:"auto"}} className="details">
                    {/* <span> Price: {value.price} </span> */}
                    {/* <p> Phn : {value.mobile_number} </p> */}
                  </div>                                                                 
              </div>
          )
      })

    const handleSearchChange = e => {
        setSearchTerm(e.target.value)
      }
      
      const handlePageChange = (pageNumber)=> {
        setCurrentPage(pageNumber)
      }

    const handlePriorityChange = data => {
        setPriority(data)
    }

    const handleDueDate = date => {
        setDueDate(date)
    }

    return (
        <div style={{gap:"10px"}} className='d-flex justify-content-center container'>

        <div className='flex-col justify-content-start appointment-doctor-list'>
          <h4 className="title-of-tasks">Service List</h4>
          <div className="search-div">
              <input
                className="form-control search-input"
                style={{width:"77%"}}
                type="text"
                maxLength="150"
                placeholder="Enter Name"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <button  className="btn btn-primary btn-col search-btn">
                {" "}
                <i className="fas fa-search"></i>{" "}
              </button>        </div>
            {serviceDisplay}

            <br/>
            <br/>

            {serviceDisplay.length > 2 ?
    <div className="pagn">

            <Pagination
          activePage={currentPage}
          itemsCountPerPage={5}
          totalItemsCount={3}
          pageRangeDisplayed={2}
          onChange={handlePageChange}
          disabledClass = "disabled-class" 
          />
    </div>
    : null}

        </div>
{
    show ?

    <div className="service-details">

    <h4 className="title-of-tasks">Home Visit</h4>
    <div className='flex-row name-content'>
        <span>Priority : </span>
        <Select
                    value={priority}
                    onChange={(data,e)=>{handlePriorityChange(data,e)
                    }}
                    options={priorityOptions}
                    name='service_type'
                    className="select-currency service-priority"
                />
                {/* {error.type ? <div className="error-validation-msg error-feature">{error.type}</div> : null} */}
    </div>

    <div className='flex-row name-content'>
        <span>Due Date : </span>
        <div className='service-date'>
            <DatePicker
                  onChange={date=>{handleDueDate(date)}}

                  minDate = {new Date()}
                  value={dueDate}
                  
             />
        </div>
                {/* {error.type ? <div className="error-validation-msg error-feature">{error.type}</div> : null} */}
    </div>

    <div className='flex-row name-content'>
        <span>Contact : </span>
            <div className='service-phone-size'>
                <PhoneInput
                inputProps={{   }}
                country={'in'}
                // value={}
                // onChange={}

                                  />
            </div>
                {/* {error.type ? <div className="error-validation-msg error-feature">{error.type}</div> : null} */}
    </div>

    <div className='flex-row name-content'>
        <span>Address : </span>

        <textarea className="text-area service-phone-size" cols="100" rows="4"/>
    </div>

    <div className='flex-row name-content'>
        <span>Details : </span>

        <textarea  className="text-area service-phone-size" cols="100" rows="4"/>
    </div>

    <button className='btn btn-primary btn-col'>Submit</button>

</div> : null

}



      </div>
    )
}

export default ServiceBooking
