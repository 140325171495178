import React from "react";

import "./writtenpresclist.css";
import { Link } from "react-router-dom";
import axios from "axios";
import Pagination from "react-js-pagination";
import "./pagination.css";
import { Dropdown } from 'react-bootstrap';
import {APIURL} from "../../../Global";
class WrittenPrescList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datalist: [],
         activePage: 1,
      order: "asc",
      dropdownOpen : false,
      totalpage:1,
      sort: "sort",
      keys : {
        'h': false,
        'd': false,
        'u': false,
        'r': false,
        'l': false,
        'i': false,
        'a': false,
        'p': false,
        'v': false,
        't': false,
        'n': false
       },
       package:"package",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
 
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); /* REGEX To remove double quotes from tokenstring */


    axios
      .get(`${APIURL}/api/v1/staff/prescriptions/v2/?is_handwritten=true&sort_by=${this.state.order}&per_page=10&page=1`, {
        headers: { Authorization: "Token " +v2 },
      })
      .then((res) => {
        if (res.data.status === "success") {
          this.setState({totalpage:res.data.total_pages})
          const datas = res.data.pending_verifications;

          this.setState({ datalist: datas });
           this.setState({activePage: 1});
        } else {

        }
      })
      .catch((err) => {

      });

        //////* Shotcuts ********/ 

  window.onkeydown =(e) => {
    
    this.state.keys[e.key]=true

    if(e.altKey && this.state.keys['h']){
      this.props.history.push('/hospitallist')
    }
    if(e.altKey && this.state.keys['d']){
      this.props.history.push('/doctorslist')
    }
    if(e.altKey && this.state.keys['u']){
      this.props.history.push('/search-hospitals')
    }
    if(e.altKey && this.state.keys['r']){
      this.props.history.push('/rejectedlist')
    }
    if(e.altKey && this.state.keys['l']){
      this.props.history.push('/reportlist')
    }
    if(e.altKey && this.state.keys['i']){
      this.props.history.push('/addsynonyms')
    }
    if(e.altKey && this.state.keys['a']){
      this.props.history.push('/failedlist')
    }
    if(e.altKey && this.state.keys['p']){
      this.props.history.push('/prescriptionlist')
    }
    if(e.altKey && this.state.keys['t']){
      this.props.history.push('/testlist')
    }
    if(e.altKey && this.state.keys['n']){
      this.props.history.push('/nutrition')
    }


  }
  window.onkeyup=(ev)=> {
    
    this.state.keys[ev.key]= false
  }

}

componentWillUnmount(){ 
  window.onkeydown=null
  window.onkeyup=null
}

  render() {
    //const toggle = () => this.setState({dropdownOpen : !this.state.dropdownOpen});
    const handlePageChange=(pageNumber)=> {
   
    this.setState({activePage: pageNumber});
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); /* REGEX To remove double quotes from tokenstring */

   
    if(this.state.package !== 'package' && this.state.package !== 'All'){
      axios
      .get(`${APIURL}/api/v1/staff/prescriptions/v2/?is_handwritten=true&sort_by=${this.state.order}&per_page=10&page=${pageNumber}&plan=${this.state.package}`, {
        headers: { Authorization: "Token "+ v2 },
      })
      .then((res) => {
        if (res.data.status === "success") {
          const datas = res.data.pending_verifications;

          this.setState({ datalist: datas });
        } else {

        }
      })
      .catch((err) => {

      });
    }

    else {
      axios
      .get(`${APIURL}/api/v1/staff/prescriptions/v2/?is_handwritten=true&sort_by=${this.state.order}&per_page=10&page=${pageNumber}`, {
        headers: { Authorization: "Token "+ v2 },
      })
      .then((res) => {
        if (res.data.status === "success") {
          const datas = res.data.pending_verifications;

          this.setState({ datalist: datas });
        } else {

        }
      })
      .catch((err) => {

      });
    }
    
  }


    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); /* REGEX To remove double quotes from tokenstring */

 
    let dataDisplay = this.state.datalist.map((data, index) => {
      let idval = data.prescription.id;
      let url = `/written-presc/${idval}`;
        let serialNum = this.state.activePage*10 - 10;
      return (
        <div className="list-item">
          <h5 className="each-list-link">
            {serialNum + index + 1}. <Link to={url}>{data.patient.name} - Prescription ID_{data.prescription.id} </Link>
          </h5>
          <div className="details">
            <p> Patient Id: {data.patient.id} </p>
            <p> Date : {data.uploaded_at} </p>
            <span>Package : {data.patient.package}</span>
          </div>
        </div>
      );
    });
    const refresh = () => {

      if(this.state.package !== 'package' && this.state.package !== 'All'){
        axios
        .get(`${APIURL}/api/v1/staff/prescriptions/v2/?is_handwritten=true&sort_by=${this.state.order}&per_page=10&page=${this.state.activePage}&plan=${this.state.package}`, {
          headers: { Authorization: "Token "+ v2 },
        })
        .then((res) => {
          if (res.data.status === "success") {
            const datas = res.data.pending_verifications;

            this.setState({ datalist: datas });
          } else {

          }
        })
        .catch((err) => {

        });
      }

      else {
        axios
        .get(`${APIURL}/api/v1/staff/prescriptions/v2/?is_handwritten=true&sort_by=${this.state.order}&per_page=10&page=${this.state.activePage}`, {
          headers: { Authorization: "Token "+ v2 },
        })
        .then((res) => {
          if (res.data.status === "success") {
            const datas = res.data.pending_verifications;

            this.setState({ datalist: datas });
          } else {

          }
        })
        .catch((err) => {

        });
      }
      
    };

const onSort = async order=>{
  await this.setState({order: order});
  
  await refresh();
}

const onFilter = (data)=> {
  const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); /* REGEX To remove double quotes from tokenstring */


    axios
      .get(`${APIURL}/api/v1/staff/prescriptions/v2/?is_handwritten=true&sort_by=${this.state.order}&per_page=10&page=1&plan=${data}`, {
        headers: { Authorization: "Token " +v2 },
      })
      .then((res) => {
        if (res.data.status === "success") {
          this.setState({totalpage:res.data.total_pages})
          const datas = res.data.pending_verifications;

          this.setState({ datalist: datas });
           this.setState({activePage: 1});
        } else {

        }
      })
      .catch((err) => {

      });
}
    return (
      <div className="container displaylist-container ">
        <div className="dashboard-navigation">
          <div>
            {" "}
            <Link to="/dashboard">
              {" "}
              <i className="fas fa-home dasboard-icon"> </i>Dashboard{" "}
            </Link>
          </div>

          <div>
            {" "}
            <i className="fas fa-angle-double-right angle-icon"></i>{" "}
          </div>
          <div>
            {" "}
            <Link to="/written-prescriptionlist"> Written-PrescriptionList </Link>{" "}
          </div>
        </div>

        <div className="header-display">
          <h2 className="title-of-page"> Written Prescription List</h2>
         

          <div className="buttons-wrapper">
          <button className="btn btn-secondary" onClick={refresh}>
            Refresh List <i className="fas fa-sync-alt"></i>
          </button>


          <Dropdown>


  <Dropdown.Toggle variant="secondary" id="dropdown-basic">
    {this.state.sort}
  </Dropdown.Toggle>

  <Dropdown.Menu>
    <Dropdown.Item onClick={()=>{onSort("asc") ;this.setState({sort:"Sort Ascending"})}}>Ascending</Dropdown.Item>
    <Dropdown.Item  onClick={()=>{onSort("desc");this.setState({sort:"Sort Decending"})}}>Descending</Dropdown.Item>
  
  </Dropdown.Menu>
</Dropdown>

<Dropdown>

<Dropdown.Toggle variant="secondary" id="dropdown-basic">
  {this.state.package}
</Dropdown.Toggle>

<Dropdown.Menu>
<Dropdown.Item  onClick={()=>{onFilter("");this.setState({package:"All"})}}>All</Dropdown.Item>
  <Dropdown.Item  onClick={()=>{onFilter("basic");this.setState({package:"Basic"})}}>Basic</Dropdown.Item>
  <Dropdown.Item  onClick={()=>{onFilter("silver");this.setState({package: "Silver"})}}>Silver</Dropdown.Item>
  <Dropdown.Item onClick={()=>{onFilter("gold") ;this.setState({package: "Gold"})}}>Gold</Dropdown.Item>
  <Dropdown.Item onClick={()=>{onFilter("platinum") ;this.setState({package: "Platinum"})}}>Platinum</Dropdown.Item>

</Dropdown.Menu>
</Dropdown>
</div>
        </div>

        <div className="test-reports-display-list">
          {dataDisplay.length ? (
            dataDisplay
          ) : (
            <span style={{ color: "red", fontSize: "25px" }}>
              No more Pending list to show!!
            </span>
          )}
        </div>

        <Pagination
          activePage={this.state.activePage}
          itemsCountPerPage={10}
          totalItemsCount={this.state.totalpage*10}
          pageRangeDisplayed={this.state.totalpage}
          onChange={handlePageChange}
          disabledClass = "disabled-class"
        />

        {/* <button type="submit" className="next-btn">Next</button> */}
      </div>
    );
  }
}

export default WrittenPrescList;
