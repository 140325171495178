import React, {useState, useEffect} from "react";

import "./patientpresc.css";
import axios from "axios";

import {APIURL} from "../../Global";
function PatientPrescription(props){
     const [prescId,] =useState(props.prescid);
      const [patientId,] =useState(props.patientid);
   const [medList,setMedList]=useState([]);
   const [imageList,setImageList]= useState([]);
     const [rotation,setRotation]=useState(0);
     const [prescDate,setPrescDate] = useState();
     const [prescDoctor,setPrescDoctor] =useState('');
     const [prescHospital,setPrescHospital] =useState('');
     const [scaleValue,setScaleValue]=useState(1);
     const [isPdf, setIsPdf]= useState(false)
     const [pdf, setPdf]= useState('')

     useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
useEffect(()=>{

  const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  /* REGEX To remove double quotes from tokenstring */
          
    
     
  
    axios.get(`${APIURL}/api/v1/patient/prescriptions/${prescId}/?src=website&patient_id=${patientId}`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
  

   
      if(res.data.status==="success"){
     
        if(res.data.data.is_pdf===false){
          setPdf(res.data.data.images[0])
          setIsPdf(true)  
        } 
     setMedList(res.data.data.medicines);
      setImageList(res.data.data.images);
      setPrescDate(res.data.data.date);
      setPrescDoctor(res.data.data.doctor_name);
      setPrescHospital(res.data.data.hospital_name);
      }else{
       
      }


    })
    .catch(err=>{
 
 
})
 

 },[]);

const handleScale=()=>{
  scaleValue === 1? setScaleValue(1.5) : setScaleValue(1);
 
}
const handleRotateLeft=()=>{
    let newRotation = rotation - 90;
    if(newRotation >= 360){
      newRotation =- 360;
    }if(newRotation === -360){
      newRotation =0;
    }
    setRotation(newRotation);
   
  }

const displayMed = <table >
                      <tr className="table-header">
                        <th> Id</th>
                        <th> Drug</th>
                        <th> Strength</th>
                        <th> Dosge Form</th>
                        <th> Route</th>
                        <th> Intake Period</th>
                        <th> Frequency</th>
                        <th> Duration (Days)</th>
                        <th> Till Date</th>
                        <th> Intake Count</th>
                        <th> Intake Unit</th>
                         <th> Intake Time</th>
                          <th> Remarks</th>
                        
                      </tr>
                      
                    {medList? medList.map(item=>{
                     return  <tr className="table-row">
                               <td> {item.id} </td>
                               <td> {item.drug} </td>
                               <td> {item.strength} {item.strength_unit} </td>
                               <td> {item.form} </td>
                               <td> {item.route} </td>
                               <td> {item.intake} </td>
                               <td> {item.frequency} </td>
                               <td> {item.duration} </td>
                               <td> {item.till_date} </td>
                               <td> {item.intake_count} </td>
                               <td> {item.intake_unit} </td>
                                <td> {item.intake_time} </td>
                                 <td> {item.remarks} </td>
                              
                            </tr>
                      }) 
                      : null}
                   </table>;

const prescImage=isPdf===false? imageList.map(prescImg=>{
  return  <div className="image-container">
        <div className="img-holder-inside">
        <img src={prescImg} width="450px" height="auto" alt="report" onDoubleClick={handleScale} style={{transform: `scale(${scaleValue}) rotate(${rotation}deg)`}}/>
        </div><button className="btn btn-primary btn-col" onClick={handleRotateLeft}> Rotate <i className="fa fa-undo" aria-hidden="true"></i> </button>
       
      </div>


}) : <div style={{width:'600px', height:'550px'}}>
<object data={pdf} type="application/pdf" width="100%" height="100%">
</object></div>

  return(
    <>
    {/*<HospDocNavbar /> */}
    <div className="patient-presc-page"><br/>
    
    <h3 className="title-of-tasks"> Prescription {prescId} </h3>
  {/*  <h5 className="title-of-tasks text-center"> Patient ID {patientId}</h5> */}
    {imageList.length===0||pdf===undefined?<> <h6 className="text-right overlay-subtitle dr-title" >Doctor : <span style={{color:"grey"}}> {prescDoctor}</span></h6>
     <h6 className="text-right overlay-subtitle hosp-title">Hospital : <span style={{color:"grey"}}> {prescHospital}</span></h6>
    <h6 className="text-right overlay-subtitle">Date: <span style={{color:"grey"}}>{prescDate? prescDate : "n/a"}</span> </h6></>:""}
   
    <div className="patient-presc-sections">
    {imageList.length===0||pdf===undefined? <div className="table-holder">
          {displayMed}
          </div>:""}
          <div className="images-holder">
          {prescImage}
          </div>
    </div>
    
    </div>
    </>)
}


export default PatientPrescription;