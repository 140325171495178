import React, {useState, useEffect} from 'react'
import axios from 'axios';
import {APIURL} from "../../Global";
import {Link, useHistory } from "react-router-dom";
import HospitalNavbar from '../HospitalNavbar/HospitalNavbar';
import './doctorpayment.css'
import LicenseTableBasic from './LicenseTableBasic';
import LicensePricing from './LicensePricing';
import FreeUserList from './FreeUserList';

function LicenseDetails(props) {

    const [licenseData, setLicenseData]= useState([])
    const [userData, setUserData]= useState([])

const id =props.location.id
const flag = props.location.flag

const history =useHistory();

const [keys,] = useState({'d': false,'a': false,'b': false,'o': false,'n': false,'s': false,'l': false,'p': false,'c': false,'h': false});

useEffect(()=> {
  window.scrollTo(0,0)
},[])


useEffect(()=>{

window.onkeydown =(e) => {
 
 keys[e.key]=true

 if(e.altKey && keys['d']){
   history.push('/doctors-list')
 }
 if(e.altKey && keys['a']){
   history.push('/hospital/add')
 }
 if(e.altKey && keys['b']){
   history.push('/bdo-list')
 }
 if(e.altKey && keys['o']){
   history.push('/hospital/add-bdo')
 }
 if(e.altKey && keys['n']){
   history.push('/emergency-number')
 }
 if(e.altKey && keys['s']){
   history.push('/service-request-list')
 }
 if(e.altKey && keys['l']){
   history.push('/license-details')
 }
 if(e.altKey && keys['p']){
   history.push('/license-payment')
 }
 if(e.altKey && keys['c']){
   history.push('/hosp-calllog')
 }
 if(e.altKey && keys['h']){
   history.push('/payment-history')
 }

}
window.onkeyup=(ev)=> {
 
 keys[ev.key]= false
}

return()=>{
 window.onkeydown=null
 window.onkeyup=null
}
},[]);

const handleBack=(e)=>{
    if(flag==="bdo-page"){
        history.push('/bdo-list');
      }
      else if(flag==="dr-page"){
        history.push('/doctors-list');
      }
      else if(flag==="others"){
        history.push('/hospital');
      }
      else if(flag==='sda-page'){
        history.push('/sda-list')
      }
      else{
        history.goBack();
      }
}


useEffect(()=>{
    const tokenString= sessionStorage.getItem('usertoken');
       
       let str= tokenString.replace(/["]+/g, '')  ;
       
    axios.get(`${APIURL}/api/v1/hospital/hospital-licenses/`, { headers: {
          
        "Authorization" : 'Token '+str  }})
      .then(res=>{
       
  
     
     
        if(res.data.status==="success"){ 

          const data =res.data.hospital_licenses
          setLicenseData(data)
          setUserData(res.data.free_user_details.active_free_user_details)
              
          }

        else{


          }
  
      })
      .catch(err=>{
  
  })
    
    
    },[]);


    return (<>
        <HospitalNavbar /><br/>
    <div className="text-left">
        
          <button className="btn btn-info back-btn" onClick={handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back</button> 


    </div> 
   
        <div className='flex-col'>
            <h2 className="title-of-page">Licence Details </h2>
            <div className='each-section'>
                <h4 className="sub-title"> Purchased Licence Details  </h4>
                
                                                     
                        <LicenseTableBasic id={id} data={licenseData} />                  
                        <Link to={{pathname:'license-payment', flag:"others-back" }}><button type="button" style={{marginLeft:"10px"}} className="btn btn-info dr-btn"> Purchase Licence </button></Link>
            </div>

           
                <div className="second-contentbox" >
                    <div className="first-content" >
                        <div className="sub-section-header box-theme">
                            <h5 className="sub-section-title"> Licence Pricing</h5>                                                                            
                        </div>
                        <LicensePricing />
                    </div>

                    <div className="first-content" >
                        <div className="sub-section-header box-theme">
                            <h5 className="sub-section-title"> Free User List</h5>                                                                            
                        </div>
                        <FreeUserList users={userData} />
                    </div>
                </div>
            
            
        </div>
          <div className="notes-info italics-text">
          
    <h6> All users will have free access during Free usage period (if any). </h6>
    <h6> Validity of any Licence bought will be calculated only from the date when Free usage period ends.</h6>
    <h6  > To add Doctors and BDOs to licence as free/paid user , goto <Link to="/doctors-list">Doctors list</Link> and <Link to="/bdo-list">BDO list</Link> , click on "Add Free User" or "Add Licence" links under Licence status in details cards .
    </h6>

    </div>
        </>
    )
}

export default LicenseDetails
