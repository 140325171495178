import React ,{Component} from 'react';
import Pagination from "react-js-pagination";
import "./pagination.css";
import './userlist.css'
import axios from "axios";
import {APIURL} from '../../../Global'
import {Link as Scroll} from 'react-scroll'
import HospBdoNavbar from "../../HospBdoNavbar/HospBdoNavbar";
import {Modal, Button} from 'react-bootstrap';
import AddPatient from './AddPatient';
let prev =['']

let j=0

const menu = 'a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z'.split(',')

class ConnectedPatientsPage extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            patientdata : [] ,
             searchTerm : '',
             currentPage : 1,
             perPage : 10,
             modalShow: false, 
             id : '',
             count : '',  
             refresh : false,
              loading:true,
              keys : {
                'u': false,
                'c': false,
                'n': false,
                'a': false,
                's': false,
                'q': false,
                'l': false
               }   
        }
    }
   

    
  handleBack=(e)=>{
     this.props.history.goBack();
   }

    componentDidMount(){
      window.scrollTo(0, 0);
        const tokenString = sessionStorage.getItem("usertoken");
  
      let v2 = tokenString.replace(
        /["]+/g,
        ""
      ); /* REGEX To remove double quotes from tokenstring */


        axios
        .get(`${APIURL}/api/v1/hospital/connected-patients/?care_programs_count=true`, {
          headers: { Authorization: "Token "+ v2 },
        })
        .then((res) => {
          this.setState({loading : false});
          if (res.data.status === "success") {
            const data = res.data.results      
            this.setState({patientdata : data})
        }
        else{

        }
    }).catch((error)=>{

    })

      //////* Shotcuts ********/ 

      window.onkeydown =(e) => {
    
        this.state.keys[e.key]=true
  
        if(e.altKey && this.state.keys['u']){
          this.props.history.push('/directory')
        }
        if(e.altKey && this.state.keys['c']){
          this.props.history.push('/careplans-db')
        }
        if(e.altKey && this.state.keys['n']){
          this.props.history.push('/notifications')
        }
        if(e.altKey && this.state.keys['a']){
          this.props.history.push('/analytics')
        }
        if(e.altKey && this.state.keys['s']){
          this.props.history.push('/service-requests')
        }
        if(e.altKey && this.state.keys['q']){
          this.props.history.push('/qrcode')
        }
        if(e.altKey && this.state.keys['l']){
          this.props.history.push('/call-log')
        }
    
    
      }
      window.onkeyup=(ev)=> {
        
        this.state.keys[ev.key]= false
      }
}

componentWillUnmount(){ 
  window.onkeydown=null
  window.onkeyup=null
}

componentDidUpdate(prevProps, prevState){
    
  if(prevState.refresh !== this.state.refresh){
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); /* REGEX To remove double quotes from tokenstring */


      axios
      .get(`${APIURL}/api/v1/hospital/connected-patients/?care_programs_count=true`, {
        headers: { Authorization: "Token "+ v2 },
      })
      .then((res) => {
        if (res.data.status === "success") {
          const data = res.data.results       
          this.setState({patientdata : data})
      }
      else{

      }
  }).catch((error)=>{

  })
  }

}

    handleSearchChange = (event)=> {
      this.setState({currentPage:1})
        this.setState({searchTerm: event.target.value})
    }

    handlePageChange = (pageNumber)=> {
      this.setState({currentPage:pageNumber})
    }

    modalClose = (childData)=> {
      this.setState({modalShow:childData})
    }

    handleRefresh =(childData)=> {
      this.setState({refresh:childData})
    }


    render() {
          
         const{patientdata ,searchTerm, currentPage, perPage, modalShow, id ,refresh}=this.state


const ProgramPopup=(props)=> {
 
 
          return (
            <Modal
              {...props}
             
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                 backdrop="static"
                  keyboard={false}
              
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                 
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <AddPatient id={id} modalStatus={this.modalClose} refresh={refresh} modalToggle={this.handleRefresh} />
               
              </Modal.Body>
              <Modal.Footer>
               
                
               <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>
               
               
              </Modal.Footer>
            </Modal>
          );
        }

        let data = patientdata.map(val=> {
          if(val.care_programs_count<1){
            return val
          }
        }).sort((a,b)=>{
          if(a.care_programs_count < 1 && b.care_programs_count < 1){
            if(a.full_name.toLowerCase() < b.full_name.toLowerCase()) return -1;
            if(a.full_name.toLowerCase() > b.full_name.toLowerCase()) return 1;
            return 0;
          }
          else{
            return null
          } 
      })
      .filter((value)=>{
        if(value!==undefined){
          if(this.state.searchTerm===''){
            return value
        } else if (value.full_name.toLowerCase().includes(this.state.searchTerm.toLowerCase())){
            return value
        }
      }
    })

        const indexLast= currentPage * perPage;
        const indexFirst= indexLast - perPage;
        const current = data.slice(indexFirst ,indexLast)
        const total = data.length;

        const pageNumbers = []
        for(let i=0 ;i<= Math.ceil(total/perPage) ;i++){
          pageNumbers.push(i)
        }
        const totalPages=pageNumbers.length;
        return (<>
          <HospBdoNavbar />
          <br/>
          <div className="flex-head">
        
          <button className="btn btn-info topleft-single-btn" onClick={this.handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back</button> 
          <h2 className=" title-of-tasks title-mg"> Connected Patients Directory </h2><br/>
    </div> 
          
            <div className="search-patient-page directory-page ">

                
        <div className="search-section">
  
       
            <div className="search-div">
              <input
                className="form-control search-input"
                type="text"
                maxLength="30"
                placeholder="Enter Name"
                value={searchTerm}
                onChange={this.handleSearchChange}
              />
              <button  className="btn btn-primary btn-col search-btn">
                {" "}
                <i className="fas fa-search"></i>{" "}
              </button>        </div>
          <div className="container search-results">  
          {
             this.state.loading===true ?
            <h6 className="text-muted"> Loading...</h6>
            :
        current.length>0?   
          current.map((value,index)=>{
              return(
            
                  <div key ={index} className='list-item'>               
                      <h5 className="each-list-link" id={value.id} key={value.id}>{(currentPage-1)*10 + index+1}.{value.full_name}</h5>  
                      <div className="details">
                        <p> Patient Id: {value.id} </p>
                        <p> Phn : {value.mobile_number} </p>
                      </div>
                      
                      <button onClick={()=>{ this.setState({modalShow:true}); this.setState({id:value.id}) }}  className="btn btn-primary btn-col btn-small btn-add-to-cp"> + Add to Care Package </button>                                                               
                  </div>
                
              )          
            }) : <h6 style={{color:"red"}}>No Patients Found</h6>
          }

         
          
            <span className="scroll-class-bdo">
          {
        current.length > 0 ? 
          current.map((val)=>{
                         
              prev[j] = val.full_name
              j++
              for(let i=0 ; i<menu.length;i++){
                  if(j>1){
                      if(prev[j-2].charAt(0).toLowerCase()===val.full_name.charAt(0).toLowerCase()){
                        if(current[0].full_name.toLowerCase()===val.full_name.toLowerCase()){
                          if(val.full_name.charAt(0).toLowerCase()===prev[j-2].charAt(0).toLowerCase()){
                            if(menu[i] === val.full_name.charAt(0).toLowerCase()){                             
                            return(
                                                 
                                <div >
                                {searchTerm==="" ? 
                                 <Scroll activeClass="active" to={val.id} spy={true} offset={-80} smooth={true} duration={450}> <button class ='btn btn-small btn-secondary btn-square btn-letter' style={{position:"relative",bottom:"150px",right:"500px"}}>{menu[i]}</button></Scroll>
                                : null
  
                                }
                                </div>
                                                    
                                  )
                              }
                        }
                          }
                          
                        else{
                          continue
                        }
                      }
                  }                                       
                  if(menu[i] !== val.full_name.charAt(0).toLowerCase()){
                      continue                                                          
                  }
                  
                  else if(menu[i] === val.full_name.charAt(0).toLowerCase()){
                      
                          return(
                                               
                              <div >
                              {searchTerm==="" ? 

                               <Scroll activeClass="active" to={val.id} spy={true} offset={-80} smooth={true} duration={750}> <button style={{position:'relative',top:'-30%',display:'block'}}  class ='btn btn-small btn-secondary btn-square btn-letter' >{menu[i]}</button></Scroll>

                              : null

                              }
                              </div>
                                                  
                                )  
                      } 
                  }                  
              }
          ) : null
        }
        </span>
          </div>
              
            </div>
            {totalPages > 1 ?
    <div className="pagn pagn-small">
    <br/>
    <br/>

            <Pagination
          activePage={currentPage}
          itemsCountPerPage={perPage}
          totalItemsCount={total}
          pageRangeDisplayed={totalPages}
          onChange={this.handlePageChange}
          disabledClass = "disabled-class" 
          />
    </div>
    : null}


       { modalShow? 
<ProgramPopup 
      show={modalShow}
      onHide={()=> this.setState({modalShow:false})}
      id={id}
      
    />

: null  }
            
        </div>
        </>
        )
    }
}

export default ConnectedPatientsPage;