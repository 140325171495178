import React ,{Component} from 'react';
import Pagination from "react-js-pagination";
import {Modal, Button} from 'react-bootstrap';
import "./pagination.css";
import './userlist.css';
import "./connectedpatients.css";
import axios from "axios";
import {APIURL} from '../../../Global'
//import {Link as Scroll} from 'react-scroll'
import AddPatient from './AddPatient';

//let prev =['']

//let j=0

//const menu = 'a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z'.split(',')

class ConnectedPatients extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             patientdata : [] ,
             searchTerm : '',
             currentPage : 1,
             perPage : 2,
             modalShow: false, 
             id : '',
             name:"",
             phn:"",
             count : '',  
             refresh : false, 
             loading:true
        }
    }
   

    
  handleBack=(e)=>{
    this.props.history.goBack();
}
    componentDidMount(){
        const tokenString = sessionStorage.getItem("usertoken");
  
      let v2 = tokenString.replace(
        /["]+/g,
        ""
      ); /* REGEX To remove double quotes from tokenstring */


        axios
        .get(`${APIURL}/api/v1/hospital/connected-patients/?care_programs_count=true`, {
          headers: { Authorization: "Token "+ v2 },
        })
        .then((res) => {
           this.setState({loading : false});
          if (res.data.status === "success") {
            const data = res.data.results       
            this.setState({patientdata : data})
        }
        else{

        }
    }).catch((error)=>{

    })
}

  componentDidUpdate(prevProps, prevState){
    
    if(prevState.refresh !== this.state.refresh){
      const tokenString = sessionStorage.getItem("usertoken");
  
      let v2 = tokenString.replace(
        /["]+/g,
        ""
      ); /* REGEX To remove double quotes from tokenstring */


        axios
        .get(`${APIURL}/api/v1/hospital/connected-patients/?care_programs_count=true`, {
          headers: { Authorization: "Token "+ v2 },
        })
        .then((res) => {
          if (res.data.status === "success") {
            const data = res.data.results       
            this.setState({patientdata : data})
        }
        else{

        }
    }).catch((error)=>{

    })
    }

  }

    handleSearchChange = (event)=> {
      this.setState({currentPage:1})
        this.setState({searchTerm: event.target.value})
    }

    handlePageChange = (pageNumber)=> {
      this.setState({currentPage:pageNumber})
    }

    modalClose = (childData)=> {
      this.setState({modalShow:childData})
    }

    handleRefresh =(childData)=> {
      this.setState({refresh:childData})
    }

    render() {
          
        const{patientdata ,searchTerm, currentPage, perPage, modalShow, refresh}=this.state

        const ProgramPopup=(props)=> {
 
 
          return (
            <Modal
              {...props}
             
               size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                 backdrop="static"
                  keyboard={false}
              
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                 
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <AddPatient id={this.state.id} name={this.state.name} phn={this.state.phn} modalStatus={this.modalClose} refresh={refresh} modalToggle={this.handleRefresh} />
               
              </Modal.Body>
              <Modal.Footer>
               
                
               <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>
               
               
              </Modal.Footer>
            </Modal>
          );
        }

        let data =  patientdata.map(val=> {
          if(val.care_programs_count<1){
            return val
          }
        })
        .sort((a,b)=>{
          if(a.care_programs_count < 1 && b.care_programs_count < 1){
            if(a.full_name.toLowerCase() < b.full_name.toLowerCase()) return -1;
            if(a.full_name.toLowerCase() > b.full_name.toLowerCase()) return 1;
            return 0;
          }
          else{
            return null
          } 
          
      })
      .filter((value)=>{
        if(value!==undefined){
          if(this.state.searchTerm===''){
            return value
        } else if (value.full_name.toLowerCase().includes(this.state.searchTerm.toLowerCase())){
            return value
        }
      }
    }) 


        const indexLast= currentPage * perPage;
        const indexFirst= indexLast - perPage;
        const current = data.slice(indexFirst ,indexLast) 
        const total = data.length;
        const pageNumbers = []
        for(let i=0 ;i<= Math.ceil(total/perPage) ;i++){
          pageNumbers.push(i)
        }
        const totalPages=pageNumbers.length;
        return (<div className="connected-list">
          
          
           
        <div className="search-section">
  
       
            <div className="search-div">
              <input
                className="form-control search-input"
                type="text"
                maxLength="30"
                placeholder="Enter Name"
                value={searchTerm}
                onChange={this.handleSearchChange}
              />
              <button  className="btn btn-primary btn-col search-btn">
                {" "}
                <i className="fas fa-search"></i>{" "}
              </button>        </div>
          <div className="container search-results">  
          {
            this.state.loading===true ?
            <h6 className="text-muted"> Loading...</h6>
            :
        current.length>0?        
          current.map((value,index)=>{
            //let link_url=`/record/${value.id}`;
              return(<>
             {
                  <div key ={index} className='list-item '>
                       <div className="flex-row-between">           
                          <h5 className="each-list-link" id={value.id} key={value.id}>{(currentPage-1)*2 + index+1}.{value.full_name}</h5>  
                          <button onClick={()=>{ 
                                this.setState({modalShow:true}); 
                                this.setState({id:value.id});
                                  this.setState({name:value.full_name});
                                 this.setState({phn:value.mobile_number});}} className="btn btn-primary btn-col btn-small"> + Add </button>
                       </div>
                      <div className="details">
                        <p> Patient Id: {value.id} </p>
                        <p> Phn : {value.mobile_number} </p>
                      </div>
                  </div>
             }
                  
                       
                  </>
                
              )          
            }) : <h6 className="text-muted"> Patients list is empty!</h6>
          
    
          }
           
          
          </div>
              
            </div>
            <br/>
            <br/>
            {totalPages > 1 ?
    <div className="pagn pagn-small">

            <Pagination
          activePage={currentPage}
          itemsCountPerPage={perPage}
          totalItemsCount={total}
          pageRangeDisplayed={totalPages}
          onChange={this.handlePageChange}
          disabledClass = "disabled-class" 
          />
    </div>
    : null}
            
            { modalShow? 
<ProgramPopup 
      show={modalShow}
      onHide={()=> this.setState({modalShow:false})}
      
    />

: null  }
        
        </div>
        )
    }
}

export default ConnectedPatients;