import React,{useState} from 'react'
import Select from 'react-select';
import axios from "axios";
import {APIURL} from "../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import DatePicker from 'react-date-picker';

function EditEquipmenthosp(props) {


    const[data, setData]= useState(props.data)
    const [type, setType]= useState({value:props.data.service_type, label: props.data.service_type.replace("_"," ")})
    const [unit, setUnit]= useState({value:props.data.service_unit, label: props.data.service_unit})

    const [successShow, setSuccessShow] = useState(false)
    const [errorShow, setErrorShow] = useState(false)

    const [year, setYear]= useState(data.manufacturing_date=== null ? '' : new Date(data.manufacturing_date))
    const [year1, setYear1]= useState(data.purchase_date=== null ? '' : new Date(data.purchase_date))
    const [expiry, setExpiry]= useState(data.expiry_date=== null ? '' : new Date(data.expiry_date))

    const [yearData, setYearData]= useState(data.manufacturing_date)
    const [yearData1, setYearData1]= useState(data.purchase_date)
    const [expiryData, setExpiryData]= useState(data.expiry_date)

    const serviceOptions = [{ value:'general', label:'General' },
    { value:'service_request', label:'Service Request' },
    { value:'Equipment Request', label:'Equipment Request' }
]

    const unitOptions = [{ value:'days', label:'Days' },
    { value: 'count', label: 'count' }
]

    const SuccessPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4 className='text-success'>Updated Successfully</h4>
             
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
             
            
            </Modal.Footer>
          </Modal>
        );
      }
    
      const ErrorPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <h5 style={{color: "red"}}>Error in data submission.Please try again!</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
             
            
            </Modal.Footer>
          </Modal>
        );
      }

      const handleTitle = (e)=> {
        e.preventDefault();        
        const val=e.target.value;
        const key=e.target.name;
        setData( current => ({
            ...current , [key] : val
        }))
      }

      const handleServiceChange= (data) => {

        setType(data)
   }

   const handleUnitChange= (data) => {

        setUnit(data)
  }

  const handleRequest = (e)=> {
   const val = e.target.value
   const key = e.target.name

   setData((current) => ({
       ...current , [key] : val==='true'? true : val==='false'? false : null
   }))

  }

  const handleYear = (date)=> {
    setYear(date);
      
      let dateUpdated= date;
        if(date!==null){
      const offset = date.getTimezoneOffset();
      
      dateUpdated = new Date(date.getTime() - (offset*60*1000))
      
      let DateExtracted = dateUpdated.toISOString();
      
      /*let dateString= DateExtracted.toString(); */
      setYearData(DateExtracted);
      
      }
  }

  const handleExpiry = (date)=> {
    setExpiry(date);
      
      let dateUpdated= date;
        if(date!==null){
      const offset = date.getTimezoneOffset();
      
      dateUpdated = new Date(date.getTime() - (offset*60*1000))
      
      let DateExtracted = dateUpdated.toISOString();
      
      /*let dateString= DateExtracted.toString(); */
      setExpiryData(DateExtracted);
      
      }
  }


  const handleYear1 = (date)=> {
    setYear1(date);
      
      let dateUpdated= date;
        if(date!==null){
      const offset = date.getTimezoneOffset();
      
      dateUpdated = new Date(date.getTime() - (offset*60*1000))
      
      let DateExtracted = dateUpdated.toISOString();
      
      /*let dateString= DateExtracted.toString(); */
      setYearData1(DateExtracted);
      
      }
  }


  const saveChanges = (e)=> {
    e.preventDefault();
    console.log(data)
    const tokenString = sessionStorage.getItem("usertoken");
 
     let v2 = tokenString.replace(
       /["]+/g,
       ""
     ); /* REGEX To remove double quotes from tokenstring */
 
  const dataToSend = {
    equipment_name: data.equipment_name,                             
    model: data.model,                                                
    price:  data.price,                                          
    serial_number: data.serial_number,                                           
    service_price: data.service_price,
    category: data.category,
    on_service:data.service,
    is_active:data.active,
    service_type:type.value,
    service_unit: unit.value,
    manufacturing_date: yearData,
    manufacture_name:data.manufacturename,
    expiry_date: expiryData,
    purchase_date:yearData1,
    distributor:data.distributer,
    company_address:data.companyaddress,
    distributor_address:data.distributeraddress,
    description:data.description
  }
 
     axios
       .put(`${APIURL}/api/v1/hospital/equipment-detail/${data.id}/`, dataToSend , {
         headers: { Authorization: "Token " + v2 },
       })
       .then((res) => {
         if (res.status==200) {
           
           setSuccessShow(true)
         } else {
           setErrorShow(true)
         }
       })
       .catch((err) => {
         setErrorShow(true)
       });

}

    return (
        <>
         <div class="myDiv">
     <h2 style={{marginTop:'0'}} className="title-of-page">Edit Equipment and Details</h2>

     <div className='feature-data pad-features'>
          <div className='flex-col justify-feature'>
              <span className='align-rental'>Equipment Name : </span>
              <input type='text' className='form-control title-flex' name='equipment_name' value={data.equipment_name} onChange={handleTitle} />
             
          </div>
          <br/>

          <div className='flex-col justify-feature'>
              <span className='align-rental'>Model : </span>
              <input type='text' className='form-control title-flex' name='model' value={data.model} onChange={handleTitle} />
             
           </div>
           <br/>
           <div className='flex-col justify-feature'>
              <span className='align-rental'>Equipment Price : </span>
              <input type='number' className='form-control title-flex' name='price' value={data.price} onChange={handleTitle} />
             
           </div>
           <div className='flex-col justify-feature'>
              <span className='align-rental'>serail Number : </span>
              <input type='text' className='form-control title-flex' name='serial_number' value={data.serial_number} onChange={handleTitle} />
              
           </div>
           <div className='flex-col justify-feature'>
              <span className='align-rental'>Service Price : </span>
              <input type='number' className='form-control title-flex' name='service_price' value={data.service_price} onChange={handleTitle} />
             
           </div>
           <div className='flex-col justify-feature'>
              <span className='align-rental'>Category : </span>
              <input type='text' className='form-control title-flex' name='category' value={data.category} onChange={handleTitle} />
              
           </div>

     <div>
     </div>

           
     <div className='flex-col justify-feature'>
              <span className='align-rental'>Equipment Unit :  </span>

          <Select
                      value={unit}
                      onChange={(data,e)=>{handleUnitChange(data,e)
                      }}
                      options={unitOptions}
                      name='serviceunit'
                      className="select-currency select-flex"
                  />
               
           </div>

           <div className='flex-col justify-feature'>
              <span className='align-rental'>Type : </span>
              <Select
                      value={type}
                      onChange={(data,e)=>{handleServiceChange(data,e)
                      }}
                      options={serviceOptions}
                      name='servicetype'
                      className="select-currency select-flex"
              />
            
           </div>
      </div>

      <div className='feature-data pad-features'>
          <div className='flex-col justify-feature'>
              <span className='align-rental'>On Request Service :</span>

          <div style={{marginBottom:'auto'}} className="form-group gender-radio-check col-lg-6 title-flex">
              <div className="form-control">
                  <input className='checkbox-class1' type='radio' id="true" name='service'  value= {true}  onChange={handleRequest} />
                  <label htmlFor='true'>Yes</label>

                  <input className='checkbox-class1' type='radio' id="false"  name='service'  value= {false} onChange={handleRequest} />
                  <label htmlFor='false'>No</label>
              </div>
          </div>
       
          </div>

          <div className='flex-col justify-feature'>
              <span className='align-rental'>Active Feature : </span>
          
          <div style={{marginBottom: 'auto'}} className="form-group gender-radio-check col-lg-6 title-flex">
              <div className="form-control">
                  <input className='checkbox-class1' type='radio' id="true" name='active'  value= {true} onChange={handleRequest} />
                  <label htmlFor='true'>Yes</label>

                  <input className='checkbox-class1' type='radio' id="false"  name='active'  value= {false} onChange={handleRequest} />
                  <label htmlFor='false'>No</label>
              </div>
          </div>
         
          </div>

      </div>

          <div className='feature-data pad-features'>
          <div className='flex-col justify-feature'>
              <span className='align-rental'>Manufacture Date :</span>
              <div className='form-control dob item title-flex'>
                  <DatePicker
                    value={year}
                    onChange={date=>{handleYear(date)}}                         
                    maxDate={new Date()}
                    
               />
               </div>
           
          </div>

          <div className='flex-col justify-feature'>
              <span className='align-rental'>Manufacture Name: </span>
              <input type='text' className='form-control title-flex' name='manufacturename' value={data.manufacturename} onChange={handleTitle} />
             
           </div>
      </div>
    <div className='feature-data pad-features'> 
          <div className='flex-col justify-feature'>
              <span className='align-rental'>Purchase Date : </span>
              <div className='form-control dob item title-flex'>
                  <DatePicker
                    value={year1}
                    onChange={date=>{handleYear1(date)}}                         
                    maxDate={new Date()}
                    
               />
               </div>
              
          </div>
          

          <div className='flex-col justify-feature'>
              <span className='align-rental'>Expiry Date : </span>
              <div className='form-control dob item title-flex'>
                  <DatePicker
                    value={expiry}
                    onChange={date=>{handleExpiry(date)}}                         
                    minDate={new Date()}
                    
               />
               </div>
              
          </div>
          </div>

        <div className='feature-data pad-features'> 
          <div className='flex-col justify-feature'>
              <span className='align-rental'>Distributor : </span>
              <input type='text' className='form-control title-flex' name='distributor' value={data.distributor} onChange={handleTitle} />
             
          </div> 
          

          <div className='flex-col justify-feature'>
              <span className='align-rental'>Company Address: </span>
              <textarea type='text' className='form-control title-flex' name='companyaddress' value={data.companyaddress} onChange={handleTitle} />
             
          </div> 
          <br/>
<div className='feature-data pad-features'>
                <div className='flex-col justify-feature'>
                    <span className='align-rental'>Distributor Address : </span>
                    <teaxtarea type='text' className='form-control title-flex' name='distributeraddress' value={data.distributeraddress} onChange={handleTitle} />
                   
                </div> 
           
          
                <div className='flex-col justify-feature'>
                    <span className='align-rental'>Description : </span>
                    <textarea type='text' className='form-control title-flex' name='description' value={data.description} onChange={handleTitle} />
                   
                </div> 
            </div>


         <br/>
         <br/>

            <div className='text-center'>
                <button onClick={saveChanges} className='btn btn-primary btn-col mb-2'>Save</button>
                
            </div>
            </div>
            
  
                   
     

  
    

     
    

  

     
     
            {successShow ? 
<SuccessPopUp
show={successShow}
onHide={() =>  {setSuccessShow(false); props.modalClose(); }}
/> 
: null}

{errorShow ? 

<ErrorPopUp
show={errorShow}
onHide={() =>  {setErrorShow(false)}}
/> 
: null}
            
        </div>
        </>
    )
}

export default EditEquipmenthosp
