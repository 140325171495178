import React, { useState } from 'react'
import Select from 'react-select';
import axios from "axios";
import { APIURL } from "../../../Global";
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import DatePicker from 'react-date-picker';

function AddDiscease(props) {

  const [data, setData] = useState({
    allergyname: '',
    allergytype: '',
    allergystage: '',
    description: ''



  })

  const [error, setError] = useState({
    allergyname: ''

  });

  const [successShow, setSuccessShow] = useState(false)
  const [errorShow, setErrorShow] = useState(false)
  const [completeShow, setCompleteShow] = useState(false)
  const [errorValidate, setErrorValidate] = useState(false)

  //     const serviceOptions = [{ value:'general', label:'General' },
  //     { value:'service_request', label:'Service Request' },
  //     { value:'rental', label:'Equipment Request' }
  // ]

  const CompletePopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className='text-success'> Successfully Saved</h4>

        </Modal.Body>
        <Modal.Footer>

          <Button className='btn btn-secondary' onClick={props.onHide}>Ok</Button>


        </Modal.Footer>
      </Modal>
    );
  }

  const SuccessPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className='text-success'> Click Proceed to add allergy.</h4>

        </Modal.Body>
        <Modal.Footer>

          <Button className='btn btn-secondary' onClick={props.onHide}>Not Now</Button>
          <Button className='btn btn-primary btn-col' onClick={saveChanges}>Proceed</Button>

        </Modal.Footer>
      </Modal>
    );
  }

  const handleError = () => {
    setErrorValidate(false);
    setSuccessShow(false)
  }

  const ErrorPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}>Check data whether duplicated or not</h5>

        </Modal.Body>
        <Modal.Footer>


          <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button>


        </Modal.Footer>
      </Modal>
    );
  }
  const ErorValidatePopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}>Allergy name must required</h5>

        </Modal.Body>
        <Modal.Footer>


          <Button className='btn btn-primary btn-col' onClick={handleError}>Ok</Button>



        </Modal.Footer>
      </Modal>
    );
  }
  const handleTitle = (e) => {
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;
    setData(current => ({
      ...current, [key]: val
    }))
  }


  const handleSave = () => {
    saveChanges();

  }
  //     const handleServiceChange= (data) => {

  //       setData( current => ({
  //           ...current , type: data
  //       }))
  //  }

  //  const handleUnitChange= (data) => {

  //   setData( current => ({
  //       ...current , unit: data
  //   }))
  // }

  // const handleRequest = (e)=> {
  //  const val = e.target.value
  //  const key = e.target.name

  //  setData((current) => ({
  //      ...current , [key] : val==='true'? true : val==='false'? false : null
  //  }))

  // }

  // const handleYear = (date)=> {
  //   setYear(date);

  //     let dateUpdated= date;
  //       if(date!==null){
  //     const offset = date.getTimezoneOffset();

  //     dateUpdated = new Date(date.getTime() - (offset*60*1000))

  //     let DateExtracted = dateUpdated.toISOString().split('T')[0];

  //     /*let dateString= DateExtracted.toString(); */
  //     setYearData(DateExtracted);

  //     }
  // }

  // const handleExpiry = (date)=> {
  //   setExpiry(date);

  //     let dateUpdated= date;
  //       if(date!==null){
  //     const offset = date.getTimezoneOffset();

  //     dateUpdated = new Date(date.getTime() - (offset*60*1000))

  //     let DateExtracted = dateUpdated.toISOString().split('T')[0];

  //     /*let dateString= DateExtracted.toString(); */
  //     setExpiryData(DateExtracted);

  //     }
  // }

  const validate = () => {

    let input = data;
    let errors = {};
    let isValid = true;
    setError({});


    if (!input["allergyname"] || input["allergyname"] === "") {
      isValid = false;
      errors["allergyname"] = "Please enter allergy Name";
    }



    setError(currentstate => ({
      ...currentstate,
      ...errors
    }));


    return isValid;
  }

  const saveChanges = () => {
   
    console.log(validate())
    if (validate()) {
      const tokenString = sessionStorage.getItem("usertoken");

      let v2 = tokenString.replace(
        /["]+/g,
        ""
      ); /* REGEX To remove double quotes from tokenstring */

      const dataToSend = {
        allergy_name: data.allergyname,
        allergy_type: data.allergytype,
        allergy_stage: data.allergystage,
        description: data.description


      }

      axios
        .post(`${APIURL}/api/v1/staff/master-allergies/`, dataToSend, {
          headers: { Authorization: "Token " + v2 },
        })
        .then((res) => {
          if (res.data.status === "success") {
            setCompleteShow(true);
            setData({
              allergyname: '',
              allergytype: '',
              allergystage: '',
              description: ''

            });
          }
          else {
            setErrorShow(true)
          }
        })
        .catch((err) => {
          setErrorShow(true)
        });
      setSuccessShow(false)
    }

   

  }

  return (
    <>
      <h2 style={{ marginTop: '0' }} className="title-of-page">Add Allergy</h2>
      <div style={{ maxHeight: '1000px' }} className='config-box fit-content'>

        <div className='feature-data pad-features'>
          <div className='flex-col justify-feature'>
            <span className='align-rental'>*Allergy Name : </span>
            <input type='text' className='form-control title-flex' name='allergyname' value={data.allergyname} onChange={handleTitle} />
            {error.allergyname ? <div className="error-validation-msg error-feature-inventory">{error.allergyname}</div> : null}
          </div>

          <div className='flex-col justify-feature'>
            <span className='align-rental'>Allergy Type : </span>
            <input type='text' className='form-control title-flex' name='allergytype' value={data.allergytype} onChange={handleTitle} />
            {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
          </div>
          <div className='flex-col justify-feature'>
            <span className='align-rental'>Allergy Stage : </span>
            <input type='text' className='form-control title-flex' name='allergystage' value={data.allergystage} onChange={handleTitle} />
            {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
          </div>
          <div className='flex-col justify-feature'>
            <span className='align-rental'>Description : </span>
            <textarea type='text' className='form-control title-flex' name='description' value={data.description} onChange={handleTitle} />
            {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
          </div>

        </div>







        {/* <div className='flex-row name-content'>
                <span>Equipment Unit : </span>
                <Select
                            value={data.unit}
                            onChange={(data,e)=>{handleUnitChange(data,e)
                            }}
                            options={unitOptions}
                            name='service_unit'
                            className="select-currency serv-width"
                        />
            </div>

            <div className='flex-row name-content'>
                <span>On Request Service : </span>

                <div style={{marginBottom:'auto'}} className="form-group gender-radio-check col-lg-6 title-input">
                    <div className="form-control">
                        <input className='checkbox-class1' type='radio' id="true" name='request'  value= {true} checked={data.request === true} onChange={handleRequest} />
                        <label htmlFor='true'>Yes</label>

                        <input className='checkbox-class1' type='radio' id="false"  name='request'  value= {false} checked={data.request === false} onChange={handleRequest} />
                        <label htmlFor='false'>No</label>
                    </div>
                </div>
            </div>

            <div className='flex-row name-content'>
                <span>Active Feature : </span>
                
                <div style={{marginBottom:'auto'}} className="form-group gender-radio-check col-lg-6 title-input">
                    <div className="form-control">
                        <input className='checkbox-class1' type='radio' id="true" name='active'  value= {true} checked={data.active === true} onChange={handleRequest} />
                        <label htmlFor='true'>Yes</label>

                        <input className='checkbox-class1' type='radio' id="false"  name='active'  value= {false} checked={data.active === false} onChange={handleRequest} />
                        <label htmlFor='false'>No</label>
                    </div>
                </div>
            </div> */}

        <div className='text-center'>
          <button onClick={handleSave} className='btn btn-primary btn-col mb-2'>Save</button>

        </div>
        {successShow ?
          <SuccessPopUp
            show={successShow}
            onProceed={() => { setSuccessShow(false); props.modalClose(); }}
            onHide={() => { setSuccessShow(false); props.notProceedClose(); }}
          />
          : null}

        {errorShow ?

          <ErrorPopUp
            show={errorShow}
            onHide={() => { setErrorShow(false) }}
          />
          : null}
        {errorValidate ?

          <ErorValidatePopup
            show={errorValidate}
            onHide={() => { setErrorValidate(false) }}
          />
          : null}


        {completeShow ?

          <CompletePopUp
            show={completeShow}
            onHide={() => { setCompleteShow(false) }}
          />
          : null}

      </div>
    </>
  )
}

export default AddDiscease
