import React, {useState, useEffect} from 'react'
import axios from 'axios';
import {APIURL} from "../../../Global";
import { useHistory } from "react-router-dom";
//import './doctorpayment.css'
import LicenseTableBasic from '../../DoctorPayment/LicenseTableBasic';
//import LicensePricing from './LicensePricing';
import FreeUserList from '../../DoctorPayment/FreeUserList';

function HospitalLicenseView() {

    const [licenseData, setLicenseData]= useState([])
    const [userData, setUserData]= useState([])


const history =useHistory();

const [keys,] = useState({'h': false,'d': false,'u': false,'r': false,'l': false,'i': false,'a': false,'p': false,'v': false,'t': false,'n': false});


console.log(sessionStorage.getItem('id'))

useEffect(()=>{

window.onkeydown =(e) => {
 
 keys[e.key]=true

 if(e.altKey && keys['h']){
   history.push('/hospitallist')
 }
 if(e.altKey && keys['d']){
   history.push('/doctorslist')
 }
 if(e.altKey && keys['u']){
   history.push('/search-hospitals')
 }
 if(e.altKey && keys['r']){
   history.push('/rejectedlist')
 }
 if(e.altKey && keys['l']){
   history.push('/reportlist')
 }
 if(e.altKey && keys['i']){
   history.push('/addsynonyms')
 }
 if(e.altKey && keys['a']){
   history.push('/failedlist')
 }
 if(e.altKey && keys['p']){
   history.push('/prescriptionlist')
 }
 if(e.altKey && keys['v']){
   history.push('/written-prescriptionlist')
 }
 if(e.altKey && keys['t']){
   history.push('/testlist')
 }
 if(e.altKey && keys['n']){
  history.push('/nutrition')
}

}
window.onkeyup=(ev)=> {
 
 keys[ev.key]= false
}

return()=>{
 window.onkeydown=null
 window.onkeyup=null
}
},[]);

const handleBack=()=>{
        history.goBack();
}


useEffect(()=>{
    const idVal = sessionStorage.getItem('id')
    const tokenString= sessionStorage.getItem('usertoken');
       
       let str= tokenString.replace(/["]+/g, '')  ;
       
    axios.get(`${APIURL}/api/v1/hospital/hospital-licenses/?hospital_id=${idVal}`, { headers: {
          
        "Authorization" : 'Token '+str  }})
      .then(res=>{
       
  
     
     
        if(res.data.status==="success"){ 

          const data =res.data.hospital_licenses
          setLicenseData(data)
          setUserData(res.data.free_user_details.active_free_user_details)
              
          }

        else{


          }
  
      })
      .catch(err=>{
  
  })
    
    
    },[]);


    return (<>
    <br/>
    <div className="text-left">
        
          <button className="btn btn-info back-btn" onClick={handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back</button> 


    </div> 
   
        <div className='flex-col'>
            <h2 className="title-of-page">Licence Details </h2>
            <div className='each-section p-4'>
                <h4 className="sub-title"> Purchased Licence Details  </h4>
                
                                                     
                        <LicenseTableBasic id={sessionStorage.getItem('id')} data={licenseData} />                  

            </div>
            <div className="second-contentbox" >
                    <div className="first-content mb-5" >
                        <div className="sub-section-header box-theme">
                            <h5 className="sub-section-title"> Free User List</h5>                                                                            
                        </div>
                        <FreeUserList users={userData} />
                    </div>
            </div>
           </div>

        </>
    )
  }

export default HospitalLicenseView