import React,{useState,useRef,useEffect} from 'react';

import { Link } from "react-router-dom";
import axios from "axios";
import {APIURL} from "../../../Global";
import {useHistory} from "react-router-dom";
import "./operations.css";
import HospBdoNavbar from "../../HospBdoNavbar/HospBdoNavbar";
import CareProgramsList from "../../CarePrograms/CareProgramsList";
import SubscribersList from "../SubscribersList/SubscribersList";
import ConnectedPatients from "../ConnectedPatients/ConnectedPatients";
import ServiceHistoryBasic from "../../ServiceRequests/ServiceHistoryBasic";

function OperationsDB() {
  const [,setReports]=useState([]);
 const  ref1=useRef();
  const  ref3=useRef();
  const  ref4=useRef();
  const  ref5=useRef();
  
  let history=useHistory();

   
  const handleBack=(e)=>{
  history.goBack();
}

const [keys,] = useState({'u': false,'n': false,'a': false,'s': false,'q': false,'l': false,});


useEffect(()=>{

  window.onkeydown =(e) => {
    
    keys[e.key]=true

    if(e.altKey && keys['u']){
      history.push('/directory')
    }
    if(e.altKey && keys['n']){
      history.push('/notifications')
    }
    if(e.altKey && keys['a']){
      history.push('/analytics')
    }
    if(e.altKey && keys['s']){
      history.push('/service-requests')
    }
    if(e.altKey && keys['q']){
      history.push('/qrcode')
    }
    if(e.altKey && keys['l']){
      history.push('/call-log')
    }


  }
  window.onkeyup=(ev)=> {
    
    keys[ev.key]= false
  }

  return()=>{
    window.onkeydown=null
    window.onkeyup=null
  }
},[]);

useEffect(()=>{
	const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  /* REGEX To remove double quotes from tokenstring */
          
  
     

     axios.get(`${APIURL}/api/v1/analytics/metabase/`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
  
     
   
      if(res.data.status ==="success"){
          
     
      setReports(res.data.reports);
   

          }else{
       
      }


    })
    .catch(err=>{
 
 
})
 

},[])

const handleClick = (ref) =>{
  console.log(ref.current)
        ref.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
         });
      



      }
 
  return (
   <> 
   <HospBdoNavbar/>
   <br/>
   <div className="flex-head">
        
          <button className="btn btn-info topleft-single-btn" onClick={handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back</button> 
          <h2 className="title-of-tasks title-mg1">Care Packages Dashboard </h2>
    </div> 
    {/*
   <h2 className="title-of-page"> Care Plans Dashboard </h2>
 */}

   <div className="patient-record-holder operations-page">



             <div className="sticky-nav">
      <div className="left-nav">
         <ul className="stickynav-ul"> 
          
            <li onClick={()=>{handleClick(ref1)}}> Service History </li>
           <li onClick={()=>{handleClick(ref3)}}> Add Patient to Care Package</li> 
  
              <li onClick={()=>{handleClick(ref3)}}> Subscribers List</li> 
            <li onClick={()=>{handleClick(ref5)}}> Care Packages </li> 
           
           {/* <li onClick={()=>{handleClick(ref6)}}> Add New Care Plan </li> */}

          

           

         </ul>


      </div>

      </div>


    

    <div className="right-section">
    
    <h4 className="prime-header"> </h4><br/>





<div className="each-section summary  section-diagnostic" ref={ref1} >
               <h5 className="sub-title"> Service History  </h5>
                <div className="second-contentbox">
          
                  <div className="sub-section vitals-subsection sub-section-careprogram">
                         
                     
                      
                      
                    
                      <ServiceHistoryBasic />
                      <span className="history-link" onClick={()=> history.push('/service-history')} >

                                       <button className="btn btn-primary btn-col btn-small btn-square">Goto Service History list </button>
                                  </span>
                    
                        
            
                        <br/>
                        <br/>
         





                
                         </div>


                       </div>

                      </div>
               

     

    <div className="each-section summary  section-diagnostic" ref={ref3}>
                  <h5 className="sub-title">  Subscribers  </h5>
                 <div className="second-contentbox" >
          
                
                         
                            <div className="sub-section vitals-subsection" >
                      <div className="sub-section-header box-theme">
                               

                      <h6 className="sub-section-title"> Add Patient to Care Package</h6>
                      </div>
                      <div className="sub-section-body">
                      <ConnectedPatients />

                      <span className="history-link" onClick={()=> history.push('/connected-list')} >

                                       <button className="btn btn-primary btn-col btn-small btn-square">Goto Connected patients list </button>
                                  </span>
                      </div>

                      </div>


                      
                           
                    
                            
                   
                    
                         
                        {/**** Inferences/ Summary******/}
                      <div className="sub-section inferences-subsection" ref={ref4} >
                      <div className="sub-section-header box-theme">
                               

                      <h6 className="sub-section-title"> Subscribers List </h6>
                      </div>
                      <div className="sub-section-body">  
                        <SubscribersList />
                        <span className="history-link"  >

                                       <button onClick={()=> history.push('/subscriberslist')} className="btn btn-primary btn-col btn-small btn-square">Goto Subscribers List </button>{" "}
                                       <button onClick={()=> history.push('/suspendedlist')} className="btn btn-primary btn-col btn-small btn-square">Goto Suspended Subscriber List </button>
                                  </span>
                      </div>

                      </div>

        </div>
             



              </div>
    
     
         <div className="each-section summary  section-diagnostic" ref={ref5} >
               <h5 className="sub-title"> Care Packages  </h5>
                <div className="second-contentbox">
          
                
                         
                      <div className="sub-section vitals-subsection sub-section-careprogram">
                      <div className="sub-section-header box-theme">
                               

                      <h6 className="sub-section-title"> Care Packages List</h6>
                      </div>
                      <div className="sub-section-body">
                      <div className="vitals-col">
                      <CareProgramsList />
                      <span className="history-link" onClick={()=> history.push('/care-programs')} >

                                       <button className="btn btn-primary btn-col btn-small btn-square">Goto Care Packages </button>
                                  </span>
                    
                        
            

         





                     </div>


                      </div>

                      </div>


                      
                           
                         <br/>
                         <br/>
                            
                <div className="flex-row">
              
                  <Link to="/care-program-add"><button className="btn btn-info btn-small" > + Add New Care Package </button></Link>
                                  
               </div>

              <br/>
              <br/>
                    
                         
                       

        </div>
             



              </div>


{/*******************ends section***********/}
   
    </div>

    </div>
   </>
  );
}

export default OperationsDB;
