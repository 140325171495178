import React, {useState, useEffect} from "react";

import "./patientlabreport.css";
import axios from "axios";
import {APIURL} from "../../Global";

function PatientLabReport(props){
		 const [reportId,] =useState(props.reportid);
     const [patientId,] = useState(props.patientid);
   const [testList,setTestList]=useState([]);
   const [imageList,setImageList]= useState([]);
   const [render,setRender]=useState(false);
   const [rotation,setRotation]=useState(0);
    const [reportDate,setReportDate] = useState();
   const [scaleValue,setScaleValue]=useState(1);
   const [isPdf, setIsPdf]= useState(false)
    const [pdf, setPdf]= useState('')

useEffect(()=>{
  setTestList(testList);
  setImageList(imageList);
},[render]);
useEffect(()=>{

  const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  /* REGEX To remove double quotes from tokenstring */
          
    
     
  
    axios.get(`${APIURL}/api/v1/patient/lab-reports/${reportId}/?src=website&patient_id=${patientId}`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
    

   
      if(res.data.status==="success"){
      
    if(res.data.data.is_pdf===false){
            setPdf(res.data.data.images[0])
            setIsPdf(true)  
          } 

     setTestList(res.data.data.tests);
    
      setImageList(res.data.data.images);
      setReportDate(res.data.data.date);
      setRender(!render);

      }else{
       
      }


    })
    .catch(err=>{
 
})
 

 },[]);

const handleScale=()=>{
  scaleValue === 1? setScaleValue(1.5) : setScaleValue(1);
 
}
const handleRotateLeft=()=>{
    let newRotation = rotation - 90;
    if(newRotation >= 360){
      newRotation =- 360;
    }if(newRotation === -360){
      newRotation =0;
    }
    setRotation(newRotation);
   
  }
const display = <table >
                      <tr className="table-header">
                        <th className='report-heading'> Test</th>
                        <th className='report-heading'> Result</th>
                        <th className='report-heading'> Units</th>
                        <th className='report-heading'> Reference Range</th>
                        <th className='report-heading'> Smart Device Data</th>
                        
                        
                      </tr>
                      
                    {testList? testList.map(item=>{
                     return  <tr className="table-row">
                               <td> {item.test} </td>
                               <td> {item.result} </td>
                               <td> {item.units} </td>
                               <td> {item.reference_range} </td> 
                               <td> {item.is_wearables? 'Yes' : 'No'} </td>   
                            </tr>
                      }) 
                      : null}
                   </table>;

const reportImage=isPdf===false?  imageList.map(reportImg=>{
  return <div className="image-container">
        <div className="img-holder-inside">
        <img src={reportImg} width="450px" height="auto" alt="report" onDoubleClick={handleScale} style={{transform: `scale(${scaleValue}) rotate(${rotation}deg)`}}/>
        </div>
        <button className="btn btn-primary btn-col" onClick={handleRotateLeft}> Rotate <i className="fa fa-undo" aria-hidden="true"></i> </button>
       
      </div>
}) 
: <div style={{width:'600px', height:'550px'}}>
<object data={pdf} type="application/pdf" width="100%" height="100%">
</object></div> 

	return(
    <>

		<div className="patient-labreport-page"><br/>
   
    <h3 className="title-of-tasks"> Lab Report {reportId}</h3>
     {/* <h5 className="title-
   of-tasks text-center"> Patient ID {patientId}</h5>*/}
      {reportDate? <h6 className="text-right overlay-subtitle">Date: <span style={{color:"grey"}}>{reportDate? reportDate : "n/a"}</span> </h6>:""}
       <div className="patient-report-sections">
       {reportDate?<div className="table-holder">
          {display}
          </div>:""}
          <div className="images-holder">
          {reportImage}
	        </div>
    </div>
		
		</div>
    </>)
}


export default PatientLabReport;