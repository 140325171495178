import React, { useEffect,useState} from "react";

import axios from "axios";
import 'react-table-v6/react-table.css'
import "./drnotes.css";
import Pagination from "react-js-pagination";
import "./pagination.css";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import {APIURL} from "../../Global";
/*function convertUTCDateToLocalDate(date) {
    var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;   
}
*/

function DrNotes(props) {

  const [patientId,] =useState(props.id);
  const [newNote,setNewNote] = useState('');
   const [notesList,setNotesList]=useState([]);
      const [activePage,setActivePage] = useState(1);
       const [totalPages,setTotalPages]=useState(1);
         const [refresh,setRefresh]= useState(false);
         const [errorMsg,setErrorMsg]=useState("");
          const [render,]= useState(true);
        
           const [submitMsg,setSubmitMsg] = useState("");
   const [modalShow,setModalShow]=useState(false);

 const [errorSubmit,setErrorSubmit]=useState(false);
 


useEffect(()=>{
const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;
          
    
  
    axios.get(`${APIURL}/api/v1/doctor/message/?is_note=true&patient_id=${patientId}&per_page=10&page=${activePage}`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
     

  
   
      if(res.data.status==="success"){

 setNotesList(res.data.data);
     
        setTotalPages(res.data.total_pages);

      }
else{
   setErrorMsg(res.data.message);
              setErrorSubmit(true);
}

    })
    .catch(err=>{

   setSubmitMsg("error");
              setErrorSubmit(true);
})


},[activePage,render,refresh]);
const SubmitPopUp=(props)=> {
 
 
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       backdrop="static"
        keyboard={false}
        centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Data submitted sucessfully!!</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
        
       <Button className='btn btn-primary btn-col' onClick={props.onHide}>Continue</Button>
       
       
      </Modal.Footer>
    </Modal>
  );
}



const SubmitErrorPopup =(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 style={{color: "red"}}> {errorMsg!=='' ? "Error:"+ ''+ errorMsg : "Please type notes before save" } </h5>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Ok</Button>

       
       

      </Modal.Footer>
    </Modal>
  );
}

const handlePageChange=(pageNumber)=> {
   
    setActivePage(pageNumber);
    }

  const display= notesList.length>0 ? notesList.map((note,index)=>{
    /* let date = convertUTCDateToLocalDate(new Date(note.date_time));
  
    */
    return(
       <div key={index} className="each-note box-3">
         {/* <div className="red-pin"> </div> */}
          <div className="note-card-header">
             <h6 className="title-of-tasks"> #{note.id} </h6>
             <h6 className="title-of-tasks">  {note.date_time? new Date(note.date_time).toString().slice(0,21) : null} 

  </h6>
          </div>
          <p>{note.notes}</p>
       </div>
          )
  })
: null;

const handleNote=(e)=>{
  e.preventDefault();
  setNewNote(e.target.value);
}
 
 const handleSave=async ()=>{

 
  const tokenString= sessionStorage.getItem('usertoken');
  let str= tokenString.replace(/["]+/g, '') ;
  
    
  const data=await {
    patient_id: patientId ,
    message: newNote,
    date_time: new Date().toISOString(),
    is_note:"True"
  }

  await setNewNote("");
  
  axios.post(`${APIURL}/api/v1/doctor/message/`, data,
     { headers:
      {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
     

   
   
    if(res.data.status==="success"){
 
     
     setSubmitMsg("success");
     setModalShow(true);


      }
      else{
              setSubmitMsg("error");
              setErrorSubmit(true);
             
      }


    })
    .catch(err=>{

  setSubmitMsg("error");
              setErrorSubmit(true);
})


}
 

  return (
 

    <div className="dr-notes-overlay">
      <h2 className="title-of-tasks"> My Notes</h2>
        <div className="dr-notes-page">
     
     {errorMsg===''?(<>
       

         {/* <h3 className=""> Patient  ID : {patientId} </h3><br/><br/> */}
     <div className="notes-holder">

     <div className="each-note box-3">
          <textarea className="p-notes box-3"  maxlength="500" value={newNote} onChange={handleNote}> {newNote} </textarea>

          {/*<i className="fas fa-save"></i>*/}
          <button className="btn btn-secondary btn-smallest btn-square save-btn" type="submit" onClick={handleSave}> Save Note </button>
       </div>
          
          {display}

    </div>
   

</>)
: <h5 style={{color: "white"}}> No Data to show!! </h5> }


    </div>
    <Pagination
          activePage={activePage}
          itemsCountPerPage={10}
          totalItemsCount={totalPages*10}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          disabledClass = "disabled-class"
        />

    {submitMsg==="success" ? 
          <SubmitPopUp
        show={modalShow}
        onHide={() =>  {setModalShow(false); setRefresh(!refresh);
                      }}
       
      />
         :  submitMsg==="error"? 
     <SubmitErrorPopup
        show={errorSubmit}
        onHide={() =>  setErrorSubmit(false)}
       
      />

         : ''
          }

      {errorMsg!=='' ?
    <SubmitErrorPopup
        show={errorSubmit}
        onHide={() =>  setErrorSubmit(false)}
       
      />: ''
    }

     </div>
  );
}

export default DrNotes;