import React, {useState, useEffect} from 'react'
import axios from 'axios';
import {APIURL} from "../../Global";
function LicensePricing(props) {

    const [freeCount, setFreeCount]= useState('')
    const [currency, setCurrency]= useState('')
    const [price, setPrice]= useState('')
    const [tax, setTax]= useState('')
    const [period, setPeriod]= useState(null)
    const [,setMsg]= useState('')

    if(props.getPrice !== undefined){
        let dataToSend = [ price , tax ]
        props.getPrice(dataToSend)
    }

    useEffect(()=>{

        const tokenString= sessionStorage.getItem('usertoken');
       
       let str= tokenString.replace(/["]+/g, '')  ;
    
    
      
        axios.get(`${APIURL}/api/v1/hospital/hospital-licenses-price/`, { headers: {
          
          "Authorization" : 'Token '+str  }})
        .then(res=>{
         
    
       
       
          if(res.data.status==="success"){ 

            const data =res.data.data
            
            if(res.data.message==='Hospital User Pricing Details Not Yet Added'){
                setMsg(res.data.message)
            }
            else{     
                if(data.cooling_period_till!==null){
                    let date = data.cooling_period_till.slice(0,10)
                    setPeriod(date)
                 }

                setFreeCount(data.free_user_count)
                setPrice(data.one_month_user_price)
                setCurrency(data.currency.toUpperCase())
                setTax(data.tax_percentage)
                
            }

          }
            else{
    
          }
          
    
    
        })
        .catch(err=>{
    
    })

},[]);


    return (
        <div>
            <div className='content-pad'>
                            <div className='flex-row align-content'>
                                <h5 className='each-text'>Free Users :</h5>
                                <span className={freeCount===null ? 'nodata-bg': 'each-subtext'}>{freeCount===null? <span className='text-danger font-weight-bold'>No Data</span> : freeCount}</span>
                            </div>
                            <br/>
                            <div className='flex-row align-content'>
                                <h5 className='each-text'>Free Usage until :</h5>
                                <span className={period===null ? 'nodata-bg': 'each-subtext'}>{period===null? <span className='text-danger font-weight-bold'>No Free Usage</span> : period}</span>
                            </div>
                            <br/>
                            <div className='flex-row align-content'>
                                <h5 className='each-text'>Monthly Price :</h5>
                                <span className={price===null ? 'nodata-bg': 'each-subtext'}>{price===null? <span className='text-danger font-weight-bold'>No Data</span> : price}</span>
                            </div>
                            <br/>
                            <div className='flex-row align-content'>
                                <h5 className='each-text'>Currency :</h5>
                                <span className={currency===null ? 'nodata-bg': 'each-subtext'}>{currency===null? <span className='text-danger font-weight-bold'>No Data</span> : currency}</span>
                            </div>
                            <br/>
                            <div className='flex-row align-content'>
                                <h5 className='each-text'>Tax %:</h5>
                                <span className={tax===null ? 'nodata-bg': 'each-subtext'}>{tax===null? <span className='text-danger font-weight-bold'>No Data</span> : tax}</span>
                            </div>
                        </div>
        </div>
    )
}

export default LicensePricing
