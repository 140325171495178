import React, {useState, useEffect} from 'react'
import axios from "axios";
import {APIURL} from "../../../Global";
import "./generateinvoice.css";

function GenerateInvoice(props) {
	const [careId ,]= useState(props.careId);
    const [careName,]= useState(props.careName);
    const [tenure,]= useState(props.tenure);
    const [patientName,]=useState(props.name);
     //const [patientId,setPatientId]=useState(props.patientId);
      const [patientPhn,]=useState(props.phn);
       const [bdo_Data,]=useState(JSON.parse(sessionStorage.getItem('bdodata'))? JSON.parse(sessionStorage.getItem('bdodata')) : null);
   const bdo_name= bdo_Data ? bdo_Data.name : null;
   
   const bdo_hospital_mobile= bdo_Data ? bdo_Data.hospital_mobile : null;
 const bdo_hospital_street= bdo_Data ? bdo_Data.hospital_street : null;
  const bdo_hospital_location= bdo_Data ? bdo_Data.hospital_location : null;
 const bdo_hospital_state= bdo_Data ? bdo_Data.hospital_state : null;
  const bdo_hospital_gst= bdo_Data ? bdo_Data.hospital_gst : null;
   const bdo_hospital_email= bdo_Data ? bdo_Data.hospital_email : null;
   const bdo_hospital_accreditation= bdo_Data ? bdo_Data.hospital_accreditation : null;
  
   /* const doctor_specializations = hospBdoData ? hospBdoData.doctor_specializations : null; 
   const department  = bdo_Data ? bdo_Data.department : null;
   const Id = bdo_Data ? bdo_Data.user_id : null;
   const QR_Id =bdo_Data ?bdo_Data.hospital_id :null;
   const QR_usertype= bdo_Data ? bdo_Data.user_type :'hospital';*/
   const hospitalName= bdo_Data ? bdo_Data.hospital_name: null;
   /*const specializations = hospBdoData ? hospBdoData.specializations : null;*/

  const [,setTitle]=useState();
   const [price,setPrice]=useState();
   const [discount,setDiscount]=useState();
 const [currency,setCurrency]=useState();
  const [,setIsActive]=useState();
  const [taxAmount,setTaxAmount]=useState();
   const [pricingType,setPricingType]=useState();
 const [,setOfferText]=useState();
      const [,setGetError]=useState('');


	 useEffect(()=>{

    const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;


  
    axios.get(`${APIURL}/api/v1/hospital/care-programs/${careId}/`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
     

   
   
      if(res.data.status==="success"){

       setTitle(res.data.data.title);
       setDiscount(res.data.data.discounted_price);
       setPrice(res.data.data.price);
       setCurrency(res.data.data.currency);
       setIsActive(res.data.data.is_active);

       let priceType=null;

  if(res.data.data.pricing_type==="one_month"){
    priceType="1 month (Monthly)";
  }
   else if(res.data.data.pricing_type==="three_months"){
    priceType="3 months (Qaurterly)";
  }
  else if(res.data.data.pricing_type==="six_months"){
    priceType="6 months (Half-yearly)";
  }
  else if(res.data.data.pricing_type==="one_year"){
    priceType="1 year (Yearly)";
  }
  else if(res.data.data.pricing_type==="life_time"){
    priceType="Life time";
  }
  setPricingType(priceType);

       setTaxAmount(res.data.data.tax_percentage)
       setOfferText(res.data.data.description);
     

     
         

      }

        else{
        setGetError(res.data.message);
      }
      


    })
    .catch(err=>{

 setGetError("Error in fetching data");
})


},[]);
	 const handlePrint=()=>{
		window.print();
	}
 return (<div>
    
 	<h2 style={{textAlign:'center'}} className="title-of-page"> Invoice  </h2><br/>
 	<div className="invoice-table flex-col to-print ">
 	 <div className="invoice-row1 flex-row">
    <div className="flex-col center-flex-content">
         <div className="flex-col ">
            <h3 className="invoice-heading caps">{hospitalName}</h3>
           <h6 className="invoice-heading caps">{bdo_hospital_street ? bdo_hospital_street +',' : null }</h6>
         <h6 className="invoice-heading caps">{bdo_hospital_location ? bdo_hospital_location +',' : null } {bdo_hospital_state}</h6>
         </div>
      </div>
      <div className="flex-col ">
          <div className="flex-col ">
          <h6 className="invoice-heading invoice-heading-normal">{bdo_hospital_mobile ? 'Phn : ' + bdo_hospital_mobile : null } </h6>
          <h6 className="invoice-heading invoice-heading-normal">Email : {bdo_hospital_email} </h6>
          <h6 className="invoice-heading invoice-heading-normal"> GSTIN : {bdo_hospital_gst} </h6>
          <h6 className="invoice-heading invoice-heading-normal"> Accreditation : {bdo_hospital_accreditation} </h6>
        </div>
        </div>
     </div>
     <div className="invoice-row2">

     <div>
        <h5 className="grey-title caps">{patientName}</h5>
         <h6 className="grey-title">Phn : {patientPhn}</h6>
  </div>
     </div>
     <div className="invoice-row3">
        <table >
        <tr>
          <th> Particulars </th>
       
          <th> Plan Duration </th>

          <th> Price </th>
          <th> Discounted Price </th>
           <th> Currency </th>
          <th> Tax </th>
          <th> Tenure </th>
          <th> Amount </th>


        </tr>

        <tr>
          <td> 
             <span className="caps">{careName} </span><br/>
             <span> ID: {careId}</span><br/>
             

           </td>
           <td> {pricingType}</td>
           
          <td> {price}</td>
          <td> {discount}</td>
           <td> {currency}</td>
          <td>{taxAmount? taxAmount : 0} % </td>
           <td>{tenure} </td>
            <td>{((discount*(taxAmount/100))+ discount)*tenure} </td>
        </tr>

        </table>
     </div>
     <div className="invoice-row4">
        <h6 className="text-right">Total : <span className="caps-full">{currency} </span>{((discount*(taxAmount/100))+ discount)*tenure} </h6>
         <br/>
         <h6 className="text-muted text-right">Thanks,</h6>
         <h6 className="text-muted text-right">{bdo_name}</h6>
          

     </div>
 	</div>
 	<br/>
 	<br/>
 	{/**<div className="to-print">
 	 <a href="https://www.felixacare.com" target="_blank"><h5 className="felixa-site" > www.felixacare.com</h5></a>

    </div>**/}
 	
 	<div className="do-not-print text-center" id="pr">
		         <button className="btn btn-primary btn-col btn-square" onClick={handlePrint}> Print </button>
		         <br/>
		         <br/>
		         <h6 style={{color:"grey"}}>** If in case 'Print' does not work, please cross check if popup blocker is switched 'off' in your Browser settings.</h6>
                  <br/>
                  <br/>
           </div>

  </div>
 	)

	 }

export default GenerateInvoice;