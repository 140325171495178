import React,{useState, useEffect} from "react";

import "./staffdashboard.css";
import { Link,useHistory } from "react-router-dom";


import tests from "../../images/icons/tests.png";

import medicine from "../../images/icons/medicine.png";
import medicon from "../../images/icons/medicon.png";
import report from "../../images/icons/report.png";
import editreport from "../../images/icons/edit.png";
import search from "../../images/icons/search.png";
import checkmark from "../../images/icons/checkmark.png";
import steth from "../../images/icons/steth1.png";
import survey from "../../images/icons/survey.png";
import icon1 from "../../images/icons/icon1.png";
//import doc from "../../images/icons/doc.png";
import patient from "../../images/icons/patient.png";
import steth1 from "../../images/icons/steth1.png";
import medicine2 from "../../images/icons/medicine2.png";
import scan from "../../images/icons/scan.png";
import labtest from "../../images/icons/labtest.png";
import {Button ,Modal} from 'react-bootstrap';


function StaffDashboard() {

  const history =useHistory();

  const [modalShow, setModalShow]= useState(false)
  const [keys,] = useState({'h': false,'d': false,'u': false,'r': false,'l': false,'i': false,'a': false,'p': false,'v': false,'t': false,'n': false});


useEffect(()=>{

window.onkeydown =(e) => {
 
 keys[e.key]=true

 if(e.altKey && keys['h']){
   history.push('/hospitallist')
 }
 if(e.altKey && keys['d']){
   history.push('/doctorslist')
 }
 if(e.altKey && keys['u']){
   history.push('/search-hospitals')
 }
 if(e.altKey && keys['r']){
   history.push('/rejectedlist')
 }
 if(e.altKey && keys['l']){
   history.push('/reportlist')
 }
 if(e.altKey && keys['i']){
   history.push('/addsynonyms')
 }
 if(e.altKey && keys['a']){
   history.push('/failedlist')
 }
 if(e.altKey && keys['p']){
   history.push('/prescriptionlist')
 }
 if(e.altKey && keys['v']){
   history.push('/written-prescriptionlist')
 }
 if(e.altKey && keys['t']){
   history.push('/testlist')
 }
 if(e.altKey && keys['n']){
  history.push('/nutrition')
}
if(e.altKey && keys['m']){
  history.push('/medicinelist')
}
if(e.altKey && keys['x']){
  history.push('/primary-disease')
}
if(e.altKey && keys['e']){
  history.push('/allergy')
}
if(e.altKey && keys['o']){
  history.push('obser-symptomBO')
}
if(e.altKey && keys['f']){
  history.push('features-list')
}
if(e.altKey && keys['g']){
  history.push('procedure-list')
}
if(e.altKey && keys['z']){
  history.push('comorbidities')
}
if(e.altKey && keys['j']){
  history.push('targetlist')
}
}
window.onkeyup=(ev)=> {
 
 keys[ev.key]= false
}

return()=>{
 window.onkeydown=null
 window.onkeyup=null
}
},[]);

const ShortcutPopup = ({onHide}) => {
  return(
  <Modal
      show
      onHide
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header >
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <h4>Shortcuts</h4>
          <br/>
          <span className='shortcut-class'>alt+h </span>{' '} <i className="fas fa-arrow-right arrow"></i>{' '}<span className='text-muted direct'>Hospitals</span>
          <br/>
          <br/>
          <span className='shortcut-class'>alt+d </span>{' '} <i className="fas fa-arrow-right arrow"></i>{' '}<span className='text-muted direct'>Doctors</span>
          <br/>
          <br/>
          <span className='shortcut-class'>alt+u </span>{' '} <i className="fas fa-arrow-right arrow"></i>{' '}<span className='text-muted direct'>All Users</span>
          <br/>
          <br/>
          <span className='shortcut-class'>alt+r </span>{' '} <i className="fas fa-arrow-right arrow"></i>{' '}<span className='text-muted direct'>rejected Users</span>
          <br/>
          <br/>
          <span className='shortcut-class'>alt+l </span>{' '} <i className="fas fa-arrow-right arrow"></i>{' '}<span className='text-muted direct'>Lab Reports</span>
          <br/>
          <br/>
          <span className='shortcut-class'>alt+i </span>{' '} <i className="fas fa-arrow-right arrow"></i>{' '}<span className='text-muted direct'>Header Issue</span>
          <br/>
          <br/>
          <span className='shortcut-class'>alt+a </span>{' '} <i className="fas fa-arrow-right arrow"></i>{' '}<span className='text-muted direct'>Failed Lab Reports</span>
          <br/>
          <br/>
          <span className='shortcut-class'>alt+p </span>{' '} <i className="fas fa-arrow-right arrow"></i>{' '}<span className='text-muted direct'>Prescriptions</span>
          <br/>
          <br/>
          <span className='shortcut-class'>alt+v </span>{' '} <i className="fas fa-arrow-right arrow"></i>{' '}<span className='text-muted direct'>Handwritten Prescriptions</span>
          <br/>
          <br/>
          <span className='shortcut-class'>alt+t </span>{' '} <i className="fas fa-arrow-right arrow"></i>{' '}<span className='text-muted direct'>Test Names</span>
          <br/>
          <br/>
          <span className='shortcut-class'>alt+n </span>{' '} <i className="fas fa-arrow-right arrow"></i>{' '}<span className='text-muted direct'>Nutrients Table</span>
          <br/>
          <br/>
          <span className='shortcut-class'>alt+m </span>{' '} <i className="fas fa-arrow-right arrow"></i>{' '}<span className='text-muted direct'>Medicine List</span>
          <br/>
          <br/>
          <span className='shortcut-class'>alt+x </span>{' '} <i className="fas fa-arrow-right arrow"></i>{' '}<span className='text-muted direct'>Primary Disease</span>
          <br/>
          <br/>
          <span className='shortcut-class'>alt+e </span>{' '} <i className="fas fa-arrow-right arrow"></i>{' '}<span className='text-muted direct'>Allergy</span>
          <br/>
          <br/>
          <span className='shortcut-class'>alt+o </span>{' '} <i className="fas fa-arrow-right arrow"></i>{' '}<span className='text-muted direct'>Observations- Symptoms</span>
          <br/>
          <br/>
          <span className='shortcut-class'>alt+f </span>{' '} <i className="fas fa-arrow-right arrow"></i>{' '}<span className='text-muted direct'>Feature List</span>
          <br/>
          <br/>
          <span className='shortcut-class'>alt+g </span>{' '} <i className="fas fa-arrow-right arrow"></i>{' '}<span className='text-muted direct'>Procedure Table</span>
          <br/>
          <br/>
          <span className='shortcut-class'>alt+z </span>{' '} <i className="fas fa-arrow-right arrow"></i>{' '}<span className='text-muted direct'>Comorbidity List</span>
          <br/>
          <br/>
          <span className='shortcut-class'>alt+j </span>{' '} <i className="fas fa-arrow-right arrow"></i>{' '}<span className='text-muted direct'>Target List</span>
          </div> 
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={onHide}>Ok</Button>          

      </Modal.Footer>
    </Modal>
  )
}

  return (
    <> <div className="jumbotron jumbotron-fluid staff-jumbo">
  <div className=" ">
      <h2 style={{color: "var(--theme-blue)",fontWeight:"bolder"}}> Welcome to Admin Dashboard</h2>
  </div>
  </div>
      <div className="">
      <h3 className="title-of-page"> Customer Management</h3>
        <div className="parent-of-lists box-container">

           <Link to="/hospitallist" >
           <div className="each-list-title">
             

           <img src={medicine} width="65px" alt="lab reports"/>

            
            <h4 className="each-title">
              {" "}
             
                Hospitals </h4>
                <h5>Pending Approval</h5>
           
           
           </div>
            </Link>

            <Link to="/doctorslist" >
            <div className="each-list-title ">
             

           <img src={steth} width="65px" alt="lab reports"/>

            
            <h4 className="each-title">
              {" "}
             
                Doctors </h4>
                <h5>Pending Approval</h5>
           
           
           </div>
            </Link>
             
        <Link to="/search-hospitals" >
          <div className="each-list-title ">

          <img src={search} width="65px" alt="add headings"/>
           

            <h4 className="each-title">
    
                {" "}
                All Users </h4>
                <h5> View Details, </h5>
                <h5> Enable/Disable Account </h5>
             
           
          </div>
         </Link>

         <Link to="/rejectedlist" >
         <div className="each-list-title ">

          <img src={survey} width="65px" alt="add headings"/>
           

            <h4 className="each-title">
    
                {" "}
                All Users </h4>
                <h5>Rejected List </h5>
             
           
          </div>
  </Link>
  <Link to="/medlist" >
            <div className="each-list-title ">
             

           <img src={medicon} width="65px" alt="lab reports"/>

            
            <h4 className="each-title">
              {" "}
             
                Medicines </h4>
                <h5>Medicine Approval</h5>
           
           
           </div>
            </Link>
          </div>

<div className="seperator divide-line"> </div>

          <h3 className="title-of-page"> Lab Reports</h3>
        <div className="parent-of-lists box-container">

           <Link to="/reportlist" >
            <div className="each-list-title ">
             

           <img src={report} width="65px" alt="lab reports"/>

            
            <h4 className="each-title">
              {" "}
             
                Lab Reports </h4>
                <h5>Verification</h5>
           
           
           </div>
            </Link>
             
        <Link to="/addsynonyms" >
          <div className="each-list-title ">

          <img src={search} width="65px" alt="add headings"/>
           

            <h4 className="each-title">
    
                {" "}
                Headers issue </h4>
                <h5> Add Header </h5>
             
           
          </div>
         </Link>
        
         <Link to="/failedlist" >
          <div className="each-list-title ">

            <img src={tests} width="65px" alt="tests"/>

            <h4 className="each-title">
            
                {" "}
                Failed Lab Reports   </h4>
                <h5>
                  {" "}
                  Add Manually{" "}
                </h5>
              
           
          </div>
          </Link>

{/*
           <Link to="/in-progress" >
          <div className="each-list-title box-blue task-box">

            <img src={tests} width="65px" alt="tests"/>

            <h4 className="each-title">
            
                {" "}
                Missing Header </h4>
                <h5>
                  {" "}
                  Add Header</h5>
              
           
          </div>
          </Link>
 */}

</div>

<div className="seperator divide-line"> </div>

 <h3 className="title-of-page"> Prescriptions & Other Tasks</h3>

 <div className="parent-of-lists box-container">
        <Link to="/prescriptionlist" >
          <div className="each-list-title  ">
             <img src={medicine} width="65px" alt="prescription"/>
            <h4 className="each-title">
            
             
                {" "}
                Prescriptions </h4>
                <h5>Verification</h5>
              
          

          </div>
          </Link>

          <Link to="/medicinelist" >
          <div className="each-list-title ">

            <img src={medicine2} width="65px" alt="tests"/>

            <h4 className="each-title">
            
                {" "}
                Add Medicines  </h4>
                <h5>
                  {" "}
                  Add Here{" "}
                </h5>
              
           
          </div>
          </Link>

          
            <Link to="/written-prescriptionlist" >
          <div className="each-list-title ">

          
           <img src={editreport} width="65px" alt="handwritten prescription"/>
            <h4 className="each-title">
            
                {" "}
                Handwritten Prescriptions </h4>
                <h5>
                  Verification</h5>
              
           
          </div>
          </Link>
              

            <Link to="/testlist" >
          <div className="each-list-title ">

            <img src={tests} width="65px" alt="tests"/>

            <h4 className="each-title">
            
                {" "}
                Test Names   </h4>
                <h5>
                  {" "}
                  Add Synonyms{" "}
                </h5>
              
           
          </div>
          </Link>
         
   

          
         


        
         


          <Link to="/nutrition" >
          <div className="each-list-title ">

            <img src={checkmark} width="65px" alt="nutrition"/>

            <h4 className="each-title">
            
                {" "}
                Nutrients Table   </h4>
                <h5>
                  {" "}
                  Add Values
                </h5>
              
           
          </div>
          </Link>

          </div>
          <div className="seperator divide-line"> </div>

<h3 className="title-of-page"> Diagnosis & Info</h3>

<div className="parent-of-lists box-container">

          <Link to="/obser-symptomBO" >
          <div className="each-list-title ">

            <img src={editreport} width="65px" alt="tests"/>

            <h4 className="each-title">
            
                {" "}
                Add Observations and Symptoms  </h4>
                <h5>
                  {" "}
                  Add Here{" "}
                </h5>
              
           
          </div>
          </Link>

          <Link to="/primary-disease" >
          <div className="each-list-title ">

            <img src={patient} width="65px" alt="tests"/>

            <h4 className="each-title">
            
                {" "}
                Add PrimaryDisease </h4>
                <h5>
                  {" "}
                  Add Disease Here{" "}
                </h5>
              
           
          </div>
          </Link>

          <Link to="/comorbidities" >
          <div className="each-list-title ">

            <img src={steth1} width="65px" alt="tests"/>

            <h4 className="each-title">
            
                {" "}
                Add Comorbidities </h4>
                <h5>
                  {" "}
                  Add Here{" "}
                </h5>
              
           
          </div>
          </Link>

          <Link to="/allergy" >
          <div className="each-list-title ">

            <img src={tests} width="65px" alt="tests"/>

            <h4 className="each-title">
            
                {" "}
                Add Allergy Details</h4>
                <h5>
                  {" "}
                  Add Here{" "}
                </h5>
              
           
          </div>
          </Link>

          <Link to="/procedure-list" >
          <div className="each-list-title  ">
             <img src={report} width="65px" alt="services"/>
            <h4 className="each-title">
            
             
                {" "}
                Procedures </h4>
                <h5>Add Procedures</h5>
              
          

          </div>
          </Link>
          
          <Link to="/targetlist" >
          <div className="each-list-title ">

            <img src={survey} width="65px" alt="tests"/>

            <h4 className="each-title">
            
                {" "}
                Add Targets  </h4>
                <h5>
                  {" "}
                  Add targets here{" "}
                </h5>
              
           
          </div>
          </Link>

          <Link to="/agegroup-list" >
          <div className="each-list-title ">

            <img src={checkmark} width="65px" alt="tests"/>

            <h4 className="each-title">
            
                {" "}
                Add Age Group  </h4>
                <h5>
                  {" "}
                  Add here{" "}
                </h5>
              
           
          </div>
          </Link>


</div>
<div className="seperator divide-line"> </div>

 <h3 className="title-of-page"> Care plans Service and Equipment Management</h3>

 <div className="parent-of-lists box-container">
        <Link to="/features-list" >
          <div className="each-list-title  ">
             <img src={steth} width="65px" alt="services"/>
            <h4 className="each-title">
            
             
                {" "}
                Service and Equipment </h4>
                <h5>Add Features</h5>
              
          

          </div>
          </Link>

        

         

  </div>
  <div className="seperator divide-line"> </div>
  <h3 className="title-of-page"> Treatment Planner</h3>
  <div className="parent-of-lists box-container">
        <Link to="/labtestBO" >
          <div className="each-list-title  ">
             <img src={labtest} width="65px" alt="services"/>
            <h4 className="each-title">
            
             
                {" "}
                Laboratory </h4>
                <h5>Add Laboratory Details</h5>
              
          

          </div>
          </Link>

          <Link to="/scantestBO" >
          <div className="each-list-title  ">
             <img src={scan} width="65px" alt="services"/>
            <h4 className="each-title">
            
             
                {" "}
                Scan</h4>
                <h5>Add Scan Details</h5>
              
          

          </div>
          </Link>


         

  </div>
       <br/>
       <p className='short-align' title="shortcuts">
<i onClick={()=> setModalShow(true)}className="fas fa-map-signs"></i>
</p>       
        <br/>
      </div>

      {
            modalShow ? 
        <ShortcutPopup
            show={modalShow}
            onHide= { ()=> {setModalShow(false)
                           }} />
            : null
        }
    </>
  );
}
export default StaffDashboard;
