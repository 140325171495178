import React, { useState, useEffect } from 'react'
import axios from "axios";
import { APIURL } from "../../../Global";
import { Link } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import './featurelist.css'
import EditFeature from './EditFeature';
import AddFeature from './AddFeature';
import { ToggleButton } from 'react-bootstrap';
import { ToggleButtonGroup } from 'react-bootstrap';
import { expand } from 'urijs';
import AddEquipment from './AddEquipment';
import EditEquipment from './EditEquipment';
import AddInventory from './AddInventory';
import EditInventory from './EditInventory';

function FeaturesList() {

  const [featureList, setFeatureList] = useState([])
  const [rentalList, setRentalList] = useState([])
  const [editShow, setEditShow] = useState(false)
  const [current, setCurrent] = useState()
  const [refresh, setRefresh] = useState(false)
  const [addShow, setAddShow] = useState(false)
  const [addRentalShow, setAddRentalShow] = useState(false)
  const [editRentalShow, setEditRentalShow] = useState(false)
  const [editInventoryShow, setEditInventoryShow] = useState(false)
  const [category, setCategory] = useState(1)
  const [expand, setExpand] = useState()
  const [modalShow, setModalShow] = useState(false)
  const [flag, setFlag] = useState(false)
  const [successShow, setSuccessShow] = useState(false)

  const [deleteShow, setDeleteShow] = useState(false)
  const [deleteIndex, setDeleteIndex] = useState('');
  const [deleteType, setDeleteType] = useState('');




  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); /* REGEX To remove double quotes from tokenstring */



    axios
      .get(`${APIURL}/api/v1/staff/update-cp-services/`, {
        headers: { Authorization: "Token " + v2 },
      })
      .then((res) => {
        if (res.data.status === "success") {
          const datas = res.data.data;
          const filteredData = datas.filter(item => {
            return item.service_type !== 'rental'
          })

          const filteredRentalData = datas.filter(item => {
            return item.service_type === 'rental'
          })
          setFeatureList(filteredData);
          setRentalList(filteredRentalData);

          if (flag) {
            const idVal = filteredRentalData.length - 1
            sessionStorage.setItem('rentalId', filteredRentalData[idVal].id)
            setModalShow(true)
          }
        } else {

        }
      })
      .catch((err) => {

      });
  }, [refresh]);

  const handleChangeRadio = (val) => {
    setCategory(val);
  }

  const modalClose = () => {
    setEditShow(false)
    setRefresh(!refresh)
  }

  const addModalClose = () => {
    setAddShow(false)
    setRefresh(!refresh)
  }

  const rentalModalClose = () => {
    setAddRentalShow(false)
    setRefresh(!refresh)
    setFlag(true)
  }

  const notProceedClose = () => {
    setAddRentalShow(false)
    setRefresh(!refresh)
  }

  const rentalEditModalClose = () => {
    setEditRentalShow(false)
    setRefresh(!refresh)
  }

  const inventoryModalClose = () => {
    setModalShow(false)
    setFlag(false)
    setRefresh(!refresh)
  }

  const editInventoryModalClose = () => {
    setEditInventoryShow(false)
    setRefresh(!refresh)
  }


  const AddPopUp = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">


          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <AddFeature modalClose={addModalClose} />

        </Modal.Body>
        <Modal.Footer>

          <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>

        </Modal.Footer>
      </Modal>
    )
  }

  const AddRentalPopUp = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">


          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <AddEquipment modalClose={rentalModalClose} notProceedClose={notProceedClose} />

        </Modal.Body>
        <Modal.Footer>

          <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>

        </Modal.Footer>
      </Modal>
    )
  }

  const EditPopUp = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">


          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <EditFeature data={current} modalClose={modalClose} />

        </Modal.Body>
        <Modal.Footer>

          <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>

        </Modal.Footer>
      </Modal>
    )
  }

  const EditRentalPopUp = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">


          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <EditEquipment data={current} modalClose={rentalEditModalClose} />

        </Modal.Body>
        <Modal.Footer>

          <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>

        </Modal.Footer>
      </Modal>
    )
  }

  const InventoryPopUp = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">


          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <AddInventory modalClose={inventoryModalClose} />

        </Modal.Body>
        <Modal.Footer>

          <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>

        </Modal.Footer>
      </Modal>
    )
  }

  const EditInventoryPopUp = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">


          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <EditInventory data={current} modalClose={editInventoryModalClose} />

        </Modal.Body>
        <Modal.Footer>

          <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>

        </Modal.Footer>
      </Modal>
    )
  }

  const handleEdit = (index) => {
    const list = featureList[index]
    setCurrent(list)
    setEditShow(true)
  }

  const handleRentalEdit = (index) => {
    const list = rentalList[index]
    setCurrent(list)
    setEditRentalShow(true)
  }

  const handleEditInventory = (index) => {
    const list = rentalList[index]
    setCurrent(list)
    setEditInventoryShow(true)
  }

  const handleDetail = (index) => {
    if (expand !== index) {
      setExpand(index)
    }
    else {
      setExpand()
    }
  }

  const handleDeletePopUp = (id, type) => {
    //  e.preventDefault();
    setDeleteType(type);

    setDeleteIndex(id);
    setDeleteShow(true);
  }

  const DeleteConfirmPopup = (props) => {


    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Are you sure you want to delete?</h4>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Cancel</Button>

          <Button variant="danger"
            onClick={() =>  {
              if(deleteType === 'feature') {
                handleDelete(deleteIndex);
              } else if (deleteType === 'equip') {
                handleDeleteEquip(deleteIndex);
              }
               
               }}
          >
            Confirm</Button>



        </Modal.Footer>
      </Modal>
    );
  }
  const SubmitPopUp = ({ onHide }) => {
    return (
      <Modal
        show
        onHide
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
       
          <h5 className='text-success'>
              {deleteType === 'feature' ? 'feature Deleted  Successfully!!' : 'Equipment Deleted  Successfully!!'}
              </h5>
        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={onHide}>Ok</Button>

        </Modal.Footer>
      </Modal>
    )
  }


  
  const handleDeleteEquip = (item) => {


    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '');

    //  const dataToSend = {
    //   "id" : item
    // }

    axios.delete(`${APIURL}/api/v1/staff/update-cp-services?id=${deleteIndex}`, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {


        if (res.status == 200) {
          setSuccessShow(true)

          // setRefresh(!refresh)



        }
        else {
          console.log('else called ');
          //  setErrorMsg(res.data.message);
          //             setErrorSubmit(true);
        }


        setDeleteIndex('');
        setDeleteShow(false);

      })
      .catch(err => {
        console.log("catch called");
        // setSubmitMsg("error");
        // setErrorSubmit(true);
      })

  }

  const handleDelete = (item) => {


    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '');

    //  const dataToSend = {
    //   "id" : item
    // }

    axios.delete(`${APIURL}/api/v1/staff/update-cp-services?id=${deleteIndex}`, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {


        if (res.status == 200) {
          setSuccessShow(true)

          // setRefresh(!refresh)



        }
        else {
          console.log('else called ');
          //  setErrorMsg(res.data.message);
          //             setErrorSubmit(true);
        }


        setDeleteIndex('');
        setDeleteShow(false);

      })
      .catch(err => {
        console.log("catch called");
        // setSubmitMsg("error");
        // setErrorSubmit(true);
      })

  }

  const dataDisplay = category === 1 ? featureList.length > 0 ? featureList.map((item, index) => {
    return (
      <div className='care-features' key={item.id}>
        <div className='feature-data'>
          <h5 className='feature-title'>{index + 1}.{item.title2}</h5>
          <h5 style={{ marginLeft: 'auto', marginRight: 'auto' }} className='feature-title'>{item.service_type.replace("_", " ")}</h5>
        </div>
        <div className='seperate-content' />
        <div className='feature-data'>
          <h6 className='feature-request'>On Request Service : {item.on_request_service === true ? 'Yes' : item.on_request_service === false ? 'No' : 'Nil'}</h6>
          <h6 className='feature-service'>Service Unit : {item.service_unit}</h6>
          <h6 className='feature-service'>Active : {item.is_active === true ? 'Yes' : item.is_active === false ? 'No' : 'Nil'}</h6>
          <button onClick={() => handleEdit(index)} className='btn btn-primary btn-col'>Edit</button>
          &nbsp;
          <button onClick={() => handleDeletePopUp(item.id,'feature')} className='btn btn-danger delete-font'> Delete</button>
        </div>
      </div>
    )

  }) : <div style={{ color: "red", fontSize: "25px", height: "200px", padding: "40px" }}>
    <h4>No Features to show!!</h4>
  </div>

    : category === 2 ? rentalList.length > 0 ? rentalList.map((item, index) => {
      return (
        <div className='care-features' key={item.id}>
          <div className='feature-data'>
            <h5 className='feature-title'>{index + 1}.{item.title2}</h5>
            <h5 style={{ marginLeft: 'auto', marginRight: 'auto' }} className='feature-title'>{item.service_type.replace("_", " ")}</h5>
            <button onClick={() => handleRentalEdit(index)} className='btn btn-primary btn-col'>Edit</button>
            &nbsp;
          <button onClick={() => handleDeletePopUp(item.id,'equip')} className='btn btn-danger delete-font'> Delete</button>

          </div>
          {
            expand !== index ?
              <div className='seperate-content' />
              : null
          }


          <div style={{ cursor: 'pointer' }} onClick={() => handleDetail(index)} className={expand === index ? 'feature-data mt-3' : 'feature-data'}>
            <h6 className='feature-request'>On Request Service : {item.on_request_service === true ? 'Yes' : item.on_request_service === false ? 'No' : 'Nil'}</h6>
            <h6 className='feature-service'>Service Unit : {item.service_unit}</h6>
            <h6 className='feature-service'>Active : {item.is_active === true ? 'Yes' : item.is_active === false ? 'No' : 'Nil'}</h6>
            <h6 className='feature-service'><i className={expand === index ? "fas fa-sort-up detail-open" : "fas fa-sort-down detail-open"}></i>Inventory Details</h6>
          </div>
          {
            expand === index ? <>
              <div className='seperate-content' />
              <div className='row-of-features'>
                <div className='col-of-features'>
                  <h6>Name : {item.name ? <span>{item.name}</span> : <span>Nil</span>}</h6>
                  <h6>Serial : {item.serial_number ? <span>{item.serial_number}</span> : <span>Nil</span>}</h6>
                  <h6>Make : {item.make_company ? <span>{item.make_company}</span> : <span>Nil</span>}</h6>
                  <h6>Model : {item.model ? <span>{item.model}</span> : <span>Nil</span>}</h6>
                  <h6>Price : {item.price ? <span>{item.price}</span> : <span>Nil</span>}</h6>
                  <h6>Buying Price : {item.buying_price ? <span>{item.buying_price}</span> : <span>Nil</span>}</h6>
                </div>

                <div className='col-of-features'>
                  <h6>Distributor Address : {item.distributor_address ? <span>{item.distributor_address}</span> : <span>Nil</span>}</h6>
                  <h6>Company Address : {item.company_address ? <span>{item.company_address}</span> : <span>Nil</span>}</h6>
                  <h6>Distributor : {item.distributor ? <span>{item.distributor}</span> : <span>Nil</span>}</h6>
                  <h6>Manufacture Year : {item.year_of_manufacture ? <span>{item.year_of_manufacture.slice(0, 10)}</span> : <span>Nil</span>}</h6>
                  <h6>Expiry Date : {item.expiry_date ? <span>{item.expiry_date.slice(0, 10)}</span> : <span>Nil</span>}</h6>
                </div>
              </div>
              <button onClick={() => handleEditInventory(index)} style={{ alignSelf: 'flex-end' }} className='btn btn-primary btn-col'>Edit Inventory</button>
            </>
              : null
          }

        </div>
      )

    }) : <div style={{ color: "red", fontSize: "25px", height: "200px", padding: "40px" }}>
      <h4>No Equipments to show!!</h4>
    </div>

      : <div style={{ color: "red", fontSize: "25px", height: "200px", padding: "40px" }}>
        <h4>No Features to show!!</h4>
      </div>


  return (
    <div className="container displaylist-container ">
      <div className="dashboard-navigation">
        <div>
          {" "}
          <Link to="/dashboard">
            {" "}
            <i className="fas fa-home dasboard-icon"> </i>Dashboard{" "}
          </Link>
        </div>

        <div>
          {" "}
          <i className="fas fa-angle-double-right angle-icon"></i>{" "}
        </div>
        <div>
          {" "}
          <Link to="/features-list"> Care Package Features List </Link>{" "}
        </div>
      </div>
      <div className="header-display">
        <h2 className="title-of-page"> Care Package Features List</h2>


        <div className="buttons-wrapper">
          <button className="btn btn-secondary" onClick={() => setRefresh(!refresh)}>
            Refresh List <i className="fas fa-sync-alt"></i>{" "}
          </button>
          {
            category === 1 ?
              <button className="btn btn-primary btn-col" onClick={() => setAddShow(true)}>
                Add Feature <i class="fas fa-plus"></i>
              </button>
              :
              category === 2 ?
                <button className="btn btn-primary btn-col" onClick={() => setAddRentalShow(true)}>
                  Add Equipment <i class="fas fa-plus"></i>
                </button>
                : null
          }

        </div>


      </div>

      <div className='mb-5'>
        <ToggleButtonGroup type="radio" name="options" defaultValue={category} onChange={handleChangeRadio}>
          <ToggleButton id="tbg-radio-1" value={1} className="btn btn-light toggle-btns toggle-btn-1">
            Feature
          </ToggleButton>
          <ToggleButton id="tbg-radio-2" value={2} className="btn btn-light toggle-btns toggle-btn-2">
            Equipment
          </ToggleButton>

        </ToggleButtonGroup>
      </div>

      <div className="test-reports-display-list">

        <div className='feature-container'>
          {dataDisplay}
        </div>

      </div>


      {
        editShow ?
          <EditPopUp
            show={editShow}
            onHide={() => setEditShow(false)}
          /> : null
      }

      {
        deleteShow ?
          <DeleteConfirmPopup
            show={deleteShow}
            onHide={() => {
              setDeleteShow(false);
              setDeleteIndex('');
            }}

          /> : ''
      }

      {
        successShow ?
          <SubmitPopUp
            show={successShow}
            onHide={() => { setSuccessShow(false); setRefresh(!refresh); }} />
          : null
      }


      {
        editRentalShow ?
          <EditRentalPopUp
            show={editRentalShow}
            onHide={() => setEditRentalShow(false)}
          /> : null
      }

      {
        addShow ?
          <AddPopUp
            show={addShow}
            onHide={() => setAddShow(false)}
          /> : null
      }

      {
        addRentalShow ?
          <AddRentalPopUp
            show={addRentalShow}
            onHide={() => setAddRentalShow(false)}
          /> : null
      }

      {modalShow ?
        <InventoryPopUp
          show={modalShow}
          onHide={() => { setModalShow(false); setFlag(false) }}
        />
        : null}

      {editInventoryShow ?
        <EditInventoryPopUp
          show={editInventoryShow}
          onHide={() => { setEditInventoryShow(false) }}
        />
        : null}

    </div>
  )


}

export default FeaturesList
