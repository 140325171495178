import React, { useState } from "react";
import axios from "axios";

import {Link } from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import {APIURL} from "../../../Global";
function RejectReport(props) {
 
 
  const [rejectSuccessModalShow,setRejectSuccessModalShow] = useState(false);
 const [rejectSuccessMsg,setRejectSuccessMsg] =useState("");
 const [rejectConfirmMsg,setRejectConfirmMsg] = useState("");
 const [rejectConfirmModalShow,setRejectConfirmModalShow] =useState(false);
 
  

  



          
   

   const RejectConfirmPopup =(props)=>{
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 id="confirm-reject">Please confirm to reject the report</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Cancel</Button>

        <Button variant="danger" onClick={()=>{setRejectConfirmModalShow(false);
                                                               handleReject();}}> Confirm</Button>

       
      </Modal.Footer>
    </Modal>
  );
}

 const RejectSuccessPopup =(props)=>{
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header >
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Report rejected successfully!</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
        

        <Link to="/addsynonyms"><Button className='btn btn-primary btn-col' > Goto Reports List</Button></Link>

       
      </Modal.Footer>
    </Modal>
  );
}



const handleRejectConfirm = (e)=>{
   e.preventDefault();

    setRejectConfirmMsg('success');
    setRejectConfirmModalShow(true);  
}
  
  const handleReject =() =>{
 
    
    const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '') ;

       axios.post(`${APIURL}/api/v1/staff/lab-reports/${props.match.params.id}/`, {status : "rejected"} , { headers: {
      'Content-Type': 'application/json',
      "Authorization" : 'Token '+str }})
  .then(res => {
    if(res.data.status==="success"){
 
    setRejectSuccessMsg('success');
    setRejectSuccessModalShow(true);  
    
   
  
   

  }
  else {
  
     setRejectSuccessMsg('error');
    
  }
}).catch(err=>{

   setRejectSuccessMsg('error');
})


}
 



  return (<div className="reject-report">
    
       <button className="btn btn-danger" type="submit" onClick={handleRejectConfirm}>Reject Report</button>
    
     {rejectConfirmMsg==="success" ? 

          <RejectConfirmPopup
        show={rejectConfirmModalShow}
        onHide={() =>  setRejectConfirmModalShow(false)}
      />

           : 
             '' }

      

       {rejectSuccessMsg==="success" ? 

          <RejectSuccessPopup
        show={rejectSuccessModalShow}
        onHide={() =>  setRejectSuccessModalShow(false)}
      />

           :  rejectSuccessMsg==="error" ? 
               (<div>
                <br/>
              <h5 style={{color: "red"}}> Error in Report rejection.Please check again! </h5>
              </div>) 
               : ''}


    </div>
  );
}

export default RejectReport;
