import React from "react";

import "./messages.css";
import { Link} from "react-router-dom";
import axios from "axios";
import Pagination from "react-js-pagination";
import "./pagination.css";
import {APIURL} from "../../Global";
import HospDrMessages from "../HospDrMessages/HospDrMessages";
import {Button} from 'react-bootstrap';

import {Modal} from 'react-bootstrap';
import HospDocNavbar from "../HospDocNavbar/HospDocNavbar";



class MessageList extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        datalist: [],
         activePage: 1,
        order: "asc",
        dropdownOpen : false,
        total_page:1,
        sort: "sort",
        messagesShow: false,
        idval:0,
        keys : {
          'p': false,
          'a': false,
          'n': false,
          'l': false,
          's': false
         } 
        
      };
    }
    
   handleBack=(e)=>{
    this.props.history.goBack();
}
    componentDidMount() {
      const tokenString = sessionStorage.getItem("usertoken");
  
      let v2 = tokenString.replace(
        /["]+/g,
        ""
      ); /* REGEX To remove double quotes from tokenstring */
  
     
  
      axios
        .get(`${APIURL}/api/v1/doctor/message-list/`, {
          headers: { Authorization: "Token "+ v2 },
        })
        .then((res) => {
          if (res.data.status === "success") {
            
            
            this.setState({total_page:res.data.total_pages})
            const datas = res.data.data;
            const date = datas.sort((a,b)=> {
              return new Date(b.date_time) - new Date(a.date_time);
          })
            this.setState({ datalist: date });
          } else {

          }
        })
        .catch((err) => {

        });

            //////* Shortcuts ********/ 

    window.onkeydown =(e) => {
    
      this.state.keys[e.key]=true

      if(e.altKey && this.state.keys['a']){
        this.props.history.push('/appointment')
      }
      if(e.altKey && this.state.keys['n']){
        this.props.history.push('/doctor/notifications')
      }
      if(e.altKey && this.state.keys['p']){
        this.props.history.push('/patients-directory')
      }
      if(e.altKey && this.state.keys['l']){
        this.props.history.push('/doctor/analytics')
      }
      if(e.altKey && this.state.keys['s']){
        this.props.history.push('/service-requestlist')
      }
  
  
    }
    window.onkeyup=(ev)=> {
      
      this.state.keys[ev.key]= false
    }
}
  
    componentWillUnmount(){ 
      window.onkeydown=null
      window.onkeyup=null
    }
  
    render() {
  
      const handleMessagesOverlay=()=>{

        this.setState({messagesShow:true});

       }
       const withdraw=(idv)=> {
        this.setState({ idval:idv  })

        handleMessagesOverlay()
      }
  

       
     
      let dataDisplay = this.state.datalist.map((data, index) => {
        const idval1 = data.patient_id;

      let url = `/patient-record/${idval1}`;
      
     return (


      

      
       <div className="list-item list-msg">
         <div className="d-flex justify-content-start">

<div className="p-6 sm-highlight mb-6">

                  <img className="profile-picture-mini" src={data.photo} alt='Profile Photo' width="50px" height="auto"/>                
         {/* <h3> {data.date_time}</h3> */}
         <Link to={url}>{data.patient_name}    -      Patient_id - {data.patient_id} </Link>

         
  
</div>

      
    </div> 
    <div className="d-flex flex-row-reverse bd-highlight">


  <div className="p-2 bd-highlight"> </div>
         
       </div>
      
       <div className="msg-section">
       <div className="p-2  bd-highlight">
        {data.last_message ? data.last_message=== 'media' ? 

          <div onClick={() => withdraw(idval1) } className=" media-button">{<span><i className="fas fa-images"></i> Media </span>}</div>
          :
          <div onClick={() => withdraw(idval1) } className=" message-button">{data.last_message}</div>

          : null }</div>
  
  <div className="p-2  bd-highlight">{new Date(data.date_time).toString().slice(0,21)}</div>
 </div>


      
                  {/* <Link to={url}>{data.patient_name}    -      Patient_id - {data.patient_id} </Link> */}

         <h5 className="each-list-link">
         
           
                        



         </h5>

         
         <div className="details">

         
        
         
         
                         

           {/* <p> Date : {data.date_time} </p> */}

         </div>
       </div>
     );
   });



        
        
         
  
      const handlePageChange=(pageNumber)=> {
     
      this.setState({activePage: pageNumber});
      const tokenString = sessionStorage.getItem("usertoken");
  
      let v2 = tokenString.replace(
        /["]+/g,
        ""
      ); /* REGEX To remove double quotes from tokenstring */
  
     
  
      axios
      .get(`${APIURL}/api/v1/doctor/message-list/`, {
        headers: { Authorization: "Token "+ v2 },
      })
      .then((res) => {
        if (res.data.status === "success") {
          
          
          this.setState({total_page:res.data.total_pages})
          const datas = res.data.data;
       

          this.setState({ datalist: datas });
        } else {

        }
      })
      .catch((err) => {

      });
  }
 


  let track = null
 
  
  const msgData = (childData) => {
    track =childData
  }
     
        
  


const handleTrack= ()=> {
  this.setState({messagesShow:false})
  if(track!==null){
    track.stop();
  }
}

  const MessagesPopup=(props)=> {
 
 
  return (
    <Modal
      {...props}
     
       aria-labelledby="example-custom-modal-styling-title"
       backdrop="static"
       keyboard={false}
       dialogClassName="modal-90w"
    
    >
      <Modal.Header closeButton >
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body >

      <HospDrMessages patientId={props.id} track={msgData} />

        
       
      </Modal.Body>
      <Modal.Footer>
       
        
       {/* <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button> */}
       
       
      </Modal.Footer>
    </Modal>
  );
}

   

  
      return (<>
        <HospDocNavbar />
         <div className=" text-left">
        <br/>
          <button className="btn btn-info back-btn" onClick={this.handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back</button> 
    </div>
        
        <div className="container displaylist-container ">

          <div className="header-display">
            <h2 className="title-of-page"> Message List</h2>
  
  
           <div className="buttons-wrapper">



  
  

  {this.state.messagesShow? 

  <MessagesPopup 
        show={this.state.messagesShow}
        onHide={handleTrack}
        id ={this.state.idval}
        

      />: null }
  
  </div>
  
  
          </div>
  
          <div className="test-reports-display-list">
            {dataDisplay.length ? (
              dataDisplay
            ) : (
             <div style={{ color: "red", fontSize: "25px",height:"200px",padding: "40px" }}>
                <h4>No messages to show at the moment!!</h4>
              </div>
            )}
          </div>
          
          {this.state.total_page*10 >10 ?
          <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.state.total_page*10}
            pageRangeDisplayed={this.state.total_page}
            onChange={handlePageChange}
            disabledClass = "disabled-class"
          />
         : null}
          {/*
        
        
                                  <button type="submit" className="next-btn">Next</button> */}
        </div>
        </>
      );
    }
  }
  
  export default MessageList;
  