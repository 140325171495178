import React,{useState} from 'react'
import Select from 'react-select';
import axios from "axios";
import {APIURL} from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import DatePicker from 'react-date-picker';

function EditMedicine(props) {

    const[data, setData]= useState(props.data)
   const [dosage_type, Setdosage_type]= useState({value:props.data.dosage_form, label: props.data.dosage_form})
   const [routine, setRoutine]= useState({value:props.data.route, label: props.data.route})
    const[strength_unit,setStrengthUnit]=useState({value:props.data.strength_unit, label: props.data.strength_unit})
    

    const [successShow, setSuccessShow] = useState(false)
    const [errorShow, setErrorShow] = useState(false)

   // const [year, setYear]= useState(data.year_of_manufacture=== null ? '' : new Date(data.year_of_manufacture))
    //const [expiry, setExpiry]= useState(data.expiry_date=== null ? '' : new Date(data.expiry_date))

  //  const [yearData, setYearData]= useState(data.year_of_manufacture)
    //const [expiryData, setExpiryData]= useState(data.expiry_date)
    const DosageOptions = [{ value:'tablets', label:'TABLETS' },                       
    { value:'capsules', label:'CAPSULES' },
    { value:'chewable tablets', label:'CHEWABLE TABLETS' },
    { value:'powders', label:'POWDERS' },
    { value:'solutions', label:'SOLUTIONS' },
    { value:'emulsions', label:'EMULSIONS' },
    { value:'suspensions', label:'SUSPENSIONS' },
    { value:'lotions', label:'LOTIONS' },
    { value:'creams', label:'CREAMS' },
    { value:'ointments', label:'OINTMENTS' },
    { value:'effervescent granules', label:'EFFERVESCENT GRANULES' },
    { value:'aerosols', label:'AEROSOLS' },
    { value:'gases', label:'GASES' },
    { value:'suppositories', label:'SUPPOSITORIES' },
    { value:'injections', label:'INJECTIONS' }
]

const RouteOptions = [{ value:'oral', label:'ORAL' },
{ value:'topical_skin', label:'TOPICAL_SKIN' },
{ value:'topical-eyes', label:'TOPICAL_EYE' },
{ value:'topical_ears', label:'TOPICAL_EARS' },
{ value:'inhalation', label:'INHALATION' },
{ value:'injections', label:'INJECTIONS' },
{ value:'vaginal', label:'VAGINAL' },
{ value:'anal', label:'ANAL' },
{ value:'sub_lingual', label:'SUB_LINGUAL' }

]
const Strengthoptions = [{ value:'mg', label:'mg' },                       
{ value:'g', label:'g' },
{ value:'ml', label:'mL' },
{ value:'mcg', label:'mcg' },
{ value:'mEq', label:'mEq' },
{ value:'iu', label:'IU' },
]


const handleServiceChange= (data) => {
  Setdosage_type(data)
}

const handleUnitChange= (data) => {

  setRoutine(data)
}

const strengthChange = (data)=>
{
 setStrengthUnit(data)
}


    const SuccessPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4 className='text-success'>Updated Successfully</h4>
             
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
             
            
            </Modal.Footer>
          </Modal>
        );
      }
    
      const ErrorPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <h5 style={{color: "red"}}>Error in data submission.Please try again!</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
             
            
            </Modal.Footer>
          </Modal>
        );
      }

      const handleTitle = (e)=> {
        e.preventDefault();        
        const val=e.target.value;
        const key=e.target.name;
        setData( current => ({
            ...current , [key] : val
        }))
      }

//       const handleServiceChange= (data) => {

//         setType(data)
//    }

//    const handleUnitChange= (data) => {

//         setUnit(data)
//   }

//   const handleRequest = (e)=> {
//    const val = e.target.value
//    const key = e.target.name

//    setData((current) => ({
//        ...current , [key] : val==='true'? true : val==='false'? false : null
//    }))

//   }

//   const handleYear = (date)=> {
//     setYear(date);
      
//       let dateUpdated= date;
//         if(date!==null){
//       const offset = date.getTimezoneOffset();
      
//       dateUpdated = new Date(date.getTime() - (offset*60*1000))
      
//       let DateExtracted = dateUpdated.toISOString().split('T')[0];
      
//       /*let dateString= DateExtracted.toString(); */
//       setYearData(DateExtracted);
      
//       }
//   }

//   const handleExpiry = (date)=> {
//     setExpiry(date);
      
//       let dateUpdated= date;
//         if(date!==null){
//       const offset = date.getTimezoneOffset();
      
//       dateUpdated = new Date(date.getTime() - (offset*60*1000))
      
//       let DateExtracted = dateUpdated.toISOString().split('T')[0];
      
//       /*let dateString= DateExtracted.toString(); */
//       setExpiryData(DateExtracted);
      
//       }
//   }


const saveChanges = (e)=> {
    e.preventDefault();


        const tokenString = sessionStorage.getItem("usertoken");
 
     let v2 = tokenString.replace(
       /["]+/g,
       ""
     ); /* REGEX To remove double quotes from tokenstring */
 
  const dataToSend = {
    med_name : data. med_name,
    generic_name :data. generic_name,
    strength : data.strength,
    med_type :   data. med_type,                               
    dosage_form : dosage_type.value,
    med_code :  data.med_code,                    
    route : routine.value,
    description: data.description, 
    manufacturer_name:data.manufacturer_name,
    strength_unit:strength_unit.value,
    composition:data.composition
        

  }
 
     axios
       .put(`${APIURL}/api/v1/staff/master-medicine-detail/${data.id}/`, dataToSend , {
         headers: { Authorization: "Token " + v2 },
       })
       .then((res) => {
         if (res.status==200) {
          setSuccessShow(true);
          setData({
            medicinename :'',
            genericname:'',
            strength:'',
            medicinetype:'',
            dosage_type:{ value:'', label:'' },
            medicinecode:'',
            routine: { value:'', label:'' },
            description:'',
            manufacturername:'',
            strength_unit:'',
            composition:''
         });}
          else {
           setErrorShow(true)
         }
       })
       .catch((err) => {
         setErrorShow(true)
       });
       setSuccessShow(false)
    }




    return (
        <>
     <h2 style={{marginTop:'0'}} className="title-of-page">Edit Medicine</h2>
        <div style={{maxHeight:'1000px'}} className='config-box fit-content'>

            
            <div className='feature-data pad-features'>
                <div className='flex-col justify-feature'>
                    <span className='align-rental'>*Medicine Name : </span>
                    <input type='text' className='form-control title-flex' name='med_name' value={data.med_name} onChange={handleTitle} />
                    {/* {error.medicinename ? <div className="error-validation-msg error-feature-inventory">{error.medicinename}</div> : null} */}
                </div>

                <div className='flex-col justify-feature'>
                    <span className='align-rental'>*Generic Name : </span>
                    <input type='text' className='form-control title-flex' name='generic_name' value={data.generic_name} onChange={handleTitle} />
                    {/* {error.genericname ? <div className="error-validation-msg error-feature-inventory">{error.genericname}</div> : null} */}
                </div>
                <div className='flex-col justify-feature'>
                    <span className='align-rental'>*Strength: </span>
                    <input type='text' className='form-control title-flex' name='strength' value={data.strength} onChange={handleTitle} />
                    {/* {error.strength ? <div className="error-validation-msg error-feature-inventory">{error.strength}</div> : null} */}
                </div>

                <div className='flex-row name-content' style={{marginLeft:"-4%",width:"51%"}}>
                <span className='align-rental'>*Strength Unit: </span>
                <Select
                           defaultValue={strength_unit}
                            onChange={(data,e)=>{strengthChange(data,e)
                            }}
                            options={Strengthoptions}
                            name='strength_unit'
                            className="select-currency select-flex"
                        />
                    {/* {error.dosage_type ? <div className="error-validation-msg error-feature-inventory">{error.dosage_type}</div> : null} */}
                </div>
                <div className='flex-col justify-feature'>
                    <span className='align-rental'>*Medicine Type : </span>
                    <input type='text' className='form-control title-flex' name='med_type' value={data. med_type} onChange={handleTitle} />
                    {/* {error.medicinetype ? <div className="error-validation-msg error-feature-inventory">{error.medicinetype}</div> : null} */}
                </div>
                
                <div className='flex-col justify-feature'>
                    <span className='align-rental'> *Medicine Code : </span>
                    <input type='text' className='form-control title-flex' name='med_code' value={data. med_code} onChange={handleTitle} />
                    {/* {error.medicinecode ? <div className="error-validation-msg error-feature-inventory">{error.medicinecode}</div> : null} */}
                </div>
               
                <div className='flex-col justify-feature'>
                    <span className='align-rental'> Description : </span>
                    <textarea type='text' className='form-control title-flex' name='description' value={data.description} onChange={handleTitle} />
                    {/* {error.description ? <div className="error-validation-msg error-feature-inventory">{error.description}</div> : null} */}
                </div>
                <div className='flex-col justify-feature'>
                    <span className='align-rental'> Manufacture Name : </span>
                    <input type='text' className='form-control title-flex' name='manufacturer_name' value={data.manufacturer_name} onChange={handleTitle} />
                    {/* {error.manufacturername ? <div className="error-validation-msg error-feature-inventory">{error.manufacturername}</div> : null} */}
                </div>
                <div className='flex-row name-content' style={{marginLeft:"-4%",width:"51%"}}>
                <span className='align-rental'>*Dosage: </span>
                <Select
                           defaultValue={dosage_type}
                            onChange={(data,e)=>{handleServiceChange(data,e)
                            }}
                            options={DosageOptions}
                            name='dosage_type'
                            className="select-currency select-flex"
                        />
                    {/* {error.dosage_type ? <div className="error-validation-msg error-feature-inventory">{error.dosage_type}</div> : null} */}
                </div>

                <div className='flex-row name-content' style={{marginLeft:"-1%",width:"51%"}}>
                <span className='align-rental'>*Route: </span>
                <Select
                defaultValue={routine}
                           
                            onChange={(data,e)=>{handleUnitChange(data,e)
                            }}
                            options={RouteOptions}
                            name='routine'
                            className="select-currency select-flex"
                        />
                    {/* {error.routine ? <div className="error-validation-msg error-feature-inventory">{error.routine}</div> : null} */}
                </div>
                <div className='flex-col justify-feature'>
                    <span className='align-rental'>Composition: </span>
                    <input type='text' className='form-control title-flex' name='composition' value={data. composition} onChange={handleTitle} />
                    {/* {error.medicinetype ? <div className="error-validation-msg error-feature-inventory">{error.medicinetype}</div> : null} */}
                </div>

                </div>

            <div className='text-center'>
                <button onClick={saveChanges} className='btn btn-primary btn-col mb-2'>Save</button>
                
            </div>
            {successShow ? 
<SuccessPopUp
show={successShow}
onHide={() =>  {setSuccessShow(false);props.modalClose() }}
/> 
: null}

{errorShow ? 

<ErrorPopUp
show={errorShow}
onHide={() =>  {setErrorShow(false)}}
/> 
: null}
            
        </div>
        </>
    )
}

export default EditMedicine
