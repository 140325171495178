import React, { useState,useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import "../../App.css";
import AddRemark from "../Remarks/AddRemark";
import axios from "axios";
import { APIURL } from "../../Global";
function CommentHistory(propsHistory) {
  
   
 const [historyshow,] = useState(propsHistory.show);
 
  const [submitMsg, setSubmitMsg] = useState(""); 
  const [patientDetails,] = useState(propsHistory.patientdata[0]);
  const [loadCommentData, setloadCommentData] = useState(propsHistory.loadCommentData);
  const [successSubmit, setSuccessSubmit] = useState(false);  
  const [errorMsg, setErrorMsg] = useState("");
  const [errorSubmit, setErrorSubmit] = useState(false);

 

  const retunHistoryById = loadCommentData.map((item, index) => {
   
    let dateObject = new Date(item.commented_at);
    let updatedDate = dateObject.toString();

    return (
      <div className="model-content">
        <div
          role="document"
          className="modal-dialog remark-width modal-dialog-centered"
        >
          <div className="comment-container">
            <div className="comment-flex" key={index}>
              <span>
                <i class="fas fa-user mr-2"></i>
                {item.commented_by}
              </span>
              <span>
                <i class="fas fa-calendar-alt mr-2"></i>
                {updatedDate.slice(0, 21)}
              </span>
            </div>
            <span className="comment-span">{item.comment}</span>
          </div>
        </div>
      </div>
    );
  });

  const handleSave = (props) => {
    

    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");
    const dataToUpdate = {
      comment: props,
      procedure_date: propsHistory.date
    };

    axios
      .post(
        `${APIURL}/api/v1/hospital/procedure-comment/?patient_id=` +
          patientDetails.patient_id,
        dataToUpdate,
        {
          headers: {
            Authorization: "Token " + str,
            "Content-Type": "application/json",
          },
        }
      )

      .then((res) => {
        if (res.data.status === "success") {
         
          
          setSubmitMsg(
            res.data.message ? res.data.message : "Saved successfully!"
          );
        
          // --------------------------------------------------------------------------------------------------

          axios
            .get(
              `${APIURL}/api/v1/hospital/procedure-comment/?procedure_date=${propsHistory.date}&patient_id=` +
              patientDetails.patient_id,
              {
                headers: {
                  Authorization: "Token " + str,
                  "Content-Type": "application/json",
                },
              }
            )

            .then((res) => {
             
              if (res.data.status === "success") {
               
                setloadCommentData(res.data.comments.reverse());
                setSuccessSubmit(true);
               
              } else {
                setErrorMsg(
                  res.data.message ? res.data.message : "Error in submission"
                );
                setErrorSubmit(true);
              }
            })
            .catch((err) => {
              setErrorMsg("Error in submission");
              setErrorSubmit(true);
            });

          //===========================================
 
        } 
      })
      .catch((err) => {
        setErrorMsg("Error in submission");
        setErrorSubmit(true);
      });
 
  };


  const SuccessPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
       
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h5 style={{ color: "green" }}>{props.msg} </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const SubmitErrorPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}>
            {" "}
            {errorMsg !== ""
              ? errorMsg
              : "Error in Data Submission. Please try again!"}{" "}
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };


  return (
    
    <>
     {/* onHide={CosthandleClose}  */}
     {
    
     historyshow ? (

    
      
      <Modal show={historyshow} animation={false} dialogClassName="comment-modal-width"   onHide={() => {
        propsHistory.retunSubmitClose(false)
        
      }}>  
        <Modal.Header closeButton>
          <Modal.Title> Comments </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <AddRemark CommentRemarkDataSave={handleSave} />

          {retunHistoryById}
        </Modal.Body>
        <Modal.Footer>
        {/* onClick={handleCloseHistory} */}
          <Button variant="secondary" 
          
          // onClick={() =>
          //   handleCloseHistory()
          // }
          onClick={() => propsHistory.retunSubmitClose(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
       ) : null
          
      }



      {successSubmit ? (
          <SuccessPopup
            show={successSubmit}
            msg={submitMsg}
            onHide={() => {
              setSuccessSubmit(false);
              setSubmitMsg(false)
              //props.modalHandle();
            }
          }
          />
        ) : null
          
        }

        {errorMsg !== "" ? (
          <SubmitErrorPopup
            show={errorSubmit}
            onHide={() => {
              setErrorSubmit(false);
              setErrorMsg("");
            }}
          />
        ) : null}
    </>
  );
}

export default CommentHistory;
