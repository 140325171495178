import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "./App.css";
import alanBtn from '@alan-ai/alan-sdk-web';
import { useEffect, useRef } from 'react';
import Navbar from "./components/Navbar/Navbar";
import Login from "./components/Login/Login";
import Home from "./components/Home/Home";
import Footer from "./components/Footer/Footer";

import Default404 from "./components/Default404/Default404";
import SuccessReg from "./components/SuccessReg/SuccessReg";
import SuccessPatient from "./components/SuccessReg/SuccessPatient";

import ErrorReg from "./components/ErrorReg/ErrorReg";
import Nutrition from "./components/Nutrition/Nutrition";
import HospitalUpdateDoctor from "./components/HospitalUpdateDoctor/HospitalUpdateDoctor";
import ProcedureRequestsBdo from "./components/ProcedureFulfillment/ProcedureRequestsBdo";
//import ProcedureRequests from "./components/ProcedureFulfillment/ProcedureRequests";
import ProcedureNamesBdo from "./components/ProcedureFulfillment/ProcedureNamesBdo";
import ProcedureRequests from "./components/ProcedureFulfillment/ProcedureRequests";

import { RegProvider } from "./contexts/RegistrationContext";
import { ReportProvider } from "./contexts/ReportContext";
import { LoginProvider } from "./contexts/LoginContext";
import { LoginStatusProvider } from "./contexts/LoginStatusContext";
import { HospLoginProvider } from "./contexts/HospitalLoginContext";
import { HospDrLoginProvider } from "./contexts/HospDrLoginContext";
import { PharmacyLoginProvider } from "./contexts/PharmacyLoginContext";
import { LabUserLoginProvider } from "./contexts/LabUserLoginContext";

import { HospBdoLoginProvider } from "./contexts/HospBdoLoginContext";
import { BdoLoginProvider } from "./contexts/BdoLoginContext";
import { TargetProvider } from "./contexts/TargetContext";
import { SubscribersProvider } from "./contexts/SubscribersContext";
import { ObservationsProvider } from "./contexts/ObservationsContext";
//import { HospitalContext } from "./contexts/HospitalContext";
import TestReportList from "./components/BACKOFFICEADMIN/TestReportList/TestReportList";
import PrescList from "./components/BACKOFFICEADMIN/PrescriptionList/PrescriptionList";
import DisSummaryList from "./components/DischargeSummaryList/DischargeSummaryList";
import ScanReport from "./components/ScanReportList/ScanReportList";
import MiscellaneousList from "./components/Miscellaneous/MiscellaneousList";

import StaffDashboard from "./components/Dashboard/StaffDashboard";
import OtherDashboard from "./components/Dashboard/OtherDashboard";
import FailedLabReportList from "./components/BACKOFFICEADMIN/FailedLabReportList/FailedLabReportList";
import AddSynonyms from "./components/BACKOFFICEADMIN/AddSynonyms/AddSynonyms";
import Report from "./components/BACKOFFICEADMIN/FailedReport/FailedReport";

import DisplayReportAndHeadings from "./components/BACKOFFICEADMIN/DisplayReportAndHeadings/DisplayReportAndHeadings";
import TestHeadingsList from "./components/BACKOFFICEADMIN/TestHeadingsList/TestHeadingsList";
import NewCall from "./components/BACKOFFICEADMIN/DisplayDetails/DisplayDetails";
import DisplayPrescription from "./components/BACKOFFICEADMIN/DisplayPrescription/DisplayPrescription";
import TestName from "./components/BACKOFFICEADMIN/TestName/TestName";
import DoctorsList from "./components/DoctorsList/DoctorsList";
import BdoList from "./components/BdoList/BdoList";
import AddDoctor from "./components/AddDoctor/AddDoctor";
import AddPharmacyUser from "./components/PharmacyUser/AddPharmacyUser";
import AddLabUser from "./components/LaboratoryUser/AddLabUser";
import FailedReportList from "./components/BACKOFFICEADMIN/FailedReportList/FailedReportList";
import Hospital from "./components/Hospital/Hospital";
import PharmacyDashboard from "./components/PharmacyUser/PharmacyDashboard";
import LabUserDashboard from "./components/LaboratoryUser/LabUserDashboard";


import HospitalPending from "./components/Hospital/HospitalPending";
import CreateAppUser from "./components/CreateAppUser/CreateAppUser";
import CreatePharmacyUser from "./components/PharmacyUser/CreatePharmacyUser";
import CreateLabUser from "./components/LaboratoryUser/CreateLabUser";
import SuspendHospitalDoctor from "./components/SuspendHospitalDoctor/SuspendHospitalDoctor";
import HospitalDoctorRevokeSuspension from "./components/HospitalDoctorRevokeSuspension/HospitalDoctorRevokeSuspension";
import MedicineApprove from "./components/BACKOFFICEADMIN/MedicineApproval/MedicineApprove";
import MissingHeaderList from "./components/BACKOFFICEADMIN/MissingHeaderList/MissingHeaderList";
import MissingHeaderReport from "./components/BACKOFFICEADMIN/MissingHeaderReport/MissingHeaderReport";
import ChangePasswordHospitalDoctor from "./components/ChangePasswordHospitalDoctor/ChangePasswordHospitalDoctor";

import DoctorRegister from "./components/Register/DoctorRegister";
import DoctorDetails from "./components/Register/DoctorDetails";
import PatientRegister from "./components/Register/PatientRegister";
import HospitalRegister from "./components/Register/HospitalRegister";
import HospRegister from "./components/Register/HospRegister";

import WrittenPrescList from "./components/BACKOFFICEADMIN/WrittenPrescList/WrittenPrescList";
import Reg1 from "./components/Register/Reg1";
import DisplayWrittenPresc from "./components/BACKOFFICEADMIN/Display_WrittenPresc/Display_WrittenPresc";

import HospitalDoctor from "./components/HospitalDoctor/HospitalDoctor";
import PatientsList from "./components/HospitalPatientsList/PatientsList";
//import SearchPatient from "./components/HospSearchPatient/SearchPatient";
import UserListDr from "./components/HospSearchPatient/UserListingDr";
import PatientMessages from "./components/Messages/PatientMessages";
import PatientAppointments from "./components/Appointment/Appointent";
import PatientAppointmentData from "./components/AppointmentPatientData/AppointmentPatientData";
import Equipment from "./components/EquipmentDetailsHospital/Equipment";
import PatientPresc from "./components/Hosp_PatientPresc/Hosp_PatientPresc";

//import HospPatientRecord from "./components/HospPatientRecord/HospPatientRecord";
import PatientRecord from "./components/HospPatientRecord/PatientRecord";
import AddPrescription from "./components/HospDr_AddPrescription/AddPrescription";

import UpdatedSoon from "./components/UpdatedSoon/UpdatedSoon";
import FoodHabits from "./components/FoodHabits/FoodHabits";
import LabReports from "./components/HospPatientLabReports/HospPatientLabReports";
import LabReportsList from "./components/HospPatientLabReports/LabReportsList";
import PatientPrescList from "./components/HospPatientPrescList/HospPatientPrescList";
import PatientPrescription from "./components/HospPatientPresc/HospPatientPresc";
import PatientLabReport from "./components/HospPatientLabReport/HospPatientLabReport";
import HospDrSchedules from "./components/HospDrSchedules/HospDrSchedules";
//import {LoginStatusContext} from "./contexts/LoginStatusContext";
import { MedProvider } from "./contexts/MedContext";

import PatientInfo from "./components/Register/PatientInfo";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";

import InputOtp2 from "./components/ForgotPassword/InputOtp2";
import ResetPassword from "./components/ForgotPassword/ResetPassword";
import AddBdo from "./components/AddBdo/AddBdo";
import HospitalBdo from "./components/HospitalBdo/HospitalBdo";

import Analytics from "./components/Analytics/Analytics";
import AnalyticsDr from "./components/Analytics/Analytics_Dr";
import AnalyticsBdo from "./components/Analytics/Analytics_Bdo";
import CreateAppUserBdo from "./components/CreateAppUserBdo/CreateAppUserBdo";
import SuspendHospitalBdo from "./components/SuspendHospitalBdo/SuspendHospitalBdo";
import HospitalBdoRevokeSuspension from "./components/HospitalBdo_RevokeSuspension/HospitalBdo_RevokeSuspension";
import HospitalUpdateBdo from "./components/HospitalUpdate_Bdo/HospitalUpdateBdo";
import ChangePasswordHospitalBdo from "./components/ChangePasswordHospitalBdo/ChangePasswordHospitalBdo";
import PatientRecordBdo from "./components/HospPatientRecord_Bdo/PatientRecord";

import CarePrograms from "./components/CarePrograms/CarePrograms";
import CareProgramDetails from "./components/CareProgram_Details/CareProgram_Details";
import CareProgramEdit from "./components/CareProgramEdit/CareProgramEdit";

import AddCareProgram from "./components/CareProgram_Add/CareProgram_Add";

import UserList from "./components/UserListing/UserListing";

import WebNotifications from "./components/WebNotifications/WebNotifications";
import WebNotificationsBdo from "./components/WebNotifications/WebNotifications_Bdo";
import WebNotificationsDr from "./components/WebNotifications/WebNotifications_Dr";
import FrequentMedsTests from "./components/FrequentMedsTests/FrequentMedsTests";
import TestsDetails from "./components/FrequentTestsDetails/FrequentTestsDetails";
import EmailConfirmation from "./components/EmailConfirmation/EmailConfirmation";
import ComparisonReport from "./components/ComparisonReport/ComparisonReport";
import QRCodePrint from "./components/QRCode/QRCode";
import Profile from "./components/Profile/Profile";
import HospProfile from "./components/HospProfile/HospProfile";
import { ProfileProvider } from "./contexts/ProfileContext";

import EmergencyNumber from "./components/EmergencyNumber/EmergencyNumber";

import GeneralSearch from "./components/GeneralSearch/GeneralSearch";
import { SearchProvider } from "./contexts/GeneralSearchContext";
import { RazorpayProvider } from "./contexts/RazorpayContext";
import RegisteredHospitals from "./components/BACKOFFICEADMIN/RegisteredHospitals/RegisteredHospitals";
import HospitalDetails from "./components/BACKOFFICEADMIN/RegisteredHospitals/HospitalDetails";
import RegisteredDoctors from "./components/BACKOFFICEADMIN/RegisteredDoctors/RegisteredDoctors";
import DoctorProfile from "./components/BACKOFFICEADMIN/RegisteredDoctors/DoctorProfile";
import SearchHospital from "./components/BACKOFFICEADMIN/AdminSearchHosp/SearchHospital";
import RejectedUsers from "./components/BACKOFFICEADMIN/RejectedUserList/RejectedUsers";
import OperationsDB from "./components/BDO_2/OperationsDB/OperationsDb";
import SubscribersListPage from "./components/BDO_2/SubscribersList/SubscribersListPage";
import Subscription from "./components/BDO_2/Subscription/Subscription";
import ConnectedPatientsPage from "./components/BDO_2/ConnectedPatients/ConnectedPatientsPage";

import SuspendedList from "./components/BDO_2/SuspendedSubscriberList/SuspendedList";

import ServiceRequests from "./components/ServiceRequests/ServiceRequests";
import ServiceRequests_doc from "./components/ServiceRequests/ServiceRequests_doc";
import ServiceRequests_hosp from "./components/ServiceRequests/ServiceRequests_hosp";
import ServiceHistoryPage from "./components/ServiceRequests/ServiceHistoryPage";

import RentalRequests from "./components/RentalRequests/RentalRequests";
import RentalRequests_doc from "./components/RentalRequests/RentalRequests_doc";
import RentalRequests_hosp from "./components/RentalRequests/RentalRequests_hosp";
import RentalHistoryPage from "./components/RentalRequests/RentalHistoryPage";

import EmergencyCallLog from "./components/EmergencyNumber/EmergencyCallLog";
import HospEmergencyCallLog from "./components/EmergencyNumber/HospEmergencyCallLog";
import LicencePayment from "./components/LicencePayment/LicencePayment";

import LicenseDetails from "./components/DoctorPayment/LicenseDetails";
import LicenseTable from "./components/DoctorPayment/LicenseTable";

import PatientDetail from "./components/BACKOFFICEADMIN/AdminSearchHosp/PatientDetail";
import RemotePayments from "./components/RemotePayments/RemotePayments";

import HospitalBdoBanner from "./components/LoginBanner/LoginBannerBdo";
import HospitalDrBanner from "./components/LoginBanner/LoginBannerDr";
import ScheduleList from "./components/Schedule/ScheduleList";

import Inference from "./components/Inference/Inference";
import HospitalLicenseView from "./components/BACKOFFICEADMIN/RegisteredHospitals/HospitalLicenseView";
import AddPrescriptionForm from "./components/HospDr_AddPrescription/AddPrescriptionForm";
import RentalOrderDetails from "./components/RentalRequests/RentalOrderDetails";
import RentalHistoryDetails from "./components/RentalRequests/RentalHistoryDetails";
import FeaturesList from "./components/BACKOFFICEADMIN/FeaturesList/FeaturesList";
import ProcedureList from "./components/BACKOFFICEADMIN/ProcedureList/ProcedureList";
import SdaList from "./components/SdaList/SdaList";
import CreateAppUserSda from "./components/CreateAppUserSda/CreateAppUserSda";
import SuspendHospitalSda from "./components/SuspendHospitalSda/SuspendHospitalSda";
import HospitalSdaRevokeSuspension from "./components/HospitalSda_RevokeSuspension/HospitalSda_RevokeSuspension";
import ChangePasswordHospitalSda from "./components/ChangePasswordHospitalSda/ChangePasswordHospitalSda";
import HospitalUpdateSda from "./components/HospitalUpdate_Sda/HospitalUpdateSda";
import AddSda from "./components/AddSda/AddSda";
//import Payments from "./components/Payments/Payments";
import ServiceCenter from "./components/Service Center/ServiceCenter";
import { BookingProvider } from "./contexts/BookingContext";
import ObservationSymptomsBO from "./components/BACKOFFICEADMIN/AddObservationsSymptoms/ObservationSympotmsBO";
import PrimaryDicease from "./components/BACKOFFICEADMIN/AddPrimaryDiscease/PrimaryDiscease";
import Comorbidities from "./components/BACKOFFICEADMIN/AddComobridities/Comorbidites";
import Allergy from "./components/BACKOFFICEADMIN/AddAllergy/Allergy";
import Medicine from "./components/BACKOFFICEADMIN/AddMedicines/Medicine";
import Target from "./components/BACKOFFICEADMIN/AddTargets/Target";
import AgeGroup from "./components/BACKOFFICEADMIN/AddAgeGroup/AgeGroup";
import Test from "./components/BACKOFFICEADMIN/AddLabTest/Test";
import Scan from "./components/BACKOFFICEADMIN/AddScanTest/Scan";
import TargetAnalysis from "./components/TargetAnalysis/TargetAnalysis";
import PharmacyUserList from "./components/PharmacyUser/PharmacyUserList";
import DeleteDoctor from "./components/Doctor/DeleteDoctor";
import DeleteBdo from "./components/BdoCard/DeleteBdo";
import ChangePasswordHospitalPharm from "./components/PharmacyUser/ChangePasswordHospitalPharm";
import ChangePasswordHospitalLab from "./components/LaboratoryUser/ChangePasswordHospitalLab"
import LabUserList from "./components/LaboratoryUser/LabUserList";
import DeleteSda from "./components/Sda/DeleteSda";
import UpdateLabUser from "./components/LaboratoryUser/UpdateLabUser";
import UpdatePharmacyUser from "./components/PharmacyUser/UpdatePharmacyUser";
import DeletePharmacyUser from "./components/PharmacyUser/DeletePharmacyUser";
import DeleteLabUser from "./components/LaboratoryUser/DeleteLabUser";

function App() {





  const PrivateRouteStaff = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        sessionStorage.getItem("usertoken") &&
          sessionStorage.getItem("usertype") === "staff" ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
  const PrivateRoutePharmacy = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        sessionStorage.getItem("usertoken") &&
          sessionStorage.getItem("usertype") === "pharmacy_user" &&
          sessionStorage.getItem("role") === "pharmacyuser" ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
  const PrivateRouteLabUser = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        sessionStorage.getItem("usertoken") &&
          sessionStorage.getItem("usertype") === "lab_user" &&
          sessionStorage.getItem("role") === "labuser" ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );

  const PrivateRouteHospital = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        sessionStorage.getItem("usertoken") &&
          sessionStorage.getItem("usertype") === "hospital" ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
  const PrivateRouteBdo = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        sessionStorage.getItem("usertoken") &&
          sessionStorage.getItem("usertype") === "hospital" &&
          sessionStorage.getItem("role") === "bdo" ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );

  const PrivateRouteHospDoc = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        sessionStorage.getItem("usertoken") &&
          sessionStorage.getItem("usertype") === "hospital_doctor" ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
  return (
    <BrowserRouter>
      <RegProvider>
        <LoginStatusProvider>
          <HospLoginProvider>
            <HospDrLoginProvider>
              <PharmacyLoginProvider>
                <LabUserLoginProvider>
                  <HospBdoLoginProvider>
                    <BdoLoginProvider>
                      <ProfileProvider>
                        <SearchProvider>
                          <LoginProvider>

                            <ReportProvider>
                              <TargetProvider>
                                <SubscribersProvider>
                                  <MedProvider>
                                    <ObservationsProvider>
                                      <RazorpayProvider>
                                        <BookingProvider>
                                          <div className="App">
                                            <div> <Navbar /></div>
                                            <div className="content">
                                              <Switch>
                                                <Route exact path="/" component={Login} />

                                                <Route exact path="/login" component={Login} />
                                                <Route exact path="/registerdoctor" component={DoctorRegister} />
                                                <Route exact path="/registerpatient" component={PatientRegister} />
                                                <Route exact path="/registerhospital" component={HospitalRegister} />
                                                <Route exact path="/success" component={SuccessReg} />
                                                <Route exact path="/error" component={ErrorReg} />


                                                <PrivateRouteStaff exact path="/reportlist" component={TestReportList} />
                                                <PrivateRouteStaff exact path="/hospitallist" component={RegisteredHospitals} />
                                                <PrivateRouteStaff exact path="/medlist" component={MedicineApprove} />
                                                <PrivateRouteStaff exact path="/doctorslist" component={RegisteredDoctors} />
                                                <PrivateRouteStaff exact path="/search-hospitals" component={SearchHospital} />
                                                <PrivateRouteStaff exact path="/patient-details/:id" component={PatientDetail} />
                                                <PrivateRouteStaff exact path="/rejectedlist" component={RejectedUsers} />
                                                <PrivateRouteStaff exact path="/prescriptionlist" component={PrescList} />

                                                <PrivateRouteStaff exact path="/dashboard" component={StaffDashboard} />
                                                <Route exact path="/loggedin" component={OtherDashboard} />
                                                <PrivateRouteStaff exact path="/report/:id" component={NewCall} />
                                                <PrivateRouteStaff exact path="/presc/:id" component={DisplayPrescription} />
                                                <PrivateRouteStaff exact path="/written-presc/:id" component={DisplayWrittenPresc} />
                                                <PrivateRouteStaff exact path="/test/:id" component={TestName} />
                                                <PrivateRouteStaff exact path="/features-list" component={FeaturesList} />
                                                <PrivateRouteStaff exact path="/procedure-list" component={ProcedureList} />
                                                <PrivateRouteStaff exact path="/obser-symptomBO" component={ObservationSymptomsBO} />
                                                <PrivateRouteStaff exact path="/primary-disease" component={PrimaryDicease} />
                                                <PrivateRouteStaff exact path="/comorbidities" component={Comorbidities} />
                                                <PrivateRouteStaff exact path="/allergy" component={Allergy} />
                                                <PrivateRouteStaff exact path="/medicinelist" component={Medicine} />\
                                                <PrivateRouteStaff exact path="/targetlist" component={Target} />
                                                <PrivateRouteStaff exact path="/agegroup-list" component={AgeGroup} />
                                                <PrivateRouteStaff exact path="/labtestBO" component={Test} />
                                                <PrivateRouteStaff exact path="/scantestBO" component={Scan} />

                                                <PrivateRouteStaff
                                                  exact
                                                  path="/failedreports"
                                                  component={FailedLabReportList}
                                                />
                                                <PrivateRouteStaff
                                                  exact
                                                  path="/report-headings/:id"
                                                  component={DisplayReportAndHeadings}
                                                />
                                                <PrivateRouteStaff
                                                  exact
                                                  path="/hospital-details/:id"
                                                  component={HospitalDetails}
                                                />
                                                <PrivateRouteStaff
                                                  exact
                                                  path="/hospital-license-view/:id"
                                                  component={HospitalLicenseView}
                                                />
                                                <PrivateRouteStaff
                                                  exact
                                                  path="/doctor-details/:id"
                                                  component={DoctorProfile}
                                                />

                                                <PrivateRouteStaff exact path="/testlist" component={TestHeadingsList} />
                                                <PrivateRouteStaff exact path="/written-prescriptionlist" component={WrittenPrescList} />

                                                <PrivateRoutePharmacy exact path="/pharmacy" component={PharmacyDashboard} />
                                                <PrivateRouteLabUser exact path="/labuser" component={LabUserDashboard} />
                                                <PrivateRouteHospital exact path="/changepassword-lab/:id" component={ChangePasswordHospitalLab} />
                                               
                                                <PrivateRouteHospital exact path="/changepassword-pharm/:id" component={ChangePasswordHospitalPharm} />
                                                <PrivateRouteHospital exact path="/doctors-list" component={DoctorsList} />
                                                <PrivateRouteHospital exact path="/pharmacyusers-list" component={PharmacyUserList} />
                                                <PrivateRouteHospital exact path="/labusers-list" component={LabUserList} />
                                                <PrivateRouteHospital exact path="/sda-list" component={SdaList} />
                                                <PrivateRouteHospital exact path="/hospital/add" component={AddDoctor} />
                                                <PrivateRouteHospital exact path="/hospital/add-Pharm-user" component={AddPharmacyUser} />
                                                <PrivateRouteHospital exact path="/hospital/add-lab-user" component={AddLabUser} />
                                                <PrivateRouteHospital exact path="/hospital" component={Hospital} />
                                                <PrivateRouteHospital exact path="/hospital/profile" component={HospProfile} />
                                                <PrivateRouteHospital exact path="/verifyhospital" component={HospitalPending} />
                                                <PrivateRouteHospital exact path="/create/:id" component={CreateAppUser} />
                                                <PrivateRouteHospital exact path="/create-pharm-user/:id" component={CreatePharmacyUser} />
                                                <PrivateRouteHospital exact path="/create-lab-user/:id" component={CreateLabUser} />
                                                <PrivateRouteHospital exact path="/license-details" component={LicenseDetails} />
                                                <PrivateRouteHospital exact path="/doctor-license" component={LicenseTable} />
                                                <PrivateRouteHospital exact path="/bdo-license" component={LicenseTable} />
                                                <PrivateRouteHospital exact path="/suspend/:id" component={SuspendHospitalDoctor} />
                                                <PrivateRouteHospital exact path="/delete/:id" component={DeleteDoctor} />
                                                <PrivateRouteHospital exact path="/deleteBdo/:id" component={DeleteBdo} />
                                                <PrivateRouteHospital exact path="/deleteSda/:id" component={DeleteSda} />


                                                <PrivateRouteHospital exact path="/deletePharmacyUser/:id" component={DeletePharmacyUser} />
                                                <PrivateRouteHospital exact path="/deleteLabUser/:id" component={DeleteLabUser} />

                                                <PrivateRouteHospital exact path="/revoke/:id" component={HospitalDoctorRevokeSuspension} />
                                                <PrivateRouteHospital exact path="/changepassword/:id" component={ChangePasswordHospitalDoctor} />
                                                <PrivateRouteHospital exact path="/equipment-details" component={Equipment} />


                                                <Route exact path="/register" component={Reg1} />
                                                <Route exact path="/in-progress" component={UpdatedSoon} />
                                                {/* <Route exact path="/logout" component={Logout} /> */}

                                                <Route exact path="/home" component={Home} />
                                                <PrivateRouteHospital exact path="/update/:id" component={HospitalUpdateDoctor} />
                                                <PrivateRouteHospital exact path="/updateLabUser/:id" component={UpdateLabUser} />
                                                <PrivateRouteHospital exact path="/updatePharmacyUser/:id" component={UpdatePharmacyUser} />

                                                {/*<PrivateRoute path='/dashboard' component={StaffDashboard} /> */}

                                                <PrivateRouteHospDoc exact path="/doctor" component={HospitalDoctor} />
                                                <PrivateRouteHospDoc exact path="/schedules" component={HospDrSchedules} />
                                                <Route exact path="/patientslist" component={PatientsList} />
                                                <PrivateRouteHospDoc exact path="/doctor/profile" component={Profile} />
                                                <PrivateRouteHospDoc exact path="/patient-general-search/:id" component={GeneralSearch} />
                                                <PrivateRouteHospDoc exact path="/patients-directory" component={UserListDr} />
                                                <PrivateRouteHospDoc path="/doctor/notifications" component={WebNotificationsDr} />
                                                <PrivateRouteHospDoc path="/doctor/analytics" component={AnalyticsDr} />

                                                <Route exact path="/patient-presc" component={PatientPresc} />


                                                <PrivateRouteHospDoc exact path="/add-prescription" component={AddPrescription} />
                                                <PrivateRouteHospDoc exact path="/prescription-form" component={AddPrescriptionForm} />
                                                <PrivateRouteHospDoc exact path="/food-habits" component={FoodHabits} />
                                                <PrivateRouteStaff path="/nutrition" component={Nutrition} />
                                                <PrivateRouteStaff
                                                  path="/addsynonyms"
                                                  component={AddSynonyms} />
                                                <PrivateRouteHospDoc exact path="/message" component={PatientMessages} />
                                                <PrivateRouteHospDoc exact path="/appointment" component={PatientAppointments} />
                                                <PrivateRouteHospDoc exact path="/schedule-list" component={ScheduleList} />
                                                <PrivateRouteStaff
                                                  path="/failedlist"
                                                  component={FailedReportList}
                                                />
                                                <PrivateRouteStaff
                                                  path="/noheader-list"
                                                  component={MissingHeaderList}
                                                />
                                                <PrivateRouteStaff path="/no-header/:id" component={MissingHeaderReport} />
                                                <PrivateRouteStaff path="/report-failed/:id" component={Report} />


                                                <PrivateRouteHospDoc exact path="/patient-record/:id" component={PatientRecord} />
                                                <Route path="/patient-discharge-summary/:id" component={DisSummaryList} />
                                                <Route path="/patient-scan-report/:id" component={ScanReport} />
                                                <Route path="/miscellaneous/:id" component={MiscellaneousList} />
                                                <Route path="/appointment/:date" component={PatientAppointmentData} />

                                                <Route path="/patient-labreports/:id" component={LabReports} />
                                                <Route path="/patient-prescriptions/:id" component={PatientPrescList} />
                                                <Route path="/patient-prescription" component={PatientPrescription} />
                                                <Route path="/patient-lab-report" component={PatientLabReport} />
                                                <Route path="/info" component={PatientInfo} />
                                                <Route path="/details" component={DoctorDetails} />

                                                <Route path="/success-reg" component={SuccessPatient} />
                                                <Route path="/hospital-register" component={HospRegister} />
                                                <Route path="/forgot-password" component={ForgotPassword} />

                                                <Route path="/input-otp" component={InputOtp2} />
                                                <Route path="/reset-password" component={ResetPassword} />

                                                <PrivateRouteHospital exact path="/hospital/add-bdo" component={AddBdo} />
                                                <PrivateRouteBdo path="/bdo" component={HospitalBdo} />

                                                <PrivateRouteHospital exact path="/create-bdo/:id" component={CreateAppUserBdo} />
                                                <PrivateRouteHospital exact path="/suspend-bdo/:id" component={SuspendHospitalBdo} />
                                                <PrivateRouteHospital exact path="/revoke-bdo/:id" component={HospitalBdoRevokeSuspension} />
                                                <PrivateRouteHospital exact path="/changepassword-bdo/:id" component={ChangePasswordHospitalBdo} />
                                                <PrivateRouteHospital exact path="/update-bdo/:id" component={HospitalUpdateBdo} />

                                                <PrivateRouteHospital exact path="/hospital/add-sda" component={AddSda} />
                                                <PrivateRouteHospital exact path="/create-sda/:id" component={CreateAppUserSda} />
                                                <PrivateRouteHospital exact path="/suspend-sda/:id" component={SuspendHospitalSda} />
                                                <PrivateRouteHospital exact path="/revoke-sda/:id" component={HospitalSdaRevokeSuspension} />
                                                <PrivateRouteHospital exact path="/changepassword-sda/:id" component={ChangePasswordHospitalSda} />
                                                <PrivateRouteHospital exact path="/update-sda/:id" component={HospitalUpdateSda} />

                                                <PrivateRouteHospital exact path="/bdo-list" component={BdoList} />


                                                <PrivateRouteBdo path="/analytics" component={AnalyticsBdo} />
                                                <Route path="/analytics" component={Analytics} />

                                                <PrivateRouteBdo path="/record/:id" component={PatientRecordBdo} />

                                                <PrivateRouteBdo path="/care-programs" component={CarePrograms} />
                                                <PrivateRouteBdo exact path="/care-program/:id" component={CareProgramDetails} />
                                                <PrivateRouteBdo exact path="/care-program/edit/:id" component={CareProgramEdit} />

                                                <PrivateRouteBdo exact path="/care-program-add" component={AddCareProgram} />
                                                <PrivateRouteBdo exact path="/careplans-db" component={OperationsDB} />
                                                <PrivateRouteBdo exact path="/subscriberslist" component={SubscribersListPage} />
                                                <PrivateRouteBdo exact path="/suspendedlist" component={SuspendedList} />
                                                <PrivateRouteBdo exact path="/connected-list" component={ConnectedPatientsPage} />
                                                {/* <PrivateRouteBdo path="/payments" component={Payments} /> */}
                                                <PrivateRouteBdo path="/customer-service" component={ServiceCenter} />

                                                <Route path="/user/analytics" component={Analytics} />
                                                <PrivateRouteBdo path="/record/:id" component={PatientRecordBdo} />
                                                <PrivateRouteBdo path="/directory" component={UserList} />
                                                <PrivateRouteBdo path="/call-log" component={EmergencyCallLog} />

                                                <PrivateRouteBdo path="/notifications" component={WebNotificationsBdo} />
                                                <PrivateRouteBdo path="/subscriber/:id" component={Subscription} />
                                                <Route path="/notifications" component={WebNotifications} />
                                                <Route path="/patient-record/analysis/:id" component={FrequentMedsTests} />
                                                <Route path="/test-detail" component={TestsDetails} />
                                                <Route path='/confirm-email/' component={EmailConfirmation} />
                                                <Route path='/comparison-report' component={ComparisonReport} />
                                                <Route path="/lab-reports/:id" component={LabReportsList} />
                                                <Route path="/qrcode" component={QRCodePrint} />
                                                <Route path="/emergency-number" component={EmergencyNumber} />
                                                <Route path="/hosp-calllog" component={HospEmergencyCallLog} />

                                                <Route path="/payment-history" component={RemotePayments} />
                                                <Route path="/service-requests" component={ServiceRequests} />
                                                <Route path="/service-requestlist" component={ServiceRequests_doc} />
                                                <Route path="/service-request-list" component={ServiceRequests_hosp} />
                                                <Route path="/service-history" component={ServiceHistoryPage} />
                                                <Route path="/rental-history" component={RentalHistoryPage} />
                                                <Route path="/rental-requests" component={RentalRequests} />
                                                <Route path="/rental-details" component={RentalOrderDetails} />
                                                <Route path="/rental-history-details" component={RentalHistoryDetails} />
                                                <Route path="/rental-requestlist" component={RentalRequests_doc} />
                                                <Route path="/rental-request-list" component={RentalRequests_hosp} />
                                                <Route path="/rental-history" component={RentalHistoryPage} />
                                                <Route path="/procedure-requests" component={ProcedureRequests} />
                                                <Route path="/procedure-names-bdo" component={ProcedureNamesBdo} />

                                                <PrivateRouteBdo path="/procedure-requests-bdo" component={ProcedureRequestsBdo} />
                                                <Route path="/licence-payment" component={LicencePayment} />
                                                <Route path="/payment-history" component={RemotePayments} />
                                                <Route path="/license-payment" component={LicencePayment} />
                                                <Route path="/bdo-invalid-license" component={HospitalBdoBanner} />
                                                <Route path="/dr-invalid-license" component={HospitalDrBanner} />
                                                <Route path="/inference-impact-analysis" component={Inference} />
                                                <Route path="/" component={Default404} />


                                              </Switch>

                                            </div>
                                            <Footer />
                                          </div>
                                        </BookingProvider>
                                      </RazorpayProvider>
                                    </ObservationsProvider>
                                  </MedProvider>
                                </SubscribersProvider>
                              </TargetProvider>
                            </ReportProvider>

                          </LoginProvider>
                        </SearchProvider>
                      </ProfileProvider>
                    </BdoLoginProvider>
                  </HospBdoLoginProvider>
                </LabUserLoginProvider>
              </PharmacyLoginProvider>
            </HospDrLoginProvider>
          </HospLoginProvider>
        </LoginStatusProvider>
      </RegProvider>
    </BrowserRouter>
  );
}

export default App;
