import React, {useState, useEffect} from "react";

import "./labreports.css";
import { Link } from "react-router-dom";
import axios from "axios";
import HospDocNavbar from "../HospDocNavbar/HospDocNavbar";
import PatientLabReport from "../HospPatientLabReport/HospPatientLabReport";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import Pagination from "react-js-pagination";
import "./pagination.css";
import { Dropdown } from 'react-bootstrap';
import {APIURL} from "../../Global";

function LabReports(props){
	const [reportList,setReportList] = useState([]);
	 const [patientId,] =useState(props.match.params.id);
   const [reportShow,setReportShow]=useState(false);
   const [reportId,setReportId] = useState();
  const [activePage,setActivePage] = useState(1);
      const [order,setOrder]=useState("desc")
     // const [dropDownOpen,setDropDownOpen]=useState(false);
      const [refresh,setRefresh]= useState(false);
      const [totalPages,setTotalPages]=useState(1);


useEffect(()=>{

  const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  /* REGEX To remove double quotes from tokenstring */
          
    
     
  
    axios.get(`${APIURL}/api/v1/patient/lab-reports/?src=website&patient_id=${patientId}&sort_by=${order}&per_page=10&page=${activePage}/`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
    

  
   
      if(res.data.status==="success"){
     
     setReportList(res.data.results);
     setTotalPages(res.data.total_pages);
      
/*
         history.push({
  pathname: `/patient-record/${searchTerm}`,
 
  state: res.data 
})*/
      }else{
       
      }


    })
    .catch(err=>{
 
})
 

 },[activePage,order,refresh]);

const handleReportOverlay=(e,reportId)=>{
 e.preventDefault();
 setReportShow(true);
 setReportId(reportId);


}
//const toggle = () => setDropDownOpen(!dropDownOpen);
    const handlePageChange=(pageNumber)=> {
   
    setActivePage(pageNumber);
    setRefresh(!refresh);
    }

    const onSort = async order=>{
  await setOrder(order);
  
  await setRefresh(!refresh);
}
const ReportPopup=(props)=> {
 
 
  return (
    <Modal
      {...props}
     
       aria-labelledby="example-custom-modal-styling-title"
       backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
      
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        {/* <h4 className="title-of-tasks"> Lab Report {props.reportid}  </h4> */}
         <h5 className="text-muted"> Patient ID {props.patientid}</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PatientLabReport {...props} />
       
     </Modal.Body>
     <Modal.Footer>
       
        
       <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>
       
       
      </Modal.Footer>
    </Modal>
  );
}
const display = reportList.map((report,index)=>{
            
                  return (<>
                       <div key={index} className="thumbnail-div col-lg-5">
                       {/*
                         <Link to={url} {...props}>  <div className="list-item"> */}

                        
                        <div className="list-item">
                       {/* <Link to={{
                    pathname: `/patient-lab-report`,
                    patientId: patientId,
                    reportId: report.id
                            }} >  */}
          <span className="history-link" onClick={(e)=>{handleReportOverlay(e,report.id)}} >
                            <div className="row-table-title">
          <h5 className="each-list-link">
            {index + 1}. Report {report.id}  
           </h5>
             <p> Date: {report.date} </p>
         
         
             </div>
             

              <table className="thumbnail-table ">
                      <tr className=" table-header-small">
                        <th> Test</th>
                        <th> Result</th>
                        <th> Units</th>
                        <th> Reference Range</th>
                        
                        
                      </tr>
                      
                    {report.tests? report.tests.map(item=>{
                     return  <tr className=" table-row-small">
                               <td> {item.test} </td>
                               <td> {item.result} </td>
                               <td> {item.units} </td>
                               <td> {item.reference_range} </td>
                              

                              
                            </tr>
                      }) 
                      : null}
                   </table>
                  {/* </Link> */}
                  </span>
                   </div> 
                       </div>

                       {reportShow && reportId===report.id? 
                       <ReportPopup 
        show={reportShow}
        onHide={() =>  setReportShow(false)}
        reportid ={reportId}
        patientid= {patientId}
         />

: null }
                       </>
                    )

                });
	return(<>
    <HospDocNavbar/>
		<div className="container"><br/>
      <div className="text-left">
    <Link to={`/patient-record/${patientId}`}><span className="btn btn-info" >  <i className="fas fa-angle-double-left"></i> Go to Patient Record</span></Link>
    </div>

    <h2 className="title-of-tasks"> Lab Reports </h2>
     <h5 className="title-of-tasks text-center"> Patient ID {patientId}</h5>
     <div className="buttons-wrapper-presc container">
       
          <button className="btn btn-secondary" onClick={()=>{setRefresh(!refresh)}}>
            Refresh List <i className="fas fa-sync-alt"></i>{" "}
          </button>

<Dropdown>
  <Dropdown.Toggle variant="secondary" id="dropdown-basic">
    Sort 
  </Dropdown.Toggle>

  <Dropdown.Menu>
    <Dropdown.Item onClick={()=>{onSort("asc")}}>Ascending</Dropdown.Item>
    <Dropdown.Item  onClick={()=>{onSort("desc")}}>Descending</Dropdown.Item>
  
  </Dropdown.Menu>
</Dropdown>

</div>
      
	<div className="tiles-holder">
		{reportList.length>0 ? display : <h6 style={{color: "red"}}> Sorry, No Lab Reports to show! </h6> }
  </div>

  <Pagination
          activePage={activePage}
          itemsCountPerPage={10}
          totalItemsCount={totalPages*10}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          disabledClass = "disabled-class"
        />
		</div>
    </>)
}


export default LabReports;