import React, { useState, createContext } from "react";

export const LabUserLoginContext = createContext();

export const LabUserLoginProvider = (props) => {
  const [labData,setLabData] = useState({});
   
  return (
    <LabUserLoginContext.Provider value={[labData,setLabData]}>
      {props.children}
    </LabUserLoginContext.Provider>
  );
};
