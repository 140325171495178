import React ,{useState ,useEffect} from 'react'
import {Button ,Modal } from 'react-bootstrap';
import axios from "axios";
import {APIURL} from "../../Global";

import '../../css/bootstrap.min.css';
import '../../css/iofrm-style.css';
import '../../css/iofrm-theme4.css';
import "./popup.css";
import "./country_region_selector.css";
import "./dob.css";
import './hospprofile.css'
import LocationUpdate from './LocationUpdate';
import PractiseUpdate from './PractiseUpdate';
import SpecUpdate from './SpecUpdate';
import FacilityUpdate from './FacilityUpdate';
import DescriptionUpdate from './DescriptionUpdate';
import WebsiteUpdate from './WebsiteUpdate';
import MobileUpdate from './MobileUpdate';
import NameUpdate from './NameUpdate';


function EditProfile(props) {

    const [img, setImg] =useState('');
    const [name,setName] = useState('');
    const [location, setLocation]=useState('');
    //const [email, setEmail]=useState('');
    const [phone, setPhone]=useState('');
    const [website, setWebsite]=useState('');
    const [practise, setPractise]=useState('');
    const [spec,setSpec] = useState([]);
    const [facilities ,setFacilities] =useState([]);
    const [desc, setDesc]= useState('');
    const [descEdit, setDescEdit]= useState(false)
    const [show, setShow]= useState(false);
    const [imgURL ,setImgURL]= useState("");
    const [previewOn , setPreviewOn]= useState(false);
    const [submitMsg,setSubmitMsg] = useState("");
    const [errorMsg,setErrorMsg]=useState("");
    const [errorSubmit,setErrorSubmit]=useState(false);
    const [locationEdit, setLocationEdit]= useState(false)
    const [practiseEdit, setPractiseEdit]= useState(false)
    const [specEdit, setSpecEdit]= useState(false)
    const [nameEdit, setNameEdit]= useState(false)
    //const [emailEdit, setEmailEdit]= useState(false)
    const [phoneEdit, setPhoneEdit]= useState(false)
    const [websiteEdit, setWebsiteEdit]= useState(false)
    const [facilityEdit, setFacilityEdit]= useState(false)
    const [sizeShow ,setSizeShow] =useState(false)
const [validShow ,setValidShow] =useState(false)
const [contentShow ,setContentShow] =useState(false)
const [uploadShow ,setUploadShow] =useState(false)
const [refresh ,setRefresh] =useState(false)
const [toggle, setToggle]= useState(false)
const [flag, setFlag]= useState(false)

     const reader =new FileReader();


     const editLocation = (e) => {
      e.preventDefault();
      setLocationEdit(true)
    }
    
    const editPractise = (e) => {
      e.preventDefault();
      setPractiseEdit(true)
    }

    const editSpec = (e) => {
      e.preventDefault();
      setSpecEdit(true)
    }

    const editFacility = (e) => {
      e.preventDefault();
      setFacilityEdit(true)
    }

    const editName = (e) => {
      e.preventDefault();
      setNameEdit(true)
    }

   /* const editEmail = (e) => {
      e.preventDefault();
      setEmailEdit(true)
    }*/

    const editPhone = (e) => {
      e.preventDefault();
      setPhoneEdit(true)
    }

    const editWebsite = (e) => {
      e.preventDefault();
      setWebsiteEdit(true)
    }

    const editDesc = (e) => {
      e.preventDefault();
      setDescEdit(true)
    }

    const handleRemove= async()=> {

      const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '') 

    const data = {
      photo : ''
    }
   
   
   
await axios.put(`${APIURL}/api/v1/hospital/hospital-profile/` ,data, { headers: {

    "Authorization" : 'Token '+str  }})
  .then(res=>{
  
  
  
    if(res.data.status==="success"){

    setSubmitMsg("success");
     setShow(true)
  
    }else{

      setErrorMsg(res.data.message);
              setErrorSubmit(true);
     
    }
  
  })
  .catch(err=>{
    setErrorMsg("error");
  
              setErrorSubmit(true);
  
  })
    }

     useEffect(()=>{
      const tokenString= sessionStorage.getItem('usertoken');
       
       let str= tokenString.replace(/["]+/g, '')                           
        
       
        axios.get(`${APIURL}/api/v1/hospital/hospital-profile/`, { headers: {
          
          "Authorization" : 'Token '+str  }})
        .then(res=>{
            
          if(res.data.status==="success"){
          const hospData= res.data.hospital_profile

            setPractise(hospData.practise_type)
            setLocation(hospData.location)          
            setSpec(hospData.specializations.join())
            setFacilities(hospData.facilities.join())
            setDesc(hospData.description)
            setImg(hospData.photo)
            //setEmail(hospData.primary_email)
            setPhone(hospData.mobile_number)
            setWebsite(hospData.website)
            setName(hospData.name)
          }
    
    
        })
        .catch(err=>{
    
    })
    
    
    },[refresh]);



    const LocationPopUp = (props) => {
      return(
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
              dialogClassName="modal-dialog"
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               <h4>Update Location</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
    
            <LocationUpdate />
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Close</Button>          
      
            </Modal.Footer>
          </Modal>
        )
    }
    
    
    const PractisePopUp = (props) => {
      return(
        <Modal
            {...props}
            
            aria-labelledby="example-custom-modal-styling-title"
            centered
             backdrop="static"
              keyboard={false}
              dialogClassName="modal-dialog"
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               <h4>Update Practice Type</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
    
            <PractiseUpdate handle={PractiseHandler} />
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Close</Button>          
      
            </Modal.Footer>
          </Modal>
        )
    }
    
    
    const SpecPopUp = (props) => {
      return(
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               <h4>Update Specialization</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body> 
    
            <SpecUpdate handle={SpecHandler} />
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Close</Button>          
      
            </Modal.Footer>
          </Modal>
        )
    }

    const FacilityPopUp = (props) => {
      return(
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               <h4>Update Facility</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
    
            <FacilityUpdate handle={FacilityHandler} />
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Close</Button>          
      
            </Modal.Footer>
          </Modal>
        )
    }

    const NamePopUp = (props) => {
      return(
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               <h4>Update Hospital Name</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
    
            <NameUpdate />
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Close</Button>          
      
            </Modal.Footer>
          </Modal>
        )
    }

    /*const EmailPopUp = (props) => {
      return(
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               <h4>Update Email</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
    
            <EmailUpdate handle={EmailHandler} />
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Close</Button>          
      
            </Modal.Footer>
          </Modal>
        )
    }*/

    const PhonePopUp = (props) => {
      return(
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               <h4>Update Phone</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{overflow:"visible"}}>
    
            <MobileUpdate handle={PhoneHandler} />
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Close</Button>          
      
            </Modal.Footer>
          </Modal>
        )
    }

    const WebsitePopUp = (props) => {
      return(
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               <h4>Update Website</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
    
            <WebsiteUpdate handle={WebsiteHandler} />
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Close</Button>          
      
            </Modal.Footer>
          </Modal>
        )
    }

    const DescriptionPopUp = (props) => {
      return(
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               <h4>Update Description</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
    
            <DescriptionUpdate handle={DescHandler} />
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Close</Button>          
      
            </Modal.Footer>
          </Modal>
        )
    }


    const ConfirmPopUp = ({show,onHide}) => {
      return(
      <Modal
          show
          onHide
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
           backdrop="static"
            keyboard={false}
        >
          <Modal.Header >
            <Modal.Title id="contained-modal-title-vcenter">
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4 id="confirm-reject">Updated Successfully</h4>
           
          </Modal.Body>
          <Modal.Footer>
           
             <Button variant="secondary" onClick={onHide}>Ok</Button>          
    
          </Modal.Footer>
        </Modal>
      )
    }
    
    
    const SubmitErrorPopup =(props)=>{
        
    
      return (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
           backdrop="static"
            keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            
            <h5 style={{color: "red"}}>Error in Data Submission. Please try again! </h5>
           
          </Modal.Body>
          <Modal.Footer>
           
             <Button variant="secondary" onClick={props.onHide}>Ok</Button>
    
           
           
    
          </Modal.Footer>
        </Modal>
      );
    }
    

const removePicPreview=(e)=>{
  e.preventDefault();
  setPreviewOn(false);
  setFlag(true)
  setToggle(false)
 }

const editImage = () =>{
  const fileInput = document.getElementById('imageInput')
  fileInput.click();
}


const uploadImage= e=>{

  const file=  e.target.files[0];

  if(!file){
    setUploadShow(!uploadShow)
    return false
  }
  if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
    setValidShow(!validShow)
    return false;
  }
  if(file.size>2e6){
    setSizeShow(!sizeShow)
    return false
  }

  reader.onload = (e) => {
    const img1 = new Image();
    img1.onload = () => {
      setToggle(true)
      setImg(file)
    };
    img1.onerror = () => {
      setPreviewOn(false)
      setContentShow(!contentShow)
      
      return false;
    };
    img1.src = e.target.result;
  };
  reader.readAsDataURL(file);

  const url =URL.createObjectURL(file)
  setImgURL(url)
  setPreviewOn(true)
}


const SizePopUp = (props)=> {
  return(
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <h4 id="confirm-reject">You have Exceeded the size limit 2MB</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
  
        </Modal.Footer>
      </Modal>
    )
}

const LoadPopUp = (props)=> {
  return(
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <h4 id="confirm-reject">Please select an Image</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
  
        </Modal.Footer>
      </Modal>
    )
}

const ValidPopUp = (props)=> {
  return(
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <h4 id="confirm-reject">Please select a valid Image</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
  
        </Modal.Footer>
      </Modal>
    )
}

const ContentPopUp = (props)=> {
  return(
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <h4 id="confirm-reject">Invalid Image Content</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
  
        </Modal.Footer>
      </Modal>
    )
}



const submitHandle = async() => {

  const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '') 

   let fd= new FormData()


   if(img!== null){
    fd.append('photo',img)
   }
   
   
  await axios.put(`${APIURL}/api/v1/hospital/hospital-profile/` ,fd, { headers: {

    "Authorization" : 'Token '+str  }})
  .then(res=>{
  
  
  
    if(res.data.status==="success"){

    setSubmitMsg("success");
     setShow(true)

    // setEmail() 
    }else if (res.data.status === "error") {
            
      
  
    }else{
      
      setErrorMsg(res.data.message);
              setErrorSubmit(true);
     
    }
  
  })
  .catch(err=>{
    setErrorMsg("error");
  
              setErrorSubmit(true);
  
  })


}


/*const LocationHandler = ()=>{
  setLocationEdit(false)
  setRefresh(!refresh)
}*/

const PractiseHandler = ()=>{
  setPractiseEdit(false)
  setRefresh(!refresh)
}

const SpecHandler = ()=>{
  setSpecEdit(false)
  setRefresh(!refresh)
}

const FacilityHandler = ()=>{
  setFacilityEdit(false)
  setRefresh(!refresh)
}
/*const EmailHandler = ()=>{
  setEmailEdit(false)
  setRefresh(!refresh)
}
const NameHandler = ()=>{
  setNameEdit(false)
  setRefresh(!refresh)
}*/
const PhoneHandler = ()=>{
  setPhoneEdit(false)
  setRefresh(!refresh)
}
const WebsiteHandler = ()=>{
  setWebsiteEdit(false)
  setRefresh(!refresh)
}
const DescHandler = ()=>{
  setDescEdit(false)
  setRefresh(!refresh)
}

    
    return (
      <div className="flex-col profile-holder">
            <h2 className="title-of-tasks"><i style={{marginRight:'4px'}} className="fas fa-user-circle"></i>Edit Profile</h2>
            {previewOn=== false ?
            <div>
            <img alt='Profile Picture' className={img!==''&& img!==null ? "profile-picture":"no-image"} src={flag===false? img: props.image } />
            <input type="file" id="imageInput" hidden="hidden" onChange={uploadImage}/>
             <i style={{cursor:'pointer'}}  onClick={editImage} id={img!==''?'cam-id':'icon-pos'} className ="fas fa-camera fa-lg"></i>         
            </div>

              :<div> <div onClick={removePicPreview}><i className="fas fa-times-circle close-img-btn"></i></div>
              <img alt='Profile Picture' src={imgURL} className="avatar"/>
              </div> }
              <br/>
              <div className='flex-row'>
              <button onClick={submitHandle} disabled={toggle===false} className="btn btn-primary btn-col" >Update Picture</button>
              <button style={{marginLeft:'10px'}} disabled={img==='https://image.shutterstock.com/image-vector/no-image-available-icon-template-600w-1036735675.jpg'||img===null}  onClick={handleRemove} className="btn btn-primary btn-col" >Remove Picture</button>
              </div>
              

        <div className='flex-col details-holder'>
      
              <div><label className='bold-label'>Hospital Name : </label><label className='bio-data'>{name===''?<span className='text-danger'>No Data</span>:name}</label>
                {/* <label onClick={editName}  style={{color:"blue" ,cursor:"pointer"}}> (change)</label> */}
              </div>

              {/*<div><label className='bold-label'>Email : </label><label className='bio-data'>{email===''?<span className='text-danger'>No Data</span>:email}</label>
                <label onClick={editEmail}  style={{color:"blue" ,cursor:"pointer"}}> (change)</label>
            </div>*/}

              <div ><label className='bold-label'>Phone : </label><label className='bio-data'>{phone===''?<span className='text-danger'>No Data</span>:phone}</label>
                <label onClick={editPhone}  style={{color:"blue" ,cursor:"pointer"}}> (change)</label>
              </div>

              <div><label className='bold-label'>Location : </label><label className='bio-data'>{location===''?<span className='text-danger'>No Data</span>:location}</label>
                <label onClick={editLocation}  style={{color:"blue" ,cursor:"pointer"}}> (change)</label>
              </div>
         
              <div><label className='bold-label'>Practice Type : </label><label className='bio-data'>{practise===''?<span className='text-danger'>No Data</span>:practise}</label>
                <label onClick={editPractise}  style={{color:"blue" ,cursor:"pointer"}}> (change)</label>
              </div>
                  
              <div ><label className='bold-label'>Specializations : </label><label className='bio-data'>{spec===[]?<span className='text-danger'>No Data</span>:spec}</label>
                <label onClick={editSpec}  style={{color:"blue" ,cursor:"pointer"}}> (change)</label>
              </div>
                     
              <div ><label className='bold-label'>Facilities : </label><label className='bio-data'>{facilities===[]?<span className='text-danger'>No Data</span>:facilities}</label>
                <label onClick={editFacility}  style={{color:"blue" ,cursor:"pointer"}}> (change)</label>
              </div>

              <div ><label className='bold-label'>Website : </label><label className='bio-data'>{website===''?<span className='text-danger'>No Data</span>:website}</label>
                <label onClick={editWebsite}  style={{color:"blue" ,cursor:"pointer"}}> (change)</label>
              </div>
          
            <div><label className='bold-label'>Description : </label><label className='bio-data'>{desc===''?<span className='text-danger'>No Data</span>:desc}</label>
                <label onClick={editDesc}  style={{color:"blue" ,cursor:"pointer"}}> (change)</label>
              </div>
            
        </div>                                 {
         submitMsg==="success" ?
        <ConfirmPopUp
            show={show}
            onHide= { ()=>{
              setShow(false)     
              setSubmitMsg('');
              setPreviewOn(false)
              setRefresh(!refresh)
            } } />
            : null
      }

{errorMsg!=='' ?
    <SubmitErrorPopup
        show={errorSubmit}
        onHide={() =>  {setErrorSubmit(false);
                       setErrorMsg('');
                       setPreviewOn(false)}}
       
      />: ''
    }

    {
      <LocationPopUp 
      show={locationEdit}
      onHide={() => {
        setLocationEdit(false)
      }} />

    }

    {
      <PractisePopUp
      show={practiseEdit}
      onHide={()=>{
        setPractiseEdit(false)
      }} />
    }

{
      <SpecPopUp
      show={specEdit}
      onHide={()=>{
        setSpecEdit(false)
        
      }} />
    }

{
      <FacilityPopUp
      show={facilityEdit}
      onHide={()=>{
        setFacilityEdit(false)
        
      }} />
    }

{
      <NamePopUp
      show={nameEdit}
      onHide={()=>{
        setNameEdit(false)
        
      }} />
    }

{/*{
      <EmailPopUp
      show={emailEdit}
      onHide={()=>{
        setEmailEdit(false)
        
      }} />
    }*/}

{
      <PhonePopUp
      show={phoneEdit}
      onHide={()=>{
        setPhoneEdit(false)
        
      }} />
    }

{
      <WebsitePopUp
      show={websiteEdit}
      onHide={()=>{
        setWebsiteEdit(false)
        
      }} />
    }

{
      <DescriptionPopUp
      show={descEdit}
      onHide={()=>{
        setDescEdit(false)
        
      }} />
    }

{
        sizeShow ? 
        <SizePopUp
        show={sizeShow}
        onHide={()=>{
          setSizeShow(false)
        }} /> : null
      } 

{
        uploadShow ? 
        <LoadPopUp
        show={uploadShow}
        onHide={()=>{
          setUploadShow(false)
        }} /> : null
      } 

{
        validShow ? 
        <ValidPopUp
        show={validShow}
        onHide={()=>{
          setValidShow(false)
        }} /> : null
      } 

{
        contentShow ? 
        <ContentPopUp
        show={contentShow}
        onHide={()=>{
          setContentShow(false)
        }} /> : null
      } 



        </div>
    )
}

export default EditProfile
