import React, {useState,useEffect} from "react";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import "./service.css";
//import Select from 'react-select';
import {Tooltip} from 'react-bootstrap';
import {OverlayTrigger} from 'react-bootstrap';
import axios from "axios";
import {APIURL} from "../../Global";
function ServiceHistoryBasic() {

const [render,]=useState(true);
  const [,setGetError]=useState('');
const [tableData,setTableData]=useState([]);
 //const [currentIndex,setCurrentIndex]=useState('');
  //const [,setCurrentColumn] = useState('');
  //const [display,setDisplay]=useState(false);

  const [search,]=useState("");
  const [,setOptions] = useState([]);
 const [,setOptionsDisplay] = useState([]);



useEffect(()=>{
  const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;
          
  
  
    axios.get(`${APIURL}/api/v1/hospital/service-request-order/`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
     

   
   
      if(res.data.status==="success"){
        
        let dataList= res.data.data;

        
        let updatedList=[];
        let filteredList=dataList.filter((item,index)=>{
          return (item.status.toLowerCase()==="completed" || item.status.toLowerCase()==="rejected" || item.status.toLowerCase()==="cancelled")
        })
        updatedList= filteredList.map((item,index)=>{
          let status;
          let statusObj;
          if(item.status.toLowerCase()==="completed"){
            status="completed";
            statusObj={value: "completed", label: "Completed"}
          }
          else if(item.status.toLowerCase()==="rejected"){
            status="rejected";
            statusObj={value: "rejected", label: "Rejected"}
          }
           else if(item.status.toLowerCase()==="cancelled"){
            status="cancelled";
            statusObj={value: "cancelled", label: "Cancelled"}
          }
         
   let priority;
   let priorityObj;
       if(item.priority.toLowerCase()==="critical"){
            priority="critical";
            priorityObj= {value: 'critical' , label: 'Critical'}
          }
          else if(item.priority.toLowerCase()==="very high"){
            priority="very high";
            priorityObj= { value: 'very high' , label: 'Very High'}
          }
          else if(item.priority.toLowerCase()==="high"){
            priority="high";
            priorityObj= { value: 'high' , label: 'High'}
          }
          
          else  if(item.priority.toLowerCase()==="medium"){
            priority="medium";
            priorityObj=  { value: 'medium' , label: 'Medium'}
          }
          else  if(item.priority.toLowerCase()==="low"){
            priority="low";
            priorityObj=  { value: 'low' , label: 'Low'}
          }

                  return({
                  name: item.patient_name,
                  date: item.order_date,
                  order: item.service_title,
                  priority: priority,
                  priorityObj: priorityObj,
                  ref:item.order_id,
                  contact_number: item.contact_number,
                  status:status,
                  statusObj: statusObj ,
                  patient_id:item.patient_id,
                  address: item.address,
                  details: item.details,
                  required_date: item.due_date,
                  assigned_to: item.assignee!==null ? item.assignee : null,
                  remarks: item.remarks? item.remarks :null
                }
              )
        })
        
         setTableData(updatedList);
      }
 
        else{
        setGetError(res.data.message);
      }
      


    })
    .catch(err=>{

 setGetError("Error in fetching data");
})


},[]);


useEffect(()=>{

    const tokenString= sessionStorage.getItem('usertoken');
     
     let str= tokenString.replace(/["]+/g, '')  
            
      
      
      axios.get(`${APIURL}/api/v1/hospital/service-request-order/search/?q=${search}`, { headers: {
        
        "Authorization" : 'Token '+str  }})
      .then(res=>{
       const assignee_List=[]
        if(res.data.status==="success"){
        
          const assigneeList=res.data.data
          assigneeList.map((person, index) => (assignee_List.push(person["id"])))
        
        setOptions(assigneeList);
        setOptionsDisplay(assignee_List)
        
  
        }
  
  
      })
      .catch(err=>{
  
  })
  
  
  },[search]);

useEffect(()=>{
    setTableData(tableData)
  },[render,tableData]);
 /*const statusOptions = [ {value: 'new' , label: 'New'},
       { value: 'ongoing' , label: 'Ongoing'},
      { value: 'accepted' , label: 'Accepted'},

       
    ];
    const priorityOptions = [ {value: 'critical' , label: 'Critical'},
    { value: 'very high' , label: 'Very High'},
       { value: 'high' , label: 'High'},
      { value: 'medium' , label: 'Medium'},
       { value: 'low' , label: 'Low'},

       
    ];*/

    
/*const renderRemarks3=(cellInfo)=> {

  return (<textarea 
         type="text" 
         className="form-group" 
         value= {tableData[cellInfo.index][cellInfo.column.id]}
         name="remarks" 
          onChange={(e)=>{

           e.preventDefault();
           const list = tableData;
          list[cellInfo.index].remarks = e.target.value;
          
          
          setTableData(list);
          setRender(!render);
   } }
     autoComplete="off"
         /> )
 
}*/
/*const renderAssigned2=(cellInfo)=> {
 return (<div  >
      
      <input
        style={{ backgroundColor: "#fafafa" }}
        value= {tableData[cellInfo.index][cellInfo.column.id]}
        className="form-control"        
       name="assignee"

        onChange={(e)=>{

           e.preventDefault();


                setCurrentIndex(cellInfo.index);
                             setCurrentColumn(cellInfo.column.id);
                               
                               setDisplay(true);
 

 

  setSearch(e.target.value);

  


 
  const list = tableData;
          list[cellInfo.index].assigned_to = e.target.value;
          
          
          setTableData(list);
   } }
     autoComplete="off"
               
      />

        {currentIndex === cellInfo.index && cellInfo.column.id=== "assigned_to" && display && (<div className="suggestions-box suggestions-assignee-box">
             {options.map((item,i)=>{
         
    return(
      <div className="scan-item" 
          
            key={i} 
            onClick={(e)=>{ e.preventDefault();
             
              setRender(!render);
              
                                      const List = tableData;
                                      List[cellInfo.index].assigned_to= item.id;
                                       
                                 
                                      setTableData(List);
                                      setDisplay(false);

                                      }}
            >
            <div className="search-item each-option-suggestion">
      <h6 className="">ID: {item.id} /  {item.name}</h6>
       <h6 className="">{item.email} </h6>
       <h6 className=""> {item.mobile_number}</h6>
       </div>

      </div>
      )
  }) 
           }

        </div>)
      }
      

      </div>
        

    )

      }*/
    
    
const renderAssigned=(cellInfo)=> {


  return ( <div > {tableData[cellInfo.index][cellInfo.column.id]}</div>)
 
}
/*const renderRemarks2=(cellInfo)=> {

 if(!tableData[cellInfo.index][cellInfo.column.id]){
  return (<textarea type="text" className="form-group " /> )
 }
 else{
  return ( <div > {tableData[cellInfo.index][cellInfo.column.id]}</div>)
 }
}*/
/*const renderInput=(cellInfo)=> {


  return (<input type="text" className="form-group" /> )

}*/
/*const renderRemarks=(cellInfo)=> {

 
  return (<textarea type="text" className="text-area-remarks " value= {tableData[cellInfo.index][cellInfo.column.id]} />)
 }*/
const renderTable=(cellInfo)=> {
  
    return (

      <div > {tableData[cellInfo.index][cellInfo.column.id]}</div>
      
    );
  }
   const renderId=(cellInfo)=> {
  if(tableData[cellInfo.index][cellInfo.column.id]){
    return (

      <div  className="fixed-col"> {tableData[cellInfo.index][cellInfo.column.id]}</div>
      
    );
  }
  else{
    return null
  }
  }
  const renderService=(cellInfo)=> {
  
    return (
     
      <div > <span style={{margin:"10px"}}> 
          {tableData[cellInfo.index][cellInfo.column.id]}
       </span>  
       <OverlayTrigger trigger='click' rootClose placement="top" overlay={<Tooltip className='my-tooltip' id="button-tooltip">
          Details: {tableData[cellInfo.index].details} <br/>
           
       
          </Tooltip> }><i style={{color:'var(--theme-blue)'}} className="fas fa-info-circle"></i></OverlayTrigger> 
       </div>
      
    );
  }
  /*const renderContact=(cellInfo)=> {
  
    return (
     
      <div > <span style={{margin:"10px"}}> 
          {tableData[cellInfo.index][cellInfo.column.id]}
       </span>  
       <OverlayTrigger trigger='click' rootClose placement="top" overlay={<Tooltip className='my-tooltip' id="button-tooltip">
          
          
          Address: {tableData[cellInfo.index].address} <br/>
          </Tooltip> }><i style={{color:'var(--theme-blue)'}} className="fas fa-info-circle"></i></OverlayTrigger> 
       </div>
      
    );
  }*/
/*const renderDetails=(cellInfo)=> {
  
    return (

     <div > <p > {tableData[cellInfo.index][cellInfo.column.id]}</p></div>
      
    );
  }*/






/*const renderStatus2=(cellInfo)=> {
  
    return (
<>
       <Select
       value={tableData[cellInfo.index].statusObj}
       onChange={(data)=>{
        const List = tableData;
       

             List[cellInfo.index].statusObj = data;
            List[cellInfo.index].status=data.value;
             setTableData(List);
             setRender(!render);
             
      }}
        options={statusOptions}
        className= {`status-select ${tableData[cellInfo.index].status ==="new" ? "new-status" : 
                         tableData[cellInfo.index].status ==="accepted" ? "accepted-status" : 
                         tableData[cellInfo.index].status ==="ongoing" ? "ongoing-status" :
                         ""}`}  
      />
      </>
    );
  }*/

  /*const renderPriority2=(cellInfo)=> {
  
    return (
<>
       <Select
       value={tableData[cellInfo.index].priorityObj}
       onChange={(data)=>{
        const List = tableData;
       

             List[cellInfo.index].priorityObj = data;
            List[cellInfo.index].priority = data.value;
             setTableData(List);
             setRender(!render);
             
      }}
        options={priorityOptions}
        className="status-select"
        className= {`status-select ${tableData[cellInfo.index].priority ==="critical" ? "critical-priority" : 
                         tableData[cellInfo.index].priority ==="very high" ? "very-high-priority" : 
                         tableData[cellInfo.index].priority ==="high" ? "high-priority" : 
                         tableData[cellInfo.index].priority ==="medium" ? "medium-priority" :
                         tableData[cellInfo.index].priority ==="low" ? "low-priority" :
                         ""}`}  
        
      />
      </>
    );
  }*/

const renderStatus=(cellInfo)=> {
  if(tableData[cellInfo.index].status==="completed"){
   return (

      <div ><div className="completed-status-2 caps"> {tableData[cellInfo.index][cellInfo.column.id].value }</div></div>
      
    );
  }
  else if(tableData[cellInfo.index].status==="rejected"){
    return (

      <div ><div className="rejected-status-2 caps"> {tableData[cellInfo.index][cellInfo.column.id].value }</div></div>
      
    );
  }
  else if(tableData[cellInfo.index].status==="cancelled"){
    return (

      <div ><div className="cancelled-status-2 caps"> {tableData[cellInfo.index][cellInfo.column.id].value }</div></div>
      
    );
  }
  
  }




const renderPriority=(cellInfo)=> {
  if(tableData[cellInfo.index].priority==="critical"){
    return (

      <div > <div className="priority critical-priority-2 caps">{tableData[cellInfo.index][cellInfo.column.id].value }</div></div>
      
    );
  }
  else if(tableData[cellInfo.index].priority==="very high"){
    return (

      <div ><div className="priority very-high-priority-2 caps"> {tableData[cellInfo.index][cellInfo.column.id].value}</div></div>
      
    );
  }
  else if(tableData[cellInfo.index].priority==="high"){
    return (

      <div ><div className="priority high-priority-2 caps"> {tableData[cellInfo.index][cellInfo.column.id].value }</div></div>
      
    );
  }
  if(tableData[cellInfo.index].priority==="medium"){
    return (

      <div ><div className="priority medium-priority-2 caps"> {tableData[cellInfo.index][cellInfo.column.id].value}</div></div>
      
    );
  }
  if(tableData[cellInfo.index].priority==="low"){
    return (

      <div ><div className="priority low-priority-2 caps"> {tableData[cellInfo.index][cellInfo.column.id].value}</div></div>
      
    );
  }
  

  }


/**************removed***details column*******************
{
  Header: "Details",
  accessor: "details",
  Cell: renderDetails,
   sortable:true,
    filterable:true,
   width:200

},



********************************/


/*const saveChanges=(index)=>{
  const rowToUpdate = tableData.filter((elt,i)=>{
    return i=== index
  })
 

callSave(rowToUpdate[0]);

}*/
/*const callSave=(rowToUpdate)=>{

  const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;
        const order_id=rowToUpdate.ref;
        const assignee=rowToUpdate.assigned_to;
        const remarks=rowToUpdate.remarks;
        const status= rowToUpdate.status;
       const priority= rowToUpdate.priority;
   const dataToUpdate={
    order_id,
    assignee,
    remarks,
    status,
    priority

   }
   
    axios.post(`${APIURL}/api/v1/hospital/service-request-order/`,dataToUpdate, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
        
      if(res.data.status==="success"){
    
      //setSubmitMsg("Successfully updated!");
      //setModalShow(true);  
   }
       else{
        // setGetError(res.data.message);
        //setErrorSubmit(true); 
      }
    


    })
    .catch(err=>{
  
   setGetError("Error in fetching data");
})

}*/


const renderDate=(cellInfo)=> {

    let dateString = tableData[cellInfo.index][cellInfo.column.id];

let dateObject = new Date(dateString);

const updatedDate = dateObject.toString();
    return (<div >
        <h6> {updatedDate.slice(4,16)}  </h6>
       <h6> {updatedDate.slice(16,24)}</h6>
     </div>)
   }


// Define a default UI for filtering
/***********************
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length

  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}
*******************/
const columns = [
{
  Header: "Id",
  accessor: "ref",
  Cell: renderId,
    sortable:true,
    filterable:true,

    filterMethod:(filter, row, column) => {
    const id = filter.pivotId || filter.id
    return row[id] !== undefined ? String(row[id]).toLowerCase().startsWith(filter.value.toLowerCase()) : true
  },     
   width:60

},

{
  Header: "Service request",
  accessor: "order",
  Cell: renderService,
   sortable:true,
    filterable:true,
    filterMethod:(filter, row, column) => {
    const id = filter.pivotId || filter.id
    return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
  },     
   width:210

},

{
  Header: "Requested Date",
  accessor: "date",
  Cell: renderDate,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },

  width:150,
 filterable:true,
   sortable:true

},
{
  Header: <><h6>Patient Name</h6>
           </>,
  accessor: "name",
  Cell: renderTable,
    sortable:true,
    filterable:true,
     filterMethod:(filter, row, column) => {
    const id = filter.pivotId || filter.id
    return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
  },         
   width:180

},
{
  Header: "Priority",
  accessor: "priorityObj",
  Cell: renderPriority,
   sortable:true,
   filterable:true,
   filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                      return true;
                    }
                    if (filter.value === "very high") {
                      return row[filter.id].value === "very high";
                    }
                    if (filter.value === "high") {
                      return row[filter.id].value === "high";
                    }
                    
                     if (filter.value === "critical") {
                      return row[filter.id].value === "critical";
                    }
                     if (filter.value === "medium") {
                      return row[filter.id].value === "medium";
                    }
                     if (filter.value === "low") {
                      return row[filter.id].value === "low";
                    }
                  },
                  Filter: ({ filter, onChange }) =>
                    <select
                      onChange={event => onChange(event.target.value)}
                      style={{ width: "100%" }}
                      value={filter ? filter.value : "all"}
                    >
                      <option value="all">Show All</option>
                       <option value="critical">Critical</option>
                      <option value="very high">Very High</option>
                      <option value="high">High</option>
                      <option value="medium">Medium</option>
                      <option value="low">Low</option>
                    </select>,
                   
              
   width:120

},


{
  Header: <><h6>Status</h6>
            </>,
  accessor: "statusObj",
  Cell: renderStatus,
   sortable:true,
    filterable:true,
   filterMethod: (filter, row) => {
    
                    if (filter.value === "all") {
                      return true;
                    }
                    if (filter.value.toLowerCase() === "completed") {
                      return row[filter.id].value ==="completed";
                    }
                     if (filter.value.toLowerCase() === "rejected") {
                      return row[filter.id].value === "rejected";
                    }
                    if (filter.value.toLowerCase() === "cancelled") {
                      return row[filter.id].value === "cancelled";
                    }
                     
                   
                     
                  },
                  Filter: ({ filter, onChange }) =>
                    <select
                      onChange={event => onChange(event.target.value)}
                      style={{ width: "100%" }}
                      value={filter ? filter.value : "all"}
                    >
                      <option value="all">Show All</option>
                       <option value="completed">Completed</option>
                       <option value="rejected">Rejected</option>
                        <option value="cancelled">Cancelled</option>
                                          
                    </select>,
                     
   width:120

},
{
  Header: <><h6>Assigned to</h6>
         </>,
  accessor: "assigned_to",
  Cell: renderAssigned,
   style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },

   sortable:true,
   filterable:true,
   filterMethod:(filter, row, column) => {
    const id = filter.pivotId || filter.id
    return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
  },     
   width:175

}






]

  const displayTable=<ReactTable 
         columns={columns}
         data={tableData}
         defaultPageSize = {5}
         className="service-req-table SR-basic-table"
         resizable
                  
                
         >

         </ReactTable> 
  return (<>
  
      <div className="text-center">
        <div className="service-table-holder flex-col"> {displayTable}
        </div>
    
     
    
    </div>
    <br/>
    <br/>
    
  
    </>
  );
}

export default ServiceHistoryBasic;
